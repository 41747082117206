import {
    PANEL_MANAGEMENT_INITIAL,
    PANEL_MANAGEMENT_REQUEST,
    PANEL_MANAGEMENT_SUCCESS,
    PANEL_MANAGEMENT_FAILURE,
    PanelManagementAction
} from './PanelManagementAction';
import { PanelListStoreState } from './PanelManagementState';

export const defaultState: PanelListStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function panelManagementReducer(
    state: PanelListStoreState = defaultState,
    action: PanelManagementAction
): PanelListStoreState {
    switch (action.type) {
        case PANEL_MANAGEMENT_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case PANEL_MANAGEMENT_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case PANEL_MANAGEMENT_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case PANEL_MANAGEMENT_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
