import React, { useEffect, Dispatch, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { Descriptions, Collapse } from 'antd';
import { ColumnsType } from 'antd/es/table';
import RootStoreState from 'rootStore';
import AlertComponent from 'components/AlertComponent';
import { ModalViewImage } from 'components/ModalComponent';
import AppTable from 'components/AppTableComponent';
import { GetMethodLoading } from 'components/LoadingComponent';
import { formatCurrency, defaultDateFormatWithoutTime, formatDate, translateGender, calculateAgeFromBirthday, DownloadBlob } from 'common/CommonUtils';
import { Images } from 'themes';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { OrderDetailDownloadAction, doOrderDetailDownloadRequest, doOrderDetailDownloadInitial } from 'features/order/OrderDetailDownload/OrderDetailDownloadAction';
import { OrderDetailAction, doOrderDetailInitial, doOrderDetailRequest } from './OrderDetailAction';
import { OrderDetailItems, OrderHistory, HttpError, OrderDetailDownload, OrderDetail } from 'data/Models';
import './index.css'
import { getHsFeeId } from 'common/constans';

export interface TableProps {
    header: string;
    columns: ColumnsType<any>;
    dataSource: any;
}
const { Panel } = Collapse;
const TEXT = {
    PaymentMethod: {
        EWallet: 'E-Wallet',
        CreditCard: 'Credit Card',
        VirtualAccount: 'Virtual Account',
    },
    OrderDetail: {
        Header: 'Order Detail',
        OrderID: 'Order ID',
        OrderDate: 'Order Date',
        OrderNumber: 'Order Number',
        Region: 'Region',
        Branch: 'Branch',
        LabNumber: 'Lab Number',
        AppointmentDate: 'Appointment Date and Time',
        VisitDate: 'Visit Date',
        BookingCode: 'Booking Code',
        ECode: 'E-code',
        GetTestResult: 'Receive Test Result at Branch',
        HomeServiceAddress: 'Home Service Address',
        NoteHS: 'Note Address',
        ServiceType: 'Service Type',
    },
    RescheduleInformation: {
        Header: 'Reschedule Information',
        DateTime: 'Reschedule Date and Time',
        ChangesDate: 'Changes Date and Time',
        StatusReschedule: 'Status Reschedule',
        ReasonReschedule: 'Reason Reschedule'
    },
    AgentDetail: {
        Header: 'Agent Detail',
        AgentName: 'Agent Name',
        AgentId: 'Agent Id',
        Scheduled: 'Scheduled',
        OnProcess: 'On Process',
        Done: 'Done',
        Completed: 'Completed',
        Cancelled: 'Cancelled'
    },
    ReferenceDetails: {
        Header: 'Reference Details',
        ReferralType: 'Referral Type',
        DoctorId: 'Doctor Id',
        DoctorName: 'Sender Name',
        DoctorSpecialty: 'Sender Specialty',
        DoctorCity: 'Sender City',
        PracticeSite: 'Sender Practice Site',
        Attachment: 'Attachment',
    },
    CustomerDetails: {
        Header: 'Customer Details',
        CustomerID: 'Customer ID',
        CustomerTitle: 'Customer Title',
        CustomerName: 'Customer Name',
        CustomerPhoneNumber: 'Customer Phone Number',
        CustomerAge: 'Customer Age',
        CustomerSex: 'Customer Sex',
        CustomerEmail: 'Customer Email',
        CustomerAddress: 'Customer Address',
        CustomerCity: 'Customer City',
    },
    PaymentDetails: {
        Header: 'Payment Details',
        ReferenceID: 'Reference ID',
        PaymentChannel: 'Payment Channel',
        CardNumber: 'Card Number',
        VANumber: 'VA Number',
        WalletName: 'Wallet Name',
        AmountOriginal: 'Amount Original',
        Discount: 'Discount',
        AmountActual: 'Amount Actual',
        CreatedAt: 'Created At',
        ExpiredAt: 'Expired At',
        ConfirmedAt: 'Confirmed At',
        PaymentStatus: 'Payment Status',
        HomeServiceCharge: 'Home Service Charge',
        ServiceCharge: 'Service Charge',
        APDFee: 'APD Fee',
        TransportFee: 'Transport Fee',
        PPN: 'Total PPN',
        PPNFree: 'PPN Free',
        PPNLevied: 'PPN Levied',
        TotalPayment: 'Total Payment',
    },
    OrderItem: {
        Header: 'Order Item',
        No: 'No',
        ItemID: 'Item ID',
        ItemName: 'Item Name',
        ItemType: 'Item Type',
        ContractID: 'Contract ID',
        DiscountID: 'Discount ID',
        NetPrice: 'Net Price',
    },
    OrderHistory: {
        Header: 'Order History',
        No: 'No',
        HistoryID: 'History ID',
        OrderStatus: 'Order Status',
        DateTime: 'Date Time',
        Note: 'Note',
        HsDate: 'HS Date',
        AgentName: 'Agent Name',
        AgentId: 'Agent Id',
    }
};
const pdfType = 'application/pdf';

const getReceiveTestResultAtBranchValue = (orderDetailData: OrderDetail | null | undefined) => {
    const value = orderDetailData?.appointment?.hardcopy_result;
    if (value === null || value === undefined) return '-';

    return value ? 'Yes' : 'No'
}

const serviceTypeDesc = {
    homeService: 'Home Service',
    goToBranch: 'Visit Branch'
}

const OrderDetailView: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const orderDetailAction = useDispatch<Dispatch<OrderDetailAction>>();
    const orderDetailState = useSelector((state: RootStoreState) => state.orderDetail);
    const orderDetailData = orderDetailState.data;
    const orderDetailDownloadAction = useDispatch<Dispatch<OrderDetailDownloadAction>>();
    const orderDetailDownloadState = useSelector((state: RootStoreState) => state.orderDetailDownload);

    const [error, setError] = useState<HttpError | null>(null);
    const [showModalViewImage, setShowModalViewImage] = useState<boolean>(false);
    const [proofUrlData, setProofUrlData] = useState<OrderDetailDownload | null>(null);
    const needDetailDownload = orderDetailData?.referral?.type === 'UPLOAD' && orderDetailData?.referral?.proof_url;
    const showPDF = proofUrlData?.blob.type === pdfType;
    const imgUrl = proofUrlData ? window.URL.createObjectURL(new Blob([proofUrlData?.blob])) : undefined;

    useEffect(() => {
        orderDetailAction(doOrderDetailRequest(orderId));
    }, [orderId, orderDetailAction]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
        if (needDetailDownload) {
            orderDetailDownloadAction(doOrderDetailDownloadRequest(orderId))
        } else if (orderDetailState.error) {
            orderDetailAction(doOrderDetailInitial());
            setError(orderDetailState.error);
        }
    }, [orderDetailAction, orderDetailState, needDetailDownload, orderDetailDownloadAction, orderId]);
    useEffect(() => {
        if (orderDetailDownloadState.data) {
            setProofUrlData(orderDetailDownloadState.data)
        } else if (orderDetailDownloadState.error) {
            orderDetailDownloadAction(doOrderDetailDownloadInitial());
            setProofUrlData(null);
            setError(orderDetailDownloadState.error);
        }
    }, [orderDetailDownloadAction, orderDetailDownloadState]);
    const loading: boolean = profileState.onRequest || orderDetailState.onRequest;
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const handleAttachment = (showPDF: boolean) => {
        if (showPDF) {
            DownloadBlob(proofUrlData, `${orderId}.pdf`)
        } else {
            setShowModalViewImage(true);
        }
    };

    const paymentCalculation = (data?: OrderDetail) => {

        if (data?.home_service.service_type) {

            if (data?.items.filter(a => a.product.type === 'SERVICE').length > 0) {
                const homeServiceFee = getHsFeeId('hs_fee', data.items);
                const apdFee = getHsFeeId('apd', data.items)
                const transportationFee = getHsFeeId('transport', data.items)
                const totalAll = homeServiceFee + apdFee + transportationFee;


                return {
                    homeServiceFee: formatCurrency(homeServiceFee),
                    apdFee: formatCurrency(apdFee),
                    transportationFee: formatCurrency(transportationFee)
                };
            } else {

                return {
                    homeServiceFee: formatCurrency(0),
                    apdFee: formatCurrency(0),
                    transportationFee: formatCurrency(0)
                };

            }
        } else {

            return {
                homeServiceFee: formatCurrency(0),
                apdFee: formatCurrency(0),
                transportationFee: formatCurrency(0)
            };

        }

    }



    const orderDetailDesc = [
        {
            label: TEXT.OrderDetail.OrderID,
            content: orderDetailData?.id,
        },
        {
            label: TEXT.OrderDetail.OrderDate,
            content: formatDate(orderDetailData?.created_at),
        },
        {
            label: TEXT.OrderDetail.OrderNumber,
            content: orderDetailData?.order_number,
        },
        {
            label: TEXT.OrderDetail.Region,
            content: orderDetailData?.appointment?.region?.name,
        },
        {
            label: TEXT.OrderDetail.Branch,
            content: orderDetailData?.appointment?.outlet.name,
        },
        {
            label: TEXT.OrderDetail.ServiceType,
            content: orderDetailData?.home_service.service_type ? serviceTypeDesc.homeService : serviceTypeDesc.goToBranch,
        },
        {
            label: TEXT.OrderDetail.LabNumber,
            content: null,
        },
        {
            label: TEXT.OrderDetail.AppointmentDate,
            content: formatDate(orderDetailData?.appointment?.date, defaultDateFormatWithoutTime),
        },
        {
            label: TEXT.OrderDetail.HomeServiceAddress,
            content: orderDetailData?.home_service?.address_hs === '' ? '-' : orderDetailData?.home_service?.address_hs,
        },
        {
            label: TEXT.OrderDetail.NoteHS,
            content: orderDetailData?.home_service?.note_hs === '' ? '-' : orderDetailData?.home_service?.note_hs,
        },
        {
            label: TEXT.OrderDetail.VisitDate,
            content: null,
        },
        {
            label: TEXT.OrderDetail.BookingCode,
            content: orderDetailData?.booking_code,
        },
        {
            label: TEXT.OrderDetail.ECode,
            content: orderDetailData?.discounts[0]?.code ?? '-',
        },
        {
            label: TEXT.OrderDetail.GetTestResult,
            content: getReceiveTestResultAtBranchValue(orderDetailData)
        },
    ];
    const agentDetailDesc = [
        {
            label: TEXT.AgentDetail.AgentName,
            content: (orderDetailData?.agent) ? orderDetailData?.agent.agent_name : '-'
        },
        {
            label: TEXT.AgentDetail.AgentId,
            content: (orderDetailData?.agent) ? orderDetailData?.agent.agent_id : '-'
        },
        {
            label: TEXT.AgentDetail.Scheduled,
            content: (orderDetailData?.agent) ? orderDetailData?.agent.scheduled_date_time : '-'
        },
        {
            label: TEXT.AgentDetail.OnProcess,
            content: (orderDetailData?.agent) ? orderDetailData?.agent.on_process_date_time : '-'
        },
        {
            label: TEXT.AgentDetail.Done,
            content: (orderDetailData?.agent) ? orderDetailData?.agent.done_date_time : '-'
        },
        {
            label: TEXT.AgentDetail.Completed,
            content: (orderDetailData?.agent) ? orderDetailData?.agent.completed_date_time : '-'
        },
        {
            label: TEXT.AgentDetail.Cancelled,
            content: (orderDetailData?.agent) ? orderDetailData?.agent.cancelled_date_time : '-'
        },
    ]
    const rescheduleInformationDesc = [
        {
            label: TEXT.RescheduleInformation.DateTime,
            content: (orderDetailData?.reschedule) ? orderDetailData?.reschedule.reschedule_date_time : '-'
        },
        {
            label: TEXT.RescheduleInformation.ChangesDate,
            content: (orderDetailData?.reschedule) ? orderDetailData?.reschedule.changes_date_time : '-'
        },
        {
            label: TEXT.RescheduleInformation.StatusReschedule,
            content: (orderDetailData?.reschedule) ? orderDetailData?.reschedule.status_reschedule : '-'
        },
        {
            label: TEXT.RescheduleInformation.ReasonReschedule,
            content: (orderDetailData?.reschedule) ? orderDetailData?.reschedule.reason_reschedule : '-'
        }

    ]
    const referenceDetailsDesc = [
        {
            label: TEXT.ReferenceDetails.DoctorId,
            content: orderDetailData?.referral?.doctor?.id,
        },
        {
            label: TEXT.ReferenceDetails.DoctorName,
            content: orderDetailData?.referral?.doctor?.name,
        },
        {
            label: TEXT.ReferenceDetails.DoctorSpecialty,
            content: orderDetailData?.referral?.doctor?.speciality_id,
        },
        {
            label: TEXT.ReferenceDetails.DoctorCity,
            content: orderDetailData?.referral?.city.name,
        },
        {
            label: TEXT.ReferenceDetails.PracticeSite,
            content: orderDetailData?.referral?.address,
        },
        {
            label: TEXT.ReferenceDetails.ReferralType,
            content: orderDetailData?.referral?.type,
        },
        {
            label: TEXT.ReferenceDetails.Attachment,
            content: proofUrlData && (
                <div className={'detailsAttachmentWrapper'}>
                    <img
                        className={'referenceDetailsAttachment'}
                        src={showPDF ? Images.DefaultPDF : imgUrl}
                        alt="reference Details Attachment"
                    />
                    <div
                        className={'zoomAttachment'}
                        onClick={() => handleAttachment(showPDF)}
                    >
                        <img
                            src={showPDF ? Images.DownloadWhite : Images.Zoom}
                            alt="zoom"
                        />
                    </div>
                </div>
            ),
        },
    ];
    const customerDetailsDesc = [
        {
            label: TEXT.CustomerDetails.CustomerID,
            content: orderDetailData?.customer?.id,
        },
        {
            label: TEXT.CustomerDetails.CustomerTitle,
            content: orderDetailData?.customer?.title,
        },
        {
            label: TEXT.CustomerDetails.CustomerName,
            content: orderDetailData?.customer?.name,
        },
        {
            label: TEXT.CustomerDetails.CustomerPhoneNumber,
            content: orderDetailData?.customer?.phone,
        },
        {
            label: TEXT.CustomerDetails.CustomerAge,
            content: calculateAgeFromBirthday(orderDetailData?.customer?.dob),
        },
        {
            label: TEXT.CustomerDetails.CustomerSex,
            content: translateGender(orderDetailData?.customer?.sex),
        },
        {
            label: TEXT.CustomerDetails.CustomerEmail,
            content: orderDetailData?.customer?.email,
        },
        {
            label: TEXT.CustomerDetails.CustomerAddress,
            content: orderDetailData?.customer?.address,
        },
        {
            label: TEXT.CustomerDetails.CustomerCity,
            content: orderDetailData?.customer?.city?.name,
        },
    ];
    const paymentMethod = orderDetailData?.payment?.method?.name_en;
    const isEWallet = paymentMethod === TEXT.PaymentMethod.EWallet;
    const isCreditCard = paymentMethod === TEXT.PaymentMethod.CreditCard;
    const isVirtualAccount = paymentMethod === TEXT.PaymentMethod.VirtualAccount;
    const paymentDetailsDesc = [
        {
            label: TEXT.PaymentDetails.ReferenceID,
            content: orderDetailData?.payment?.reference_id,
        },
        {
            label: TEXT.PaymentDetails.PaymentChannel,
            content: orderDetailData?.payment?.channel.name_en,
        },
        {
            label: TEXT.PaymentDetails.CardNumber,
            content: isCreditCard && orderDetailData?.payment?.account_no,
        },
        {
            label: TEXT.PaymentDetails.VANumber,
            content: isVirtualAccount && orderDetailData?.payment?.account_no,
        },
        {
            label: TEXT.PaymentDetails.WalletName,
            content: isEWallet && orderDetailData?.payment?.bank_name,
        },
        {
            label: TEXT.PaymentDetails.AmountOriginal,
            content: orderDetailData?.amount?.original && formatCurrency(orderDetailData?.amount?.original),
        },
        {
            label: TEXT.PaymentDetails.AmountActual,
            content: orderDetailData?.amount?.actual && formatCurrency(orderDetailData?.amount?.actual),
        },
        {
            label: TEXT.PaymentDetails.ServiceCharge,
            content: paymentCalculation(orderDetailData ?? undefined).homeServiceFee,
        },
        {
            label: TEXT.PaymentDetails.APDFee,
            content: paymentCalculation(orderDetailData ?? undefined).apdFee,
        },
        {
            label: TEXT.PaymentDetails.TransportFee,
            content: paymentCalculation(orderDetailData ?? undefined).transportationFee,
        },
        {
            label: TEXT.PaymentDetails.Discount,
            content: orderDetailData?.amount?.discount && formatCurrency(orderDetailData?.amount?.discount),
        },
        {
            label: TEXT.PaymentDetails.PPN,
            content: orderDetailData?.amount?.actual && formatCurrency(orderDetailData?.home_service?.amount_ppn ?? 0),
        },
        {
            label: TEXT.PaymentDetails.PPNFree,
            content: orderDetailData?.amount?.actual && formatCurrency(orderDetailData?.home_service?.amount_ppn_free ?? 0),
        },
        {
            label: TEXT.PaymentDetails.PPNLevied,
            content: orderDetailData?.amount?.actual && formatCurrency(orderDetailData?.home_service?.amount_ppn_levied ?? 0),
        },
        {
            label: TEXT.PaymentDetails.CreatedAt,
            content: formatDate(orderDetailData?.created_at),
        },
        {
            label: TEXT.PaymentDetails.ExpiredAt,
            content: formatDate(orderDetailData?.payment?.expired_at),
        },
        {
            label: TEXT.PaymentDetails.ConfirmedAt,
            content: formatDate(orderDetailData?.payment?.confirmed_at),
        },
        {
            label: TEXT.PaymentDetails.PaymentStatus,
            content: orderDetailData?.status,
        },
    ];
    const renderCollapse = () => {
        return (
            <Collapse
                bordered={false}
                expandIcon={({ isActive }) => {
                    return (
                        <img src={isActive ? Images.UpOutlined : Images.DownOutlined} alt="DownOutlined" />
                    )
                }}
                expandIconPosition={'right'}
                className="customCollapseWrapper"
                ghost
            >
                <Panel header={TEXT.OrderDetail.Header} key="1">
                    <Descriptions>
                        {orderDetailDesc.map((item, index) => {
                            return (
                                item.content && <Descriptions.Item key={`orderDetailItem${index}`} label={item.label} span={3}>{item.content}</Descriptions.Item>
                            )
                        })}
                    </Descriptions>
                </Panel>
                <Panel header={TEXT.RescheduleInformation.Header} key="2">
                    <Descriptions>
                        {rescheduleInformationDesc.map((item, index) => {
                            return (
                                item.content && <Descriptions.Item key={`rescheduleInformationDesc${index}`} label={item.label} span={3}>{item.content}</Descriptions.Item>
                            )
                        })}
                    </Descriptions>
                </Panel>
                <Panel header={TEXT.AgentDetail.Header} key="3">
                    <Descriptions>
                        {agentDetailDesc.map((item, index) => {
                            return (
                                item.content && <Descriptions.Item key={`agentDetailDesc${index}`} label={item.label} span={3}>{item.content}</Descriptions.Item>
                            )
                        })}
                    </Descriptions>
                </Panel>
                <Panel header={TEXT.ReferenceDetails.Header} key="4">
                    <Descriptions>
                        {referenceDetailsDesc.map((item, index) => {
                            return (
                                item.content && <Descriptions.Item key={`referenceDetailsItem${index}`} label={item.label} span={3}>{item.content}</Descriptions.Item>
                            )
                        })}
                    </Descriptions>
                </Panel>
                <Panel header={TEXT.CustomerDetails.Header} key="5">
                    <Descriptions>
                        {customerDetailsDesc.map((item, index) => {
                            return (
                                item.content && <Descriptions.Item key={`customerDetailsItem${index}`} label={item.label} span={3}>{item.content}</Descriptions.Item>
                            )
                        })}
                    </Descriptions>
                </Panel>
                <Panel header={TEXT.PaymentDetails.Header} key="6">
                    <Descriptions>
                        {paymentDetailsDesc.map((item, index) => {
                            return (
                                item.content && <Descriptions.Item key={`paymentDetailsItems${index}`} label={item.label} span={3}>{item.content}</Descriptions.Item>
                            )
                        })}
                    </Descriptions>
                </Panel>
            </Collapse>
        )
    };
    const columnsOrderDetail: ColumnsType<OrderDetailItems> = [
        {
            title: TEXT.OrderItem.No,
            dataIndex: '',
            key: '',
            render: (text, row, index: any) => {
                return (
                    <span className="indexStyle">{index + 1}</span>
                );
            },
            width: '5%',
        },
        {
            title: TEXT.OrderItem.ItemID,
            dataIndex: ['id'],
            key: TEXT.OrderItem.ItemID,
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: TEXT.OrderItem.ItemName,
            dataIndex: ['product', 'name'],
            key: TEXT.OrderItem.ItemName,
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: TEXT.OrderItem.ItemType,
            dataIndex: ['product', 'type'],
            key: TEXT.OrderItem.ItemType,
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: TEXT.OrderItem.ContractID,
            dataIndex: ['contract_id'],
            key: TEXT.OrderItem.ContractID,
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: TEXT.OrderItem.DiscountID,
            dataIndex: ['discount_id'],
            key: TEXT.OrderItem.DiscountID,
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: TEXT.OrderItem.NetPrice,
            dataIndex: ['price', 'actual'],
            key: TEXT.OrderItem.NetPrice,
            render: (text: number) => {
                return (
                    <span>{text && formatCurrency(text)}</span>
                )
            },
        },
    ];
    const columnsOrderHistory: ColumnsType<OrderHistory> = [
        {
            title: TEXT.OrderHistory.No,
            dataIndex: 'no',
            key: '',
            render: (text, row, index: any) => {
                return (
                    <span className="indexStyle">{index + 1}</span>
                );
            },
            width: '5%',
        },
        {
            title: TEXT.OrderHistory.HistoryID,
            dataIndex: ['id'],
            key: TEXT.OrderHistory.HistoryID,
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: TEXT.OrderHistory.OrderStatus,
            dataIndex: ['status'],
            key: TEXT.OrderHistory.OrderStatus,
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: TEXT.OrderHistory.DateTime,
            dataIndex: ['created_at'],
            key: TEXT.OrderHistory.DateTime,
            render: (text: string) => {
                return (
                    <span>{formatDate(text)}</span>
                )
            },
        },
        {
            title: TEXT.OrderHistory.Note,
            dataIndex: ['note'],
            key: TEXT.OrderHistory.Note,
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
            width: '30%',
        },
    ];

    if (orderDetailData?.home_service.service_type) {

        columnsOrderHistory.push(
            {
                title: TEXT.OrderHistory.HsDate,
                dataIndex: ['hs_date'],
                key: TEXT.OrderHistory.HsDate,
                render: (text: string) => {
                    return (
                        <span>{text}</span>
                    )
                },
            },
        )

    }

    const renderTable = (props: TableProps) => {
        const {
            header,
            columns,
            dataSource,
        } = props;
        return (
            <div>
                <div className={'itemHeader'}>{header}</div>
                <AppTable
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={(record, idx) => idx + record.id + record.status + record.created_at}
                    rowClassName={(record, index) => index % 2 === 0 ? 'rowWhite' : 'rowBlue'}
                    showPagination={false}
                />
            </div>
        )
    };

    return (
        <div className="orderDetailWrapper">
            {loading && <GetMethodLoading />}
            {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            <ModalViewImage
                visible={showModalViewImage}
                handleClose={() => {
                    setShowModalViewImage(false)
                }}
                imgSrc={imgUrl}
            />
            {orderDetailData && (
                <div className={'orderDetailContent'}>
                    {renderCollapse()}
                    {renderTable({
                        header: TEXT.OrderItem.Header,
                        columns: columnsOrderDetail,
                        dataSource: orderDetailData.items,
                    })}
                    {renderTable({
                        header: TEXT.OrderHistory.Header,
                        columns: columnsOrderHistory,
                        dataSource: orderDetailData.history,
                    })}
                </div>
            )}
        </div>
    );
};

export default OrderDetailView;
