import { HttpError } from 'data/Models';

export const CHANGE_PASSWORD_INITIAL = 'CHANGE_PASSWORD_INITIAL';
export type CHANGE_PASSWORD_INITIAL = typeof CHANGE_PASSWORD_INITIAL;

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export type CHANGE_PASSWORD_REQUEST = typeof CHANGE_PASSWORD_REQUEST;

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export type CHANGE_PASSWORD_SUCCESS = typeof CHANGE_PASSWORD_SUCCESS;

export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export type CHANGE_PASSWORD_FAILURE = typeof CHANGE_PASSWORD_FAILURE;

interface ChangePasswordInitial {
    type: CHANGE_PASSWORD_INITIAL;
}

export interface ChangePasswordRequest {
    type: CHANGE_PASSWORD_REQUEST;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

interface ChangePasswordSuccess {
    type: CHANGE_PASSWORD_SUCCESS;
}

interface ChangePasswordFailure {
    type: CHANGE_PASSWORD_FAILURE;
    error: HttpError;
}

export type ChangePasswordAction = ChangePasswordInitial | ChangePasswordRequest | ChangePasswordSuccess | ChangePasswordFailure;

export function doChangePasswordInitial(): ChangePasswordInitial {
    return {
        type: CHANGE_PASSWORD_INITIAL,
    };
}

export function doChangePasswordRequest(currentPassword: string, newPassword: string, confirmPassword: string): ChangePasswordRequest {
    return {
        type: CHANGE_PASSWORD_REQUEST,
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
    };
}

export function doChangePasswordSuccess(): ChangePasswordSuccess {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
    };
}

export function doChangePasswordFailure(error: HttpError): ChangePasswordFailure {
    return {
        type: CHANGE_PASSWORD_FAILURE,
        error: error,
    };
}
