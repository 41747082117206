import { HttpError, ContractDetail } from 'data/Models';

export const CONTRACT_DETAIL_INITIAL = 'CONTRACT_DETAIL_INITIAL';
export type CONTRACT_DETAIL_INITIAL = typeof CONTRACT_DETAIL_INITIAL;

export const CONTRACT_DETAIL_REQUEST = 'CONTRACT_DETAIL_REQUEST';
export type CONTRACT_DETAIL_REQUEST = typeof CONTRACT_DETAIL_REQUEST;

export const CONTRACT_DETAIL_SUCCESS = 'CONTRACT_DETAIL_SUCCESS';
export type CONTRACT_DETAIL_SUCCESS = typeof CONTRACT_DETAIL_SUCCESS;

export const CONTRACT_DETAIL_FAILURE = 'CONTRACT_DETAIL_FAILURE';
export type CONTRACT_DETAIL_FAILURE = typeof CONTRACT_DETAIL_FAILURE;

interface ContractDetailInitial {
    type: CONTRACT_DETAIL_INITIAL;
}

export interface ContractDetailRequest {
    type: CONTRACT_DETAIL_REQUEST;
    contractId: string;
}

interface ContractDetailSuccess {
    type: CONTRACT_DETAIL_SUCCESS;
    data: ContractDetail;
}

interface ContractDetailFailure {
    type: CONTRACT_DETAIL_FAILURE;
    error: HttpError;
}

export type ContractDetailAction = ContractDetailInitial | ContractDetailRequest | ContractDetailSuccess | ContractDetailFailure;

export function doContractDetailInitial(): ContractDetailInitial {
    return {
        type: CONTRACT_DETAIL_INITIAL
    };
}

export function doContractDetailRequest(contractId: string): ContractDetailRequest {
    return {
        type: CONTRACT_DETAIL_REQUEST,
        contractId: contractId,
    };
}

export function doContractDetailSuccess(data: ContractDetail): ContractDetailSuccess {
    return {
        type: CONTRACT_DETAIL_SUCCESS,
        data: data
    };
}

export function doContractDetailFailure(error: HttpError): ContractDetailFailure {
    return {
        type: CONTRACT_DETAIL_FAILURE,
        error: error
    };
}
