import {
    PROMO_MANAGEMENT_INITIAL,
    PROMO_MANAGEMENT_REQUEST,
    PROMO_MANAGEMENT_SUCCESS,
    PROMO_MANAGEMENT_FAILURE,
    PromoManagementAction
} from './PromoManagementAction';
import { PromoListStoreState } from './PromoManagementState';

export const defaultState: PromoListStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function promoManagementReducer(
    state: PromoListStoreState = defaultState,
    action: PromoManagementAction
): PromoListStoreState {
    switch (action.type) {
        case PROMO_MANAGEMENT_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case PROMO_MANAGEMENT_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case PROMO_MANAGEMENT_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case PROMO_MANAGEMENT_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
