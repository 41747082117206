import { call, put, takeLatest } from 'redux-saga/effects';
import RoleApi from 'data/api/RoleApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './RoleEditAction';

function* updateRoleTask(updateRoleAction: action.UpdateRoleRequest) {
    const { updateData } = updateRoleAction;
    const response = yield call(RoleApi.updateRole, updateData);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doUpdateRoleSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doUpdateRoleRequest(updateData) });
            yield put(action.doUpdateRoleFailure(newTimeoutError));
        } else {
            yield put(action.doUpdateRoleFailure(response.error));
        }
    }
}
export function* updateRoleSaga() {
    yield takeLatest(
        action.UPDATE_ROLE_REQUEST,
        updateRoleTask,
    );
}
