import React, { useState } from 'react';
import { Input } from 'antd';
import { Images } from 'themes';
import './index.css';

const SearchBox: React.FC<{
    placeholder?: string;
    allowClear?: boolean;
    border?: boolean;
    full?: boolean;
    onSearch?: (query: string) => void;
}> = ({
    placeholder = 'Search by name',
    allowClear = true,
    border = false,
    full = false,
    onSearch = (query: string) => {}
}) => {
    const [query, setQuery] = useState('');
    const handleSearch = () => {
        onSearch(query);
    };
    const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        handleSearch();
    };
    const handleOnChange = (value: any) => {
        setQuery(value);
        if (!value) {
            onSearch('')
        }
    };
    let classes = 'searchBoxWrapper';
    if (border) classes += ' searchBoxWrapper--bordered';
    if (full) classes += ' searchBoxWrapper--full';

    return (
        <div className={classes}>
            <Input
                placeholder={placeholder}
                allowClear={allowClear}
                suffix={
                    <div className={'anticon anticon-search'}>
                        <img src={Images.SearchGrey} alt="Arrow Down" onClick={handleSearch}/>
                    </div>
                }
                onPressEnter={handlePressEnter}
                onChange={(event) => handleOnChange(event.target.value)}
                className="searchBox"
            />
        </div>
    )
};

export default SearchBox;
