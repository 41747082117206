import React from 'react';
import { useSelector } from 'react-redux';
import { Role } from 'data/Models';
import RootStoreState from 'rootStore';
import { SelectComponent } from 'components/SelectComponent';

const Text = {
    placeholder: 'Choose Role',
};

interface Props {
    selectedOption?: string;
    onSelect: (roles: Role) => void;
}
function RoleInputView(props: Props) {
    const roleListState = useSelector((state: RootStoreState) => state.roleManagement);
    const roleList = roleListState.data?.data ?? [];

    const onGetOption = (role: Role) => {
        return { value: role.id, label: role.name }
    };

    return (
        <SelectComponent
            selectProps={{
                placeholder: Text.placeholder,
            }}
            selectedOption={props.selectedOption}
            data={roleList}
            onMapOption={onGetOption}
            onSelectedChange={props.onSelect}
        />
    );
}

export default RoleInputView;
