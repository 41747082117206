import { HttpError } from 'data/Models';

export const DELETE_PROMO_INITIAL = 'DELETE_PROMO_INITIAL';
export type DELETE_PROMO_INITIAL = typeof DELETE_PROMO_INITIAL;

export const DELETE_PROMO_REQUEST = 'DELETE_PROMO_REQUEST';
export type DELETE_PROMO_REQUEST = typeof DELETE_PROMO_REQUEST;

export const DELETE_PROMO_SUCCESS = 'DELETE_PROMO_SUCCESS';
export type DELETE_PROMO_SUCCESS = typeof DELETE_PROMO_SUCCESS;

export const DELETE_PROMO_FAILURE = 'DELETE_PROMO_FAILURE';
export type DELETE_PROMO_FAILURE = typeof DELETE_PROMO_FAILURE;

interface DeletePromoInitial {
    type: DELETE_PROMO_INITIAL;
}

export interface DeletePromoRequest {
    type: DELETE_PROMO_REQUEST;
    promoId: string;
    promoType: string;
}

interface DeletePromoSuccess {
    type: DELETE_PROMO_SUCCESS;
    promoType: string;
}

interface DeletePromoFailure {
    type: DELETE_PROMO_FAILURE;
    promoType: string;
    error: HttpError;
}

export type DeletePromoAction = DeletePromoInitial | DeletePromoRequest | DeletePromoSuccess | DeletePromoFailure;

export function doDeletePromoInitial(): DeletePromoInitial {
    return {
        type: DELETE_PROMO_INITIAL
    };
}

export function doDeletePromoRequest(promoId: string, promoType: string): DeletePromoRequest {
    return {
        type: DELETE_PROMO_REQUEST,
        promoId: promoId,
        promoType: promoType,
    };
}

export function doDeletePromoSuccess(promoType: string): DeletePromoSuccess {
    return {
        type: DELETE_PROMO_SUCCESS,
        promoType: promoType,
    };
}

export function doDeletePromoFailure(promoType: string, error: HttpError): DeletePromoFailure {
    return {
        type: DELETE_PROMO_FAILURE,
        promoType: promoType,
        error: error,
    };
}
