import React from 'react';
import './index.css';
import { Checkbox, Col, Row } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

const productTypes = [
    {
        label: 'Most Popular',
        value: 'popular'
    },
    {
        label: 'Newest',
        value: 'newest'
    }
];

function ProductTypeInputView(props: CheckboxGroupProps) {
    return (
        <Checkbox.Group {...props}>
            {productTypes.map((item) => {
                return (
                    <Row key={item.value} gutter={[10, 10]}>
                        <Col className="productTypeItemWrapper">
                            <Checkbox value={item.value}>
                                {item.label}
                            </Checkbox>
                        </Col>
                    </Row>
                );
            })}
        </Checkbox.Group>
    )
}

export default ProductTypeInputView;
