import { call, put, takeLatest } from 'redux-saga/effects';
import OrderApi from 'data/api/OrderApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './OrderDetailDownloadAction';

function* orderDetailDownloadTask(sagaAction: action.OrderDetailDownloadRequest) {
    const { orderId } = sagaAction;
    const response = yield call(OrderApi.getOrderDetailDownload, orderId);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doOrderDetailDownloadSuccess(response.data.orderDetailDownload));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doOrderDetailDownloadRequest(orderId) });
            yield put(action.doOrderDetailDownloadFailure(newTimeoutError));
        } else {
            yield put(action.doOrderDetailDownloadFailure(response.error));
        }
    }
}
export function* orderDetailDownloadSaga() {
    yield takeLatest(
        action.ORDER_DETAIL_DOWNLOAD_REQUEST,
        orderDetailDownloadTask,
    );
}