import { call, put, takeLatest } from 'redux-saga/effects';
import AuthApi from 'data/api/AuthApi';
import { AuthStorage } from 'data/local/AuthStorage';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './LoginAction';
import { sessionTimeConfigStore } from 'lib/SessionTimeManager';

export function* sagaTask(sagaAction: action.LoginRequest) {
    const { email, password, captchaToken } = sagaAction;
    const response = yield call(AuthApi.login, email, password, captchaToken);

    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        sessionTimeConfigStore.save(response.data.timeoutConfig);
        yield call(AuthStorage.save, response.data);
        yield put(action.doLoginSuccess(response.data));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doLoginRequest(email, password, captchaToken) });
            yield put(action.doLoginFailure(newTimeoutError));
        } else {
            yield put(action.doLoginFailure(response.error));
        }
    }
}

export function* loginSaga() {
    yield takeLatest(
        action.LOGIN_REQUEST,
        sagaTask,
    );
}
