import { BranchListQuery } from "common/States";
import { HttpError, BranchList } from "data/Models";

export const BRANCH_LIST_INITIAL = 'BRANCH_LIST_INITIAL';
export type BRANCH_LIST_INITIAL = typeof BRANCH_LIST_INITIAL;

export const BRANCH_LIST_REQUEST = 'BRANCH_LIST_REQUEST';
export type BRANCH_LIST_REQUEST = typeof BRANCH_LIST_REQUEST;

export const BRANCH_LIST_SUCCESS = 'BRANCH_LIST_SUCCESS';
export type BRANCH_LIST_SUCCESS = typeof BRANCH_LIST_SUCCESS;

export const BRANCH_LIST_FAILURE = 'BRANCH_LIST_FAILURE';
export type BRANCH_LIST_FAILURE = typeof BRANCH_LIST_FAILURE;

interface BranchListInitial {
    type: BRANCH_LIST_INITIAL;
}

export interface BranchListRequest {
    type: BRANCH_LIST_REQUEST;
    filterParams: BranchListQuery
}

interface BranchListSuccess {
    type: BRANCH_LIST_SUCCESS;
    data: BranchList;
}

interface BranchListFailure {
    type: BRANCH_LIST_FAILURE;
    error: HttpError;
}

export type BranchListAction = BranchListInitial | BranchListRequest | BranchListSuccess | BranchListFailure;

export function doBranchListInitial(): BranchListInitial {
    return {
        type: BRANCH_LIST_INITIAL,
    };
}

export function doBranchListRequest(
    filterParams: BranchListQuery
): BranchListRequest {
    return {
        type: BRANCH_LIST_REQUEST,
        filterParams,
    };
}

export function doBranchListSuccess(data: BranchList): BranchListSuccess {
    return {
        type: BRANCH_LIST_SUCCESS,
        data: data,
    };
}

export function doBranchListFailure(error: HttpError): BranchListFailure {
    return {
        type: BRANCH_LIST_FAILURE,
        error: error,
    };
}
