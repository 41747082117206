import React from 'react';
import { Button } from 'antd';
import './index.css';

const OvalButton: React.FC<{
    buttonText?: string;
    disabled?: boolean;
    onSearch?: () => void;
    type?: any;
    wrapperClassName?: string;
    htmlType?: any;
    onClick?: () => void;
    icon?: any;
}> = ({
    buttonText = '',
    disabled = false,
    onSearch = () => {},
    type = 'primary',
    wrapperClassName = '',
    htmlType = 'button',
    onClick = () => {},
    icon = ''
}) => {
    return (
        <Button
            type={type}
            htmlType={htmlType}
            disabled={disabled}
            className={`ovalButtonWrapper ${wrapperClassName}`}
            onClick={onClick}
            icon={icon}
        >
            {buttonText}
        </Button>
    )
};

export default OvalButton;
