import { call, put, takeLatest } from 'redux-saga/effects';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './TNCManagementAction';
import HomeServiceApi from 'data/api/HomeServiceApi';

export function* updateTNCTask(actionRequest: action.UpdateTNCRequest) {
    const newData = actionRequest.newData;
    const response = yield call(HomeServiceApi.updateTNC, newData);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doUpdateTNCSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doUpdateTNCRequest(newData) });
            yield put(action.doUpdateTNCFailure(newTimeoutError));
        } else {
            yield put(action.doUpdateTNCFailure(response.error));
        }
    }
}

export function* updateTNCSaga() {
    yield takeLatest(
        action.UPDATE_TNC_REQUEST,
        updateTNCTask,
    );
}
