import { HttpError, BranchDetail } from 'data/Models';

export const BRANCH_DETAIL_INITIAL = 'BRANCH_DETAIL_INITIAL';
export type BRANCH_DETAIL_INITIAL = typeof BRANCH_DETAIL_INITIAL;

export const BRANCH_DETAIL_REQUEST = 'BRANCH_DETAIL_REQUEST';
export type BRANCH_DETAIL_REQUEST = typeof BRANCH_DETAIL_REQUEST;

export const BRANCH_DETAIL_SUCCESS = 'BRANCH_DETAIL_SUCCESS';
export type BRANCH_DETAIL_SUCCESS = typeof BRANCH_DETAIL_SUCCESS;

export const BRANCH_DETAIL_FAILURE = 'BRANCH_DETAIL_FAILURE';
export type BRANCH_DETAIL_FAILURE = typeof BRANCH_DETAIL_FAILURE;

interface BranchDetailInitial {
    type: BRANCH_DETAIL_INITIAL;
}

export interface BranchDetailRequest {
    type: BRANCH_DETAIL_REQUEST;
    branchId: string;
}

interface BranchDetailSuccess {
    type: BRANCH_DETAIL_SUCCESS;
    data: BranchDetail;
}

interface BranchDetailFailure {
    type: BRANCH_DETAIL_FAILURE;
    error: HttpError;
}

export type BranchDetailAction = BranchDetailInitial | BranchDetailRequest | BranchDetailSuccess | BranchDetailFailure;

export function doBranchDetailInitial(): BranchDetailInitial {
    return {
        type: BRANCH_DETAIL_INITIAL
    };
}

export function doBranchDetailRequest(branchId: string): BranchDetailRequest {
    return {
        type: BRANCH_DETAIL_REQUEST,
        branchId: branchId,
    };
}

export function doBranchDetailSuccess(data: BranchDetail): BranchDetailSuccess {
    return {
        type: BRANCH_DETAIL_SUCCESS,
        data: data
    };
}

export function doBranchDetailFailure(error: HttpError): BranchDetailFailure {
    return {
        type: BRANCH_DETAIL_FAILURE,
        error: error
    };
}
