import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootStoreState from 'rootStore';
import { SelectComponent } from 'components/SelectComponent';
import { ContractListAction, doContractListInitial, doContractListRequest } from './ContractListAction';

const Text = {
    placeholder: 'Choose',
};

interface Props {
    selectedOption?: string;
    onSelect: (contractId: string) => void;
    onLoad: (contractIDs: string[]) => void;
}

function ContractInputView(props: Props) {
    const contractListAction = useDispatch<Dispatch<ContractListAction>>();
    const contractListState = useSelector((state: RootStoreState) => state.contractList);
    const [contractList, setContractList] = useState<string[]>([]);
    const [isAlreadyLoaded, setAlreadyLoaded] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");

    const handleLoadData = useCallback(() => {
        const total = contractListState.data?.pagination?.total ?? 0;
        if (total === 0 || total > contractList.length) {
            setAlreadyLoaded(false);
            const page = contractListState.data?.pagination?.page ?? 0;
            const query = {
                page: page + 1,
                limit: 20,
                keyword: searchKeyword,
            };
            contractListAction(doContractListRequest(query));
        }
    }, [contractListState, contractList.length, contractListAction, searchKeyword]);

    const handleScroll = useCallback((event: React.UIEvent) => {
        const target = event.target as HTMLDivElement
        if (!contractListState.onRequest && target.scrollTop + target.offsetHeight === target.scrollHeight) {
            handleLoadData();
        }
    }, [contractListState.onRequest, handleLoadData]);

    const handleSearchData = useCallback((keyword: string) => {
        setSearchKeyword(keyword);
        setContractList([]);
        props.onLoad([]);
        contractListAction(doContractListInitial());
    }, [contractListAction, props]);

    useEffect(() => {
        const { onRequest, data, error } = contractListState;
        if (!onRequest && data == null && error == null) {
            handleLoadData();
        } else if (data && !isAlreadyLoaded) {
            setAlreadyLoaded(true);
            const newData = [...contractList, ...data.data];
            setContractList(newData);
            props.onLoad(newData);

            if (props.selectedOption && newData.indexOf(props.selectedOption) === -1) {
                handleLoadData();
            }
        }
    }, [contractList, contractListState, handleLoadData, isAlreadyLoaded, props]);

    const onGetOption = useCallback((contractID: string) => {
        return { value: contractID, label: contractID }
    }, []);

    return (
        <SelectComponent
            selectProps={{
                placeholder: Text.placeholder,
                showSearch: true,
                searchValue: searchKeyword,
                onSearch: handleSearchData,
                onPopupScroll: handleScroll,
            }}
            selectedOption={props.selectedOption}
            data={contractList}
            onMapOption={onGetOption}
            onSelectedChange={props.onSelect}
        />
    );
}

export default ContractInputView;
