import { HttpError, TNCDetail } from "data/Models";

export const UPDATE_TNC_INITIAL = 'UPDATE_TNC_INITIAL';
export type UPDATE_TNC_INITIAL = typeof UPDATE_TNC_INITIAL;

export const UPDATE_TNC_REQUEST = 'UPDATE_TNC_REQUEST';
export type UPDATE_TNC_REQUEST = typeof UPDATE_TNC_REQUEST;

export const UPDATE_TNC_SUCCESS = 'UPDATE_TNC_SUCCESS';
export type UPDATE_TNC_SUCCESS = typeof UPDATE_TNC_SUCCESS;

export const UPDATE_TNC_FAILURE = 'UPDATE_TNC_FAILURE';
export type UPDATE_TNC_FAILURE = typeof UPDATE_TNC_FAILURE;

interface UpdateTNCInitial {
    type: UPDATE_TNC_INITIAL;
}

export interface UpdateTNCRequest {
    type: UPDATE_TNC_REQUEST;
    newData: TNCDetail;
}

interface UpdateTNCSuccess {
    type: UPDATE_TNC_SUCCESS;
}

interface UpdateTNCFailure {
    type: UPDATE_TNC_FAILURE;
    error: HttpError;
}

export type UpdateTNCAction = UpdateTNCInitial | UpdateTNCRequest | UpdateTNCSuccess | UpdateTNCFailure;

export function doUpdateTNCInitial(): UpdateTNCInitial {
    return {
        type: UPDATE_TNC_INITIAL,
    };
}

export function doUpdateTNCRequest(newData: TNCDetail): UpdateTNCRequest {
    return {
        type: UPDATE_TNC_REQUEST,
        newData: newData,
    };
}

export function doUpdateTNCSuccess(): UpdateTNCSuccess {
    return {
        type: UPDATE_TNC_SUCCESS,
    };
}

export function doUpdateTNCFailure(error: HttpError): UpdateTNCFailure {
    return {
        type: UPDATE_TNC_FAILURE,
        error: error,
    };
}
