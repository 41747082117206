import { HttpError, OrderItems } from 'data/Models';
import { UnknownErrorCode } from './HttpCode';

export const UnknownErrorMsg = 'Unknown error';
export const UnAuthenticatedErrorMsg = 'Your session has ended please log back in';

export const ScreenType = {
    DEFAULT: 'DEFAULT',
    WITH_SCAFFOLLD: 'WITH_SCAFFOLLD'
};

export const GeneralError = new HttpError(UnknownErrorCode, UnknownErrorMsg);
export const TestError = new HttpError("code", "required and cannot be empty", [{ field: "field_name", error: "required and cannot be empty" }]);
export const UnAuthenticatedError = new HttpError(UnknownErrorCode, UnAuthenticatedErrorMsg);

export type ScreenType = typeof ScreenType
export const defaultRetrieveAll: boolean = false;
export const defaultPage: number = 1;
export const defaultLimit: number = 20;
export interface DefaultQueryType {
    page: number;
    limit: number;
    from_date? : string;
    to_date? : string;
}
export const defaultQuery = {
    page: defaultPage,
    limit: defaultLimit,
};

export const promoTypeListState = [
    {
        id: 'special_promo.special_offer',
        name: 'Special Offer'
    },
    {
        id: 'special_promo.flash_sale',
        name: 'Flash Sale'
    },
    {
        id: 'promo_code.general_code',
        name: 'Promo Code General'
    },
    {
        id: 'promo_discount.birthday_promo',
        name: 'Birthday Promo'
    },
    {
        id: 'voucher.voucher',
        name: 'Voucher'
    },
];

export const findPromoTypeName = (promoType: string) => {
    let promoTypeName = '';
    switch (promoType) {
        case promoTypeListState[0].id:
            promoTypeName = promoTypeListState[0].name;
            break;
        case promoTypeListState[1].id:
            promoTypeName = promoTypeListState[1].name;
            break;
        case promoTypeListState[2].id:
            promoTypeName = promoTypeListState[2].name;
            break;
    }

    return promoTypeName;
}

export const SendActivationLinkPermission = 'admin_management.resend_activation';

export const HomeServiceFee = [
    { id: 'R2002037', type: 'transport' },
    { id: 'R2002232', type: 'transport' },
    { id: 'R2002233', type: 'transport' },
    { id: 'R2002234', type: 'transport' },
    { id: 'R2002051', type: 'apd' },
    { id: 'R2002053', type: 'apd' },
    { id: 'R2002235', type: 'apd' },
    { id: 'R2002236', type: 'apd' },
    { id: 'R2002213', type: 'hs_fee' },
    { id: 'R2002216', type: 'hs_fee' },
    { id: 'R2002237', type: 'hs_fee' },
    { id: 'R2002238', type: 'hs_fee' },
]

export const getHsFeeId = (flag: string, data: OrderItems[]) => {

    const dataService = data.filter(a => a.product.type === 'SERVICE');
    
    let hsFilter: OrderItems[] = [];
    dataService.forEach((val, i) => {
        const dataFilter = HomeServiceFee.filter(b => b.id === val.product.id && b.type === flag);
        if(dataFilter.length > 0) {
            hsFilter = dataService.filter( c => c.id = dataFilter[0].id)
        }
    });

    // console.log(flag, hsFilter[0].price.actual);

    return hsFilter[0].price.actual;
}