import { HttpError, PromoDetail } from 'data/Models';

export const PROMO_DETAIL_INITIAL = 'PROMO_DETAIL_INITIAL';
export type PROMO_DETAIL_INITIAL = typeof PROMO_DETAIL_INITIAL;

export const PROMO_DETAIL_REQUEST = 'PROMO_DETAIL_REQUEST';
export type PROMO_DETAIL_REQUEST = typeof PROMO_DETAIL_REQUEST;

export const PROMO_DETAIL_SUCCESS = 'PROMO_DETAIL_SUCCESS';
export type PROMO_DETAIL_SUCCESS = typeof PROMO_DETAIL_SUCCESS;

export const PROMO_DETAIL_FAILURE = 'PROMO_DETAIL_FAILURE';
export type PROMO_DETAIL_FAILURE = typeof PROMO_DETAIL_FAILURE;

interface PromoDetailInitial {
    type: PROMO_DETAIL_INITIAL;
}

export interface PromoDetailRequest {
    type: PROMO_DETAIL_REQUEST;
    promoId: string;
    promoType: string;
}

interface PromoDetailSuccess {
    type: PROMO_DETAIL_SUCCESS;
    data: PromoDetail;
}

interface PromoDetailFailure {
    type: PROMO_DETAIL_FAILURE;
    error: HttpError;
}

export type PromoDetailAction = PromoDetailInitial | PromoDetailRequest | PromoDetailSuccess | PromoDetailFailure;

export function doPromoDetailInitial(): PromoDetailInitial {
    return {
        type: PROMO_DETAIL_INITIAL
    };
}

export function doPromoDetailRequest(promoId: string, promoType: string): PromoDetailRequest {
    return {
        type: PROMO_DETAIL_REQUEST,
        promoId: promoId,
        promoType: promoType,
    };
}

export function doPromoDetailSuccess(data: PromoDetail): PromoDetailSuccess {
    return {
        type: PROMO_DETAIL_SUCCESS,
        data: data
    };
}

export function doPromoDetailFailure(error: HttpError): PromoDetailFailure {
    return {
        type: PROMO_DETAIL_FAILURE,
        error: error
    };
}
