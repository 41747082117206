import { PermissionGroup, HttpError } from 'data/Models';

export const GET_PERMISSIONS_INITIAL = 'GET_PERMISSIONS_INITIAL';
export type GET_PERMISSIONS_INITIAL = typeof GET_PERMISSIONS_INITIAL;

export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export type GET_PERMISSIONS_REQUEST = typeof GET_PERMISSIONS_REQUEST;

export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export type GET_PERMISSIONS_SUCCESS = typeof GET_PERMISSIONS_SUCCESS;

export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';
export type GET_PERMISSIONS_FAILURE = typeof GET_PERMISSIONS_FAILURE;

interface GetPermissionsInitial {
    type: GET_PERMISSIONS_INITIAL;
}

interface GetPermissionsRequest {
    type: GET_PERMISSIONS_REQUEST;
}

interface GetPermissionsSuccess {
    type: GET_PERMISSIONS_SUCCESS;
    data: PermissionGroup[]
}

interface GetPermissionsFailure {
    type: GET_PERMISSIONS_FAILURE;
    error: HttpError;
}

export type GetPermissionsAction = GetPermissionsInitial | GetPermissionsRequest | GetPermissionsSuccess | GetPermissionsFailure;

export function doGetPermissionsInitial(): GetPermissionsInitial {
    return {
        type: GET_PERMISSIONS_INITIAL,
    };
}

export function doGetPermissionsRequest(): GetPermissionsRequest {
    return {
        type: GET_PERMISSIONS_REQUEST,
    };
}

export function doGetPermissionsSuccess(data: PermissionGroup[]): GetPermissionsSuccess {
    return {
        type: GET_PERMISSIONS_SUCCESS,
        data: data,
    };
}

export function doGetPermissionsFailure(error: HttpError): GetPermissionsFailure {
    return {
        type: GET_PERMISSIONS_FAILURE,
        error: error,
    };
}
