import React, { useState, useEffect, useCallback, Dispatch } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Popover, Space } from 'antd';
import { TableIncrementNumber } from 'common/CommonUtils';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import RootStoreState from 'rootStore';
import { BranchListItem, HttpError } from 'data/Models';
import { UnauthorizedPermissionCode } from 'common/HttpCode';
import { DefaultQueryType, defaultQuery } from 'common/constans';
import { FrontendRoutes } from 'navigation/Routes';
import { Images } from 'themes';

import { BranchListAction, doBranchListRequest, doBranchListInitial } from './BranchAction';
import AppTable from 'components/AppTableComponent';
import SearchBox from 'components/FilterComponent/SearchBox';
import RegionFilterView from 'components/FilterComponent/RegionFilterView';
import AlertComponent from 'components/AlertComponent';
import { GetMethodLoading } from 'components/LoadingComponent';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import PopOverContent from 'components/PopoverContent/PopoverContent';
import './index.css';
import { BranchRefererPage } from 'common/BranchUtils';
import HomeServiceFilterView from 'components/FilterComponent/HomeServiceFilterView';

interface Filter {
	id?: string;
	name?: string;
}

const TableHeaderText = {
	no: 'No',
	branchID: 'Branch ID',
	region: 'Region',
	branchName: 'Branch Name',
	city: 'City',
	hs: "Availibility Home Service",
};

const AvailabilityHSText = {
	Available: "Yes",
	Unavailable: "No"
};

const PopoverText = {
	view: 'View Detail',
	edit: 'Edit',
};

const FilterText = {
	searchPlaceholder: 'Search',
};

const wrapperId = 'branchManagementWrapperId';

const BranchManagementView = () => {
	const history = useHistory();
	const profileAction = useDispatch<Dispatch<GetProfileAction>>();
	const profileState = useSelector((state: RootStoreState) => state.profile);
	const permissions = profileState.data?.permissions || [];
	const branchListState = useSelector((state: RootStoreState) => state.branchList);
	const branchListData = branchListState.data;
	const [error, setError] = useState<HttpError | null>(null);
	const [filterParams, setFilterParams] = useState<DefaultQueryType>(defaultQuery);
	const [shownPopOver, setShownPopover] = useState<number | null>(null);
	const branchListAction = useDispatch<Dispatch<BranchListAction>>();
	const containerId = document.getElementById(wrapperId);
	const branchPermissions = {
		isAllowedShowList: isPermissionAllowed(FrontendRoutes.BranchManagement.permissionKey, permissions),
		isAllowedViewDetail: isPermissionAllowed(FrontendRoutes.BranchDetailPage.permissionKey, permissions),
		isAllowedEditConfig: isPermissionAllowed(FrontendRoutes.BranchEditPage.permissionKey, permissions),
	};
	const tableLoading = profileState.onRequest || branchListState.onRequest;
	const showDots = branchPermissions.isAllowedViewDetail || branchPermissions.isAllowedEditConfig;

	const handleRequestListAction = (query: DefaultQueryType) => {
		setShownPopover(null);
		setFilterParams(query);
		branchListAction(doBranchListRequest(query));
	};
	const handleSearchByKeyword = (keyword: string) => {
		const newQuery = Object.assign({}, filterParams, { keyword: keyword, page: defaultQuery.page });
		handleRequestListAction(newQuery);
	};
	const handleRegionSelected = (values: Filter[]) => {
		const newRegionIds = values.map((item) => item.id).join(',');
		const newQuery = Object.assign({}, filterParams, { regions: newRegionIds, page: defaultQuery.page });
		handleRequestListAction(newQuery);
	};
	const handleAvailabilityHSSelected = (value: Filter) => {
		const newQuery = Object.assign({}, filterParams, { available_hs: value.id, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };
	const handleShowPopOver = useCallback((isShown, index) => {
		let shownPopOver = null;
		if (!isShown) {
			shownPopOver = index;
		}

		setShownPopover(shownPopOver);
	}, []);
	const handleHistoryPush = useCallback(
		(path: string, state?: unknown) => {
			history.push(path, state);
		},
		[history]
	);
	const handleClearFn = useCallback(() => {
		setError(null);
	}, []);

	useEffect(() => {
		branchListAction(doBranchListInitial());
		branchListAction(doBranchListRequest(defaultQuery));
	}, [branchListAction, branchPermissions.isAllowedShowList]);
	useEffect(() => {
		if (profileState.error) {
			profileAction(doGetProfileInitial());
			setError(profileState.error);
		}
	}, [profileAction, profileState]);
	useEffect(() => {
		if (branchListState.error) {
			branchListAction(doBranchListInitial());
			if (branchListState.error.code === UnauthorizedPermissionCode) return;
			setError(branchListState.error);
		}
	}, [branchListAction, branchListState]);

	const columns: ColumnsType<BranchListItem> = [
		{
			title: TableHeaderText.no,
			dataIndex: '',
			key: '',
			render: (text, row, index: any) => {
				return (
					<span className="indexStyle">
						{TableIncrementNumber(filterParams.page, filterParams.limit, index)}
					</span>
				);
			},
			width: '5%',
		},
		{
			title: TableHeaderText.branchID,
			dataIndex: 'id',
			key: 'id',
			render: (text: string, record: any) => {
				if (branchPermissions.isAllowedViewDetail) {
					return (
						<Link
							to={{
								pathname: FrontendRoutes.BranchDetailPage.path.replace(':branchId', record.id),
							}}
						>
							<span className="nameStyle">{text}</span>
						</Link>
					);
				}
				return <span className="nameStyle">{text}</span>;
			},
		},
		{
			title: TableHeaderText.branchName,
			dataIndex: ['name'],
			key: 'branchName',
			render: (text: string) => {
				return <span>{text}</span>;
			},
		},
		{
			title: TableHeaderText.region,
			dataIndex: ['region', 'name'],
			key: 'region',
			render: (text: string) => {
				return <span>{text}</span>;
			},
			width: '170px',
		},
		{
			title: TableHeaderText.city,
			dataIndex: ['city', 'name'],
			key: 'city',
			render: (text: string) => {
				return <span>{text}</span>;
			},
		},
		{
            title: TableHeaderText.hs,
            dataIndex: 'available_hs',
            key: 'available_hs',
            render: (text, record) => {
                const availableHS = record.available_hs ? AvailabilityHSText.Available : AvailabilityHSText.Unavailable;
                return (<span>{availableHS}</span>)
            },
        },
		{
			title: '',
			key: '',
			render: (text, record, index) => {
				const mockPaths = {
					detail: '/branch/detail/:branchId',
					edit: '/branch/edit/:branchId',
				};

				const content = (
					<PopOverContent
						options={[
							{
								clickFn: () => handleHistoryPush(mockPaths.detail.replace(':branchId', record.id)),
								imgSrc: Images.EyeBlue,
								text: PopoverText.view,
								isAllowed: branchPermissions.isAllowedViewDetail,
							},
							{
								clickFn: () => handleHistoryPush(mockPaths.edit.replace(':branchId', record.id), BranchRefererPage.LIST),
								imgSrc: Images.PencilBlue,
								text: PopoverText.edit,
								isAllowed: branchPermissions.isAllowedEditConfig,
								popOverItemClass: 'edit',
							},
						]}
					/>
				);
				const isShown = shownPopOver === index;
				let popupContainer = document.body;
				if (containerId) {
					popupContainer = containerId;
				}
				return (
					<div>
						<Popover
							content={content}
							visible={isShown}
							trigger="click"
							placement="bottomRight"
							overlayClassName={'actionGroupPopOver'}
							getPopupContainer={() => popupContainer}
						>
							<div className="branchManagementDotsWrapper">
								{showDots && (
									<div
										className="branchManagementDots"
										onClick={() => handleShowPopOver(isShown, index)}
									>
										<img src={Images.Dots} alt="Dots" />
									</div>
								)}
							</div>
						</Popover>
					</div>
				);
			},
			width: '141px',
		},
	];

	return (
		<div className="branchManagementWrapper" id={wrapperId}>
			{error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
			<div className="branchManagementFilterWrapper">
				<Space size={16}>
					<SearchBox placeholder={FilterText.searchPlaceholder} onSearch={handleSearchByKeyword} />
					<RegionFilterView onSelect={handleRegionSelected} />
					<HomeServiceFilterView onSingleSelect={handleAvailabilityHSSelected}/>
				</Space>
			</div>
			<AppTable
				loading={{
					spinning: tableLoading,
					indicator: <GetMethodLoading />,
					wrapperClassName: 'tableLoading',
				}}
				pageSize={branchListData?.pagination?.limit}
				total={branchListData?.pagination?.total}
				current={branchListData?.pagination?.page}
				onRequestData={(currentPage, pageSize) => {
					const newQuery = Object.assign({}, filterParams, {
						page: currentPage,
						limit: pageSize,
					});
					handleRequestListAction(newQuery);
				}}
				columns={columns}
				dataSource={branchListData?.data}
				rowKey={(record) => record.id}
				rowClassName={(record, index) => (index % 2 === 0 ? 'rowWhite' : 'rowBlue')}
			/>
		</div>
	);
};

export default BranchManagementView;
