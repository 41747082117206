import { call, put, takeLatest } from 'redux-saga/effects';
import OutletApi from 'data/api/OutletApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './BranchAction';
import { BranchList, HttpError } from 'data/Models';
import { FilterParamMapper } from 'common/CommonUtils';

function* branchListTask(branchListAction: action.BranchListRequest) {
    const { filterParams } = branchListAction;
    let queryParams = FilterParamMapper(filterParams);

    if (filterParams.isRetrieveAll) {
        const maxLimit = 100;
        let requestPage = 1;
        let isMoveToNext = true;
        let branchList: BranchList | null = null;
        let error: HttpError | null = null;

        while (isMoveToNext) {
            const response = yield call(OutletApi.getOutletList, { page: requestPage, limit: maxLimit });
            if (response.status === http.HTTP_RESPONSE_SUCCESS) {
                if (branchList) {
                    branchList.data = [...branchList.data, ...response.data.branchList.data];
                } else {
                    branchList = response.data.branchList;
                }

                if (response.data.branchList.pagination.total <= requestPage * maxLimit) {
                    isMoveToNext = false;
                }
            } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
                error = response.error;
                break;
            }

            requestPage++;
        }

        if (branchList) {
            yield put(action.doBranchListSuccess(branchList));
        } else if (error) {
            if (error.code === AbortError) {
                const newTimeoutError = Object.assign({}, error, { action: () => action.doBranchListRequest({ isRetrieveAll: true, page: requestPage, limit: maxLimit }) });
                yield put(action.doBranchListFailure(newTimeoutError));
            } else {
                yield put(action.doBranchListFailure(error));
            }
        }
    } else {
        const response = yield call(OutletApi.getOutletList, filterParams);
        if (response.status === http.HTTP_RESPONSE_SUCCESS) {
            yield put(action.doBranchListSuccess(response.data.branchList));
        } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
            if (response.error.code === AbortError) {
                const newTimeoutError = Object.assign({}, response.error, { action: () => action.doBranchListRequest(queryParams) });
                yield put(action.doBranchListFailure(newTimeoutError));
            } else {
                yield put(action.doBranchListFailure(response.error));
            }
        }
    }
}
export function* branchListSaga() {
    yield takeLatest(
        action.BRANCH_LIST_REQUEST,
        branchListTask,
    );
}