import React, { Dispatch, useState, useCallback, useEffect } from 'react';
import { Layout } from 'antd';
import HeaderView from './header/HeaderView';
import SiderView from './sider/SiderView';
import * as style from './ScaffoldView.style';
import { useDispatch, useSelector } from 'react-redux';
import { doGetProfileRequest, doLogoutRequest, GetProfileAction, LogoutAction } from 'features/scaffold/ScaffoldAction';
import RootStoreState from 'rootStore';
import { useHistory } from 'react-router-dom';
import { FrontendRoutes } from 'navigation/Routes';
import { HttpError } from 'data/Models';
import AlertComponent from 'components/AlertComponent';

interface Props {
    title: string,
    children: any;
}

const ScaffoldView: React.FC<Props> = ({ title, children }) => {
    const [error, setError] = useState<HttpError | null>(null);
    const history = useHistory();
    const getProfileAction = useDispatch<Dispatch<GetProfileAction>>();
    const logoutAction = useDispatch<Dispatch<LogoutAction>>();
    const onLogout = useSelector((state: RootStoreState) => state.logout.onLogout);
    const logoutError = useSelector((state: RootStoreState) => state.logout.error);

    useEffect(() => {
        getProfileAction(doGetProfileRequest());
    }, [getProfileAction]);

    useEffect(() => {
        if (logoutError) {
            setError(logoutError);
        }
    }, [logoutError]);

    useEffect(() => {
        if (onLogout) {
            history.replace(FrontendRoutes.Login.path);
        }
    }, [onLogout, history]);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);

    const didLogout = useCallback(() => {
        logoutAction(doLogoutRequest());
    }, [logoutAction]);

    return (
        <Layout style={style.conntainer}>
            {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            <SiderView title={title} />
            <Layout>
                <HeaderView title={title} didLogout={didLogout} />
                <Layout.Content>
                    {children}
                </Layout.Content>
            </Layout>
        </Layout>
    );
}

export default ScaffoldView;
