import { call, put, takeLatest } from 'redux-saga/effects';
import PromoApi from 'data/api/PromoApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './PromoDetailAction';

function* promoDetailTask(promoDetailAction: action.PromoDetailRequest) {
    const { promoId, promoType } = promoDetailAction;
    const response = yield call(PromoApi.getPromoDetail, promoId, promoType);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doPromoDetailSuccess(response.data.promoDetail));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doPromoDetailRequest(promoId, promoType) });
            yield put(action.doPromoDetailFailure(newTimeoutError));
        } else {
            yield put(action.doPromoDetailFailure(response.error));
        }
    }
}

export function* promoDetailSaga() {
    yield takeLatest(
        action.PROMO_DETAIL_REQUEST,
        promoDetailTask,
    );
}
