import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RootStoreState from 'rootStore';
import { HttpError } from 'data/Models';
import { FrontendRoutes } from 'navigation/Routes';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { ModalError, TypePageNotAllowed } from 'components/ModalComponent';
import { LoadingWithMask, GetMethodLoading } from 'components/LoadingComponent';
import AlertComponent from 'components/AlertComponent';
import { doGetProfileInitial, GetProfileAction } from 'features/scaffold/ScaffoldAction';
import { GetPermissionsAction, doGetPermissionsInitial } from 'features/user/RoleForm/PermissionTree/PermissionTreeAction';
import RoleFormView from '../RoleForm/RoleFormView';
import { status } from '../RoleForm/RoleFormConfig';
import { CreateRoleAction, doCreateRoleInitial, doCreateRoleRequest } from './RoleCreateAction';

function RoleCreateView() {
    const history = useHistory();

    const initialValues = {
        status: status[0].value,
    };
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];
    const isAllowedCreateRole = isPermissionAllowed(FrontendRoutes.RoleCreatePage.permissionKey, permissions);
    const getPermissionsAction = useDispatch<Dispatch<GetPermissionsAction>>();
    const permissionsState = useSelector((state: RootStoreState) => state.permissions);
    const createRoleAction = useDispatch<Dispatch<CreateRoleAction>>();
    const createRoleState = useSelector((state: RootStoreState) => state.createRole);
    const [error, setError] = useState<HttpError | null>(null);
    const [showNotAllowed, setShowNotAllowed] = useState<boolean>(false);

    useEffect(() => {
        if (profileState.data && !isAllowedCreateRole) {
            setShowNotAllowed(true);
        } else if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState, isAllowedCreateRole]);
    useEffect(() => {
        if (permissionsState.error) {
            getPermissionsAction(doGetPermissionsInitial());
            setError(permissionsState.error);
        }
    }, [getPermissionsAction, permissionsState]);
    useEffect(() => {
        if (createRoleState.success === true) {
            history.replace(FrontendRoutes.RoleManagement.path);
        } else if (createRoleState.error) {
            createRoleAction(doCreateRoleInitial());
            setError(createRoleState.error);
        }
    }, [createRoleAction, createRoleState, history]);
    const loading: boolean = profileState.onRequest || permissionsState.onRequest;
    const handleRoleCreate = useCallback((values: any) => {
        const data = {
            name: values.roleName,
            permissions: values.permissions,
            isActive: values.status === status[0].value,
        };
        createRoleAction(doCreateRoleRequest(data));
    }, [createRoleAction]);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);

    return (
        <div style={{ padding: '0 25px' }}>
            {!showNotAllowed ? (
                <>
                    {loading && <GetMethodLoading />}
                    {createRoleState.onRequest && <LoadingWithMask />}
                    {error && <AlertComponent error={error} clearFn={handleClearFn}/>}
                    {isAllowedCreateRole && (
                        <RoleFormView
                            initialValues={initialValues}
                            onFinish={handleRoleCreate}
                        />
                    )}
                </>
            ) : (
                <ModalError
                    visible={true}
                    type={TypePageNotAllowed}
                />
            )}
        </div>
    );
}

export default RoleCreateView;
