import {
    TEST_DETAIL_INITIAL,
    TEST_DETAIL_REQUEST,
    TEST_DETAIL_SUCCESS,
    TEST_DETAIL_FAILURE,
    TestDetailAction
} from './TestDetailAction';
import { TestDetailStoreState } from './TestDetailState';

export const defaultState: TestDetailStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function testDetailReducer(
    state: TestDetailStoreState = defaultState,
    action: TestDetailAction
): TestDetailStoreState {
    switch (action.type) {
        case TEST_DETAIL_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case TEST_DETAIL_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case TEST_DETAIL_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case TEST_DETAIL_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
