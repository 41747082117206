import { BranchFormData, HttpError } from "data/Models";

export const UPDATE_BRANCH_INITIAL = 'UPDATE_BRANCH_INITIAL';
export type UPDATE_BRANCH_INITIAL = typeof UPDATE_BRANCH_INITIAL;

export const UPDATE_BRANCH_REQUEST = 'UPDATE_BRANCH_REQUEST';
export type UPDATE_BRANCH_REQUEST = typeof UPDATE_BRANCH_REQUEST;

export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS';
export type UPDATE_BRANCH_SUCCESS = typeof UPDATE_BRANCH_SUCCESS;

export const UPDATE_BRANCH_FAILURE = 'UPDATE_BRANCH_FAILURE';
export type UPDATE_BRANCH_FAILURE = typeof UPDATE_BRANCH_FAILURE;

interface UpdateBranchInitial {
    type: UPDATE_BRANCH_INITIAL;
}

export interface UpdateBranchRequest {
    type: UPDATE_BRANCH_REQUEST;
    data: BranchFormData;
}

interface UpdateBranchSuccess {
    type: UPDATE_BRANCH_SUCCESS;
}

interface UpdateBranchFailure {
    type: UPDATE_BRANCH_FAILURE;
    error: HttpError;
}

export type UpdateBranchAction = UpdateBranchInitial | UpdateBranchRequest | UpdateBranchSuccess | UpdateBranchFailure;

export function doUpdateBranchInitial(): UpdateBranchInitial {
    return {
        type: UPDATE_BRANCH_INITIAL,
    };
}

export function doUpdateBranchRequest(data: BranchFormData): UpdateBranchRequest {
    return {
        type: UPDATE_BRANCH_REQUEST,
        data,
    };
}

export function doUpdateBranchSuccess(): UpdateBranchSuccess {
    return {
        type: UPDATE_BRANCH_SUCCESS,
    };
}

export function doUpdateBranchFailure(error: HttpError): UpdateBranchFailure {
    return {
        type: UPDATE_BRANCH_FAILURE,
        error: error,
    };
}
