import { call, put, takeLatest } from 'redux-saga/effects';
import PromoApi from 'data/api/PromoApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './DeletePromoAction';

function* deletePromoTask(deletePromoAction: action.DeletePromoRequest) {
    const { promoId, promoType } = deletePromoAction;
    const response = yield call(PromoApi.deletePromo, promoId, promoType);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doDeletePromoSuccess(promoType));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doDeletePromoRequest(promoId, promoType) });
            yield put(action.doDeletePromoFailure(promoType, newTimeoutError));
        } else {
            yield put(action.doDeletePromoFailure(promoType, response.error));
        }
    }
}

export function* deletePromoSaga() {
    yield takeLatest(
        action.DELETE_PROMO_REQUEST,
        deletePromoTask,
    );
}
