import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import AdminFormView from '../AdminForm/AdminFormView';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAdminAction, doCreateAdminInitial, doCreateAdminRequest } from './AdminCreateAction';
import RootStoreState from 'rootStore';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { doRoleManagementInitial, RoleManagementAction } from 'features/user/RoleManagement/RoleManagementAction';
import { doRegionListInitial, RegionListAction } from 'features/region/RegionAction';
import { doBranchListInitial, BranchListAction } from 'features/branch/BranchManagement/BranchAction';
import { ModalError, TypePageNotAllowed } from 'components/ModalComponent';
import { LoadingWithMask, GetMethodLoading } from 'components/LoadingComponent';
import AlertComponent from 'components/AlertComponent';
import { HttpError } from 'data/Models';
import { FrontendRoutes } from 'navigation/Routes';
import { useHistory } from 'react-router-dom';

function AdminCreateView() {
    const history = useHistory();

    const initialValues = {};

    const roleListAction = useDispatch<Dispatch<RoleManagementAction>>();
    const roleListState = useSelector((state: RootStoreState) => state.roleManagement);
    const regionListAction = useDispatch<Dispatch<RegionListAction>>();
    const regionListState = useSelector((state: RootStoreState) => state.regionList);
    const branchListAction = useDispatch<Dispatch<BranchListAction>>();
    const branchListState = useSelector((state: RootStoreState) => state.branchList);
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];
    const isAllowedCreateNew = isPermissionAllowed(FrontendRoutes.UserCreatePage.permissionKey, permissions);
    const createAdminAction = useDispatch<Dispatch<CreateAdminAction>>();
    const createAdminState = useSelector((state: RootStoreState) => state.createAdmin);
    const [error, setError] = useState<HttpError | null>(null);
    const [showNotAllowed, setShowNotAllowed] = useState<boolean>(false);

    useEffect(() => {
        if (profileState.data && !isAllowedCreateNew) {
            setShowNotAllowed(true);
        } else if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState, isAllowedCreateNew]);
    useEffect(() => {
        if (roleListState.error) {
            roleListAction(doRoleManagementInitial());
            setError(roleListState.error);
        }
    }, [roleListAction, roleListState]);
    useEffect(() => {
        if (regionListState.error) {
            regionListAction(doRegionListInitial());
            setError(regionListState.error);
        }
    }, [regionListAction, regionListState]);
    useEffect(() => {
        if (branchListState.error) {
            branchListAction(doBranchListInitial());
            setError(branchListState.error);
        }
    }, [branchListAction, branchListState]);
    useEffect(() => {
        if (createAdminState.success === true) {
            history.replace(FrontendRoutes.AdminManagement.path);
        } else if (createAdminState.error) {
            createAdminAction(doCreateAdminInitial());
            setError(createAdminState.error);
        }
    }, [createAdminAction, createAdminState, history]);
    const loading: boolean = profileState.onRequest || roleListState.onRequest|| branchListState.onRequest || regionListState.onRequest;
    const handleAdminCreate = useCallback((values: any) => {
        const newData = {
            fullName: values.fullName,
            email: values.email,
            regions: values.regions.join(','),
            branchs: values.branchs.join(','),
            role: values.role,
        };
        createAdminAction(doCreateAdminRequest(newData));
    }, [createAdminAction]);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);

    return (
        <div style={{ padding: '0 25px' }}>
            {!showNotAllowed ? (
                <>
                    {loading && <GetMethodLoading />}
                    {createAdminState.onRequest && <LoadingWithMask />}
                    {error && (<AlertComponent error={error} clearFn={handleClearFn}/>)}
                    {isAllowedCreateNew && (
                        <AdminFormView
                            mode="create"
                            initialValues={initialValues}
                            onFinish={handleAdminCreate}
                        />
                    )}
                </>
            ) : (
                <ModalError
                    visible={true}
                    type={TypePageNotAllowed}
                />
            )}
        </div>
    );
}

export default AdminCreateView;
