import { REACT_APP_API_TIMEOUT } from 'common/Predicates';
import { AuthStorage } from 'data/local/AuthStorage';
import { HttpError } from 'data/Models';
import { UnknownErrorCode, AbortError } from './HttpCode';
import { GeneralError, UnAuthenticatedError, UnknownErrorMsg } from './constans';
import sessionTimeManager from 'lib/SessionTimeManager';
import ApiEndpoint from 'data/api/ApiEndpoint';

const apiTimeout = REACT_APP_API_TIMEOUT;

export const HTTP_RESPONSE_SUCCESS = 'HTTP_RESPONSE_SUCCESS';
export type HTTP_RESPONSE_SUCCESS = typeof HTTP_RESPONSE_SUCCESS;

export const HTTP_RESPONSE_FAILURE = 'HTTP_RESPONSE_FAILURE';
export type HTTP_RESPONSE_FAILURE = typeof HTTP_RESPONSE_FAILURE;

interface HttpServiceSucess<T> {
    status: HTTP_RESPONSE_SUCCESS;
    data: T;
}

interface HttpServiceFailure {
    status: HTTP_RESPONSE_FAILURE;
    error: HttpError
}

export type HttpService<T> = HttpServiceSucess<T> | HttpServiceFailure

export function handleSuccess<T>(data: T): HttpServiceSucess<T> {
    return {
        status: HTTP_RESPONSE_SUCCESS,
        data: data
    };
}

export function handleFailure(error: HttpError): HttpServiceFailure {
    return {
        status: HTTP_RESPONSE_FAILURE,
        error: error
    };
}

export function handleDownloadResponse(
    successCode: number,
    response: Response,
    map: (blob: Blob, filename: string) => any
): Promise<HttpService<Blob>> {
    const filename = response.headers.get('Content-Disposition')?.split(';')[1].split('=')[1].replace(/"/g, '')
    const contentTypes = ["application/pdf", "image/jpeg", "image/png"];
    const responseType = response.headers.get('Content-Type') || '';
    const needBlob = contentTypes.includes(responseType);
    if (filename || needBlob) {
        return response.blob().then((blob) => {
            if (filename && response.status === successCode) {
                return handleSuccess(map(blob, filename));
            } else if (needBlob) {
                return handleSuccess(map(blob, ''));
            } else {
                return handleFailure(GeneralError);
            }
        })
    }
    return response.text().then((body) => {
        if (response.status) {
            const error = Object.assign(Object.create(HttpError.prototype), JSON.parse(body));
            return handleFailure(error);
        } else {
            return handleFailure(GeneralError);
        }
    })
}

export function handleResponse<T>(
    successCode: number,
    response: Response,
    map: (json: any) => T
): Promise<HttpService<T>> {
    return response.text().then((body) => {
        if (response.status === successCode) {
            if (body.length > 0) {
                return handleSuccess(map(JSON.parse(body)));
            } else {
                return handleSuccess(map(undefined));
            }
        } else {
            const error = Object.assign(Object.create(HttpError.prototype), JSON.parse(body));
            if (response.status === 422 && error.errors?.length > 0) {
                error.message = error.errors[0].error
            }
            return handleFailure(error);
        }
    })
}

export function createFallbackHttpError(error: Error): HttpError {
    if (error instanceof HttpError) return error;
    if (error.name === AbortError) {
        return new HttpError(AbortError, AbortError);
    }
    return new HttpError(UnknownErrorCode, UnknownErrorMsg);
}

export function handleCatch(error: HttpError): HttpServiceFailure {
    const httpError = createFallbackHttpError(error);

    return handleFailure(httpError);
}

function handleUnauthorized (url: string, response: Response) {
    const excludedEndpoints = [
        ApiEndpoint.Auth.Login,
        ApiEndpoint.Activation.CreatePassword,
        ApiEndpoint.Activation.Verify,
    ];
    const ignoreUnauthorizedHandling = excludedEndpoints.some(endpoint => url.includes(endpoint));
    if (ignoreUnauthorizedHandling) {
        return response;
    }

    if (response.status === 401) {
        sessionTimeManager.invalidateSession();
        return Promise.reject(UnAuthenticatedError);
    }

    return response;
}

export const CONTENT_TYPE_JSON = 'application/json';

export const defaultHeaders = {
    'Content-Type': 'application/json',
};

export const defaultOptions = {
    method: 'GET',
    headers: defaultHeaders,
};

export const constructUrlGetParameters = (baseUrl: any, params: any) => {
    const result = Object.keys(params).map(key => {
        if (params[key]) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
        }
        return '';
    });
    const queryString = result.length ? `?${result.join('&')}` : '';
    return `${baseUrl}${queryString}`;
};

export class RequestBuilder {
    private headers: any = {};
    private method: string = "GET";
    private body: any = {};
    private uri: any = '';
    private queryUrl: any = {};
    private requireHeadersReturn: boolean = false;
    private requestUrl: any = '';
    private isMultipart = false;

    constructor() {
        this.headers = defaultOptions.headers;
        this.body = {};
        this.uri = '';
        this.queryUrl = {};
        this.requireHeadersReturn = false;
        this.requestUrl = '';
        this.isMultipart = false;
    }

    setMethod(method: string) {
        this.method = method;
        return this;
    }

    setUri(uri: string) {
        this.uri = uri;
        return this;
    }

    setHeaders(headers: any) {
        this.headers = { ...this.headers, ...headers};
        return this;
    }

    setBody(body: any) {
        this.body = body;
        return this;
    }

    asMultipart() {
        this.isMultipart = true;
        return this;
    }

    setQueryParameter(queryUrl: any) {
        if (typeof queryUrl === 'object') {
            Object.keys(queryUrl).forEach((key) => {
                this.getQueryParameterUrl(key, queryUrl[key]);
            });
        }
        return this;
    }

    getQueryParameterUrl(key: any, value: any) {
        this.queryUrl[key] = value;
        return this;
    }

    getAuthHeader(): Record<string,string> {
        try {
            const token = AuthStorage.getValidToken();
            return { 'Authorization': `Bearer ${token}` };
        } catch (err) {
            return {};
        }
    }

    doMethod(method = 'GET'): Promise<Response> {
        const options: any = {
            mode: 'cors',
            cache: 'no-cache',
            ...defaultOptions,
            headers: {
                ...this.getAuthHeader(),
                ...this.headers,
            },
            method,
        };
        switch (method)
        {
            case "GET":
                this.requestUrl = constructUrlGetParameters(this.uri, this.queryUrl);
                break;
            case "POST":
            case "PUT":
            case "DELETE":
            case "PATCH":
                this.requestUrl = this.uri;

                if (this.body && typeof this.body === 'object') {
                    if (
                        options.headers['Content-Type'] &&
                        options.headers['Content-Type'].includes(CONTENT_TYPE_JSON) &&
                        !this.isMultipart
                    ) {
                        options.body = JSON.stringify(this.body);
                    } else {
                        options.body = this.body;
                    }
                }

                if (this.isMultipart) {
                    delete options.headers['Content-Type'];
                }

                break;
            default:
                const httpError = new HttpError(UnknownErrorCode, 'Unsupported Method');
                handleFailure(httpError);
                break;
        }
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), Number(apiTimeout));
        const response = fetch(this.requestUrl, {
            ...options,
            signal: controller.signal
        });
        response.then(() => {
            clearTimeout(id);
        }).catch(()=>{
            clearTimeout(id);
        });

        return response
            .then(res => handleUnauthorized(this.requestUrl, res));
    }

    doPost(): Promise<Response> {
        return this.doMethod('POST');
    }

    doPut(): Promise<Response> {
        return this.doMethod('PUT');
    }

    doGet(): Promise<Response> {
        return this.doMethod('GET');
    }

    doDelete(): Promise<Response> {
        return this.doMethod('DELETE');
    }

    doPatch(): Promise<Response> {
        return this.doMethod('PATCH');
    }
}
