import {
    TEST_MANAGEMENT_INITIAL,
    TEST_MANAGEMENT_REQUEST,
    TEST_MANAGEMENT_SUCCESS,
    TEST_MANAGEMENT_FAILURE,
    TestManagementAction
} from './TestManagementAction';
import { TestListStoreState } from './TestManagementState';

export const defaultState: TestListStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function testManagementReducer(
    state: TestListStoreState = defaultState,
    action: TestManagementAction
): TestListStoreState {
    switch (action.type) {
        case TEST_MANAGEMENT_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case TEST_MANAGEMENT_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case TEST_MANAGEMENT_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case TEST_MANAGEMENT_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
