import { FrontendRoutes } from "navigation/Routes";

export interface SideMeuItem {
    title: string;
    customTitle?: string;
    link?: string;
    childs?: SideMeuItem[];
    permissionKey?: string;
    isCustomTitle?: boolean;
}

export const availableMenus: SideMeuItem[] = [
    {
        title: FrontendRoutes.Dashboard.title,
        link: FrontendRoutes.Dashboard.path,
    },
    {
        title: FrontendRoutes.OrderManagement.title,
        link: FrontendRoutes.OrderManagement.path,
        permissionKey: FrontendRoutes.OrderManagement.permissionKey,
    },
    {
        title: 'Product Management',
        childs: [
            {
                title: FrontendRoutes.TestManagement.title,
                link: FrontendRoutes.TestManagement.path,
                permissionKey: FrontendRoutes.TestManagement.permissionKey,
            },
            {
                title: FrontendRoutes.PanelManagement.title,
                link: FrontendRoutes.PanelManagement.path,
                permissionKey: FrontendRoutes.PanelManagement.permissionKey,
            }
        ]
    },
    {
        title: 'Home Service',
        childs: [
            {
                title: FrontendRoutes.TNCManagementPage.title,
                link: FrontendRoutes.TNCManagementPage.path,
                permissionKey: FrontendRoutes.TNCManagementPage.permissionKey,
            },
        ]
    },
    {
        title: FrontendRoutes.PromoManagement.title,
        link: FrontendRoutes.PromoManagement.path,
        permissionKey: FrontendRoutes.PromoManagement.permissionKey,
    },
    {
        title: 'User Management',
        childs: [
            {
                title: FrontendRoutes.AdminManagement.title,
                link: FrontendRoutes.AdminManagement.path,
                permissionKey: FrontendRoutes.AdminManagement.permissionKey,
            },
            {
                title: FrontendRoutes.RoleManagement.title,
                link: FrontendRoutes.RoleManagement.path,
                permissionKey: FrontendRoutes.RoleManagement.permissionKey,
            }
        ]
    },
    {
        title: FrontendRoutes.BranchManagement.title,
        link: FrontendRoutes.BranchManagement.path,
        permissionKey: FrontendRoutes.BranchManagement.permissionKey,
    }
];
