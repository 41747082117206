import React, { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Images } from 'themes';
import RootStoreState from 'rootStore';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { UsedByUsersCode } from 'common/HttpCode';
import { TableIncrementNumber } from 'common/CommonUtils';
import { DefaultQueryType, defaultQuery, defaultRetrieveAll } from 'common/constans';
import { FrontendRoutes } from 'navigation/Routes';
import { Role, HttpError } from 'data/Models';
import AppTable from 'components/AppTableComponent';
import { SearchBox } from 'components/FilterComponent';
import { OvalButton } from 'components/ButtonComponent';
import AlertComponent from 'components/AlertComponent';
import { GetMethodLoading, LoadingWithMask } from 'components/LoadingComponent';
import { ModalConfirmation, TypeDeleteConfirmation } from 'components/ModalComponent';
import { DeleteRoleAction, doDeleteRoleInitial, doDeleteRoleRequest} from 'features/user/RoleDelete/DeleteRoleAction';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { CreateRoleAction, doCreateRoleInitial } from '../RoleCreate/RoleCreateAction';
import { doUpdateRoleInitial, UpdateRoleAction } from '../RoleEdit/RoleEditAction';
import { RoleManagementAction, doRoleManagementRequest, doRoleManagementInitial } from './RoleManagementAction';
import './index.css';

const Text = {
    wrapperId: 'roleManagementWrapperId',
    addNew: 'ADD NEW ROLE',
    viewDetail: 'View Detail',
    edit: 'Edit',
    delete: 'Delete',
    no: 'No',
    roleName: 'Role Name',
    deleteConfirmation: 'Are you sure you want to delete this role?',
};
const RoleManagementView = () => {
    const history = useHistory();
    const [shownPopOver, setShownPopover] = useState<number | null>(null);
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];

    const isAllowedRoleList = isPermissionAllowed(FrontendRoutes.RoleManagement.permissionKey, permissions);
    const isAllowedCreateRole = isPermissionAllowed(FrontendRoutes.RoleCreatePage.permissionKey, permissions);
    const isAllowedViewDetail = isPermissionAllowed(FrontendRoutes.RoleDetailPage.permissionKey, permissions);
    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.RoleEditPage.permissionKey, permissions);
    const isAllowedDeleteDetail = isPermissionAllowed(FrontendRoutes.RoleDeletePage.permissionKey, permissions);
    const showDots = isAllowedViewDetail || isAllowedEditDetail || isAllowedDeleteDetail;
    const roleManagementAction = useDispatch<Dispatch<RoleManagementAction>>();
    const roleListState = useSelector((state: RootStoreState) => state.roleManagement);
    const roleListData = roleListState.data;
    const deleteRoleAction = useDispatch<Dispatch<DeleteRoleAction>>();
    const deleteRoleState = useSelector((state: RootStoreState) => state.deleteRole);
    const [error, setError] = useState<HttpError | null>(null);
    const [deleteFromList, setDeleteFromList] = useState<boolean>(false);
    const [showModalConfirmation, setShowModalConfirmation] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [deleteConfirmationId, setDeleteConfirmationId] = useState<string>('');
    const [filterParams, setFilterParams] = useState<DefaultQueryType>(defaultQuery);

    useEffect(() => {
        if (isAllowedRoleList) {
            roleManagementAction(doRoleManagementRequest(defaultRetrieveAll, defaultQuery));
        }
    }, [roleManagementAction, isAllowedRoleList]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
        if (roleListState.error) {
            roleManagementAction(doRoleManagementInitial());
            setError(roleListState.error);
        }
    }, [roleManagementAction, roleListState]);
    const tableLoading: boolean = profileState.onRequest || roleListState.onRequest;
    useEffect(() => {
        if (deleteRoleState.error) {
            if (deleteRoleState.error.code === UsedByUsersCode) {
                setShowModalConfirmation(deleteRoleState.error.message);
            } else {
                setError(deleteRoleState.error);
            }
            deleteRoleAction(doDeleteRoleInitial());
        }
    }, [deleteRoleAction, deleteRoleState, history]);
    const handleDeleteFn = (roleId: string) => {
        setShowDeleteConfirmation(true);
        setDeleteConfirmationId(roleId);
        setShownPopover(null);
    };
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);
    const handleHistoryPush = useCallback((path: string) => {
        history.push(path);
    }, [history]);
    const handleCreateRole = useCallback(() =>{
        if (isAllowedCreateRole) {
            history.push(FrontendRoutes.RoleCreatePage.path);
        }
    }, [isAllowedCreateRole, history]);
    const handleShowPopOver = useCallback((isShown, index) => {
        let shownPopOver = null;
        if (!isShown) {
            shownPopOver = index;
        }

        setShownPopover(shownPopOver);
    }, []);
    const containerId = document.getElementById(Text.wrapperId);
    const PopOverContent = (record: any) => {
        const options = [
            {
                clickFn: () => handleHistoryPush(FrontendRoutes.RoleDetailPage.path.replace(':roleId', record.id)),
                imgSrc: Images.EyeBlue,
                text: Text.viewDetail,
                isAllowed: isAllowedViewDetail,
            },
            {
                clickFn: () => handleHistoryPush(FrontendRoutes.RoleEditPage.path.replace(':roleId', record.id)),
                imgSrc: Images.PencilBlue,
                text: Text.edit,
                isAllowed: isAllowedEditDetail,
                popOverItemClass: 'edit',
            },
            {
                clickFn: () => handleDeleteFn(record.id),
                imgSrc: Images.Delete,
                text: Text.delete,
                isAllowed: isAllowedDeleteDetail,
                popOverItemClass: 'red',
            },
        ];
        const popOverContentItems = options.map((props) => {
            const { clickFn, imgSrc, text, isAllowed, popOverItemClass } = props;

            if (isAllowed) {
                return (
                    <div key={text} className={`appPopOverItem ${popOverItemClass}`} onClick={clickFn}>
                        <img src={imgSrc} alt={text} />
                        <span>{text}</span>
                    </div>
                );
            }

            return null;
        });

        return (
            <div className="appPopOverContent">
                {popOverContentItems}
            </div>
        );
    };
    const columns: ColumnsType<Role> = [
        {
            title: Text.no,
            dataIndex: '',
            key: '',
            render: (text, row, index: number) => {
                return (
                    <span className="indexStyle">{TableIncrementNumber(filterParams.page, filterParams.limit, index)}</span>
                );
            },
            width: '10%',
        },
        {
            title: Text.roleName,
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any) => {
                if (isAllowedViewDetail) {
                    return (
                        <Link to={{
                            pathname: FrontendRoutes.RoleDetailPage.path.replace(':roleId', record.id),
                        }}>
                            <span className="nameStyle">{text}</span>
                        </Link>
                    )
                }
                return (
                    <span className="nameStyle">{text}</span>
                )
            },
        },
        {
            title: '',
            key: '',
            render: (text: any, record: any, index: any) => {
                const content = PopOverContent(record);
                const isShown = shownPopOver === index;

                let popupContainer = document.body;
                if (containerId) {
                    popupContainer = containerId;
                }
                return (
                    <div>
                        <Popover
                            content={content}
                            visible={isShown}
                            trigger="click"
                            placement="bottomRight"
                            onVisibleChange={()=>{}}
                            overlayClassName={'actionGroupPopOver'}
                            getPopupContainer={() => popupContainer}
                        >
                            <div className="roleManagementDotsWrapper">
                                {showDots && (
                                    <div className="roleManagementDots"
                                         onClick={() => handleShowPopOver(isShown, index)
                                         }>
                                        <img src={Images.Dots} alt="Dots" />
                                    </div>
                                )}
                            </div>
                        </Popover>
                    </div>
                )
            },
            width: '141px',
        },
    ];
    const handleRequestListAction = (retrieveAll: boolean, query: DefaultQueryType) => {
        setShownPopover(null);
        setFilterParams(query);
        roleManagementAction(doRoleManagementRequest(retrieveAll, query));
    };
    const handleSearchByKeyword = (keyword: string) => {
        const newQuery = Object.assign({}, filterParams, { keyword: keyword, page: defaultQuery.page });
        handleRequestListAction(defaultRetrieveAll, newQuery)
    };
    return (
        <div className="roleManagementWrapper" id={Text.wrapperId}>
            {deleteRoleState.onRequest && <LoadingWithMask />}
            <RoleActionAlertView deleteFromList={deleteFromList} setFilterParams={setFilterParams} />
            {error && (<AlertComponent error={error} clearFn={() => handleClearFn()}/>)}
            <ModalConfirmation
                visible={!!showDeleteConfirmation}
                handleClose={() => {
                    setShowDeleteConfirmation(false);
                }}
                buttonClick={()=>{
                    deleteRoleAction(doDeleteRoleRequest(deleteConfirmationId));
                    setDeleteFromList(true);
                }}
                message={Text.deleteConfirmation}
                type={TypeDeleteConfirmation}
            />
            <ModalConfirmation
                visible={!!showModalConfirmation}
                handleClose={() => {
                    setShowModalConfirmation('')
                }}
                message={showModalConfirmation}
            />
            <div className="roleManagementFilterWrapper">
                <SearchBox onSearch={handleSearchByKeyword}/>
                {isAllowedCreateRole && (
                    <OvalButton
                        type="primary"
                        buttonText={Text.addNew}
                        icon={<PlusOutlined />}
                        onClick={()=>handleCreateRole()}
                    />
                )}
            </div>
            <AppTable
                loading={{
                    spinning: tableLoading,
                    indicator: <GetMethodLoading />,
                    wrapperClassName: 'tableLoading'
                }}
                pageSize={roleListData?.pagination?.limit}
                total={roleListData?.pagination?.total}
                current={roleListData?.pagination?.page}
                onRequestData={(currentPage, pageSize) => {
                    const newQuery = Object.assign({}, filterParams, {
                        page: currentPage,
                        limit: pageSize,
                    });
                    handleRequestListAction(defaultRetrieveAll, newQuery);
                }}
                columns={columns}
                dataSource={roleListData?.data}
                rowKey={record => record.id}
                rowClassName={(record,index)=>index%2===0?'rowWhite':'rowBlue'}
            />
        </div>
    );
};

interface RoleActionAlertProp {
    deleteFromList: boolean;
    setFilterParams: Dispatch<SetStateAction<DefaultQueryType>>;
}
function RoleActionAlertView(props: RoleActionAlertProp) {
    const { deleteFromList, setFilterParams } = props;
    const createRoleAction = useDispatch<Dispatch<CreateRoleAction>>();
    const createRoleState = useSelector((state: RootStoreState) => state.createRole);
    const updateRoleAction = useDispatch<Dispatch<UpdateRoleAction>>();
    const updateRoleState = useSelector((state: RootStoreState) => state.updateRole);
    const deleteRoleAction = useDispatch<Dispatch<DeleteRoleAction>>();
    const deleteRoleState = useSelector((state: RootStoreState) => state.deleteRole);
    const roleManagementAction = useDispatch<Dispatch<RoleManagementAction>>();
    const [success, setSuccess] = useState<string | null>('');
    const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);

    const handleClearFn = useCallback(() => {
        setSuccess(null);
        if (deleteSuccess && deleteFromList) {
            setDeleteSuccess(false);
            roleManagementAction(doRoleManagementRequest(defaultRetrieveAll, defaultQuery));
            setFilterParams(defaultQuery);
        }
    }, [deleteSuccess, roleManagementAction, deleteFromList, setFilterParams]);
    useEffect(() => {
        if (createRoleState.success === true) {
            createRoleAction(doCreateRoleInitial());
            setSuccess('Create new role is success');
        } else if (updateRoleState.success === true) {
            updateRoleAction(doUpdateRoleInitial());
            setSuccess('Edit role is success');
        } else if (deleteRoleState.success === true) {
            deleteRoleAction(doDeleteRoleInitial());
            setSuccess('Delete role is success');
            setDeleteSuccess(true);
        }
    }, [createRoleAction, createRoleState, updateRoleAction, updateRoleState, deleteRoleAction, deleteRoleState, roleManagementAction]);

    return (
        <div>
            {success && <AlertComponent message={success} type={'success'} timeout={3000} clearFn={() => handleClearFn()}/>}
        </div>   
    );
}

export default RoleManagementView;
