import React from 'react';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Input } from 'antd';

interface RichTextEditorProps {
    htmlValue?: string;
    readOnly?: boolean;
    onEditorChange: (content: string, editor: TinyMCEEditor) => void;
}

function RichTextEditor(props: RichTextEditorProps) {
    const { htmlValue, readOnly, onEditorChange } = props;
    return (
        <div>
            <Editor
                value={htmlValue}
                init={{
                    height: 300,
                    width: 700,
                    menubar: false,
                    plugins: [
                        'lists'
                      ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help',
                }}
                disabled={readOnly}
                onEditorChange={onEditorChange}
            />
            <Input hidden/>
        </div>
    );
}

export default RichTextEditor
