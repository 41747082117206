import React, { useCallback, useState } from 'react';
import { SelectComponent } from 'components/SelectComponent';
import * as config from './config';
import { Images } from 'themes';

interface ItemOption {
    id: string;
    name: string;
}
interface Props {
    onSingleSelect?: (values: ItemOption) => void;
    onMultipleSelect?: (values: ItemOption[]) => void;
}
const Text = {
    availabilityHSLabel: 'Filter by Availibility Home Service',
    availabilityHSPlaceholder: 'Search',
};

function HomeServiceFilterView(props: Props) {
    let newListState = config.availibilityHSListState;
    let newLabel = Text.availabilityHSLabel;

    const [query, setQuery] = useState('');
    const handleGetOption = useCallback((availability: ItemOption) => {
        return { value: availability.id, label: availability.name }
    }, []);
    
    const availabilityHSList = newListState.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())) ?? [];

    return <SingleSelectFilterView
        label={newLabel}
        statusList={availabilityHSList}
        onGetOption={handleGetOption}
        onSelect={(value) => {
            if (props.onSingleSelect) {
                props.onSingleSelect(value);
            }
        }}
    />;
}

interface SingleSelectFilterViewProps {
    label: string;
    statusList: ItemOption[]
    onGetOption: (option: ItemOption) => { value: string; label: string };
    onSelect: (values: ItemOption) => void;
}

function SingleSelectFilterView(props: SingleSelectFilterViewProps) {
    const [isVisible, setDropdownVisible] = useState<boolean>(false);

    return (
        <SelectComponent
            selectProps={{
                placeholder: props.label,
                bordered: false,
                onDropdownVisibleChange: (open) => {
                    setDropdownVisible(open)
                },
                suffixIcon: () => {
                    return <img src={isVisible ? Images.ChevronUpGrey : Images.ChevronDownGrey} alt="Arrow"/>
                }
            }}
            data={props.statusList}
            onMapOption={props.onGetOption}
            onSelectedChange={props.onSelect}
            prefixLabel={`${props.label}: `}
            className={'statusFilterView'}
            optionClassName={'selectOptionWrapper'}
        />
    );
}

export default HomeServiceFilterView;
