import React, {Dispatch, useCallback, useEffect, useState} from 'react';
import { Button, Card, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RootStoreState from 'rootStore';
import FrontendScreens from 'navigation/Frontend.screens';
import { Branch, Region, Role, AdminAccountStatus } from 'data/Models';
import StatusInputView from 'components/StatusInput';
import { defaultQuery } from 'common/constans';
import { formRules } from 'features/user/AdminForm/AdminFormConfig';
import RegionInputView from 'features/region/RegionInputView';
import BranchInputView from 'features/branch/BranchManagement/BranchInputView';
import RoleInputView from 'features/user/RoleInput/RoleInputView';
import { doRegionListRequest, RegionListAction } from 'features/region/RegionAction';
import { doRoleManagementRequest, RoleManagementAction } from 'features/user/RoleManagement/RoleManagementAction';
import { BranchListAction, doBranchListRequest } from 'features/branch/BranchManagement/BranchAction';
import './AdminFormView.css';

export interface AdminFormValue {
    fullName?: string;
    email?: string;
    regions?: string[];
    branchs?: string[];
    role?: string;
    status?: AdminAccountStatus;
}

const Text = {
    fullName: 'Full Name',
    email: 'Email',
    region: 'Region',
    branch: 'Branch',
    role: 'Role',
    status: 'Status',
    submit: 'SUBMIT',
    cancel: 'Cancel',
};

interface Props {
    mode: 'create' | 'edit';
    initialValues: AdminFormValue;
    onFinish: (values: AdminFormValue) => void;
}

function AdminFormView(props: Props) {
    const history = useHistory();
    const [form] = Form.useForm();
    const [selectedRegionIds, setSelectedRegionIds] = useState<string[]>([]);
    const [selectedBranchIds, setSelectedBranchIds] = useState<string[]>([]);
    const [selectedBranchs, setSelectedBranchs] = useState<Branch[]>([]);
    const [allBranchs, setAllBranchs] = useState<Branch[]>([]);
    const regionListState = useSelector((state: RootStoreState) => state.regionList);
    const regionListAction = useDispatch<Dispatch<RegionListAction>>();
    const roleListAction = useDispatch<Dispatch<RoleManagementAction>>();
    const roleListState = useSelector((state: RootStoreState) => state.roleManagement);
    const branchListAction = useDispatch<Dispatch<BranchListAction>>();
    const branchListState = useSelector((state: RootStoreState) => state.branchList);
    const shouldHideStatusField = form.getFieldValue('status') === AdminAccountStatus.RequireActivation || !form.getFieldValue('status');

    useEffect(() => {
        branchListAction(doBranchListRequest({ isRetrieveAll: true }));
    }, [branchListAction]);
    useEffect(() => {
        roleListAction(doRoleManagementRequest(true, defaultQuery));
    }, [roleListAction]);
    useEffect(() => {
        regionListAction(doRegionListRequest());
    }, [regionListAction]);
    useEffect(() => {
        if (props.initialValues.regions && props.initialValues.regions.length > 0) {
            setSelectedRegionIds(props.initialValues.regions);
        }

        if (props.initialValues.branchs && props.initialValues.branchs.length > 0) {
            setSelectedBranchIds(props.initialValues.branchs);
        }

        form.setFieldsValue(props.initialValues);
    }, [form, props]);
    const loading = regionListState.onRequest || roleListState.onRequest || branchListState.onRequest;

    const handleRegionLoaded = (values: Region[]) => {
        if (selectedRegionIds.length === 0) {
            const regionIds = values.map((item) => item.id);
            form.setFieldsValue({ regions: regionIds });
        }
    };

    const handleRegionSelected = (values: Region[]) => {
        const regionIds = values.map((item) => item.id);
        const newAddedRegionIds = regionIds
            .filter((item) => !selectedRegionIds.includes(item));
        const newRemovedRegionIds = selectedRegionIds
            .filter((item) => !regionIds.includes(item));
        setSelectedRegionIds(regionIds);

        const branch = allBranchs.filter((item) => {
            if (item.region && newAddedRegionIds.includes(item.region.id)) {
                return true;
            } else if (item.region && newRemovedRegionIds.includes(item.region.id)) {
                return false;
            } else if (item.region && selectedRegionIds.includes(item.region.id) && selectedBranchs.includes(item)) {
                return true;
            } else {
                return false;
            }
        });
        if (values.length === 0) {
            const allRegionIds = regionListState?.data?.data?.map((item) => item.id);
            form.setFieldsValue({ regions: allRegionIds });
            form.setFieldsValue({ branchs: [] });
        } else {
            form.setFieldsValue({ regions: regionIds });
        }
        setSelectedBranchs(branch);
    };

    const handleBranchLoaded = (values: Branch[]) => {
        if (selectedBranchIds.length === 0) {
            form.setFieldsValue({ branchs: [] });
            setSelectedBranchs(values);
        }

        setAllBranchs(values);
    };

    const handleBranchSelected = (values: Branch[]) => {
        const branchIds = values.map((item) => item.id);
        form.setFieldsValue({ branchs: branchIds });
        setSelectedBranchIds(branchIds);
        setSelectedBranchs(values);
    };

    const handleRoleSelected = (values: Role) => {
        form.setFieldsValue({ role: values.id });
    };

    const handleCancel = useCallback(() => {
        if (history.length > 2) {
            history.goBack(); 
        } else {
            history.replace(FrontendScreens.AdminManagementPage.path)
        }
    }, [history]);

    return (
        !loading ? (
            <Card className="adminFormViewWrapper">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={props.onFinish}
                >
                    <Form.Item
                        label={Text.fullName}
                        name="fullName"
                        rules={formRules.fullName}
                        validateFirst
                    >
                        <Input bordered/>
                    </Form.Item>
                    <Form.Item
                        label={Text.email}
                        name="email"
                        rules={formRules.email}
                    >
                        <Input bordered disabled={props.mode === 'edit'}/>
                    </Form.Item>
                    <Form.Item
                        label={Text.region}
                        name="regions"
                    >
                        <RegionInputView
                            selectedOptions={selectedRegionIds}
                            onLoad={handleRegionLoaded}
                            onSelect={handleRegionSelected}
                        />
                    </Form.Item>
                    <Form.Item
                        label={Text.branch}
                        name="branchs"
                    >
                        <BranchInputView
                            selectedOptions={selectedBranchIds}
                            regionIds={selectedRegionIds}
                            onLoad={handleBranchLoaded}
                            onSelect={handleBranchSelected}
                        />
                    </Form.Item>
                    {!shouldHideStatusField ? (
                        <Form.Item
                            label={Text.status}
                            name="status"
                        >
                            <StatusInputView />
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label={Text.role}
                        name="role"
                        rules={formRules.role}
                    >
                        <RoleInputView
                            selectedOption={props.initialValues.role}
                            onSelect={handleRoleSelected}
                        />
                    </Form.Item>
                    <div className="adminEditViewAction">
                        <Button type="primary" htmlType="submit">
                            {Text.submit}
                        </Button>
                        <span style={{ flex: 1 }}/>
                        <Button type="text" onClick={handleCancel}>
                            {Text.cancel}
                        </Button>
                    </div>
                </Form>
            </Card>
        ) : null
    );
}

export default AdminFormView;
