import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import RootStoreState from 'rootStore';
import { HttpError } from 'data/Models';
import { FrontendRoutes } from 'navigation/Routes';
import { isPermissionAllowed } from 'common/PermissionUtils';
import * as status from 'components/StatusInput'
import { AgeUnit } from 'components/AgeInput';
import AlertComponent from 'components/AlertComponent';
import { ModalError, TypePageNotAllowed } from 'components/ModalComponent';
import { LoadingWithMask, GetMethodLoading } from 'components/LoadingComponent';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { doUpdatePanelInitial, doUpdatePanelRequest, UpdatePanelAction } from './PanelEditAction';
import { doPanelDetailInitial, doPanelDetailRequest, PanelDetailAction } from '../PanelDetail/PanelDetailAction';
import PanelFormView, { PanelFormValue } from '../PanelForm/PanelFormView';

function PanelEditView() {
    const history = useHistory();
    const { panelId } = useParams<{ panelId: string }>();
    const [initialValues, setInitialValues] = useState<PanelFormValue>();

    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];
    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.PanelEditPage.permissionKey, permissions);
    const panelDetailAction = useDispatch<Dispatch<PanelDetailAction>>();
    const panelDetailState = useSelector((state: RootStoreState) => state.panelDetail);
    const [isAlreadyFetchDetail, setAlreadyFetchDetail] = useState(false);

    const updatePanelAction = useDispatch<Dispatch<UpdatePanelAction>>();
    const updatePanelState = useSelector((state: RootStoreState) => state.updatePanel);
    const [error, setError] = useState<HttpError | null>(null);
    const [showNotAllowed, setShowNotAllowed] = useState<boolean>(false);

    useEffect(() => {
        if (profileState.data && !isAllowedEditDetail) {
            setShowNotAllowed(true);
        } else if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState, isAllowedEditDetail]);
    useEffect(() => {
        if (!isAlreadyFetchDetail && isAllowedEditDetail) {
            setAlreadyFetchDetail(true);
            panelDetailAction(doPanelDetailRequest(panelId));
        } else if (panelDetailState.data) {
            const { data } = panelDetailState;
            setInitialValues({
                name: data.name,
                nameEn: data.name_en,
                types: data.types,
                beginAge: data.requirement.age_min,
                beginAgeUnit: AgeUnit.Days,
                endAge: data.requirement.age_max,
                endAgeUnit: AgeUnit.Days,
                status: data.is_active ? status.active : status.inactive
            });
        } else if (panelDetailState.error) {
            panelDetailAction(doPanelDetailInitial());
            setError(panelDetailState.error);
        }
    }, [isAlreadyFetchDetail, isAllowedEditDetail, panelDetailAction, panelDetailState, panelId]);

    useEffect(() => {
        if (updatePanelState.success === true) {
            history.replace(FrontendRoutes.PanelManagement.path);
        } else if (updatePanelState.error) {
            updatePanelAction(doUpdatePanelInitial());
            setError(updatePanelState.error);
        }
    }, [updatePanelAction, updatePanelState, history]);

    const loading: boolean = profileState.onRequest || panelDetailState.onRequest;
    const handlePanelUpdate = useCallback((values: PanelFormValue) => {
        const updateData = {
            id: panelId,
            types: values.types,
            beginAge: values.beginAge,
            endAge: values.endAge,
            isActive: values.status === status.active,
        };
        updatePanelAction(doUpdatePanelRequest(updateData));
    }, [updatePanelAction, panelId]);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);

    return (
        <div style={{ padding: '0 25px' }}>
            {!showNotAllowed ? (
                <>
                    {loading && <GetMethodLoading />}
                    {updatePanelState.onRequest && <LoadingWithMask />}
                    {error && <AlertComponent error={error} clearFn={handleClearFn}/>}
                    {!loading &&isAllowedEditDetail && initialValues && (
                        <PanelFormView
                            initialValues={initialValues}
                            onFinish={handlePanelUpdate}
                        />
                    )}
                </>
            ) : (
                <ModalError
                    visible={true}
                    type={TypePageNotAllowed}
                />
            )}
        </div>
    );
}

export default PanelEditView;
