import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Branch } from 'data/Models';
import RootStoreState from 'rootStore';
import { BranchListAction, doBranchListRequest } from 'features/branch/BranchManagement/BranchAction';
import { SelectFilterComponent } from 'components/SelectComponent';

const Text = {
    label: 'Filter by Branch',
    searchPlaceholder: 'Search Branch',
};

interface Props {
    regionId?: number;
    onSelect: (values: Branch[]) => void;
}

function BranchFilterView(props: Props) {
    const branchListAction = useDispatch<Dispatch<BranchListAction>>();
    const branchListState = useSelector((state: RootStoreState) => state.branchList);
    const [query, setQuery] = useState('');
    const branchList = branchListState.data?.data?.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())) ?? [];

    useEffect(() => {
        branchListAction(doBranchListRequest({ isRetrieveAll: true }));
    }, [branchListAction]);
    
    const onGetOption = (region: Branch) => {
        return { value: region.id, label: region.name }
    };

    return (
        <SelectFilterComponent
            label={Text.label}
            searchPlaceholder={Text.searchPlaceholder}
            onSearch={(query) => setQuery(query)}
            data={branchList}
            onMapOption={onGetOption}
            onSelectedChange={props.onSelect}
        />
    );
}

export default BranchFilterView;
