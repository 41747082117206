import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Role } from 'data/Models';
import RootStoreState from 'rootStore';
import { doRoleManagementRequest, RoleManagementAction } from 'features/user/RoleManagement/RoleManagementAction';
import { SelectFilterComponent } from 'components/SelectComponent';
import { defaultQuery } from 'common/constans';

const Text = {
    label: 'Filter by Role',
    searchPlaceholder: 'Search Role',
};

interface Props {
    onSelect: (values: Role[]) => void;
}
function RoleFilterView(props: Props) {
    const roleListAction = useDispatch<Dispatch<RoleManagementAction>>();
    const roleListState = useSelector((state: RootStoreState) => state.roleManagement);
    const [query, setQuery] = useState('');
    const roleList = roleListState.data?.data?.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())) ?? [];

    useEffect(() => {
        roleListAction(doRoleManagementRequest(true, defaultQuery));
    }, [roleListAction]);
    
    const onGetOption = (region: Role) => {
        return { value: region.id, label: region.name }
    };

    return (
        <SelectFilterComponent
            label={Text.label}
            searchPlaceholder={Text.searchPlaceholder}
            onSearch={(query) => setQuery(query)}
            data={roleList}
            onMapOption={onGetOption}
            onSelectedChange={props.onSelect}
        />
    );
}

export default RoleFilterView;
