import {HomeServiceListQuery} from "../../common/States";
import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";
import { TNCDetail } from "data/Models";

const HomeServiceApi = {
    getTestList(queryParams: HomeServiceListQuery): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.HomeService.Base)
            .setQueryParameter(queryParams)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    homeServiceList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    getTNCDetail(): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.HomeService.TNC)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    tncDetail: json
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    updateTNC(newData: TNCDetail): Promise<http.HttpService<{}>> {
        
        const body = {
            checkout: true,
            detail: newData.detail,
            summary: newData.summary
        }

        return new http.RequestBuilder()
            .setUri(ApiEndpoint.HomeService.UPDATE_TNC)
            .setBody(body)
            .doPost()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true }
            }))
            .catch(error => http.handleCatch(error));
    },
};

export default HomeServiceApi;
