import { RuleObject } from "antd/lib/form";

export const formRules = {
    fullName: [
        {
            required: true,
            message: 'Cannot be empty',
        }
    ],
    email: [
        () => ({
            validator(rule: RuleObject, value: any) {
                let error: Error | null = null;
                
                if (!value || value.length === 0) {
                    error = new Error("Cannot be empty")
                } else if (value.length < 5 || value.length > 50) {
                    error = new Error('The email length should be within 5 to 50 characters');
                } else if (!value.match(/\S+@\S+\.\S+/)) {
                    error = new Error("Wrong email formats")
                }

                if (error) {
                    return Promise.reject(error);
                }

                return Promise.resolve();
            }
        })
    ],
    role: [
        {
            required: true,
            message: 'Cannot be empty',
        }
    ]
};
