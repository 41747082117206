import * as http from "common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";
import {PromoListQuery} from "common/States";
import {PromoFormData} from "data/Models";
import {promoTypeListState} from "common/constans";

const PromoApi = {
  getPromoList(queryParams: PromoListQuery): Promise<http.HttpService<{}>> {
    return new http.RequestBuilder()
      .setUri(ApiEndpoint.Promo.Base)
      .setQueryParameter(queryParams)
      .doGet()
      .then((response) =>
        http.handleResponse(200, response, (json: any): {} => {
          return {
            promoList: json,
          };
        })
      )
      .catch((error) => http.handleCatch(error));
  },
  getPromoDetail(
    promoId: string,
    promoType: string
  ): Promise<http.HttpService<{}>> {
    let path = "";
    switch (promoType) {
      case promoTypeListState[0].id:
        path = ApiEndpoint.Promo.Offer;
        break;
      case promoTypeListState[1].id:
        path = ApiEndpoint.Promo.FlashSale;
        break;
      case promoTypeListState[2].id:
        path = ApiEndpoint.Promo.General;
        break;
    }

    return new http.RequestBuilder()
      .setUri(`${path}/${promoId}`)
      .setQueryParameter({})
      .doGet()
      .then((response) =>
        http.handleResponse(200, response, (json: any): {} => {
          return {
            promoDetail: json,
          };
        })
      )
      .catch((error) => http.handleCatch(error));
  },
  deletePromo(
    promoId: string,
    promoType: string
  ): Promise<http.HttpService<{}>> {
    let path = "";
    switch (promoType) {
      case promoTypeListState[0].id:
        path = ApiEndpoint.Promo.Offer;
        break;
      case promoTypeListState[1].id:
        path = ApiEndpoint.Promo.FlashSale;
        break;
      case promoTypeListState[2].id:
        path = ApiEndpoint.Promo.General;
        break;
    }

    return new http.RequestBuilder()
      .setUri(`${path}/${promoId}`)
      .setQueryParameter({})
      .doDelete()
      .then((response) =>
        http.handleResponse(200, response, (json: any): {} => {
          return {
            success: true,
          };
        })
      )
      .catch((error) => http.handleCatch(error));
  },
  createPromo(newData: PromoFormData): Promise<http.HttpService<{}>> {
    const body = new FormData();
    body.append("title", newData.title);
    body.append("source", newData.source);
    body.append("title_en", newData.titleEn);
    body.append("description", newData.description);
    body.append("description_en", newData.descriptionEn);
    body.append("tnc", newData.tnc);
    body.append("tnc_en", newData.tncEn);
    if (newData.promoCode) {
      body.append("code", newData.promoCode);
    }
    if (newData.contractId) {
      body.append("contract_id", newData.contractId);
    }
    if (newData.contractIds) {
      body.append("contract_ids", newData.contractIds);
    }
    if (newData.aliases) {
      body.append("aliases", JSON.stringify(newData.aliases));
    }
    if (newData.quota) {
      body.append("quota", `${newData.quota}`);
    }
    body.append("periode_date_start", newData.periodeDate.start);
    body.append("periode_date_end", newData.periodeDate.end);
    body.append("publish_date_start", newData.publishDate.start);
    body.append("publish_date_end", newData.publishDate.end);
    body.append("cover_image", newData.coverImage);

    let uri = "";
    if (newData.promoType === promoTypeListState[0].id) {
      uri = `${ApiEndpoint.Promo.Offer}`;
    } else if (newData.promoType === promoTypeListState[2].id) {
      uri = `${ApiEndpoint.Promo.General}`;
    }

    return new http.RequestBuilder()
      .setUri(uri)
      .asMultipart()
      .setBody(body)
      .doPost()
      .then((response) =>
        http.handleResponse(200, response, (): {} => {
          return {success: true};
        })
      )
      .catch((error) => http.handleCatch(error));
  },
  updatePromo(newData: PromoFormData): Promise<http.HttpService<{}>> {
    const body = new FormData();
    body.append("title", newData.title);
    body.append("source", newData.source);
    body.append("title_en", newData.titleEn);
    body.append("description", newData.description);
    body.append("description_en", newData.descriptionEn);
    body.append("tnc", newData.tnc);
    body.append("tnc_en", newData.tncEn);
    if (newData.promoCode) {
      body.append("code", newData.promoCode);
    }
    if (newData.contractId) {
      body.append("contract_id", newData.contractId);
    }
    if (newData.contractIds) {
      body.append("contract_ids", newData.contractIds);
    }
    if (newData.aliases) {
      body.append("aliases", JSON.stringify(newData.aliases));
    }
    if (newData.quota) {
      body.append("quota", `${newData.quota}`);
    }
    body.append("periode_date_start", newData.periodeDate.start);
    body.append("periode_date_end", newData.periodeDate.end);
    body.append("publish_date_start", newData.publishDate.start);
    body.append("publish_date_end", newData.publishDate.end);
    if (newData.coverImage) {
      body.append("cover_image", newData.coverImage);
    }

    let uri = "";
    if (newData.promoType === promoTypeListState[0].id) {
      uri = `${ApiEndpoint.Promo.Offer}/${newData.id}`;
    } else if (newData.promoType === promoTypeListState[2].id) {
      uri = `${ApiEndpoint.Promo.General}/${newData.id}`;
    }

    return new http.RequestBuilder()
      .setUri(uri)
      .asMultipart()
      .setBody(body)
      .doPut()
      .then((response) =>
        http.handleResponse(200, response, (): {} => {
          return {success: true};
        })
      )
      .catch((error) => http.handleCatch(error));
  },
};

export default PromoApi;
