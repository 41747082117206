import { DefaultStoreState } from 'common/States';
import {
    UPDATE_TEST_INITIAL,
    UPDATE_TEST_REQUEST,
    UPDATE_TEST_SUCCESS,
    UPDATE_TEST_FAILURE,
    UpdateTestAction,
} from './TestEditAction';

export const defaultState: DefaultStoreState = {
    onRequest: false,
    success: false,
    error: null,
}

export function updateTestReducer(
    state: DefaultStoreState = defaultState,
    action: UpdateTestAction
): DefaultStoreState {
    switch (action.type) {
        case UPDATE_TEST_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, success: defaultState.success, error: defaultState.error };
        case UPDATE_TEST_REQUEST:
            return { ...state, onRequest: true, success: defaultState.success, error: defaultState.error };
        case UPDATE_TEST_SUCCESS:
            return { ...state, onRequest: false, success: true, error: defaultState.error };
        case UPDATE_TEST_FAILURE:
            return { ...state, onRequest: false, success: false, error: action.error };
        default:
            return state;
    }
}
