import { all, fork } from 'redux-saga/effects';
import { loginSaga } from 'features/login/LoginSaga';
import { getProfileSaga, logoutSaga } from 'features/scaffold/ScaffoldSaga';
import { changePasswordSaga } from 'features/account/ChangePassword/ChangePasswordSaga';
import { roleListSaga } from 'features/user/RoleManagement/RoleManagementSaga';
import { permissionListSaga } from 'features/user/RoleForm/PermissionTree/PermissionTreeSaga';
import { createRoleSaga } from 'features/user/RoleCreate/RoleCreateSaga';
import { updateRoleSaga } from 'features/user/RoleEdit/RoleEditSaga';
import { roleDetailSaga } from 'features/user/RoleDetail/RoleDetailSaga';
import { regionListSaga } from 'features/region/RegionSaga';
import { branchListSaga } from 'features/branch/BranchManagement/BranchSaga';
import { branchDetailSaga } from 'features/branch/BranchDetail/BranchDetailSaga';
import { updateBranchSaga } from 'features/branch/BranchEdit/BranchEditSaga';
import { deleteRoleSaga } from 'features/user/RoleDelete/DeleteRoleSaga';
import { adminManagementSaga } from 'features/user/AdminManagement/AdminManagementSaga';
import { createAdminSaga } from 'features/user/AdminCreate/AdminCreateSaga';
import { adminDetailSaga } from 'features/user/AdminDetail/AdminDetailSaga';
import { deleteAdminSaga } from 'features/user/AdminDelete/DeleteAdminSaga';
import { updateAdminSaga } from 'features/user/AdminEdit/AdminEditSaga';
import { panelManagementSaga } from 'features/product/panel/PanelManagement/PanelManagementSaga';
import { panelDetailSaga } from 'features/product/panel/PanelDetail/PanelDetailSaga';
import { updatePanelSaga } from 'features/product/panel/PanelEdit/PanelEditSaga';
import { testManagementSaga } from 'features/product/test/TestManagement/TestManagementSaga';
import { testDetailSaga } from 'features/product/test/TestDetail/TestDetailSaga';
import { updateTestSaga } from 'features/product/test/TestEdit/TestEditSaga';
import { orderManagementSaga } from 'features/order/OrderManagement/OrderManagementSaga';
import { paymentChannelListSaga } from 'features/paymentChannel/PaymentChannelSaga';
import { orderManagementDownloadSaga } from 'features/order/OrderManagementDownload/OrderManagementDownloadSaga';
import { orderDetailSaga } from 'features/order/OrderDetail/OrderDetailSaga';
import { orderDetailDownloadSaga } from 'features/order/OrderDetailDownload/OrderDetailDownloadSaga';
import { promoListSaga } from 'features/promo/PromoManagement/PromoManagementSaga';
import { promoDetailSaga } from 'features/promo/PromoDetail/PromoDetailSaga';
import { deletePromoSaga } from 'features/promo/PromoDelete/DeletePromoSaga';
import { contractListSaga } from 'features/contract/ContractList/ContractListSaga';
import { contractDetailSaga } from 'features/contract/ContractDetail/ContractDetailSaga';
import { createPromoSaga } from 'features/promo/PromoCreate/PromoCreateSaga';
import { updatePromoSaga } from 'features/promo/PromoEdit/PromoEditSaga';
import { tncDetailSaga } from 'features/homeService/tnc/TNCDetail/TNCDetailSaga';
import { updateTNCSaga } from 'features/homeService/tnc/TNCManagement/TNCManagementSaga';

function* rootSaga() {
    yield all([
        fork(loginSaga),
        fork(logoutSaga),
        fork(getProfileSaga),
        fork(changePasswordSaga),
        fork(roleListSaga),
        fork(createRoleSaga),
        fork(updateRoleSaga),
        fork(permissionListSaga),
        fork(roleDetailSaga),
        fork(deleteRoleSaga),
        fork(regionListSaga),
        fork(branchListSaga),
        fork(branchDetailSaga),
        fork(updateBranchSaga),
        fork(adminManagementSaga),
        fork(createAdminSaga),
        fork(updateAdminSaga),
        fork(adminDetailSaga),
        fork(deleteAdminSaga),
        fork(panelManagementSaga),
        fork(panelDetailSaga),
        fork(updatePanelSaga),
        fork(testManagementSaga),
        fork(tncDetailSaga),
        fork(updateTNCSaga),
        fork(testDetailSaga),
        fork(updateTestSaga),
        fork(orderManagementSaga),
        fork(paymentChannelListSaga),
        fork(orderManagementDownloadSaga),
        fork(orderDetailSaga),
        fork(orderDetailDownloadSaga),
        fork(promoListSaga),
        fork(promoDetailSaga),
        fork(deletePromoSaga),
        fork(contractListSaga),
        fork(contractDetailSaga),
        fork(createPromoSaga),
        fork(updatePromoSaga),
    ])
}

export default rootSaga;
