import React from 'react';
import { Space } from 'antd';
import { defaultQuery } from 'common/constans';
import { TestListQuery } from 'common/States';
import { SearchBox } from 'components/FilterComponent';
import HomeServiceFilterView from 'components/FilterComponent/HomeServiceFilterView';

interface AvailabilityFilter {
    id?: string;
    name?: string;
}

interface TestManagementFilterViewProps {
    filterParams: TestListQuery;
    onRequestListAction: (query: TestListQuery) => void
}

function TestManagementFilterView(props: TestManagementFilterViewProps) {
    const { filterParams, onRequestListAction } = props;

    const handleSearchByKeyword = (keyword: string) => {
        if (keyword.length >= 3) {
            const newQuery = { ...filterParams, keyword: keyword, page: defaultQuery.page };
            onRequestListAction(newQuery);
        } else if (filterParams.keyword?.length ?? 0 >= 3) {
            const newQuery = { ...filterParams, keyword: "", page: defaultQuery.page };
            onRequestListAction(newQuery);
        }
    };
    const handleAvailabilityHSSelected = (value: AvailabilityFilter) => {
        const newQuery = { ...filterParams, available_hs: value.id, page: defaultQuery.page };
        onRequestListAction(newQuery);
    };

    return (
        <Space size={16}>
            <SearchBox onSearch={handleSearchByKeyword}/>
            <HomeServiceFilterView onSingleSelect={handleAvailabilityHSSelected}/>
        </Space>
    );
}

export default TestManagementFilterView;
