import { PromoFormData, HttpError } from "data/Models";

export const UPDATE_PROMO_INITIAL = 'UPDATE_PROMO_INITIAL';
export type UPDATE_PROMO_INITIAL = typeof UPDATE_PROMO_INITIAL;

export const UPDATE_PROMO_REQUEST = 'UPDATE_PROMO_REQUEST';
export type UPDATE_PROMO_REQUEST = typeof UPDATE_PROMO_REQUEST;

export const UPDATE_PROMO_SUCCESS = 'UPDATE_PROMO_SUCCESS';
export type UPDATE_PROMO_SUCCESS = typeof UPDATE_PROMO_SUCCESS;

export const UPDATE_PROMO_FAILURE = 'UPDATE_PROMO_FAILURE';
export type UPDATE_PROMO_FAILURE = typeof UPDATE_PROMO_FAILURE;

interface UpdatePromoInitial {
    type: UPDATE_PROMO_INITIAL;
}

export interface UpdatePromoRequest {
    type: UPDATE_PROMO_REQUEST;
    newData: PromoFormData;
}

interface UpdatePromoSuccess {
    type: UPDATE_PROMO_SUCCESS;
}

interface UpdatePromoFailure {
    type: UPDATE_PROMO_FAILURE;
    error: HttpError;
}

export type UpdatePromoAction = UpdatePromoInitial | UpdatePromoRequest | UpdatePromoSuccess | UpdatePromoFailure;

export function doUpdatePromoInitial(): UpdatePromoInitial {
    return {
        type: UPDATE_PROMO_INITIAL,
    };
}

export function doUpdatePromoRequest(newData: PromoFormData): UpdatePromoRequest {
    return {
        type: UPDATE_PROMO_REQUEST,
        newData: newData,
    };
}

export function doUpdatePromoSuccess(): UpdatePromoSuccess {
    return {
        type: UPDATE_PROMO_SUCCESS,
    };
}

export function doUpdatePromoFailure(error: HttpError): UpdatePromoFailure {
    return {
        type: UPDATE_PROMO_FAILURE,
        error: error,
    };
}
