import React, { useMemo } from 'react';
import { BranchDetail, BranchDetailTest } from 'data/Models';
import BranchEditTestList from './BranchEditTestList';
import BranchEditSelectedTestList from './BranchEditSelectedTestList';
import { Images } from 'themes';

import './BranchTestListInput.css';

interface Props {
  data: BranchDetail;
  availableTests: BranchDetailTest[];
  persistedSelectedTests: BranchDetailTest[];
  selectedTestIds: string[];
  removedTestIds: string[];
  onRemovedTestsChange: (testIds: string[]) => void;
  onSelectedTestsChange: (testIds: string[]) => void;
  onPersistTestIdsSelection: () => void;
  onPersistTestIdsRemoval: () => void;
}

const BranchTestListInput: React.FC<Props> = (props) => {
  const {
    availableTests,
    persistedSelectedTests,
    selectedTestIds,
    removedTestIds,
    onRemovedTestsChange,
    onSelectedTestsChange,
    onPersistTestIdsSelection,
    onPersistTestIdsRemoval,
  } = props;

  const persistedSelectedTestIds = useMemo(() => {
    return persistedSelectedTests.map(test => test.id);
  }, [persistedSelectedTests]);
  const filteredAvailableTest = useMemo(() => {
    return availableTests.filter(test => !persistedSelectedTestIds.includes(test.id))
  }, [availableTests, persistedSelectedTestIds]);
  return (
    <div className="branchTestListInput">
      <div className="testAvailableWrapper">
        <BranchEditTestList 
          tests={filteredAvailableTest}
          selectedTestIds={selectedTestIds}
          onChange={onSelectedTestsChange} 
        />
      </div>
      <div className="moveItemButtons">
        <button type="button" disabled={!Boolean(selectedTestIds.length)} onClick={onPersistTestIdsSelection}>
          <img src={Images.ArrowRight} alt="arrow right" />
        </button>
        <button type="button" disabled={!Boolean(removedTestIds.length)} onClick={onPersistTestIdsRemoval}>
          <img src={Images.ArrowLeft} alt="arrow left" />
        </button>
      </div>
      <div className="testSelectedWrapper">
        <BranchEditSelectedTestList 
          selectedTests={persistedSelectedTests}
          selectedTestIds={removedTestIds}
          onChange={onRemovedTestsChange}
        />
      </div>
    </div>
  );
};

export default BranchTestListInput;