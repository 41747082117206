import { HttpError } from 'data/Models';

export const DELETE_ROLE_INITIAL = 'DELETE_ROLE_INITIAL';
export type DELETE_ROLE_INITIAL = typeof DELETE_ROLE_INITIAL;

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export type DELETE_ROLE_REQUEST = typeof DELETE_ROLE_REQUEST;

export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export type DELETE_ROLE_SUCCESS = typeof DELETE_ROLE_SUCCESS;

export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';
export type DELETE_ROLE_FAILURE = typeof DELETE_ROLE_FAILURE;

interface DeleteRoleInitial {
    type: DELETE_ROLE_INITIAL;
}

export interface DeleteRoleRequest {
    type: DELETE_ROLE_REQUEST;
    roleId: string;
}

interface DeleteRoleSuccess {
    type: DELETE_ROLE_SUCCESS;
}

interface DeleteRoleFailure {
    type: DELETE_ROLE_FAILURE;
    error: HttpError;
}

export type DeleteRoleAction = DeleteRoleInitial | DeleteRoleRequest | DeleteRoleSuccess | DeleteRoleFailure;

export function doDeleteRoleInitial(): DeleteRoleInitial {
    return {
        type: DELETE_ROLE_INITIAL
    };
}

export function doDeleteRoleRequest(roleId: string): DeleteRoleRequest {
    return {
        type: DELETE_ROLE_REQUEST,
        roleId: roleId,
    };
}

export function doDeleteRoleSuccess(): DeleteRoleSuccess {
    return {
        type: DELETE_ROLE_SUCCESS
    };
}

export function doDeleteRoleFailure(error: HttpError): DeleteRoleFailure {
    return {
        type: DELETE_ROLE_FAILURE,
        error: error
    };
}
