import React, { useCallback, useEffect, useState } from 'react';
import './index.css';
import { Button, Card, Col, Form, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import FrontendScreens from 'navigation/Frontend.screens';
import AgeInputView from 'components/AgeInput';
import StatusInputView from 'components/StatusInput';
import ProductTypeInputView from 'components/ProductTypeInput';

export interface TestFormValue {
    name: string;
    nameEn: string;
    types: string[];
    beginAge: number;
    beginAgeUnit: string;
    endAge: number;
    endAgeUnit: string;
    status: string;
}

const Text = {
    name: {
        id: 'Name Test (ID)',
        en: 'Name Test (EN)'
    },
    types: 'Type',
    beginAge: 'Begin Age',
    endAge: 'End Age',
    status: 'Status',
    submit: 'SUBMIT',
    cancel: 'Cancel',
};

interface Props {
    initialValues: TestFormValue;
    onFinish: (values: TestFormValue) => void;
}

function TestFormView(props: Props) {
    const history = useHistory();
    const [form] = Form.useForm();
    const [beginAge, setBeginAge] = useState(0);
    const [endAge, setEndAge] = useState(0);

    useEffect(() => {
        form.setFieldsValue(props.initialValues);
        if (props.initialValues.beginAge) {
            setBeginAge(props.initialValues.beginAge);
        }
        if (props.initialValues.endAge) {
            setEndAge(props.initialValues.endAge);
        }
    }, [form, props]);

    const handleBeginAgeChange = useCallback((value: number) => {
        setBeginAge(value);
    }, []);
    const handleEndAgeChange = useCallback((value: number) => {
        setEndAge(value);
    }, []);
    const handleFinish = useCallback((values: TestFormValue) => {
        props.onFinish({
            ...values,
            beginAge: beginAge,
            endAge: endAge,
        });
    }, [props, beginAge, endAge]);
    const handleCancel = useCallback(() => {
        if (history.length > 2) {
            history.goBack(); 
        } else {
            history.replace(FrontendScreens.TestManagementPage.path)
        }
    }, [history]);

    const infoItems = [
        {
            label: Text.name.id,
            value: props.initialValues.name,
        },
        {
            label: Text.name.en,
            value: props.initialValues.nameEn,
        },
    ];

    return (
        <Card className="testFormViewWrapper">
            {infoItems.map((item) => <InfoItem key={item.label} value={item.value} label={item.label} />)}
            <Form
                layout="vertical"
                form={form}
                onFinish={handleFinish}
            >
                <Form.Item
                    label={Text.types}
                    name="types"
                >
                    <ProductTypeInputView />
                </Form.Item>
                <Form.Item
                    label={Text.beginAge}
                >
                    <AgeInputView
                        value={props.initialValues.beginAge}
                        onChange={handleBeginAgeChange}
                    />
                </Form.Item>
                <Form.Item
                    label={Text.endAge}
                >
                    <AgeInputView
                        value={props.initialValues.endAge}
                        onChange={handleEndAgeChange}
                    />
                </Form.Item>
                <Form.Item
                    label={Text.status}
                    name="status"
                >
                    <StatusInputView />
                </Form.Item>
                <div className="testFormViewAction">
                    <Button type="primary" htmlType="submit">
                        {Text.submit}
                    </Button>
                    <span style={{ flex: 1 }}/>
                    <Button type="text" onClick={handleCancel}>
                        {Text.cancel}
                    </Button>
                </div>
            </Form>
        </Card>
    );
}

interface InfoItemProps {
    label: string;
    value: string;
}

function InfoItem(props: InfoItemProps) {
    return (
        <Row className="infoRow">
            <Col span={12} className="infoCol"><div className="infoLabel">{props.label}</div></Col>
            <Col span={12} className="infoCol"><div className="infoValue">{props.value}</div></Col>
        </Row>
    );
}

export default TestFormView;
