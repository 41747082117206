import React from 'react';
import { Modal } from 'antd';
import { Images } from 'themes';
import { OvalButton } from 'components/ButtonComponent';
import { TypeDeleteConfirmation } from './index';
import './index.css';

const Text = {
    deleteConfirmationButton: 'YES, DELETE',
};
const ModalConfirmation: React.FC<{
    title?: string;
    visible?: boolean;
    width?: number;
    maskStyle?: Object;
    closable?: boolean;
    maskClosable?: boolean;
    handleClose?: () => void;
    message?: string;
    wrapClassName?: string;
    buttonText?: string;
    buttonIcon?: any;
    buttonType?: string;
    buttonClassName?: string;
    buttonClick?: () => void;
    type?: string;
}> = ({
    title = 'Confirmation',
    visible = false,
    width = 300,
    maskStyle = {
        opacity: 0.7,
        backgroundColor: '#000000',
    },
    closable = true,
    maskClosable = false,
    handleClose = () => {},
    message = '',
    wrapClassName = '',
    buttonText = 'OK',
    buttonIcon = '',
    buttonType = 'primary',
    buttonClassName = '',
    buttonClick = null,
    type = ''
}) => {
    const closeIcon = (
        <img src={Images.Close} alt="close"/>
    );
    const handleCloseFn = () => {
        handleClose();
    };
    let footer: any[];
    if (type === TypeDeleteConfirmation) {
        footer = [
            <OvalButton
                type={buttonType}
                wrapperClassName={'ovalButtonRed'}
                buttonText={Text.deleteConfirmationButton}
                key={buttonText}
                onClick={()=>{
                    handleCloseFn()
                    if (buttonClick) {
                        buttonClick()
                    }
                }}
                icon={<img className="ovalButtonImg" src={Images.DeleteWhite} alt="delete" />}
            />
        ];
    } else {
        footer = [
            <OvalButton
                type={buttonType}
                wrapperClassName={buttonClassName}
                buttonText={buttonText}
                key={buttonText}
                onClick={()=>{
                    handleCloseFn()
                    if (buttonClick) {
                        buttonClick()
                    }
                }}
                icon={buttonIcon}
            />
        ];
    }
    return (
      <>
          <Modal
              maskClosable={maskClosable}
              title={title}
              centered
              closeIcon={closeIcon}
              visible={visible}
              width={width}
              maskStyle={maskStyle}
              closable={closable}
              onCancel={() => handleCloseFn()}
              footer={footer}
              wrapClassName={`${wrapClassName} ModalConfirmationWrapper ${type === TypeDeleteConfirmation && TypeDeleteConfirmation}`}
          >
              <div>
                  {message}
              </div>
          </Modal>
      </>
    )
};

export default ModalConfirmation;
