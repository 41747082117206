import { ContractListQuery } from "common/States";
import { HttpError, ContractList } from "data/Models";

export const CONTRACT_LIST_INITIAL = 'CONTRACT_LIST_INITIAL';
export type CONTRACT_LIST_INITIAL = typeof CONTRACT_LIST_INITIAL;

export const CONTRACT_LIST_REQUEST = 'CONTRACT_LIST_REQUEST';
export type CONTRACT_LIST_REQUEST = typeof CONTRACT_LIST_REQUEST;

export const CONTRACT_LIST_SUCCESS = 'CONTRACT_LIST_SUCCESS';
export type CONTRACT_LIST_SUCCESS = typeof CONTRACT_LIST_SUCCESS;

export const CONTRACT_LIST_FAILURE = 'CONTRACT_LIST_FAILURE';
export type CONTRACT_LIST_FAILURE = typeof CONTRACT_LIST_FAILURE;

interface ContractListInitial {
    type: CONTRACT_LIST_INITIAL;
}

export interface ContractListRequest {
    type: CONTRACT_LIST_REQUEST;
    filterParams: ContractListQuery;
}

interface ContractListSuccess {
    type: CONTRACT_LIST_SUCCESS;
    data: ContractList;
}

interface ContractListFailure {
    type: CONTRACT_LIST_FAILURE;
    error: HttpError;
}

export type ContractListAction = ContractListInitial | ContractListRequest | ContractListSuccess | ContractListFailure;

export function doContractListInitial(): ContractListInitial {
    return {
        type: CONTRACT_LIST_INITIAL,
    };
}

export function doContractListRequest(filterParams: ContractListQuery): ContractListRequest {
    return {
        type: CONTRACT_LIST_REQUEST,
        filterParams: filterParams,
    };
}

export function doContractListSuccess(data: ContractList): ContractListSuccess {
    return {
        type: CONTRACT_LIST_SUCCESS,
        data: data,
    };
}

export function doContractListFailure(error: HttpError): ContractListFailure {
    return {
        type: CONTRACT_LIST_FAILURE,
        error: error,
    };
}
