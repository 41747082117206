// @ts-ignore 
import AuthStorageSharedWorker from './auth-storage.worker';
import { actions, getToken, setToken } from './message';

export default class InMemoryAuthStorage {
  static instance: InMemoryAuthStorage | null = null;
  private token: string = ''; 
  private sharedWorker: SharedWorker;
  private broadcastChannel: BroadcastChannel;
  
  private constructor () {
    this.sharedWorker = new AuthStorageSharedWorker();
    this.sharedWorker.port.start();
    this.sharedWorker.onerror = console.log;
    this.sharedWorker.port.onmessageerror = console.log;
    this.sharedWorker.port.addEventListener('message', this.handleSetToken.bind(this));
    this.broadcastChannel = new BroadcastChannel('auth-storage');
    this.broadcastChannel.addEventListener('message', this.handleBroadcast.bind(this));
  }

  static getInstance () : InMemoryAuthStorage {
    if (!InMemoryAuthStorage.instance) {
      InMemoryAuthStorage.instance = new InMemoryAuthStorage();
    }

    return InMemoryAuthStorage.instance;
  }

  private handleBroadcast(event: MessageEvent) : void {
    if (event.data.action === actions.SET_TOKEN) {
      this.token = event.data.token;
    } 
  }

  /**
   * Broadcast token to other tab/window
   */
  private broadcastToken(token: string) : void {
    this.broadcastChannel.postMessage(setToken(token));
  }

  private handleSetToken(event: MessageEvent) : void {
    if (event.data.action === actions.SET_TOKEN_REPLY) {
      this.token = event.data.token;
      this.broadcastToken(event.data.token);
    }
  }
  
  public getToken () : string {
    return this.token;
  }
  
  /**
   * Fetch token from web worker
   * The token will be cached in the instance
   * To get the cached token, use getToken() method.
   */
  public fetchToken () : Promise<string> {
    return new Promise((resolve) => {
      const self = this;

      function messageCallback (event: MessageEvent) {
        if (event.data.action === actions.GET_TOKEN_REPLY) {
          self.token = event.data.token;
        }
                
        resolve(self.token);
        self.sharedWorker.port.removeEventListener('message', messageCallback);
      };

      this.sharedWorker.port.addEventListener('message', messageCallback);
      this.sharedWorker.port.postMessage(getToken());
    });
  }

  /**
   * Store token to web worker.
   */
  public setToken (token: string) : Promise<string> {
    return new Promise((resolve, reject) => {
      if (typeof token !== 'string') {
        reject(new TypeError('token should be a string'));
        return; 
      }
      this.token = token;
      this.sharedWorker.port.postMessage(setToken(token));
      const self = this;
      function messageCallback(event: MessageEvent) {
        if (event.data.action === actions.SET_TOKEN_REPLY) {
          resolve(token);
          self.sharedWorker.port.removeEventListener('message', messageCallback);
        }
      }

      this.sharedWorker.port.addEventListener('message', messageCallback);
    });
  }
}