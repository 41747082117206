import { AdminDetail, UserFormData } from "data/Models";
import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";
import { UserListQuery } from "common/States";

interface UpdateUserPayload {
    name: string;
    email: string;
    region_ids: string[];
    outlet_ids: string[];
    role_id: string;
    status?: string;
}

const UserApi = {
    getUserList (queryParams: UserListQuery): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.User.Base)
            .setQueryParameter(queryParams)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    userList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    createUser (newData: UserFormData): Promise<http.HttpService<{}>> {
        const body = {
            name: newData.fullName,
            email: newData.email,
            region: newData.email,
            region_ids: newData.regions,
            outlet_ids: newData.branchs,
            role_id: newData.role,
        }
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.User.Base)
            .setBody(body)
            .doPost()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true }
            }))
            .catch(error => http.handleCatch(error));
    },
    updateUser (newData: UserFormData): Promise<http.HttpService<{}>> {
        const body : UpdateUserPayload = {
            name: newData.fullName,
            email: newData.email,
            region_ids: newData.regions,
            outlet_ids: newData.branchs,
            role_id: newData.role,
        };

        if (newData.status) {
            body.status = newData.status;
        }

        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.User.Base}/${newData.id}`)
            .setBody(body)
            .doPut()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true }
            }))
            .catch(error => http.handleCatch(error));
    },
    getAdminDetail (userId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.User.Base}/${userId}`)
            .setQueryParameter({})
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    userDetail: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    deleteAdmin (userId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.User.Base}/${userId}`)
            .setQueryParameter({})
            .doDelete()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    success: true,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    sendActivation (userId: string): Promise<{ activationPIN: string }> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Activation.Resend)
            .setBody({
                user_id: userId,
            })
            .doPost()
            .then(response => http.handleResponse(200, response, (json: any): {} => ({
                data: json
            }))
            .then((response) => {
                if (response.status === http.HTTP_RESPONSE_FAILURE) {
                    return Promise.reject(response.error);
                }

                return response;
            }))
            .then(() => this.getAdminDetail(userId))
            .then((res) => {
                const response = res as unknown as  { data: { userDetail: AdminDetail } };

                return { activationPIN: response.data.userDetail.activation_pin };
            })
            .catch((error) => Promise.reject(http.createFallbackHttpError(error)));
    }
};

export default UserApi;
