import { DefaultStoreState } from 'common/States';
import {
    UPDATE_TNC_INITIAL,
    UPDATE_TNC_REQUEST,
    UPDATE_TNC_SUCCESS,
    UPDATE_TNC_FAILURE,
    UpdateTNCAction,
} from './TNCManagementAction';

export const defaultState: DefaultStoreState = {
    onRequest: false,
    success: false,
    error: null,
}

export function updateTNCReducer(
    state: DefaultStoreState = defaultState,
    action: UpdateTNCAction
): DefaultStoreState {
    switch (action.type) {
        case UPDATE_TNC_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, success: defaultState.success, error: defaultState.error };
        case UPDATE_TNC_REQUEST:
            return { ...state, onRequest: true, success: defaultState.success, error: defaultState.error };
        case UPDATE_TNC_SUCCESS:
            return { ...state, onRequest: false, success: true, error: defaultState.error };
        case UPDATE_TNC_FAILURE:
            return { ...state, onRequest: false, success: false, error: action.error };
        default:
            return state;
    }
}
