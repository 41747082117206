import React from 'react';
import { Modal } from 'antd';
import Images from 'themes/Images';

/**
 * Modal with prodia logo in its header
 */
const ModalProdia: React.FC<{
  children: JSX.Element | string,
  footer?: React.ReactNode,
  visible: boolean,
}> = ({ children, visible = false, footer = null }) => {
  
  const title = (
    <img src={Images.ProdiaLogo} alt="prodia logo" />
  );

  return (
    <Modal
      className="ModalProdia"
      wrapClassName="modalProdiaWrapper"
      centered
      title={title}
      footer={footer}
      closeIcon={null}
      closable={false}
      visible={visible}
      width={500}
    >
      <div className="modalContent">
        {children}
      </div>
    </Modal>
  );
};

export default ModalProdia;