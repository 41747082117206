import {
    GET_PERMISSIONS_INITIAL,
    GET_PERMISSIONS_REQUEST,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
    GetPermissionsAction,
} from './PermissionTreeAction';
import { PermissionsStoreState } from './PermissionTreeState';

export const defaultState: PermissionsStoreState = {
    onRequest: false,
    data: null,
    error: null,
}

export function getPermissionsReducer(
    state: PermissionsStoreState = defaultState,
    action: GetPermissionsAction
): PermissionsStoreState {
    switch (action.type) {
        case GET_PERMISSIONS_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case GET_PERMISSIONS_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case GET_PERMISSIONS_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case GET_PERMISSIONS_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
