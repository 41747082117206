import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FrontendRoutes } from 'navigation/Routes';
import { AuthStorage } from 'data/local/AuthStorage';
import { Images } from 'themes';
import { HttpError } from 'data/Models';
import { LoadingWithMask } from 'components/LoadingComponent';
import AlertComponent from 'components/AlertComponent';
import { OvalButton } from 'components/ButtonComponent';
import { emailRules, pinRules, tokenRules, activationFormText, maxEmailLen, maxPasswordLen } from './AccountActivationConfig';
import ActivationApi, { VerifyResponse } from 'data/api/ActivationApi';
import { AbortError, ActivationTokenExpiredCode } from 'common/HttpCode';
import { ModalProdia } from 'components/ModalComponent';
import './AccountActivation.css';

const AccountActivationView: React.FC<{}> = () => {
    const history = useHistory();
    const isAuthenticated = AuthStorage.isAuthenticated();
    const [error, setError] = useState<HttpError | null>(null);
    const [form] = Form.useForm();
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const hasSessionExpiredError = Boolean(error && error.code === ActivationTokenExpiredCode);
    const hasOtherError = Boolean(error && error.code !== ActivationTokenExpiredCode);

    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const onFinish = useCallback(() => {
        setIsVerifying(true);
        setError(null)
        ActivationApi.verify(
            form.getFieldValue('email'),
            form.getFieldValue('pin'),
            form.getFieldValue('token')
        ).then((res) => {
            const { data } = res as { data: VerifyResponse };
            history.push(`/create-password?exchange_token=${data.token}`);
        })
        .catch((err) => {
            if (err instanceof HttpError && err.code === AbortError) {
                err.action = () => {
                    onFinish();
                };
            }
            setError(err);
        })
        .finally(() => {
            setIsVerifying(false);
        });
    }, [form, history]);
    useEffect(() => {
        if (isAuthenticated) {
            history.push(FrontendRoutes.Dashboard.path);
        }
    }, [isAuthenticated, history]);

    return (
        <div className="accountActivationViewWrapper">
            {hasSessionExpiredError && (
                <ModalProdia
                    visible
                    footer={[
                        <OvalButton
                            key="OK"
                            type="primary"
                            htmlType="submit"
                            buttonText="OK"
                            onClick={handleClearFn}
                        /> 
                    ]}
                >
                    <>
                        <p className="contentTitle">{activationFormText.tokenExpiredModal.title}</p>
                        <p className="contentText">{activationFormText.tokenExpiredModal.description}</p>
                    </>
                </ModalProdia>
            )}
            {isVerifying && <LoadingWithMask />}
            {hasOtherError && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <div className="accountActivationViewHeader">
                    <img src={Images.LogoLoginHeader} alt="login logo" />
                </div>
                <div className="accountActivationViewFormContent">
                    <div className="accountActivationViewFormTitle">
                        {activationFormText.title}
                    </div>
                    <div className="accountActivationViewFormDesc">
                        {activationFormText.description}
                    </div>
                    <Form.Item
                        label="Email Address"
                        name="email"
                        rules={emailRules}
                        validateFirst
                    >
                        <Input maxLength={maxEmailLen} bordered />
                    </Form.Item>
                    <Form.Item
                        label="Token"
                        name="token"
                        rules={tokenRules}
                    >
                        <Input
                          maxLength={maxPasswordLen}
                          bordered
                          suffix={
                            <Tooltip align={{ offset: [15, 0] }} overlayClassName="blue-tooltip" placement="bottomRight" title={activationFormText.tooltips.token}>
                              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                          }
                        />
                    </Form.Item>
                    <Form.Item
                        label="PIN"
                        name="pin"
                        rules={pinRules}
                    >
                        <Input
                          maxLength={maxPasswordLen}
                          bordered
                          suffix={
                            <Tooltip align={{ offset: [15, 0] }} overlayClassName="blue-tooltip pin-tooltip" placement="bottomRight" title={activationFormText.tooltips.pin}>
                              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                          }
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <div className="activationViewButtonWrapper">
                                <OvalButton
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                    buttonText={activationFormText.verifyButton}
                                />
                            </div>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default AccountActivationView;
