import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import RootStoreState from 'rootStore';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { defaultQuery, defaultPage, defaultLimit } from 'common/constans';
import { TableIncrementNumber } from 'common/CommonUtils';
import AppTable from 'components/AppTableComponent';
import AlertComponent from 'components/AlertComponent';
import { GetMethodLoading } from 'components/LoadingComponent';
import { TestListItem, HttpError } from 'data/Models';
import { FrontendRoutes } from 'navigation/Routes';
import { doTestManagementRequest, TestManagementAction, doTestManagementInitial } from './TestManagementAction';
import { Images } from 'themes';
import { formatCurrency } from 'common/CommonUtils';
import { doUpdateTestInitial, UpdateTestAction } from '../TestEdit/TestEditAction';
import { doGetProfileInitial, GetProfileAction } from 'features/scaffold/ScaffoldAction';
import './index.css';
import TestManagementFilterView from './TestManagementFilterView';
import { TestListQuery } from 'common/States';

const Text = {
    wrapperId: 'TestManagementWrapperId',
    SearchPlaceHolder: "Search",
    TableColumnHeader: {
        No: "No",
        Code: "Code Test",
        Name: "Test Name",
        Cost: "Price",
        Status: "Status",
        HS: "Availibility Home Service",
    },
    Status: {
        Active: "Active",
        Inactive: "Inactive"
    },
    AvailabilityHS: {
        Available: "Yes",
        Unavailable: "No"
    },
    PopOverMenu: {
        ViewDetail: 'View Detail',
        Edit: 'Edit',
    }
};

interface TestPermissions {
    isAllowedShowList: boolean;
    isAllowedViewDetail: boolean;
    isAllowedEdit: boolean;
}

function TestManagementView() {
    const history = useHistory();
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];

    const testPermissions = {
        isAllowedShowList: isPermissionAllowed(FrontendRoutes.TestManagement.permissionKey, permissions),
        isAllowedViewDetail: isPermissionAllowed(FrontendRoutes.TestDetailPage.permissionKey, permissions),
        isAllowedEdit: isPermissionAllowed(FrontendRoutes.TestEditPage.permissionKey, permissions),
    };
    const showDots = testPermissions.isAllowedViewDetail || testPermissions.isAllowedEdit;
    const [error, setError] = useState<HttpError | null>(null);
    const testManagementAction = useDispatch<Dispatch<TestManagementAction>>();
    const testListState = useSelector((state: RootStoreState) => state.testManagement);
    const testListData = testListState.data;
    const [shownPopOver, setShownPopover] = useState<number | null>(null);
    const [filterParams, setFilterParams] = useState<TestListQuery>(defaultQuery);
    const containerId = document.getElementById(Text.wrapperId);

    useEffect(() => {
        if (testPermissions.isAllowedShowList) {
            testManagementAction(doTestManagementRequest(defaultQuery));
        }
    }, [testManagementAction, testPermissions.isAllowedShowList]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
        if (testListState.error) {
            testManagementAction(doTestManagementInitial());
            setError(testListState.error);
        }
    }, [testManagementAction, testListState]);
    const tableLoading: boolean = profileState.onRequest || testListState.onRequest;
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const handleRequestListAction = useCallback((query: TestListQuery) => {
        setShownPopover(null);
        setFilterParams(query);
        testManagementAction(doTestManagementRequest(query));
    }, [testManagementAction]);
    const handleHistoryPush = useCallback((path: string) => {
        history.push(path);
    }, [history]);
    const handleShowPopOver = useCallback((isShown, index) => {
        let shownPopOver = null;
        if (!isShown) {
            shownPopOver = index;
        }

        setShownPopover(shownPopOver);
    }, []);

    const columns: ColumnsType<TestListItem> = [
        {
            title: Text.TableColumnHeader.No,
            dataIndex: '',
            key: '',
            render: (text, row, index: number) => {
                return (
                    <span className="indexStyle">{TableIncrementNumber(filterParams.page ?? defaultPage, filterParams.limit ?? defaultLimit, index)}</span>
                );
            },
            width: '5%',
        },
        {
            title: Text.TableColumnHeader.Code,
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                const cell = (<span className="nameStyle">{text}</span>);

                if (testPermissions.isAllowedViewDetail) {
                    return (
                        <Link to={{pathname: FrontendRoutes.TestDetailPage.path.replace(':testId', record.id)}}>
                            {cell}
                        </Link>
                    )
                }

                return cell;
            },
        },
        {
            title: Text.TableColumnHeader.Name,
            dataIndex: 'name',
            key: 'name',
            render: (text) => (<span>{text}</span>),
        },
        {
            title: Text.TableColumnHeader.Cost,
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => (
                <span>{formatCurrency(record.price.actual)}</span>
            ),
        },
        {
            title: Text.TableColumnHeader.Status,
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                const status = record.is_active ? Text.Status.Active : Text.Status.Inactive;
                return (<span>{status}</span>)
            },
        },
        {
            title: Text.TableColumnHeader.HS,
            dataIndex: 'available_hs',
            key: 'available_hs',
            render: (text, record) => {
                const availableHS = record.available_hs ? Text.AvailabilityHS.Available : Text.AvailabilityHS.Unavailable;
                return (<span>{availableHS}</span>)
            },
        },
        {
            title: '',
            key: '',
            render: (text, record, index) => {
                const content = PopOverContent(record, testPermissions, handleHistoryPush);
                const isShown = shownPopOver === index;
                
                let popupContainer = document.body;
                if (containerId) {
                    popupContainer = containerId;
                }

                return (
                    <div>
                        <Popover
                            content={content}
                            visible={isShown}
                            trigger="click"
                            placement="bottomRight"
                            overlayClassName={'appActionGroupPopOver'}
                            getPopupContainer={() => popupContainer}
                        >
                            <div className="testManagementDotsWrapper">
                                {showDots && (
                                    <div
                                        className="testManagementDots"
                                        onClick={() => handleShowPopOver(isShown, index)
                                    }>
                                        <img src={Images.Dots} alt="Dots" />
                                    </div>
                                )}
                            </div>
                        </Popover>
                    </div>
                );
            },
            width: '141px',
        },
    ];

    return (
        <div className="testManagementWrapper" id={Text.wrapperId}>
            <TestActionAlertView />
            {error && (<AlertComponent error={error} clearFn={() => handleClearFn()} />)}
            <div className="testManagementHeader">
                <TestManagementFilterView
                    filterParams={filterParams}
                    onRequestListAction={handleRequestListAction}
                />
            </div>
            <AppTable
                loading={{
                    spinning: tableLoading,
                    indicator: <GetMethodLoading />,
                    wrapperClassName: 'tableLoading'
                }}
                pageSize={testListData?.pagination?.limit}
                total={testListData?.pagination?.total}
                current={testListData?.pagination?.page}
                onRequestData={(currentPage, pageSize) => {
                    const newQuery = Object.assign({}, filterParams, {
                        page: currentPage,
                        limit: pageSize,
                    });
                    handleRequestListAction(newQuery);
                }}
                columns={columns}
                dataSource={testListData?.data}
                rowKey={record => record.id}
                rowClassName={(record, index) => index%2 === 0 ? 'rowWhite' : 'rowBlue'}
            />
        </div>
    );
}

function PopOverContent(
    record: TestListItem,
    testPermissions: TestPermissions,
    navigateTo: (path: string) => void
) {
    const options = [
        {
            clickFn: () => navigateTo(FrontendRoutes.TestDetailPage.path.replace(':testId', record.id)),
            imgSrc: Images.EyeBlue,
            text: Text.PopOverMenu.ViewDetail,
            isAllowed: testPermissions.isAllowedViewDetail,
        },
        {
            clickFn: () => navigateTo(FrontendRoutes.TestEditPage.path.replace(':testId', record.id)),
            imgSrc: Images.PencilBlue,
            text: Text.PopOverMenu.Edit,
            isAllowed: testPermissions.isAllowedEdit,
            popOverItemClass: 'edit',
        },
    ];

    const popOverContentItems = options.map((props) => {
        const { clickFn, imgSrc, text, isAllowed, popOverItemClass } = props;
        
        if (isAllowed) {
            return (
                <div key={text} className={`appPopOverItem ${popOverItemClass}`} onClick={clickFn}>
                    <img src={imgSrc} alt={text} />
                    <span>{text}</span>
                </div>
            );
        }
    
        return null;
    });

    return (
        <div className="appPopOverContent">
            {popOverContentItems}
        </div>
    );
}

function TestActionAlertView() {
    const updatePanelAction = useDispatch<Dispatch<UpdateTestAction>>();
    const updatePanelState = useSelector((state: RootStoreState) => state.updateTest);
    const [success, setSuccess] = useState<string | null>('');
    const handleClearFn = useCallback(() => {
        setSuccess(null);
    }, []);
    useEffect(() => {
        if (updatePanelState.success === true) {
            updatePanelAction(doUpdateTestInitial());
            setSuccess('Edit test is success');
        }
    }, [updatePanelAction, updatePanelState]);

    return (
        <div>
            {success && <AlertComponent message={success} type={'success'} timeout={3000} clearFn={() => handleClearFn()} />}
        </div>   
    );
}

export default TestManagementView;
