import { FrontendRoutes } from "navigation/Routes";
import { Images } from "themes";

export const texts = {
    title: 'Welcome Back Admin.',
    subTitle: 'We have assembled some links to get you started:',
};

interface DashboardShortcut {
    permissionKey: string;
    link: string;
}

export interface DashBoardMenuitem {
    icon: string;
    title: string;
    shortcuts: DashboardShortcut[];
    color: string;
}

export const menus: DashBoardMenuitem[] = [
    {
        icon: Images.UserManagement,
        title: 'User Management',
        shortcuts: [
            {
                permissionKey: FrontendRoutes.AdminManagement.permissionKey,
                link: FrontendRoutes.AdminManagement.path
            },
            {
                permissionKey: FrontendRoutes.RoleManagement.permissionKey,
                link: FrontendRoutes.RoleManagement.path
            }
        ],
        color: '#173f5f',
    },
    {
        icon: Images.ProductManagement,
        title: 'Product (Test/Panel) Management',
        shortcuts: [
            {
                permissionKey: FrontendRoutes.TestManagement.permissionKey,
                link: FrontendRoutes.TestManagement.path
            },
            {
                permissionKey: FrontendRoutes.PanelManagement.permissionKey,
                link: FrontendRoutes.PanelManagement.path
            }
        ],
        color: '#00b1a3',
    },
    {
        icon: Images.OrderManagement,
        title: 'Order Management',
        shortcuts: [
            {
                permissionKey: FrontendRoutes.OrderManagement.permissionKey,
                link: FrontendRoutes.OrderManagement.path
            }
        ],
        color: '#ff462b',
    },
    {
        icon: Images.PromoEventManagement,
        title: 'Promo & Event',
        color: '#0063a0',
        shortcuts: [
            {
                permissionKey: FrontendRoutes.PromoManagement.permissionKey,
                link: FrontendRoutes.PromoManagement.path
            }
        ],
    }
];
