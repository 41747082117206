import React, { useState } from 'react';
import { SelectComponent } from 'components/SelectComponent';
import {Images} from "../../themes";

interface Option {
    id: string;
    name: string;
}
interface Props {
    onSelect: (sortValues: Option) => void;
    options: Option[];
    placeholder?: string;
    prefixLabel?: string;
}
const Text = {
    defaultPlaceholder: 'Sort by',
};

function SortFilterView(props: Props) {
    const [isVisible, setDropdownVisible] = useState<boolean>(false);

    const onGetOption = (option: Option) => {
        return { value: option.id, label: option.name }
    };
    const placeholder = props.placeholder || Text.defaultPlaceholder;
    const prefixLabel = `${(props.prefixLabel || placeholder)}: `;

    return (
        <SelectComponent
            selectProps={{
                placeholder,
                bordered: false,
                onDropdownVisibleChange: (open) => {
                    setDropdownVisible(open)
                },
                suffixIcon: () => {
                    return <img src={isVisible ? Images.ChevronUpGrey : Images.ChevronDownGrey} alt="Arrow"/>
                }
            }}
            data={props.options}
            onMapOption={onGetOption}
            onSelectedChange={props.onSelect}
            prefixLabel={prefixLabel}
            className={'sortFilterView'}
            optionClassName={'sortOptionWrapper'}
        />
    );
}

export default SortFilterView;
