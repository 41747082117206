import React, { useEffect, Dispatch, useState, useCallback, useMemo, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';
import { Descriptions, Button } from 'antd';
import { EyeOutlined, EyeInvisibleFilled } from '@ant-design/icons';
import RootStoreState from 'rootStore';
import AlertComponent from 'components/AlertComponent';
import { AdminAccountStatus, HttpError } from 'data/Models';
import { LoadingWithMask, GetMethodLoading } from 'components/LoadingComponent';
import { OvalButton } from 'components/ButtonComponent';
import BreadCrumbsComp from 'components/BreadCrumbsComponent';
import { ModalConfirmation, ModalSendAccountActivation, TypeDeleteConfirmation } from 'components/ModalComponent';
import { DeleteAdminAction, doDeleteAdminInitial, doDeleteAdminRequest} from 'features/user/AdminDelete/DeleteAdminAction';
import { formatDate } from 'common/CommonUtils';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { DefaultCustomProps } from 'common/States';
import { FrontendRoutes } from 'navigation/Routes';
import { Images } from 'themes';
import { doGetProfileInitial, GetProfileAction } from 'features/scaffold/ScaffoldAction';
import { AdminDetailAction, doAdminDetailInitial, doAdminDetailRequest } from './AdminDetailAction';
import { SendActivationLinkPermission } from 'common/constans';
import userApi from 'data/api/UserApi';
import { getElementListHeight } from 'common/DOMUtils';
import { AbortError } from 'common/HttpCode';
import './index.css';

function getStatusText(status: string) {
    switch (status) {
        case AdminAccountStatus.Active:
            return 'Active';
        case AdminAccountStatus.Inactive:
            return 'Inactive';
        case AdminAccountStatus.RequireActivation:
            return 'Require Activation';
        default:
            return status;
    }
}

function shouldShowActivationPinRow(status: string) {
    return status === AdminAccountStatus.RequireActivation;
}

function ActivationPINRow (props: { 
    isPINVisible: boolean, 
    activationPIN: string,
    onCopyPIN: () => void,
    onToggleShowPIN: () => void,
}) {
    const { isPINVisible, activationPIN, onCopyPIN, onToggleShowPIN } = props;
    return (
        <div className="activationPINRow">
            <div className="activationPINWrapper">
                <span>{activationPIN}</span>
                <Button className="iconButton" type="text" onClick={onCopyPIN}>
                    <img src={Images.Clipboard} alt="copy clipboard" />
                </Button>
                {!isPINVisible && (
                    <div className="activationPINMask">
                        <span className="ellipsis" />
                        <span className="ellipsis" />
                        <span className="ellipsis" />
                        <span className="ellipsis" />
                        <span className="ellipsis" />
                        <span className="ellipsis" />
                    </div>
                )}
            </div>
            <Button className="iconButton" type="text" onClick={onToggleShowPIN}>
                {isPINVisible ? <EyeOutlined  /> : <EyeInvisibleFilled />}
            </Button>
        </div>
    );
}

interface Props {
    customProps?: DefaultCustomProps
}
const Text = {
    detailsInformation: 'Details Information',
    fullName: 'Full Name',
    email: 'Email',
    branchList: 'Branch List',
    region: 'Region',
    status: 'Status',
    role: 'Role',
    createdDate: 'Join Date',
    createdBy: 'Created By',
    updateDate: 'Update Date',
    updateBy: 'Update By',
    edit: 'EDIT',
    delete: 'DELETE',
    sendActivation: 'SEND ACTIVATION',
    deleteConfirmation: 'Are you sure you want to delete this user?',
    allRegion: 'All Region',
    allBranch: 'All Branch',
    pin: 'PIN',
};
const AdminDetailView: React.FC<Props> = (props) => {
    const history = useHistory();
    const clipboard = useClipboard();
    const { userId } = useParams<{ userId: string }>();
    const [activationPIN, setActivationPIN] = useState<string>('');
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];
    const [isShowPINRowValue, setIsShowPINRowValue] = useState<boolean>(false);

    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.UserEditPage.permissionKey, permissions);
    const isAllowedDeleteDetail = isPermissionAllowed(FrontendRoutes.UserDeletePage.permissionKey, permissions);

    const adminDetailAction = useDispatch<Dispatch<AdminDetailAction>>();
    const adminDetailState = useSelector((state: RootStoreState) => state.adminDetail);
    const adminDetailData = adminDetailState.data;
    const deleteAdminAction = useDispatch<Dispatch<DeleteAdminAction>>();
    const deleteAdminState = useSelector((state: RootStoreState) => state.deleteAdmin);
    const [error, setError] = useState<HttpError | null>(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    // send user link activation
    const [isSendActivationSuccess, setIsSendActivationSuccess] = useState<boolean>(false);
    const [isSendingActivation, setIsSendingActivation] = useState<boolean>(false);
    const isAllowedToSendActivationLink = isPermissionAllowed(SendActivationLinkPermission, permissions);
    const shouldShowSendActivationLinkButton = isAllowedToSendActivationLink && adminDetailData?.status === AdminAccountStatus.RequireActivation;
    const isTestMoreThanFifteen = useMemo(() => {
        const length = adminDetailData?.outlets?.length || 0;
        return length > 15;
    }, [adminDetailData]);
    const hasActionProcessing = isSendingActivation || deleteAdminState.onRequest;
    const branchListContainerRef = useRef<HTMLDivElement | null>(null);
    useLayoutEffect(() => {
        const el = branchListContainerRef.current as HTMLDivElement;
        if (!el) return;
        const height = getElementListHeight(el);
        el.setAttribute('style', `max-height: ${height}px`);
    }, [isTestMoreThanFifteen]);

    const handleCopyActivationPin = useCallback(() => {
        clipboard.copy(activationPIN);
    }, [activationPIN, clipboard]);

    const handleSendActivationLink = useCallback(() => {
        const userId = adminDetailData?.id as string;
        setError(null);
        setIsSendingActivation(true);
        setIsSendActivationSuccess(false);
        userApi.sendActivation(userId)
            .then((response) => {
                setActivationPIN(response.activationPIN);
                setIsSendActivationSuccess(true);
            })
            .catch((error) => {
                if (error instanceof HttpError && error.code === AbortError) {
                    error.action = () => {
                        handleSendActivationLink();
                    };
                }
                setError(error);
            })
            .finally(() => {
                setIsSendingActivation(false);
            });
    }, [adminDetailData]);

    useEffect(() => {
        adminDetailAction(doAdminDetailRequest(userId));
    }, [userId, adminDetailAction]);
    useEffect(() => {
        if (adminDetailData?.activation_pin) {
            setActivationPIN(adminDetailData.activation_pin);
        }
    }, [adminDetailData]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
       if (adminDetailState.error) {
            adminDetailAction(doAdminDetailInitial());
            setError(adminDetailState.error);
        }
    }, [adminDetailAction, adminDetailState]);
    useEffect(() => {
        if (deleteAdminState.success === true) {
            history.replace(FrontendRoutes.AdminManagement.path);
        } else if (deleteAdminState.error) {
            setError(deleteAdminState.error);
            deleteAdminAction(doDeleteAdminInitial());
        }
    }, [deleteAdminAction, deleteAdminState, history]);
    const loading: boolean = profileState.onRequest || adminDetailState.onRequest;
    const handleDeleteFn = useCallback(() => {
        setShowDeleteConfirmation(true);
    }, []);
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const handleToggleShowPIN = useCallback(() => {
        setIsShowPINRowValue(!isShowPINRowValue);
    }, [isShowPINRowValue]);
    const adminDetailDesc = [
        {
            label: Text.fullName,
            content: adminDetailData?.name,
        },
        {
            label: Text.email,
            content: adminDetailData?.email,
        },
        {
            label: Text.pin,
            content: (
                <ActivationPINRow
                    isPINVisible={isShowPINRowValue}
                    activationPIN={activationPIN}
                    onCopyPIN={handleCopyActivationPin}
                    onToggleShowPIN={handleToggleShowPIN}
                />
            ),
            hide: !shouldShowActivationPinRow(adminDetailData?.status || ''),
        },
        {
            label: Text.branchList,
            content: (
                <div className="branchListContainer" ref={branchListContainerRef}>
                    {adminDetailData?.is_all_outlets ? Text.allBranch : (
                        adminDetailData?.outlets?.length === 0 ? '-' : (
                            adminDetailData?.outlets?.map((group, index)=>{
                                return (
                                    <div key={`regionsList${index}`}>
                                        <span className="listItemIndex">{`${index + 1}`}.</span>
                                        <span>{group.name} </span>
                                    </div>
                                )
                            })
                        )
                    )}
                </div>
            )
        },
        {
            label: Text.region,
            content: (
                <div>
                    {adminDetailData?.is_all_regions ? Text.allRegion : (
                        adminDetailData?.regions?.length === 0 ? '-' : (
                            adminDetailData?.regions?.map((group, index)=>{
                                return (
                                    <div key={`regionsList${index}`}>
                                        <span className="listItemIndex">{`${index + 1}`}.</span>
                                        <span>{group.name} </span>
                                    </div>
                                )
                            })
                        )
                    )}
                </div>
            )
        },
        {
            label: Text.status,
            content: getStatusText(adminDetailData?.status || ''),
        },
        {
            label: Text.role,
            content: adminDetailData?.role?.name,
        },
        {
            label: Text.createdDate,
            content: formatDate(adminDetailData?.created_at),
        },
        {
            label: Text.createdBy,
            content: adminDetailData?.created_by?.name,
        },
        {
            label: Text.updateDate,
            content: formatDate(adminDetailData?.updated_at),
        },
        {
            label: Text.updateBy,
            content: adminDetailData?.updated_by?.name,
        },
    ];
    const handleNavigateToEdit = () => {
        const path = FrontendRoutes.UserEditPage.path.replace(
            ':userId',
            userId,
        );
        history.push(path);
    };
    const breadCrumbsButtons = () => {
        return (
            <div className="breadCrumbsButtonsWrapper">
                {shouldShowSendActivationLinkButton && (
                    <OvalButton
                        type="default"
                        buttonText={Text.sendActivation}
                        wrapperClassName={'ovalButtonWhite'}
                        icon={<img className="ovalButtonImg" src={Images.SendActivation} alt="send activation" />}
                        onClick={() => handleSendActivationLink()}
                    />
                )}
                {isAllowedEditDetail && (
                    <OvalButton
                        type="primary"
                        buttonText={Text.edit}
                        icon={<img className="ovalButtonImg" src={Images.PencilWhite} alt="edit" />}
                        onClick={() => handleNavigateToEdit()}
                    />
                )}
                {isAllowedDeleteDetail && (
                    <OvalButton
                        type="primary"
                        buttonText={Text.delete}
                        wrapperClassName={'ovalButtonRed'}
                        icon={<img className="ovalButtonImg" src={Images.DeleteWhite} alt="delete" />}
                        onClick={() => handleDeleteFn()}
                    />
                )}
            </div>
        )
    };

    return (
        <div className="adminDetailWrapper">
            {props.customProps?.breadcrumbs && (
                <BreadCrumbsComp
                    crumbs={props.customProps.breadcrumbs}
                    customCrumbParams={{ lastCrumbName: adminDetailData?.name }}
                >
                    {breadCrumbsButtons()}
                </BreadCrumbsComp>
            )}
            {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            {loading && <GetMethodLoading />}
            {hasActionProcessing && <LoadingWithMask />}
            {isSendActivationSuccess && (
                <ModalSendAccountActivation
                    activationPIN={activationPIN}
                    onOKClick={() => setIsSendActivationSuccess(false)}
                />
            )}
            <ModalConfirmation
                visible={!!showDeleteConfirmation}
                handleClose={() => {
                    setShowDeleteConfirmation(false);
                }}
                buttonClick={() => {
                    deleteAdminAction(doDeleteAdminRequest(userId));
                }}
                message={Text.deleteConfirmation}
                type={TypeDeleteConfirmation}
            />
            {adminDetailData && (
                <Descriptions
                    title={Text.detailsInformation}
                    className={'adminDetailContent'}
                >
                    {adminDetailData && adminDetailDesc.map((adminDetailItem, index) => {
                        if (adminDetailItem.hide === true) return null;
                        return (
                            <Descriptions.Item key={`adminDetailItem${index}`} label={adminDetailItem.label} span={3}>{adminDetailItem.content}</Descriptions.Item>
                        )
                    })}
                </Descriptions>
            )}
        </div>
    );
};

export default AdminDetailView;
