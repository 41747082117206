import React from 'react';
import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';

const { Option } = Select;

export const active = 'active';
export const inactive = 'inactive';
export const status = [
    {
        label: 'Active',
        value: active
    },
    {
        label: 'Inactive',
        value: inactive
    },
];

function StatusInputView(props: SelectProps<SelectValue>) {
    return (
        <Select {...props}>
            {status.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
        </Select>
    )
}

export default StatusInputView;
