import { Button, Col, Dropdown, Layout, Menu, Modal, Row } from 'antd';
import { Avatar } from 'antd';
import { ProfileDetail } from 'data/Models';
import React, { useCallback, useState, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import RootStoreState from 'rootStore';
import { Images } from 'themes';
import * as style from './HeaderView.style';
import * as config from './HeaderConfig';
import ChangePasswordView from 'features/account/ChangePassword/ChangePasswordView';
import ChangePasswordAlertView from 'features/account/ChangePassword/ChangePasswordAlertView';

interface HeaderProps {
    title: string;
    didLogout: () => void;
}
const Text = {
    allRegion: 'All Region',
    allBranch: 'All Branch',
};

function HeaderView(props: HeaderProps) {
    const profile = useSelector((state: RootStoreState) => state.profile.data);
    const [isMyAccountVisible, setMyAccountVisiblity] = useState(false);

    const showMyAccountModal = useCallback(() => {
        setMyAccountVisiblity(true);
    }, []);
    const closeMyAccountModal = useCallback(() => {
        setMyAccountVisiblity(false);
    }, []);
    const findNameAbbreviation = useCallback((name: string): string => {
        return name.toUpperCase().split(' ', 2).map((c) => c[0]).join('');
    }, []);

    const menu = (
        <Menu>
            <Menu.Item
                key="0"
                style={{ ...style.menuItem, color: '#3d7dc3' }}
                onClick={showMyAccountModal}
            >
                <img src={Images.UserOutlined} alt="Account"/> Account
            </Menu.Item>
            <Menu.Item
                key="1"
                style={{ ...style.menuItem, color: '#c33d3d' }}
                onClick={props.didLogout}
            >
                <img src={Images.LogoutOutlined} alt="Logout"/> Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            <Layout.Header style={style.conntainer}>
                <span style={style.pageTitle}>{props.title.toUpperCase()}</span>
                <Avatar size={34}>{findNameAbbreviation(profile?.name ?? '')}</Avatar>
                <Dropdown overlay={menu} trigger={['click']}>
                    <Button type="text" style={style.username}>
                        {profile?.name} <img src={Images.DownOutlined} alt="Arrow Down"/>
                    </Button>
                </Dropdown>
            </Layout.Header>
            {profile && <MyAccounteModal
                isVisible={isMyAccountVisible}
                profile={profile}
                handleClose={closeMyAccountModal}
                handleLogout={props.didLogout}
            />}
        </div>
    );
}

interface MyAccountProps {
    isVisible: boolean;
    profile: ProfileDetail;
    handleClose: () => void;
    handleLogout: () => void;
}

function MyAccounteModal(props: MyAccountProps) {
    const { isVisible, profile, handleClose, handleLogout } = props;
    const profileItems = [
        {
            label: config.text.MyAccountModal.Name,
            value: profile.name,
        },
        {
            label: config.text.MyAccountModal.Email,
            value: profile.email,
        },
        {
            label: config.text.MyAccountModal.Role,
            value: profile.role?.name,
        },
        {
            label: config.text.MyAccountModal.Branch,
            value: (
                <div>
                    {profile?.is_all_outlets ? Text.allBranch : (
                        profile?.outlets?.length === 0 ? '-' : (
                            profile?.outlets?.map((group, index)=>{
                                return (
                                    <div key={`regionsList${index}`}>
                                        <span style={style.listItemIndex}>{`${index + 1}`}.</span>
                                        <span>{group.name} </span>
                                    </div>
                                )
                            })
                        )
                    )}
                </div>
            ),
        },
        {
            label: config.text.MyAccountModal.Region,
            value: (
                <div>
                    {profile?.is_all_regions ? Text.allRegion : (
                        profile?.regions?.length === 0 ? '-' : (
                            profile?.regions?.map((group, index)=>{
                                return (
                                    <div key={`regionsList${index}`}>
                                        <span style={style.listItemIndex}>{`${index + 1}`}.</span>
                                        <span>{group.name} </span>
                                    </div>
                                )
                            })
                        )
                    )}
                </div>
            ),
        }
    ];
    const [isChangePasswordVisible, setChangePasswordVisiblity] = useState(false);

    const handleModalClose = () => {
        if (isChangePasswordVisible) {
            setChangePasswordVisiblity(false);
        } else {
            handleClose();
        }
    };

    const showChangePasswordModal = () => {
        setChangePasswordVisiblity(true);
    };
    const closeChangePasswordModal = () => {
        setChangePasswordVisiblity(false);
    };

    return (
        <Modal
            centered
            width={550}
            visible={isVisible}
            closeIcon={<img src={Images.Close} alt="close"/>}
            maskClosable={false}
            onCancel={handleModalClose}
            footer={null}
        >
            {isChangePasswordVisible ? (
                <ChangePasswordView
                    handleClose={closeChangePasswordModal}
                />
            ) : (
                <div>
                    <div style={style.profileTitle}>
                        {config.text.MyAccountModal.ModalTitle}
                    </div>
                    {profileItems.map((item) => <ProfileItem key={item.label} label={item.label} value={item.value} />)}
                    <div style={style.profileFooter}>
                        <Button style={style.profileButton} onClick={handleLogout}>
                            <img src={Images.LogoutOutlined} alt="logout"/>
                            <span style={{...style.profileButtonText, color: '#c33d3d', marginLeft: 8}}>{config.text.MyAccountModal.Logout}</span>
                        </Button>
                        <span style={{flex: 1}}/>
                        <Button style={style.profileButton} onClick={showChangePasswordModal}>
                            <span style={{...style.profileButtonText, color: '#375dbb'}}>{config.text.MyAccountModal.ChangePassword}</span>
                        </Button>
                    </div>
                </div>
            )}
            <ChangePasswordAlertView />
        </Modal>
    );
}

interface ProfileItemProps {
    label: string;
    value: string | ReactNode;
}

function ProfileItem(props: ProfileItemProps) {
    return (
        <Row>
            <Col span={12} style={style.profileItem}><div style={style.profileLabel}>{props.label}</div></Col>
            <Col span={12} style={style.profileItem}><div style={style.profileValue}>{props.value}</div></Col>
        </Row>
    );
}

export default HeaderView;
