import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";

const PaymentApi = {
    getPaymentChannelList (): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Payment.Channel)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    paymentChannelList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
};

export default PaymentApi;
