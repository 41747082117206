import { call, put, takeLatest } from 'redux-saga/effects';
import UserApi from 'data/api/UserApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './DeleteAdminAction';

function* deleteAdminTask(deleteAdminAction: action.DeleteAdminRequest) {
    const { userId } = deleteAdminAction;
    const response = yield call(UserApi.deleteAdmin, userId);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doDeleteAdminSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doDeleteAdminRequest(userId) });
            yield put(action.doDeleteAdminFailure(newTimeoutError));
        } else {
            yield put(action.doDeleteAdminFailure(response.error));
        }
    }
}

export function* deleteAdminSaga() {
    yield takeLatest(
        action.DELETE_ADMIN_REQUEST,
        deleteAdminTask,
    );
}
