import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import './index.css';
import { DefaultCustomProps } from 'common/States';
import { Button, Form } from 'antd';
import RichTextEditor from 'components/RichTextEditor';
import { useHistory } from 'react-router-dom';
import { HttpError, TNCDetail } from 'data/Models';
import { useDispatch, useSelector } from 'react-redux';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import RootStoreState from 'rootStore';
import { TNCDetailAction, doTNCDetailInitial, doTNCDetailRequest } from '../TNCDetail/TNCDetailAction';
import { doUpdateTNCInitial, doUpdateTNCRequest, UpdateTNCAction } from './TNCManagementAction';
import TextArea from 'antd/lib/input/TextArea';
import { GetMethodLoading, LoadingWithMask } from 'components/LoadingComponent';
import AlertComponent from 'components/AlertComponent';
import { sum } from 'lodash';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { FrontendRoutes } from 'navigation/Routes';

interface Props {
    customProps?: DefaultCustomProps
}

const Text = {
    wrapperId: 'TNCEditWrapperId',
    Form: {
        Summary: {
            Label: 'Summary',
            Name: 'summary',
        },
        TNC: {
            Label: 'Term And Condition',
            Name: 'tnc'
        }
    },
    Button: {
        Save: 'Save',
        Cancel: 'Cancel',
        Edit: 'Edit'
    }
};

const TNCManagementView: React.FC<Props> = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [isEdit, setIsEdit] = useState<boolean>();
    const [tnc, setTNC] = useState<string>();
    const [summary, setSummary] = useState<string>();
    const [isDetailDataRequested, setDetailDataRequested] = useState<boolean>(false);
    const [error, setError] = useState<HttpError | null>(null);

    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const tncDetailAction = useDispatch<Dispatch<TNCDetailAction>>();
    const tncDetailState = useSelector((state: RootStoreState) => state.tncDetail);
    const updateTNCAction = useDispatch<Dispatch<UpdateTNCAction>>();
    const updateTNCState = useSelector((state: RootStoreState) => state.updateTNC);

    const updatePermissionKey = 'hs_tnc.update';
    const permissions = profileState.data?.permissions || [];

    const tncPermissions = {
        isAllowedViewDetail: isPermissionAllowed(FrontendRoutes.TNCManagementPage.permissionKey, permissions),
        isAllowedUpdate: isPermissionAllowed(updatePermissionKey, permissions),
    };

    useEffect(() => {

        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        } else if (profileState.data) {

            if (tncPermissions.isAllowedViewDetail) {
                tncDetailAction(doTNCDetailInitial());
                tncDetailAction(doTNCDetailRequest());
            }
        }
    }, [profileAction, profileState, tncDetailAction]);
    useEffect(() => {
        if (tncDetailState.onRequest) {
            setDetailDataRequested(false);
        } else {
            setDetailDataRequested(true)
            if (tncDetailState.error) {
                setError(tncDetailState.error);
                tncDetailAction(doTNCDetailInitial());
            }
        }
    }, [tncDetailAction, tncDetailState]);
    useEffect(() => {
        if (isDetailDataRequested && tncDetailState.data) {
            const data = tncDetailState.data;

            setSummary(data.summary);
            setTNC(data.detail);
        }
    }, [isDetailDataRequested, profileState.data, tncDetailState.data]);
    useEffect(() => {
        if (updateTNCState.success === true) {
            setIsEdit(false);
            tncDetailAction(doTNCDetailInitial());
            tncDetailAction(doTNCDetailRequest());
        } else if (updateTNCState.error) {
            setError(updateTNCState.error);
            updateTNCAction(doUpdateTNCInitial());
        }
    }, [updateTNCAction, tncDetailAction, updateTNCState, history]);
    useEffect(() => {
        form.setFieldsValue({ tnc: tnc, summary: summary });
    }, [form, summary, tnc]);

    const handleSummary = useCallback((content: any) => {
        setSummary(content.target.value);
    }, []);
    const handleTNC = useCallback((content: any) => {
        console.log(content);
        setTNC(content);
    }, []);
    const handleEdit = useCallback(() => {
        setIsEdit(true);
    }, []);
    const handleCancel = useCallback(() => {
        setIsEdit(false);

        if (tncDetailState.data) {
            const data = tncDetailState.data;

            setSummary(data.summary);
            setTNC(data.detail);
        }
    }, [tncDetailState.data]);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);
    const onFinish = useCallback(() => {
        const data = new TNCDetail();
        data.summary = summary ?? '';
        data.detail = tnc ?? '';

        console.log(data);

        updateTNCAction(doUpdateTNCRequest(data));
    }, [summary, tnc, updateTNCAction]);
    const isLoading: boolean = profileState.onRequest || tncDetailState.onRequest;

    return (
        <div className="settingsManagementWrapper" id={Text.wrapperId}>
            <TNCActionAlertView />
            {error && (<AlertComponent error={error} clearFn={() => handleClearFn()} />)}
            {isLoading ? <GetMethodLoading /> : (
                <div className='settingsDetailWrapper'>
                    <div className='settingsDetailContent'>
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label={Text.Form.Summary.Label}
                                name={Text.Form.Summary.Name}
                            >
                                <TextArea
                                    onChange={handleSummary}
                                    disabled={!isEdit}
                                    bordered
                                    rows={5}
                                    maxLength={500}
                                >
                                </TextArea>
                            </Form.Item>
                            <Form.Item
                                label={Text.Form.TNC.Label}
                                name={Text.Form.TNC.Name}
                            >
                                <RichTextEditor
                                    htmlValue={tnc}
                                    onEditorChange={handleTNC}
                                    readOnly={!isEdit}
                                />
                            </Form.Item>
                            {
                                isEdit && (
                                    <div className="settingsViewAction">
                                        <Button type="primary" htmlType="submit">
                                            {Text.Button.Save}
                                        </Button>
                                        <span style={{ flex: 1 }} />
                                        <Button type="text" onClick={handleCancel}>
                                            {Text.Button.Cancel}
                                        </Button>
                                    </div>
                                )
                            }
                        </Form>
                        {tncPermissions.isAllowedUpdate && !isEdit && (
                            <div className="settingsViewAction">
                                <Button type="primary" onClick={handleEdit}>
                                    {Text.Button.Edit}
                                </Button>
                            </div>
                        )
                        }
                        {updateTNCState.onRequest && <LoadingWithMask />}
                    </div>
                </div>
            )}
        </div>
    );
}

function TNCActionAlertView() {
    const updatePanelAction = useDispatch<Dispatch<UpdateTNCAction>>();
    const updatePanelState = useSelector((state: RootStoreState) => state.updateTNC);
    const [success, setSuccess] = useState<string | null>('');
    const handleClearFn = useCallback(() => {
        setSuccess(null);
    }, []);
    useEffect(() => {
        if (updatePanelState.success === true) {
            updatePanelAction(doUpdateTNCInitial());
            setSuccess('Edit TNC is success');
        }
    }, [updatePanelAction, updatePanelState]);

    return (
        <div>
            {success && <AlertComponent message={success} type={'success'} timeout={3000} clearFn={() => handleClearFn()} />}
        </div>
    );
}

export default TNCManagementView;
