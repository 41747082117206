import * as http from "common/HttpUtils";
import { PanelFormData } from "data/Models";
import ApiEndpoint from "./ApiEndpoint";
import { PanelListQuery } from "common/States";

const PanelApi = {
    getPanelList(queryParams: PanelListQuery): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Panel.Base)
            .setQueryParameter(queryParams)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    panelList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    getPanelDetail(panelId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Panel.Base}/${panelId}`)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    panelDetail: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    updatePanel(updateData: PanelFormData): Promise<http.HttpService<{}>> {
        const body = {
            types: updateData.types,
            min_age: updateData.beginAge,
            max_age: updateData.endAge,
            is_active: updateData.isActive,
        }
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Panel.Base}/${updateData.id}`)
            .setBody(body)
            .doPut()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true }
            }))
            .catch(error => http.handleCatch(error));
    },
};

export default PanelApi;
