import React, {Dispatch, Key, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, Input, Select } from 'antd';
import RootStoreState from 'rootStore';
import FrontendScreens from 'navigation/Frontend.screens';
import { formRules, status } from 'features/user/RoleForm/RoleFormConfig';
import { doGetPermissionsRequest, GetPermissionsAction } from 'features/user/RoleForm/PermissionTree/PermissionTreeAction';
import PermissionTreeView from 'features/user/RoleForm/PermissionTree/PermissionTreeView';
import './RoleFormView.css';

const { Option } = Select;

export interface RoleFormValue {
    roleName?: string;
    status: string;
    permissions?: string[];
}

const Text = {
    name: "Role Name",
    permission: "Choose Permission",
    status: "Status",
    submit: 'SUBMIT',
    cancel: 'Cancel',
};

interface Props {
    initialValues: RoleFormValue;
    onFinish: (values: RoleFormValue) => void;
}

function RoleFormView(props: Props) {
    const history = useHistory();
    const [form] = Form.useForm();
    const [permissionError, setPermissionError] = useState<string | null>(null);
    const getPermissionsAction = useDispatch<Dispatch<GetPermissionsAction>>();
    const permissionsState = useSelector((state: RootStoreState) => state.permissions);

    useEffect(() => {
        getPermissionsAction(doGetPermissionsRequest());
    }, [getPermissionsAction]);
    useEffect(() => {
        form.setFieldsValue(props.initialValues);
    }, [form, props]);

    const handlePermissionChecked = (values: Key[]) => {
        form.setFieldsValue({permissions: values});
        setPermissionError(values.length === 0 ? formRules.permission[0].message : null);
    };

    const handleFinish = (values: any) => {
        if (values.permissions && values.permissions.length > 0) {
            props.onFinish(values);
        } else {
            setPermissionError(formRules.permission[0].message);
        }
    };

    const handleCancel = useCallback(() => {
        if (history.length > 2) {
            history.goBack(); 
        } else {
            history.replace(FrontendScreens.RoleManagementPage.path)
        }
    }, [history]);
    return (
        !permissionsState.onRequest ? (
            <Card className="roleFormViewWrapper">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={handleFinish}
                >
                    <Form.Item
                        label={Text.name}
                        name="roleName"
                        rules={formRules.name}
                        validateFirst
                    >
                        <Input bordered/>
                    </Form.Item>
                    <Form.Item
                        label={Text.permission}
                        name="permissions"
                    >
                        <PermissionTreeView
                            initialValues={props.initialValues.permissions}
                            onChecked={handlePermissionChecked}
                            permissionError={permissionError}
                        />
                    </Form.Item>
                    <Form.Item
                        label={Text.status}
                        name="status"
                    >
                        <Select>
                            {status.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                        </Select>
                    </Form.Item>
                    <div className="roleEditViewAction">
                        <Button type="primary" htmlType="submit">
                            {Text.submit}
                        </Button>
                        <span style={{ flex: 1 }}/>
                        <Button type="text" onClick={handleCancel}>
                            {Text.cancel}
                        </Button>
                    </div>
                </Form>
            </Card>
        ) : null
    );
}

export default RoleFormView;
