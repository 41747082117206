import React, { useCallback, useEffect, useState } from 'react';
import { Button, Upload } from 'antd';
import './index.css';

const Text = {
    chooseFile: 'CHOOSE FILE',
    changeFile: 'CHANGE FILE',
    imageInfo: 'Max 2 MB, JPEG/JPG/PNG File type, Landscape Image',
    imageError: {
        unsupportedType: 'Unsupported Type',
        excessMaxlength: 'Image file size cannot exceeding 2MB',
        unsupportedOrientation: 'Image width should be bigger than height',
    },
};

interface ImageUploadProps {
    defaultImage?: string;
    imageState: 'showImage' | 'hideImage';
    onImageChange: (value: string | undefined) => void;
    onValidateError: (error: string | null) => void;
}

function ImageUpload(props: ImageUploadProps) {
    const { defaultImage, imageState, onImageChange, onValidateError} = props;

    const [image, setImage] = useState<string>();
    const [uploadButton, setUploadButton] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (defaultImage) {
            setImage(defaultImage);
        }
    }, [defaultImage]);

    const handleBeforeUpload = useCallback((file: any) => {
        return false;
    }, []);
    const handleImageUploadRef = useCallback((ref) => {
        if (ref) {
            setUploadButton(ref);
        }
    }, []);
    const handleImageUpload = useCallback(info => {
        const reader = new FileReader();
        const image = new Image();
        reader.readAsDataURL(info.file);
        reader.onload = () => {
            if (reader.result) {
                image.src = reader.result.toString();
            }
        };
        image.onload = () => {
            setImage(undefined);
            onImageChange(undefined);

            if (image.height >= image.width) {
                onValidateError(Text.imageError.unsupportedOrientation);
            } else if (info.file.size > 2000000) {
                onValidateError(Text.imageError.excessMaxlength);
            } else if (!(info.file.type === 'image/jpeg' || info.file.type === 'image/png')) {
                onValidateError(Text.imageError.unsupportedType);
            } else {
                onValidateError(null);
                setImage(reader.result?.toString());
                onImageChange(info.file);
            }
        };
    }, [onImageChange, onValidateError]);

    return (
        <div className="imageUpload">
            <Upload
                listType="picture-card"
                showUploadList={false}
                fileList={[]}
                beforeUpload={handleBeforeUpload}
                onChange={handleImageUpload}
                accept="image/png, image/jpeg"
            >
                <div className="promoImageWrapper">
                    {imageState === 'showImage' ? <img src={image} alt="promoImage" className="promoImage" ref={handleImageUploadRef} /> : <div/>}
                    <div className="buttonUploadWrapper">
                        {imageState === 'hideImage' ? <Button className="buttonUpload">{Text.chooseFile}</Button> : <div />}
                    </div>
                </div>
            </Upload>
            <div className="imageEditWrapper">
                {imageState === 'showImage' ? <Button className="buttonUpload" style={{ top: 0 }} onClick={() => uploadButton?.click()}>{Text.changeFile}</Button> : <div />}
                <div className="imageInfoWrapper">
                    <div className="imageInfo">{Text.imageInfo}</div>
                </div>
            </div>
        </div>
    );
}

export default ImageUpload;