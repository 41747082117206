import React from 'react';
import { Modal } from 'antd';
import { Images } from 'themes';
import './index.css';

const ModalViewImage: React.FC<{
    visible?: boolean;
    width?: number;
    maskStyle?: Object;
    closable?: boolean;
    maskClosable?: boolean;
    handleClose?: () => void;
    imgSrc?: string;
    wrapClassName?: string;
}> = ({
    visible = false,
    width = 588,
    maskStyle = {
        opacity: 0.7,
        backgroundColor: '#000000',
    },
    closable = true,
    maskClosable = false,
    handleClose = () => {},
    imgSrc = '',
    wrapClassName = '',
}) => {
    const closeIcon = (
        <img src={Images.CloseWhite} alt="close"/>
    );
    const handleCloseFn = () => {
        handleClose();
    };
    return (
      <>
          <Modal
              maskClosable={maskClosable}
              centered
              closeIcon={closeIcon}
              visible={visible}
              width={width}
              maskStyle={maskStyle}
              closable={closable}
              onCancel={() => handleCloseFn()}
              footer={null}
              wrapClassName={`${wrapClassName} ModalViewImageWrapper`}
          >
              <div>
                  <img src={imgSrc} alt="viewImg" className={'viewImg'}/>
              </div>
          </Modal>
      </>
    )
};

export default ModalViewImage;
