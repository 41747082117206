import { Layout, Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import SubMenu from 'antd/lib/menu/SubMenu';
import { findGroupKey, isPermissionAllowed } from 'common/PermissionUtils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RootStoreState from 'rootStore';
import { Images } from 'themes';
import { availableMenus, SideMeuItem } from './SideMenuConfig';
import './SiderView.css';

interface Props {
    title: string;
}

function SiderView(props: Props) {
    const permissions = useSelector((state: RootStoreState) => state.profile.data?.permissions);

    const openKey = findGroupKey(props.title);
    const [openKeys, setOpenKeys] = useState([openKey]);

    return (
        <Layout.Sider width={250}>
            <span className="brandLogo">
                <img src={Images.BrandLogo} alt="Brand Logo" />
            </span>
            <div className="menuText sideMenuTitle">Menu</div>
            {renderSideMenus(
                props.title,
                permissions ?? [""],
                openKeys,
                (keys) => setOpenKeys(keys.map((key) => key.toString())))
            }
        </Layout.Sider>
    );
}

function renderSideMenus(
    selectedMenu: string,
    permissions: string[],
    openKeys: string[],
    onOpenChange: (openKeys: React.Key[]) => void
): React.ReactElement {
    const menuItems = availableMenus.map((item) => {
        if (item.link && isPermissionAllowed(item.permissionKey, permissions)) {
            return renderMenuItem(item);
        } else {
            const childs = item.childs
                ?.filter((subItem) => isPermissionAllowed(subItem.permissionKey, permissions))
                ?.map((subItem) => renderMenuItem(subItem));
            return renderSubMenu(item, childs);
        }
    });

    let menuProps: MenuProps =  {
        mode: "inline",
        selectedKeys: [selectedMenu]
    };

    if (openKeys.length > 0) {
        menuProps = {
            ...menuProps,
            openKeys: openKeys,
            onOpenChange: onOpenChange
        };
    }

    return (<Menu {...menuProps}>{menuItems}</Menu>);
}

function renderMenuItem(item: SideMeuItem) {
    return (
        <Menu.Item className="menuText" key={item.title}>
            <Link to={item.link ?? ''}>
                <span>{item.isCustomTitle ? item.customTitle : item.title}</span>
            </Link>
        </Menu.Item>
    );
}

function renderSubMenu(item: SideMeuItem, childs: React.ReactElement[] | undefined) {
    if (childs && childs.length > 0) {
        return (
            <SubMenu className="menuText" title={item.isCustomTitle ? item.customTitle : item.title} key={item.title}>
                {childs}
            </SubMenu>
        );
    } else {
        return (<div key={item.title} />);
    }
}

export default SiderView;
