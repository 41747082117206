import { RoleFormData, HttpError } from "data/Models";

export const CREATE_ROLE_INITIAL = 'CREATE_ROLE_INITIAL';
export type CREATE_ROLE_INITIAL = typeof CREATE_ROLE_INITIAL;

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export type CREATE_ROLE_REQUEST = typeof CREATE_ROLE_REQUEST;

export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export type CREATE_ROLE_SUCCESS = typeof CREATE_ROLE_SUCCESS;

export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';
export type CREATE_ROLE_FAILURE = typeof CREATE_ROLE_FAILURE;

interface CreateRoleInitial {
    type: CREATE_ROLE_INITIAL;
}

export interface CreateRoleRequest {
    type: CREATE_ROLE_REQUEST;
    newData: RoleFormData;
}

interface CreateRoleSuccess {
    type: CREATE_ROLE_SUCCESS;
}

interface CreateRoleFailure {
    type: CREATE_ROLE_FAILURE;
    error: HttpError;
}

export type CreateRoleAction = CreateRoleInitial | CreateRoleRequest | CreateRoleSuccess | CreateRoleFailure;

export function doCreateRoleInitial(): CreateRoleInitial {
    return {
        type: CREATE_ROLE_INITIAL,
    };
}

export function doCreateRoleRequest(newData: RoleFormData): CreateRoleRequest {
    return {
        type: CREATE_ROLE_REQUEST,
        newData: newData,
    };
}

export function doCreateRoleSuccess(): CreateRoleSuccess {
    return {
        type: CREATE_ROLE_SUCCESS,
    };
}

export function doCreateRoleFailure(error: HttpError): CreateRoleFailure {
    return {
        type: CREATE_ROLE_FAILURE,
        error: error,
    };
}
