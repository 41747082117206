import { HttpError } from 'data/Models';
import { RoleList } from 'data/Models';
import { RoleListQuery } from 'common/States';

export const ROLE_MANAGEMENT_INITIAL = 'ROLE_MANAGEMENT_INITIAL';
export type ROLE_MANAGEMENT_INITIAL = typeof ROLE_MANAGEMENT_INITIAL;

export const ROLE_MANAGEMENT_REQUEST = 'ROLE_MANAGEMENT_REQUEST';
export type ROLE_MANAGEMENT_REQUEST = typeof ROLE_MANAGEMENT_REQUEST;

export const ROLE_MANAGEMENT_SUCCESS = 'ROLE_MANAGEMENT_SUCCESS';
export type ROLE_MANAGEMENT_SUCCESS = typeof ROLE_MANAGEMENT_SUCCESS;

export const ROLE_MANAGEMENT_FAILURE = 'ROLE_MANAGEMENT_FAILURE';
export type ROLE_MANAGEMENT_FAILURE = typeof ROLE_MANAGEMENT_FAILURE;

interface RoleManagementInitial {
    type: ROLE_MANAGEMENT_INITIAL;
}

export interface RoleManagementRequest {
    type: ROLE_MANAGEMENT_REQUEST;
    isRetrieveAll?: boolean;
    filterParams : RoleListQuery
}

interface RoleManagementSuccess {
    type: ROLE_MANAGEMENT_SUCCESS;
    data: RoleList;
}

interface RoleManagementFailure {
    type: ROLE_MANAGEMENT_FAILURE;
    error: HttpError;
}

export type RoleManagementAction = RoleManagementInitial | RoleManagementRequest | RoleManagementSuccess | RoleManagementFailure;

export function doRoleManagementInitial(): RoleManagementInitial {
    return {
        type: ROLE_MANAGEMENT_INITIAL
    };
}

export function doRoleManagementRequest(
    isRetrieveAll: boolean,
    filterParams: RoleListQuery,
): RoleManagementRequest {
    return {
        type: ROLE_MANAGEMENT_REQUEST,
        isRetrieveAll: isRetrieveAll,
        filterParams: filterParams,
    };
}

export function doRoleManagementSuccess(data: RoleList): RoleManagementSuccess {
    return {
        type: ROLE_MANAGEMENT_SUCCESS,
        data: data
    };
}

export function doRoleManagementFailure(error: HttpError): RoleManagementFailure {
    return {
        type: ROLE_MANAGEMENT_FAILURE,
        error: error
    };
}
