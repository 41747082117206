import { call, put, takeLatest } from 'redux-saga/effects';
import PromoApi from 'data/api/PromoApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './PromoEditAction';

export function* updatePromoTask(actionRequest: action.UpdatePromoRequest) {
    const newData = actionRequest.newData;
    const response = yield call(PromoApi.updatePromo, newData);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doUpdatePromoSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doUpdatePromoRequest(newData) });
            yield put(action.doUpdatePromoFailure(newTimeoutError));
        } else {
            yield put(action.doUpdatePromoFailure(response.error));
        }
    }
}

export function* updatePromoSaga() {
    yield takeLatest(
        action.UPDATE_PROMO_REQUEST,
        updatePromoTask,
    );
}
