import { call, put, takeLatest } from 'redux-saga/effects';
import UserApi from 'data/api/UserApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './AdminEditAction';

function* updateAdminTask(updateAdminAction: action.UpdateAdminRequest) {
    const { updateData } = updateAdminAction;
    const response = yield call(UserApi.updateUser, updateData);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doUpdateAdminSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doUpdateAdminRequest(updateData) });
            yield put(action.doUpdateAdminFailure(newTimeoutError));
        } else {
            yield put(action.doUpdateAdminFailure(response.error));
        }
    }
}

export function* updateAdminSaga() {
    yield takeLatest(
        action.UPDATE_ADMIN_REQUEST,
        updateAdminTask,
    );
}