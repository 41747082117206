import React from 'react';
import { Popover } from 'antd';
import { ContractDetail } from 'data/Models';
import { Images } from 'themes';

const Text = {
    edit: 'Edit',
    delete: 'Delete',
};

interface ContractOptionsViewProps {
    containerId: HTMLElement | null;
    index: number;
    record: ContractDetail;
    isShown: boolean;
    onPopoverVisibilityChange: (shownPopOver: number | null) => void
    handleEditFn: (index: number) => void;
    handleDeleteFn: (contractId: string) => void;
}

function ContractOptionsView(props: ContractOptionsViewProps) {
    const { containerId, index, record, isShown, onPopoverVisibilityChange, handleEditFn, handleDeleteFn } = props;

    let popupContainer = document.body;
    if (containerId) {
        popupContainer = containerId;
    }

    const content = () => {
        const options = [
            {
                clickFn: () => handleEditFn(index),
                imgSrc: Images.PencilBlue,
                text: Text.edit,
                popOverItemClass: 'edit',
            },
            {
                clickFn: () => handleDeleteFn(record.id),
                imgSrc: Images.Delete,
                text: Text.delete,
                popOverItemClass: 'red',
            },
        ];
        const popOverContentItems = options.map((props) => {
            const { clickFn, imgSrc, text, popOverItemClass } = props;

            return (
                <div key={text} className={`appPopOverItem ${popOverItemClass}`} onClick={clickFn}>
                    <img src={imgSrc} alt={text} />
                    <span>{text}</span>
                </div>
            );
        });

        return (
            <div className="appPopOverContent">
                {popOverContentItems}
            </div>
        );
    };

    return (
        <div>
            <Popover
                content={content}
                visible={isShown}
                trigger="click"
                placement="bottomRight"
                overlayClassName={'actionGroupPopOver'}
                getPopupContainer={() => popupContainer}
            >
                <div className="promoManagementDotsWrapper">
                    <div
                        className="promoManagementDots"
                        onClick={() => onPopoverVisibilityChange(!isShown ? index : null)}
                    >
                        <img src={Images.Dots} alt="Dots" />
                    </div>
                </div>
            </Popover>
        </div>
    );
}

export default ContractOptionsView;