import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

export const defaultDateFormat = "d MMM yyyy '-' HH:mm";
export const defaultDateFormatWithoutTime = "d MMM yyyy";

export const formatDate = (date = '', formatString = defaultDateFormat) => {
    if (date) {
       const localDate = new Date(date);
       return format(localDate, formatString);
    }
    return '';
};

export const defaultLocale = "id-ID";
export const defaultCurrency = "IDR";
export const formatCurrency = (value: number, locale: string = defaultLocale, currency: string = defaultCurrency): string => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
    });

    return formatter.format(value).replace(/\s/g, '');
};
export const formatDays = (days: number): string => {
    let newDate = '';
    if (days <= 30) {
        newDate = days + ' Days Old'
    } else if (days < 365) {
        newDate = Math.floor(days/ 31) + ' Months Old'
    } else {
        newDate = Math.floor(days/ 365) + ' Years Old'
    }
    return newDate;
};
export const LanguageType = {
    id: 'id',
    en: 'en',
};
export const translateGender = (value: string = '', type: string = LanguageType.id): string => {
    let newGender = '';
    const isEn = type === LanguageType.en;
    switch (value) {
        case 'M':
            return newGender = isEn ? 'Male': 'Laki-Laki';
        case 'F':
            return newGender = isEn ? 'Female': 'Perempuan';
        default:
            return newGender;
    }
};
export const calculateAgeFromBirthday = (date = '') => {
    if (date) {
        const birthday = new Date(date);
        const today = new Date();
        const diffDays = differenceInCalendarDays(today, birthday) + 1;
        return formatDays(diffDays);
    }
    return '';
};
export const FilterParamMapper = (filterParams: object) => {
    const queryParams: any = {};
    Object.entries(filterParams).forEach(entry => {
        const [entryKey, entryValue] = entry;
        if (entryValue) {
            queryParams[entryKey] = entryValue;
        }
    });
    return queryParams;
};
export const TableIncrementNumber = (page: number, limit: number, index: number) => {
    return page > 1 ? (page -1) * limit + index + 1 : index + 1;
};
export const DownloadBlob = (data: any, name?: string) => {
    if (data.blob) {
        const url = window.URL.createObjectURL(new Blob([data.blob]));
        const link = document.createElement('a');
        const fileName = data.filename || name;
        link.href = url;
        link.setAttribute('download', `${fileName}`);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
        setTimeout(() => { window.URL.revokeObjectURL(url); }, 100);
    }
};
export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
};
/**
 * Simulate delay with Promise.
 */
export function waitFor(delayInMs: number) : Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, delayInMs);
    });
}
/**
 * To check whether a given string contains at least n letter
 */
export function isContainMinimumAlphabet(str: string, min: number) : boolean {
    const match = str.match(/([A-Za-z])/g);
    return Boolean(match && match.length >= min);
}
