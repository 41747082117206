interface TimerTickEvent {
  elapsedTime: number; // in millisecond
}

interface TimerTimeoutEvent {
  elapsedTime: number; // in millisecond
}

interface TimerEventMap {
  "tick": TimerTickEvent;
  "timeout": TimerTimeoutEvent;
}

export default class Timer {
  private timeoutInMs: number;
  private intervalID: number = 0;
  private elapsedTimeInMs: number = 0;
  private listenersMap: Map<keyof TimerEventMap, Set<(event: TimerEventMap[keyof TimerEventMap]) => void>> = new Map();

  constructor (timeoutInMs: number) {
    this.timeoutInMs = timeoutInMs;
    this.listenersMap = new Map();
    this.listenersMap.set('tick', new Set());
    this.listenersMap.set('timeout', new Set());
  }

  public on<K extends keyof TimerEventMap>(event: K, callback: (event: TimerEventMap[K]) => void) {
    const listeners = this.listenersMap.get(event);
    if (!listeners) return this;

    listeners.add(callback);

    return this;
  }

  public getElapsedTime() : number {
    return this.elapsedTimeInMs;
  }

  private callTickEventListeners (elapsedTimeInMs: number) : void {
    const listeners = this.listenersMap.get('tick');
    if (!listeners) return;
    for (let listener of Array.from(listeners)) {
      listener({ elapsedTime: elapsedTimeInMs });
    }
  }

  private callTimeoutEventListeners (elapsedTimeInMs: number) : void {
    const listeners = this.listenersMap.get('timeout');
    if (!listeners) return;
    for (let listener of Array.from(listeners)) {
      listener({ elapsedTime: elapsedTimeInMs });
    }
  }

  public tick () : void {
    const isStillTicking = this.intervalID > 0;
    if (isStillTicking) {
      console.warn('Timer is ticking already');
      return;
    };
    clearInterval(this.intervalID);

    const oneSecondInMs = 1000;
    this.intervalID = setInterval(() => {

      this.elapsedTimeInMs += oneSecondInMs;
      this.callTickEventListeners(this.elapsedTimeInMs);
      if (this.elapsedTimeInMs === this.timeoutInMs) {
        this.callTimeoutEventListeners(this.elapsedTimeInMs);
        this.stop();
        this.reset();
      }
    }, oneSecondInMs) as unknown as number;
  }

  /**
   * Reset timer
   */
  public reset () : this {
    this.elapsedTimeInMs = 0;
    return this;
  }

  /**
   * Stop tick by clearing the interval
   */
  public stop () : this {
    clearInterval(this.intervalID);
    this.intervalID = 0;
    return this;
  }

  /**
   * Make timer as if it just created
   */
  public clean () : this {
    this.stop();
    this.reset();
    this.removeListeners('tick');
    this.removeListeners('timeout');
    return this;
  }

  private removeListeners(event: keyof TimerEventMap) : void {
    const listeners = this.listenersMap.get(event);
    if (!listeners) return;
    listeners.clear();
  }
}