import Timer from './Timer';

interface TickPayload {
  state: SessionState;
  elapsedTimeInMs: number;
}


interface IdleTimerConfig {
  idleDelayTimerInMs: number;
  idleTimeoutInMs: number;
  idleGracePeriodConfirmationInMs: number;
}

type SessionEvent =
  | 'activity'        // user is doing an activity
  | 'idle_timeout'    // no user activity and the idle time limit is over
  | 'refresh_session' // user choose to refresh the session
  | 'end_session';    // use choose to end the session

type SessionEventPayload = {
  triggeredByThisWindow: boolean;
  isSessionValid: boolean;
}

// All events are using same payload at the moment
interface SessionTimerEventMap {
    "activity": SessionEventPayload;
    "idle_timeout": SessionEventPayload;
    "refresh_session": SessionEventPayload;
    "end_session": SessionEventPayload;
}

interface BroadcastMessagePayload {
  event: SessionEvent;
}

enum SessionState {
  Active = 'active',                            // user is considered active
  Idle = 'idle',                                // no user activity
  WaitingConfirmation = 'waiting_confirmation', // wait for user choice, to stay or end the session
  NoSession = 'no_session',                     // no session / session is over
}

class SessionTimeManager {
  private state : SessionState = SessionState.NoSession;
  private totalElapsedTimeInMs: number = 0;
  private isLoggingEnabled = false;
  private onTick?: (data: TickPayload) => void;
  private broadcastChannel: BroadcastChannel;
  
  private listenersMap: Map<SessionEvent, Set<<K extends SessionEvent>(ev: SessionTimerEventMap[K]) => void>> = new Map();
  private delayTimer: Timer = new Timer(0);
  private idleTimer: Timer = new Timer(0);
  private graceConfirmationPeriodTimer: Timer = new Timer(0);

  constructor () {
    // tabs / window communication channel
    this.broadcastChannel = new window.BroadcastChannel('idle_session_channel');

    this.listenersMap.set('activity', new Set());
    this.listenersMap.set('end_session', new Set());
    this.listenersMap.set('idle_timeout', new Set());
    this.listenersMap.set('refresh_session', new Set());
  }

  public enableLogging() : void {
    this.isLoggingEnabled = true;
  }

  public disableLogging() : void {
    this.isLoggingEnabled = true;
  }

  public getState () : SessionState {
    return this.state;
  }

  public getTotalElapsedTime () : number {
    return this.totalElapsedTimeInMs;
  }

  private log (level: 'warn' | 'info', ...args: unknown[]) : void {
    if (this.isLoggingEnabled) {
      console[level].call(null, ...args);
    }
  }

  private tryOnTick (elapsedTimeInMs: number) : void {
    if (!this.onTick) return;
    this.onTick({
      state: this.state,
      elapsedTimeInMs,
    });
  }

  private listenBroadcast() : void {
    this.broadcastChannel.addEventListener('message', this.handleBroadcastMessage);
  }

  private unlistenBroadcast() : void {
    this.broadcastChannel.removeEventListener('message', this.handleBroadcastMessage);
  }

  handleBroadcastMessage = (channelEvent: MessageEvent) => {
    const payload = channelEvent.data as BroadcastMessagePayload;
    const allowedEvents : SessionEvent[] = [
      'activity',
      'idle_timeout',
      'refresh_session',
      'end_session',
    ];
    if (!allowedEvents.includes(payload.event)) {
      this.log('warn', `Unsupported event:`, payload.event);
      return;
    }

    this.callEventListeners(payload.event);
  };

  public setTimers (config: IdleTimerConfig) : void {
    if (this.state !== SessionState.NoSession) {
      throw new Error('Cannot set the timers while the timer is started');
    }

    this.delayTimer = new Timer(config.idleDelayTimerInMs);
    this.idleTimer = new Timer(config.idleTimeoutInMs);
    this.graceConfirmationPeriodTimer = new Timer(config.idleGracePeriodConfirmationInMs);
  }

  private broadcastRefreshSession () : void {
    this.broadcastChannel.postMessage({
      event: 'refresh_session',
    });
  }

  private bumpTotalElapsedTime () : void {
    this.totalElapsedTimeInMs += 1000;
  }

  private resetTotalElapsedTime () : void {
    this.totalElapsedTimeInMs = 0;
  }

  start (onTick?: (data: TickPayload) => void) : void {
    this.log('info', 'Initialized session time');
    if (onTick) {
      this.onTick = onTick;
    }
    
    // start method should be called only if there is no ongoing session.
    // The initial state should be: no_session.
    if (this.state === SessionState.NoSession) {
      this.state = SessionState.Active;
      this.broadcastRefreshSession();
    } else {
      const message = `Could not start the session while the state is: ${this.state}`;
      throw new Error(message);
    }

    // Make sure to listen the broadcast message only when
    // The start method is called
    this.listenBroadcast();
    this.refreshSession();

    this.delayTimer
      .on('tick', (event) => {
        this.bumpTotalElapsedTime();
        this.tryOnTick(event.elapsedTime);
      })
      .on('timeout', () => {
        this.handleDelayTimeout();

        this.idleTimer
          .clean()
          .on('tick', (event) => {
            this.bumpTotalElapsedTime();
            this.tryOnTick(event.elapsedTime);
          })
          .on('timeout', () : void => {
            this.flagIdleTimeout();
            
            this.graceConfirmationPeriodTimer
              .clean()
              .on('tick', (event) => {
                this.bumpTotalElapsedTime();
                this.tryOnTick(event.elapsedTime);
              })
              .on('timeout', (event) => {
                this.endSession();
              })
              .tick();
          })
          .tick();
      })
      .tick();
  }

  /**
   * @param event 
   * @param ownTrigger The flag to indicate whether the event is triggered by current window or not
   */
  private callEventListeners (event: SessionEvent, ownTrigger: boolean = false) :void {
    const listeners = this.listenersMap.get(event);
    if (!listeners) return;

    switch (event) {
      case 'activity':
        this.handleActivity();
        break;
      case 'refresh_session':
        this.handleRefreshSession();
        break;
      case 'end_session':
        this.handleEndSession();
        break;
      case 'idle_timeout':
        this.handleIdleTimeout();
        break;
      default:
        break;
    }

    for (let listener of Array.from(listeners.values())) {
      listener({ triggeredByThisWindow: ownTrigger, isSessionValid: true });
    }  
  }

  public invalidateSession () : void {
    this.handleEndSession();
    const endSessionListeners = this.listenersMap.get('end_session');
    if (!endSessionListeners) return;
    for (let listener of Array.from(endSessionListeners.values())) {
      listener({ triggeredByThisWindow: true, isSessionValid: false });
    }
    this.broadcastChannel.postMessage({
      event: 'end_session',
    });
  }

  private handleActivity () : void {
    this.resetTotalElapsedTime();
    this.delayTimer.reset();
    if (this.state === SessionState.Idle) {
      this.delayTimer.tick();
    }
    
    this.idleTimer.stop().reset();
    this.state = SessionState.Active;
  }

  private handleDelayTimeout() : void {
    this.state = SessionState.Idle;
  }

  private handleRefreshSession () : void {
    this.resetTotalElapsedTime();
    this.state = SessionState.Active;
    this.graceConfirmationPeriodTimer.clean();
    this.idleTimer.clean();
    this.delayTimer.stop().reset().tick();
  }

  private handleEndSession () : void {
    this.state = SessionState.NoSession;
    this.delayTimer.clean();
    this.idleTimer.clean();
    this.graceConfirmationPeriodTimer.clean();
    this.unlistenBroadcast();
  }

  public flagIdleTimeout () : void {
    this.handleIdleTimeout();
    this.callEventListeners('idle_timeout', true);
    this.broadcastChannel.postMessage({
      event: 'idle_timeout',
    });
  }

  private handleIdleTimeout () : void {
    this.state = SessionState.WaitingConfirmation;
  }

  public captureActivity () : void {
    const shouldHandleActivity = this.state !== SessionState.NoSession && this.state !== SessionState.WaitingConfirmation;
    if (!shouldHandleActivity) {
      this.log('warn', `Ignore to handle activity. Current state is`, this.state);
      return;
    };

    this.log('info', 'Activity captured');

    // prevent the state if timeout or grace 
    this.handleActivity();
    this.callEventListeners('activity', true);
    this.broadcastChannel.postMessage({
      event: 'activity',
    });
  }

  public refreshSession () : void {
    if (this.state === SessionState.NoSession) return;

    this.handleRefreshSession();
    this.callEventListeners('refresh_session', true);
    this.broadcastChannel.postMessage({
      event: 'refresh_session',
    });
  }

  public endSession () : void {
    if (this.state === SessionState.NoSession) return;

    this.handleEndSession();
    this.callEventListeners('end_session', true);
    this.broadcastChannel.postMessage({
      event: 'end_session',
    });
  }

  /**
   * Add event listener for specific event
   */
  on <K extends SessionEvent>(event: K, callback : (ev: SessionTimerEventMap[K]) => void) : void {
    const listeners = this.listenersMap.get(event);
    if (!listeners) return;

    if (listeners.has(callback)) return;
    listeners.add(callback);
  }

  /**
   * Remove event listener for specific event
   */
  off (event: SessionEvent, callback: () => void) : void {
    const listeners = this.listenersMap.get(event);
    if (!listeners) return;

    if (listeners.has(callback)) return;
    listeners.delete(callback);
  }
}

export default SessionTimeManager;
