import { call, put, takeLatest } from 'redux-saga/effects';
import RoleApi from 'data/api/RoleApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './DeleteRoleAction';

function* deleteRoleTask(deleteRoleAction: action.DeleteRoleRequest) {
    const { roleId } = deleteRoleAction;
    const response = yield call(RoleApi.deleteRole, roleId);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doDeleteRoleSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doDeleteRoleRequest(roleId) });
            yield put(action.doDeleteRoleFailure(newTimeoutError));
        } else {
            yield put(action.doDeleteRoleFailure(response.error));
        }
    }
}
export function* deleteRoleSaga() {
    yield takeLatest(
        action.DELETE_ROLE_REQUEST,
        deleteRoleTask,
    );
}
