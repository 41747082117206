export enum actions {
  SET_TOKEN = 'SET_TOKEN',
  SET_TOKEN_REPLY = 'SET_TOKEN_REPLY',
  GET_TOKEN = 'GET_TOKEN',
  GET_TOKEN_REPLY = 'GET_TOKEN_REPLY',
};

export const setToken = (token: string) => ({
  action: actions.SET_TOKEN,
  token,
});

export const setTokenReply = (token: string) => ({
  action: actions.SET_TOKEN_REPLY,
  token,
});

export const getToken = () => ({
  action: actions.GET_TOKEN,
});

export const getTokenReply = (token: string) => ({
  action: actions.GET_TOKEN_REPLY,
  token,
});
