import React, { useState } from 'react';
import { Badge, Button, Checkbox, Dropdown, Menu } from 'antd';
import './index.css';
import { Images } from 'themes';
import { SearchBox } from 'components/FilterComponent';

interface Props<T> {
    label: string;
    searchPlaceholder?: string;
    onSearch?: (query: string) => void;
    data: T[];
    onMapOption: (item: T) => { value: string | number, label: string };
    onSelectedChange: (selecteds: T[]) => void;
}

function SelectFilterComponent<T>(props: Props<T>) {
    const [isVisible, setVisibility] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<T[]>([]);
    const [isAlreadyDone, setAlreadyDone] = useState(false);

    const handleOptionChecked = (isChecked: boolean, value: T) => {
        let newValues: T[] = []
        if (isChecked) {
            newValues = [...selectedOptions, ...[value]];
        } else {
            newValues = selectedOptions.filter((filtered) => filtered !== value);
        }
        setSelectedOptions(newValues);
    };
    const handleSelected = () => {
        props.onSelectedChange(selectedOptions);
        setAlreadyDone(true);
        setVisibility(false);
    };
    const handleVisibilityChange = (isVisible: boolean) => {
        if (!isAlreadyDone) {
            setSelectedOptions([]);
        }
        setVisibility(isVisible);
    };

    const options = (data: T[]): React.ReactElement[] => {
        return data.map((item, index) => {
            const { value, label } = props.onMapOption(item);
            return (
                <Menu.Item className="selectOptionWrapper" key={`${value} ${index}`}>
                    <Checkbox
                        checked={selectedOptions.includes(item)}
                        onChange={(event) => handleOptionChecked(event.target.checked, item)}
                    >
                        {label}
                    </Checkbox>
                </Menu.Item>
            );
        })
    };

    const menu = (
        <Menu className="selectFilterMenu">
            <div className="header">
                <SearchBox placeholder={props.searchPlaceholder} onSearch={props.onSearch} />
                <Button type="link" onClick={handleSelected}>DONE</Button>
            </div>
            <Menu.ItemGroup className="content">
                {options(selectedOptions)}
                <div className="divider"/>
                {options(props.data.filter((item) => !selectedOptions.includes(item)))}
            </Menu.ItemGroup>
        </Menu>
    );

    return (
        <Dropdown
            className="selectFilterWrapper"
            overlay={menu}
            trigger={['click']}
            onVisibleChange={handleVisibilityChange}
            visible={isVisible}
        >
            <Button className={isVisible ? 'selectFilterOpenedButton' : ''} onClick={() => setVisibility(!isVisible)}>
                <div className="label">{props.label}</div>
                {selectedOptions.length > 0 && <Badge count={selectedOptions.length} />}
                <div style={{ flex: 1 }} />
                <img src={isVisible ? Images.ChevronUpGrey : Images.ChevronDownGrey} alt="Arrow"/>
            </Button>
        </Dropdown>
    );
};

export default SelectFilterComponent;
