import React from 'react';
import { BranchDetailFacilityCategory } from 'common/BranchUtils';

interface Props {
  facilityCategories: BranchDetailFacilityCategory[]
}

const BranchDetailFacilities: React.FC<Props> = (props) => {
  const { facilityCategories } = props;  

  return (
    <div className="serviceListContent">
      <ol className="serviceList">
        {facilityCategories.length === 0 ? <li>- </li>: null}
        {facilityCategories.map(category => (
          <li key={category.id}>
            <span className="service-group">{category.category_en}</span>
            <ol className="serviceList" type="a">{category.facilities.map(facility => (
              <li key={facility.name}>{facility.name_en}</li>
            ))}</ol>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default BranchDetailFacilities;