import {HttpError, OrderListDownload} from 'data/Models';
import { OrderListDownloadQuery } from "common/States";

export const ORDER_MANAGEMENT_DOWNLOAD_INITIAL = 'ORDER_MANAGEMENT_DOWNLOAD_INITIAL';
export type ORDER_MANAGEMENT_DOWNLOAD_INITIAL = typeof ORDER_MANAGEMENT_DOWNLOAD_INITIAL;

export const ORDER_MANAGEMENT_DOWNLOAD_REQUEST = 'ORDER_MANAGEMENT_DOWNLOAD_REQUEST';
export type ORDER_MANAGEMENT_DOWNLOAD_REQUEST = typeof ORDER_MANAGEMENT_DOWNLOAD_REQUEST;

export const ORDER_MANAGEMENT_DOWNLOAD_SUCCESS = 'ORDER_MANAGEMENT_DOWNLOAD_SUCCESS';
export type ORDER_MANAGEMENT_DOWNLOAD_SUCCESS = typeof ORDER_MANAGEMENT_DOWNLOAD_SUCCESS;

export const ORDER_MANAGEMENT_DOWNLOAD_FAILURE = 'ORDER_MANAGEMENT_DOWNLOAD_FAILURE';
export type ORDER_MANAGEMENT_DOWNLOAD_FAILURE = typeof ORDER_MANAGEMENT_DOWNLOAD_FAILURE;

interface OrderManagementDownloadInitial {
    type: ORDER_MANAGEMENT_DOWNLOAD_INITIAL;
}

export interface OrderManagementDownloadRequest {
    type: ORDER_MANAGEMENT_DOWNLOAD_REQUEST;
    filterParams: OrderListDownloadQuery;
}

interface OrderManagementDownloadSuccess {
    type: ORDER_MANAGEMENT_DOWNLOAD_SUCCESS;
    data: OrderListDownload;
}

interface OrderManagementDownloadFailure {
    type: ORDER_MANAGEMENT_DOWNLOAD_FAILURE;
    error: HttpError;
}

export type OrderManagementDownloadAction = OrderManagementDownloadInitial | OrderManagementDownloadRequest | OrderManagementDownloadSuccess | OrderManagementDownloadFailure;

export function doOrderManagementDownloadInitial(): OrderManagementDownloadInitial {
    return {
        type: ORDER_MANAGEMENT_DOWNLOAD_INITIAL
    };
}

export function doOrderManagementDownloadRequest(filterParams : OrderListDownloadQuery): OrderManagementDownloadRequest {
    return {
        type: ORDER_MANAGEMENT_DOWNLOAD_REQUEST,
        filterParams: filterParams
    };
}

export function doOrderManagementDownloadSuccess(data: OrderListDownload): OrderManagementDownloadSuccess {
    return {
        type: ORDER_MANAGEMENT_DOWNLOAD_SUCCESS,
        data: data
    };
}

export function doOrderManagementDownloadFailure(error: HttpError): OrderManagementDownloadFailure {
    return {
        type: ORDER_MANAGEMENT_DOWNLOAD_FAILURE,
        error: error
    };
}
