import { LoginResult } from "./LoginState";
import { HttpError } from "data/Models";

export const LOGIN_INITIAL = 'LOGIN_INITIAL';
export type LOGIN_INITIAL = typeof LOGIN_INITIAL;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export type LOGIN_REQUEST = typeof LOGIN_REQUEST;

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS;

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export type LOGIN_FAILURE = typeof LOGIN_FAILURE;

interface LoginInitial {
    type: LOGIN_INITIAL;
}

export interface LoginRequest {
    type: LOGIN_REQUEST;
    email: string;
    password: string;
    captchaToken: string;
}

interface LoginSuccess {
    type: LOGIN_SUCCESS;
    data: LoginResult;
}

interface LoginFailure {
    type: LOGIN_FAILURE;
    error: HttpError;
}

export type LoginAction = LoginInitial | LoginRequest | LoginSuccess | LoginFailure;

export function doLoginInitial(): LoginInitial {
    return {
        type: LOGIN_INITIAL
    };
}

export function doLoginRequest(email: string, password: string, captchaToken: string): LoginRequest {
    return {
        type: LOGIN_REQUEST,
        email,
        password,
        captchaToken,
    };
}

export function doLoginSuccess(data: LoginResult): LoginSuccess {
    return {
        type: LOGIN_SUCCESS,
        data: data
    };
}

export function doLoginFailure(error: HttpError): LoginFailure {
    return {
        type: LOGIN_FAILURE,
        error: error
    };
}
