import React, { ReactNode, useState, useEffect } from 'react';
import { Modal, Spin, DatePicker } from 'antd';
import { useSelector } from "react-redux";
import endOfToday from 'date-fns/endOfToday'
import { Images } from 'themes';
import RootStoreState from "../../rootStore";
import { OvalButton } from 'components/ButtonComponent';
import { SelectComponent, SelectOneAndSearchComponent } from 'components/SelectComponent';
import * as config from '../FilterComponent/config';
import './index.css';

const { RangePicker } = DatePicker;

const Text = {
    descriptionOne: 'Please select your order periode to download.',
    descriptionTwo: 'Maximal periode is 1 year.',
    downloadButton: 'DOWNLOAD ORDER',
    selectBranch: 'Select Branch',
    selectJenisLayanan: 'Filter Service Type',
};
const dateFormat = 'DD/MM/YYYY';
const DownloadComponent: React.FC<{
    title?: string;
    type?: any;
    loadingIconSrc?: string;
    visible?: boolean;
    width?: number;
    maskStyle?: Object;
    closable?: boolean;
    maskClosable?: boolean;
    withLoading?: boolean;
    confirmLoading?: boolean;
    children?: ReactNode;
    handleOk?: (dateValue: any[]) => void;
    handleClose?: () => void;
    loadingMsg?: string;
    forceClose?: boolean;
}> = ({
    title = 'Download',
    type = '',
    loadingIconSrc = Images.TreeLoad,
    visible = false,
    width = 700,
    maskStyle = {
        opacity: 0.7,
        backgroundColor: '#000000',
    },
    closable = true,
    maskClosable = false,
    withLoading = true,
    confirmLoading = false,
    children = 'children',
    handleOk = () => { },
    handleClose = () => { },
    loadingMsg = `Please wait<br/><span>We are processing your request.</span>`,
    forceClose = false,
}) => {
        const profileState = useSelector((state: RootStoreState) => state.profile);
        const branchList = profileState.data?.outlets || [];
        const [visibleState, setVisible] = useState(false);
        const [confirmLoadingState, setLoading] = useState(false);
        const [dates, setDates] = useState<any>([]);
        const [value, setValue] = useState<any>(null);
        const [disableButton, setDisableButton] = useState<boolean>(true);
        const [clearSelectValue, setClearSelectValue] = useState(false);

        let newListState = config.serviceTypeListState;
        const [query, setQuery] = useState('');
        const serviceTypeList = newListState.filter((item) => item.name.toLowerCase()) ?? [];

        const onGetOption = (branch: any) => {
            return { value: branch.id, label: branch.name }
        };
        const onGetOptionServiceType = (serviceType: any) => {
            return { value: serviceType.id, label: serviceType.name }
        };
        useEffect(() => {
            setVisible(visible);
            if (!visible) {
                initialFn();
            }
        }, [visible]);
        useEffect(() => {
            if (value && value.dateValue && value.dateValue[0] && value.dateValue[1]) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
            }
        }, [value]);
        useEffect(() => {
            if (forceClose) {
                handleClose();
            }
        }, [forceClose, handleClose]);
        const initialFn = () => {
            setLoading(false);
            setDates([]);
            setValue(null);
            setDisableButton(true);
            setClearSelectValue(false);
        };
        const disabledDate = (current: any) => {
            let tooLate = false;
            let tooEarly = false;
            const afterToday = current && current > endOfToday();
            if (dates && dates.length > 0) {
                tooLate = dates[0] && current.diff(dates[0], 'year') >= 1;
                tooEarly = dates[1] && dates[1].diff(current, 'year') >= 1;
            }
            return afterToday || tooEarly || tooLate;
        };
        const closeIcon = (
            <img src={Images.Close} alt="close" />
        );
        const loadingIcon = <img className="modalWithLoadingIcon" src={loadingIconSrc} alt="loading" />;
        const handleOkFn = () => {
            if (withLoading && !confirmLoading) {
                setLoading(true);
            } else {
                handleClose();
            }
            value && handleOk(value);
        };
        const footer: ReactNode[] = [
            <OvalButton
                type={'primary'}
                buttonText={Text.downloadButton}
                key={Text.downloadButton}
                onClick={handleOkFn}
                disabled={disableButton}
            />
        ];
        const handleCloseIcon = () => {
            setClearSelectValue(true);
            handleClose();
        };
        return (
            <>
                <Modal
                    maskClosable={maskClosable}
                    title={!confirmLoadingState ? title : null}
                    centered
                    closeIcon={closeIcon}
                    visible={visibleState}
                    width={width}
                    maskStyle={maskStyle}
                    closable={!confirmLoadingState ? closable : false}
                    onOk={() => handleOkFn()}
                    confirmLoading={confirmLoadingState}
                    onCancel={handleCloseIcon}
                    footer={!confirmLoadingState ? footer : null}
                    wrapClassName={`downloadContainerWrapper`}
                >
                    {confirmLoadingState ? (
                        <div className="loadingWrapper">
                            <Spin indicator={loadingIcon} />
                            <div
                                className="loadingMsg"
                                dangerouslySetInnerHTML={{ __html: loadingMsg }}
                            />
                        </div>
                    ) : (
                        <div>
                            <div>{Text.descriptionOne}</div>
                            <div>{Text.descriptionTwo}</div>
                            <div className={'downloadContentWrapper'}>
                                <div className={'datePickerWrapper'}>
                                    <RangePicker
                                        className={'rangePickerWrapper'}
                                        format={dateFormat}
                                        value={value?.dateValue}
                                        disabledDate={disabledDate}
                                        onCalendarChange={val => setDates(val)}
                                        onChange={val => {
                                            const newValue = Object.assign({}, value, { dateValue: val });
                                            setValue(newValue);
                                        }}
                                        inputReadOnly={true}
                                    />
                                </div>
                                <div className='padding-horizonatal-10'>
                                    <SelectOneAndSearchComponent
                                        selectProps={{
                                            placeholder: Text.selectBranch,
                                        }}
                                        data={branchList}
                                        onMapOption={onGetOption}
                                        onSelectedChange={(branchValue: any) => {
                                            const newValue = Object.assign({}, value, { branchValue: branchValue });
                                            setValue(newValue);
                                        }}
                                        clearSelectValue={clearSelectValue}
                                    />
                                </div>
                                <div>
                                    <SelectComponent
                                        selectProps={{
                                            placeholder: Text.selectJenisLayanan,
                                        }}
                                        data={serviceTypeList}
                                        onMapOption={onGetOptionServiceType}
                                        onSelectedChange={(serviceType: any) => {
                                            const newValue = Object.assign({}, value, { serviceType: serviceType.id });
                                            setValue(newValue);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </Modal>
            </>
        )
    };

export default DownloadComponent;
