import { HttpError, TestFormData } from "data/Models";

export const UPDATE_TEST_INITIAL = 'UPDATE_TEST_INITIAL';
export type UPDATE_TEST_INITIAL = typeof UPDATE_TEST_INITIAL;

export const UPDATE_TEST_REQUEST = 'UPDATE_TEST_REQUEST';
export type UPDATE_TEST_REQUEST = typeof UPDATE_TEST_REQUEST;

export const UPDATE_TEST_SUCCESS = 'UPDATE_TEST_SUCCESS';
export type UPDATE_TEST_SUCCESS = typeof UPDATE_TEST_SUCCESS;

export const UPDATE_TEST_FAILURE = 'UPDATE_TEST_FAILURE';
export type UPDATE_TEST_FAILURE = typeof UPDATE_TEST_FAILURE;

interface UpdateTestInitial {
    type: UPDATE_TEST_INITIAL;
}

export interface UpdateTestRequest {
    type: UPDATE_TEST_REQUEST;
    updateData: TestFormData;
}

interface UpdateTestSuccess {
    type: UPDATE_TEST_SUCCESS;
}

interface UpdateTestFailure {
    type: UPDATE_TEST_FAILURE;
    error: HttpError;
}

export type UpdateTestAction = UpdateTestInitial | UpdateTestRequest | UpdateTestSuccess | UpdateTestFailure;

export function doUpdateTestInitial(): UpdateTestInitial {
    return {
        type: UPDATE_TEST_INITIAL,
    };
}

export function doUpdateTestRequest(updateData: TestFormData): UpdateTestRequest {
    return {
        type: UPDATE_TEST_REQUEST,
        updateData: updateData,
    };
}

export function doUpdateTestSuccess(): UpdateTestSuccess {
    return {
        type: UPDATE_TEST_SUCCESS,
    };
}

export function doUpdateTestFailure(error: HttpError): UpdateTestFailure {
    return {
        type: UPDATE_TEST_FAILURE,
        error: error,
    };
}
