import { REACT_APP_API_DOMAIN } from 'common/Predicates';

const baseUrl = REACT_APP_API_DOMAIN;

const ApiEndpoint = {
    Auth: {
        Login: baseUrl + "cms/auth/login",
        Logout: baseUrl + "cms/auth/logout",
    },
    Activation: {
        Resend: baseUrl + "cms/auth/activation/resend",
        Verify: baseUrl + "cms/auth/activate/exchange",
        CreatePassword: baseUrl + "cms/auth/activation/password",
    },
    Profile: {
        Get: baseUrl + "cms/me",
        ChangePassword: baseUrl + "cms/me/password",
    },
    Role: {
        Base: baseUrl + "cms/role",
        Permission: baseUrl + "cms/role/permission",
    },
    Region: {
        Base: baseUrl + "cms/region",
    },
    Outlet: {
        Base: baseUrl + "cms/outlet",
    },
    User: {
        Base: baseUrl + "cms/user",
    },
    Panel: {
        Base: baseUrl + "cms/panel",
    },
    Test: {
        Base: baseUrl + "cms/test",
    },
    HomeService: {
        Base: baseUrl + "cms/hs/test",
        TNC: baseUrl + "cms/order/hs-tnc",
        UPDATE_TNC: baseUrl + "cms/order/hs-update-tnc",
    },
    Order: {
        Base: baseUrl + "cms/order",
        Download: baseUrl + "cms/order/download",
        DetailDownload: baseUrl + "cms/file/order-referral",
    },
    Payment: {
        Base: baseUrl + "cms/payment",
        Channel: baseUrl +  "cms/doku/channel",
    },
    Promo: {
        Base: baseUrl + "cms/promo",
        General: baseUrl + "cms/promo/general",
        Offer: baseUrl + "cms/promo/offer",
        FlashSale: baseUrl + "cms/promo/flash-sale",
    },
    Contract: {
        Base: baseUrl + "cms/contract",
    },
};

export default ApiEndpoint;
