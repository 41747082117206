import { call, put, takeLatest } from 'redux-saga/effects';
import UserApi from 'data/api/UserApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './AdminDetailAction';

function* adminDetailTask(adminDetailAction: action.AdminDetailRequest) {
    const { userId } = adminDetailAction;
    const response = yield call(UserApi.getAdminDetail, userId);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doAdminDetailSuccess(response.data.userDetail));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doAdminDetailRequest(userId) });
            yield put(action.doAdminDetailFailure(newTimeoutError));
        } else {
            yield put(action.doAdminDetailFailure(response.error));
        }
    }
}

export function* adminDetailSaga() {
    yield takeLatest(
        action.ADMIN_DETAIL_REQUEST,
        adminDetailTask,
    );
}
