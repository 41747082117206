import React, { useEffect, Dispatch, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { Descriptions } from 'antd';
import RootStoreState from 'rootStore';
import AlertComponent from 'components/AlertComponent';
import { GetMethodLoading } from 'components/LoadingComponent';
import { OvalButton } from 'components/ButtonComponent';
import BreadCrumbsComp from 'components/BreadCrumbsComponent';
import { formatCurrency, formatDays, LanguageType, translateGender } from 'common/CommonUtils';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { DefaultCustomProps } from 'common/States';
import { FrontendRoutes } from 'navigation/Routes';
import { Images } from 'themes';
import { doGetProfileInitial, GetProfileAction } from 'features/scaffold/ScaffoldAction';
import { PanelDetailAction, doPanelDetailInitial, doPanelDetailRequest } from './PanelDetailAction';
import { PanelTestItem, HttpError } from 'data/Models';
import './index.css'

interface Props {
    customProps?: DefaultCustomProps
}
const Text = {
    detailsInformation: 'Details Information',
    code: 'Code Panel',
    panelName: {
        id: 'Panel Name (ID)',
        en: 'Panel Name (EN)',
    },
    description: {
        id: 'Panel Description (ID)',
        en: 'Panel Description (EN)',
    },
    benefit: {
        id: 'Panel Benefit (ID)',
        en: 'Panel Benefit (EN)',
    },
    preparations: {
        id: 'Panel Preparation (ID)',
        en: 'Panel Preparation (EN)',
    },
    actualPrice: 'Actual Price',
    originalPrice: 'Original Price',
    type: 'Panel Type',
    condition: {
        id: 'Condition Panel (ID)',
        en: 'Condition Panel (EN)',
    },
    gender: {
        id: 'Gender (ID)',
        en: 'Gender (EN)',
    },
    beginAge: 'Begin Age',
    endAge: 'End Age',
    status: 'Status',
    edit: 'EDIT',
    Status: {
        Active: 'Active',
        Inactive: 'Inactive',
    },
    descriptionRow: 'descriptionRow'
};

const PanelDetailView: React.FC<Props> = (props) => {
    const history = useHistory();
    const { panelId } = useParams<{ panelId: string }>();
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];

    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.PanelEditPage.permissionKey, permissions);
    const panelDetailAction = useDispatch<Dispatch<PanelDetailAction>>();
    const panelDetailState = useSelector((state: RootStoreState) => state.panelDetail);
    const panelDetailData = panelDetailState.data;
    const [error, setError] = useState<HttpError | null>(null);
    useEffect(() => {
        panelDetailAction(doPanelDetailRequest(panelId));
    }, [panelId, panelDetailAction]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
       if (panelDetailState.error) {
            panelDetailAction(doPanelDetailInitial());
            setError(panelDetailState.error);
        }
    }, [panelDetailAction, panelDetailState]);
    const loading: boolean = profileState.onRequest || panelDetailState.onRequest;
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const renderTests = useCallback((detailData, type = LanguageType.id) => {
        return (
            <div>
                {
                    detailData?.tests?.map((group: PanelTestItem, index: number)=>{
                        return (
                            <div key={`panelTestList${index}`}>
                                <div>
                                    <span className="listItemIndex">{`${index + 1}`}.</span>
                                    <span>{type === LanguageType.en ? group.name_en: group.name}</span>
                                </div>
                                {group.preparations.map((preparationItem)=> {
                                    return (
                                        <div className={'preparationItem'} key={`panelPreparationItem${index}`}>
                                            <span className="listItemIndex">-</span>
                                            <span>{type === LanguageType.en ? preparationItem.description_en : preparationItem.description}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })
                }
            </div>
        )
    }, []);
    const panelDetailDesc = [
        {
            label: Text.code,
            content: panelDetailData?.id,
        },
        {
            label: Text.panelName.id,
            content: panelDetailData?.name,
        },
        {
            label: Text.panelName.en,
            content: panelDetailData?.name_en,
        },
        {
            label: Text.description.id,
            content: panelDetailData?.description,
            className: Text.descriptionRow
        },
        {
            label: Text.description.en,
            content: panelDetailData?.description_en,
            className: Text.descriptionRow
        },
        {
            label: Text.preparations.id,
            content: renderTests(panelDetailData),
            className: Text.descriptionRow
        },
        {
            label: Text.preparations.en,
            content: renderTests(panelDetailData, LanguageType.en),
            className: Text.descriptionRow
        },
        {
            label: Text.benefit.id,
            content: panelDetailData?.benefit,
            className: Text.descriptionRow
        },
        {
            label: Text.benefit.en,
            content: panelDetailData?.benefit_en,
            className: Text.descriptionRow
        },
        {
            label: Text.actualPrice,
            content: panelDetailData?.price?.actual && formatCurrency(panelDetailData.price.actual),
        },
        {
            label: Text.originalPrice,
            content: panelDetailData?.price?.original && formatCurrency(panelDetailData.price.original),
        },
        {
            label: Text.type,
            content: (
                <div>
                    {panelDetailData?.types?.map((typeItem, index)=>{
                        return (
                            <div key={`typeList${index}`}>{typeItem}</div>
                        )
                    })}
                </div>
            ),
        },
        {
            label: Text.condition.id,
            content: panelDetailData?.condition?.name,
        },
        {
            label: Text.condition.en,
            content: panelDetailData?.condition?.name_en,
        },
        {
            label: Text.gender.id,
            content: translateGender(panelDetailData?.requirement?.sex),
        },
        {
            label: Text.gender.en,
            content: translateGender(panelDetailData?.requirement?.sex, LanguageType.en),
        },
        {
            label: Text.beginAge,
            content: panelDetailData?.requirement && formatDays(panelDetailData?.requirement?.age_min),
        },
        {
            label: Text.endAge,
            content: panelDetailData?.requirement && formatDays(panelDetailData?.requirement?.age_max) ,
        },
        {
            label: Text.status,
            content: panelDetailData?.is_active ? Text.Status.Active : Text.Status.Inactive,
        },
    ];
    const handleNavigateToEdit = () => {
        const path = FrontendRoutes.PanelEditPage.path.replace(
            ':panelId',
            `${panelId}`,
        );
        history.push(path);
    };
    const breadCrumbsButtons = () => {
        return (
            <div className="breadCrumbsButtonsWrapper">
                {isAllowedEditDetail && (
                    <OvalButton
                        type="primary"
                        buttonText={Text.edit}
                        icon={<img className="ovalButtonImg" src={Images.PencilWhite} alt="edit" />}
                        onClick={() => handleNavigateToEdit()}
                    />
                )}
            </div>
        )
    };
    return (
        <div className="panelDetailWrapper">
            {props.customProps?.breadcrumbs && (
                <BreadCrumbsComp
                    crumbs={props.customProps.breadcrumbs}
                >
                    {breadCrumbsButtons()}
                </BreadCrumbsComp>
            )}
            {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            {loading && <GetMethodLoading />}
            {panelDetailData && (
                <Descriptions
                    title={Text.detailsInformation}
                    className={'panelDetailContent'}
                >
                    {panelDetailData && panelDetailDesc.map((panelDetailItem, index) => {
                        return (
                            <Descriptions.Item key={`panelDetailItem${index}`} className={panelDetailItem.className || ''} label={panelDetailItem.label} span={3}>{panelDetailItem.content}</Descriptions.Item>
                        )
                    })}
                </Descriptions>
            )}
        </div>
    );
};

export default PanelDetailView;
