import {
    ORDER_DETAIL_DOWNLOAD_INITIAL,
    ORDER_DETAIL_DOWNLOAD_REQUEST,
    ORDER_DETAIL_DOWNLOAD_SUCCESS,
    ORDER_DETAIL_DOWNLOAD_FAILURE,
    OrderDetailDownloadAction
} from './OrderDetailDownloadAction';
import { OrderDetailDownloadStoreState } from './OrderDetailDownloadState';

export const defaultState: OrderDetailDownloadStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function orderDetailDownloadReducer(
    state: OrderDetailDownloadStoreState = defaultState,
    action: OrderDetailDownloadAction
): OrderDetailDownloadStoreState {
    switch (action.type) {
        case ORDER_DETAIL_DOWNLOAD_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case ORDER_DETAIL_DOWNLOAD_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case ORDER_DETAIL_DOWNLOAD_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case ORDER_DETAIL_DOWNLOAD_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
