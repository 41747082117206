import ModalConfirmation from './ModalConfirmation';
import ModalWithLoading from './ModalWithLoading';
import ModalViewImage from './ModalViewImage';
import ModalError from './ModalError';
import ModalTimeout from './ModalTimeout';
import ModalProdia from './ModalProdia';
import ModalSendAccountActivation from './ModalSendAccountActivation';

export const TypeDeleteConfirmation = 'TypeDeleteConfirmation';
export const TypePageNotAllowed = 'TypePageNotAllowed';
export const TypeSessionExpired = 'TypeSessionExpired';
export const TypePageNotFound = 'TypePageNotFound';
export const TypeTimeOut = 'TypeTimeOut';
export const TypeProdia = 'TypeModalProdia';

export {
    ModalConfirmation,
    ModalWithLoading,
    ModalViewImage,
    ModalError,
    ModalTimeout,
    ModalProdia,
    ModalSendAccountActivation,
};