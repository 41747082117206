import { HttpError } from 'data/Models';

export const DELETE_ADMIN_INITIAL = 'DELETE_ADMIN_INITIAL';
export type DELETE_ADMIN_INITIAL = typeof DELETE_ADMIN_INITIAL;

export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST';
export type DELETE_ADMIN_REQUEST = typeof DELETE_ADMIN_REQUEST;

export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export type DELETE_ADMIN_SUCCESS = typeof DELETE_ADMIN_SUCCESS;

export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';
export type DELETE_ADMIN_FAILURE = typeof DELETE_ADMIN_FAILURE;

interface DeleteAdminInitial {
    type: DELETE_ADMIN_INITIAL;
}

export interface DeleteAdminRequest {
    type: DELETE_ADMIN_REQUEST;
    userId: string;
}

interface DeleteAdminSuccess {
    type: DELETE_ADMIN_SUCCESS;
}

interface DeleteAdminFailure {
    type: DELETE_ADMIN_FAILURE;
    error: HttpError;
}

export type DeleteAdminAction = DeleteAdminInitial | DeleteAdminRequest | DeleteAdminSuccess | DeleteAdminFailure;

export function doDeleteAdminInitial(): DeleteAdminInitial {
    return {
        type: DELETE_ADMIN_INITIAL
    };
}

export function doDeleteAdminRequest(userId: string): DeleteAdminRequest {
    return {
        type: DELETE_ADMIN_REQUEST,
        userId: userId,
    };
}

export function doDeleteAdminSuccess(): DeleteAdminSuccess {
    return {
        type: DELETE_ADMIN_SUCCESS
    };
}

export function doDeleteAdminFailure(error: HttpError): DeleteAdminFailure {
    return {
        type: DELETE_ADMIN_FAILURE,
        error: error
    };
}
