import { RuleObject } from "antd/lib/form";

export const minEmailLen = 5;
export const maxEmailLen = 50;
export const minPasswordLen = 8;
export const maxPasswordLen = 255;
export const emailRules: any[] = [
    () => ({
        validator(rule: RuleObject, value: any) {
            let error: Error | null = null;

            if (!value || value.length === 0) {
                error = new Error('Please input Email Address.');
            } else if (value.length < 5 || value.length > 50) {
                error = new Error('The email length should be within 5 to 50 characters.');
            } else if (!value.match(/\S+@\S+\.\S+/)) {
                error = new Error('Wrong email formats.');
            }

            if (error) {
                return Promise.reject(error);
            }

            return Promise.resolve();
        }
    }),
];
export const passwordRules: any[] = [
    {
        required: true,
        message: 'Please input password.',
    },
    {
        min: minPasswordLen,
        message: 'Password requires at least 8 characters.',
    },
    {
        max: maxPasswordLen,
        message: 'The password length should be within 8 to 255 characters.',
    },
];

export const loginText = {
    loginViewFormTitle: 'Welcome Back Admin.',
    loginViewFormDesc: 'Please login to your account.',
    loginButtonText: 'LOGIN',
    loginOKButtonText: 'OK',
};
