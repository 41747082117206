import {
    ADMIN_MANAGEMENT_INITIAL,
    ADMIN_MANAGEMENT_REQUEST,
    ADMIN_MANAGEMENT_SUCCESS,
    ADMIN_MANAGEMENT_FAILURE,
    AdminManagementAction
} from './AdminManagementAction';
import { UserListStoreState } from './AdminManagementState';

export const defaultState: UserListStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function adminManagementReducer(
    state: UserListStoreState = defaultState,
    action: AdminManagementAction
): UserListStoreState {
    switch (action.type) {
        case ADMIN_MANAGEMENT_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case ADMIN_MANAGEMENT_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case ADMIN_MANAGEMENT_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case ADMIN_MANAGEMENT_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
