import ProfileApi from 'data/api/ProfileApi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './ScaffoldAction';
import { ProfileStorageUtils } from 'data/local/AuthStorage';
import sessionTimeManager from 'lib/SessionTimeManager';

function* getProfileSagaTask() {
    const response = yield call(ProfileApi.getProfile);

    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield call(ProfileStorageUtils.set, response.data);
        yield put(action.doGetProfileSuccess(response.data.profileDetail));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doGetProfileRequest() });
            yield put(action.doGetProfileFailure(newTimeoutError));
        } else {
            yield put(action.doGetProfileFailure(response.error));
        }
    }
}

export function* getProfileSaga() {
    yield takeLatest(
        action.GET_PROFILE_REQUEST,
        getProfileSagaTask,
    );
}

export function* logoutSagaTask() {
    yield call(sessionTimeManager.endSession.bind(sessionTimeManager));
}

export function* logoutSaga() {
    yield takeLatest(
        action.LOGOUT_REQUEST,
        logoutSagaTask
    );
}
