import AlertComponent from 'components/AlertComponent';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootStoreState from 'rootStore';
import { ChangePasswordAction, doChangePasswordInitial } from './ChangePasswordAction';

function ChangePasswordAlertView() {
    const changePasswordAction = useDispatch<Dispatch<ChangePasswordAction>>();
    const changePasswordState = useSelector((state: RootStoreState) => state.changePassword);
    const [success, setSuccess] = useState<string>();

    useEffect(() => {
        if (changePasswordState.success === true) {
            changePasswordAction(doChangePasswordInitial());
            setSuccess('Success Change Password');
        }
    }, [changePasswordAction, changePasswordState]);

    return (
        <div>
            {success && <AlertComponent message={success} type={'success'} timeout={3000}/>}
        </div>   
    );
}

export default ChangePasswordAlertView;
