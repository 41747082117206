import React from "react";
import {Descriptions, Typography} from "antd";
import {PromoDetail} from "data/Models";
import {capitalizeFirstLetter, formatDate} from "common/CommonUtils";
import {Text} from "./PromoDetailView";
import {findPromoTypeName} from "common/constans";

interface Props {
  promoDetailData: PromoDetail;
  promoType: string;
}

const PromoDetailWithSingleContract: React.FC<Props> = (props) => {
  const {promoDetailData, promoType} = props;

  const promoDetailDesc = [
    {
      label: Text.promoType,
      content: findPromoTypeName(promoType),
    },
    {
      label: Text.contractID,
      content: promoDetailData?.contract?.id,
    },
    {
      label: Text.source,
      content: promoDetailData?.source,
    },
    {
      label: Text.contractName.id,
      content: promoDetailData?.contract?.name,
    },
    {
      label: Text.contractName.en,
      content: promoDetailData?.contract?.name_en,
    },
    {
      label: Text.testName.id,
      content: (
        <div className={"listWrapper"}>
          {promoDetailData?.contract?.tests &&
            (promoDetailData?.contract?.tests.length === 0
              ? "-"
              : promoDetailData?.contract?.tests?.map((group, index) => {
                  return (
                    <div key={`testsList${index}`}>
                      <span className="listItemIndex">{`${index + 1}`}.</span>
                      <span>{group.name} </span>
                    </div>
                  );
                }))}
        </div>
      ),
      className: Text.descriptionRow,
    },
    {
      label: Text.testName.en,
      content: (
        <div className={"listWrapper"}>
          {promoDetailData?.contract?.tests &&
            (promoDetailData?.contract?.tests.length === 0
              ? "-"
              : promoDetailData?.contract?.tests?.map((group, index) => {
                  return (
                    <div key={`testsListEn${index}`}>
                      <span className="listItemIndex">{`${index + 1}`}.</span>
                      <span>{group.name_en} </span>
                    </div>
                  );
                }))}
        </div>
      ),
      className: Text.descriptionRow,
    },
    {
      label: Text.discount,
      content: promoDetailData?.contract?.discount + "%",
    },
    {
      label: Text.promoCode,
      content: promoDetailData?.promo_code,
    },
    {
      label: Text.branches,
      content: (
        <div className={"listWrapper"}>
          {promoDetailData?.contract?.outlets &&
            (promoDetailData?.contract?.outlets.length === 0 ? (
              "-"
            ) : (
              <div
                className={`branchListWrapper ${
                  promoDetailData?.contract?.outlets.length > 15
                    ? "branchListScroll"
                    : ""
                }`}
              >
                {promoDetailData?.contract?.outlets?.map((group, index) => {
                  return (
                    <div key={`outletsList${index}`}>
                      <span className="listItemIndex">{`${index + 1}`}.</span>
                      <span>{group} </span>
                    </div>
                  );
                })}
              </div>
            ))}
        </div>
      ),
      className: Text.descriptionRow,
    },
    {
      label: Text.usedQuota,
      content: `${promoDetailData?.quantity.used ?? "-"}/${
        promoDetailData?.quantity.quota ?? "-"
      }`,
    },
    {
      label: Text.title.id,
      content: promoDetailData?.title,
    },
    {
      label: Text.title.en,
      content: promoDetailData?.title_en,
    },
    {
      label: Text.imageDisplay,
      content: promoDetailData?.cover_image && (
        <img
          className={"promoDetailImg"}
          src={promoDetailData?.cover_image}
          alt="promoDetailImg"
        />
      ),
    },
    {
      label: Text.description.id,
      content: promoDetailData?.description,
      className: Text.descriptionRowWithBorder,
      setInnerHTML: true,
    },
    {
      label: Text.description.en,
      content: promoDetailData?.description_en,
      className: Text.descriptionRowWithBorder,
      setInnerHTML: true,
    },
    {
      label: Text.termsAndCondition.id,
      content: promoDetailData?.tnc,
      className: Text.descriptionRowWithBorder,
      setInnerHTML: true,
    },
    {
      label: Text.termsAndCondition.en,
      content: promoDetailData?.tnc_en,
      className: Text.descriptionRowWithBorder,
      setInnerHTML: true,
    },
    {
      label: Text.startPromoDate,
      content: formatDate(promoDetailData?.periode_date?.start),
    },
    {
      label: Text.endPromoDate,
      content: formatDate(promoDetailData?.periode_date?.end),
    },
    {
      label: Text.startPublishDate,
      content: formatDate(promoDetailData?.publish_date?.start),
    },
    {
      label: Text.endPublishDate,
      content: formatDate(promoDetailData?.publish_date?.end),
    },
    {
      label: Text.createdDate,
      content: formatDate(promoDetailData?.created_at),
    },
    {
      label: Text.createdBy,
      content: promoDetailData?.created_by?.name,
    },
    {
      label: Text.updateDate,
      content: formatDate(promoDetailData?.updated_at),
    },
    {
      label: Text.updateBy,
      content: promoDetailData?.updated_by?.name,
    },
    {
      label: Text.status,
      content: promoDetailData?.status
        ? capitalizeFirstLetter(promoDetailData?.status)
        : "",
    },
  ];

  return (
    <Descriptions
      title={Text.detailsInformation}
      className={"promoDetailContent"}
    >
      {promoDetailDesc &&
        promoDetailDesc.map((promoDetailItem, index) => {
          const isFullWidthBorder =
            promoDetailItem.className === Text.descriptionRowWithBorder;
          return (
            <Descriptions.Item
              key={`promoDetailItem${index}`}
              className={promoDetailItem.className || ""}
              label={promoDetailItem.label}
              labelStyle={
                isFullWidthBorder ? {} : {borderBottom: "1px solid #dde1e8"}
              }
              span={3}
            >
              <div>
                {promoDetailItem.setInnerHTML ? (
                  <Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: promoDetailItem.content ?? "",
                      }}
                    />{" "}
                  </Typography>
                ) : (
                  promoDetailItem.content
                )}
                {!isFullWidthBorder && <div className="descDivider"></div>}
              </div>
            </Descriptions.Item>
          );
        })}
    </Descriptions>
  );
};

export default PromoDetailWithSingleContract;
