import sessionTimeManager from 'lib/SessionTimeManager';
import { LoginResult } from '../../features/login/LoginState';
import { InMemoryAuthStorage } from './InMemoryAuthStorage';
import { ProfileStorageUtils } from './LocalAuthStorage';

const TemporaryLocalTokenKey = 'tmp_token_key';

const InMemoryAuthStorageAdapter = {
    storage: InMemoryAuthStorage.getInstance(),
    getTemporaryLocalToken () {
        return sessionStorage.getItem(TemporaryLocalTokenKey) || '';
    },
    temporaryStoreLocalToken (token: string) {
        sessionStorage.setItem(TemporaryLocalTokenKey, token);
    },
    removeTemporaryLocalToken () {
        sessionStorage.removeItem(TemporaryLocalTokenKey);
    },
    init (onDone: (str: string) => void) : void {
        // For maintaining user session when opening one tab / window and refresh 
        const tokenStoredTemporary = InMemoryAuthStorageAdapter.getTemporaryLocalToken();
        if (tokenStoredTemporary !== '') {
            InMemoryAuthStorageAdapter.storage.setToken(tokenStoredTemporary || '')
                .then(() => onDone(tokenStoredTemporary));
        } 
        // This block is to handle initialization for second, third and any other new tab/window
        else {
            InMemoryAuthStorageAdapter.storage.fetchToken()
                .then((token) => {
                    InMemoryAuthStorageAdapter.temporaryStoreLocalToken(token);
                    onDone(token);
                });
        }
    },
    save(loginResult: LoginResult): Promise<string> {
        InMemoryAuthStorageAdapter.temporaryStoreLocalToken(loginResult.token);
        return InMemoryAuthStorageAdapter.storage.setToken(loginResult.token);
    },
    clear(): void {
        InMemoryAuthStorageAdapter.removeTemporaryLocalToken();
        InMemoryAuthStorageAdapter.storage.setToken('');
        return;
    },
    getValidToken(): string {
        return InMemoryAuthStorageAdapter.storage.getToken();
    },
    isAuthenticated(): boolean {
        const token = InMemoryAuthStorageAdapter.storage.getToken();
        return token !== '';
    },
    logout(): void {
        InMemoryAuthStorageAdapter.removeTemporaryLocalToken();
        ProfileStorageUtils.remove();
        InMemoryAuthStorageAdapter.storage.setToken('');
        sessionTimeManager.endSession();
    },
};

export default InMemoryAuthStorageAdapter;