import { HttpError, OrderDetailDownload } from 'data/Models';

export const ORDER_DETAIL_DOWNLOAD_INITIAL = 'ORDER_DETAIL_DOWNLOAD_INITIAL';
export type ORDER_DETAIL_DOWNLOAD_INITIAL = typeof ORDER_DETAIL_DOWNLOAD_INITIAL;

export const ORDER_DETAIL_DOWNLOAD_REQUEST = 'ORDER_DETAIL_DOWNLOAD_REQUEST';
export type ORDER_DETAIL_DOWNLOAD_REQUEST = typeof ORDER_DETAIL_DOWNLOAD_REQUEST;

export const ORDER_DETAIL_DOWNLOAD_SUCCESS = 'ORDER_DETAIL_DOWNLOAD_SUCCESS';
export type ORDER_DETAIL_DOWNLOAD_SUCCESS = typeof ORDER_DETAIL_DOWNLOAD_SUCCESS;

export const ORDER_DETAIL_DOWNLOAD_FAILURE = 'ORDER_DETAIL_DOWNLOAD_FAILURE';
export type ORDER_DETAIL_DOWNLOAD_FAILURE = typeof ORDER_DETAIL_DOWNLOAD_FAILURE;

interface OrderDetailDownloadInitial {
    type: ORDER_DETAIL_DOWNLOAD_INITIAL;
}

export interface OrderDetailDownloadRequest {
    type: ORDER_DETAIL_DOWNLOAD_REQUEST;
    orderId: string;
}

interface OrderDetailDownloadSuccess {
    type: ORDER_DETAIL_DOWNLOAD_SUCCESS;
    data: OrderDetailDownload;
}

interface OrderDetailDownloadFailure {
    type: ORDER_DETAIL_DOWNLOAD_FAILURE;
    error: HttpError;
}

export type OrderDetailDownloadAction = OrderDetailDownloadInitial | OrderDetailDownloadRequest | OrderDetailDownloadSuccess | OrderDetailDownloadFailure;

export function doOrderDetailDownloadInitial(): OrderDetailDownloadInitial {
    return {
        type: ORDER_DETAIL_DOWNLOAD_INITIAL
    };
}

export function doOrderDetailDownloadRequest(orderId: string): OrderDetailDownloadRequest {
    return {
        type: ORDER_DETAIL_DOWNLOAD_REQUEST,
        orderId: orderId,
    };
}

export function doOrderDetailDownloadSuccess(data: OrderDetailDownload): OrderDetailDownloadSuccess {
    return {
        type: ORDER_DETAIL_DOWNLOAD_SUCCESS,
        data: data
    };
}

export function doOrderDetailDownloadFailure(error: HttpError): OrderDetailDownloadFailure {
    return {
        type: ORDER_DETAIL_DOWNLOAD_FAILURE,
        error: error
    };
}
