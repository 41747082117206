import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import { SearchBox } from 'components/FilterComponent';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { sortTestList } from 'common/BranchUtils';
import { BranchDetailTest } from 'data/Models';

import './BranchEditTestList.css';

interface Props {
  onChange?: (testIds: string[]) => void;
  tests: BranchDetailTest[];
  selectedTestIds: string[];
}

const BranchEditTestList : React.FC<Props> = (props) => {
  const { tests, selectedTestIds, onChange = () => {} } = props;  
  const [keyword, setKeyword] = useState('');
  const isAllChecked = useMemo(() => {
    return selectedTestIds.length === tests.length;
  }, [selectedTestIds, tests]);

  const filteredTests = useMemo(() => {
    return sortTestList(tests.filter(test => {
      if (!keyword) return true;
      const q = keyword.toLowerCase();
      return test.name.toLowerCase().includes(q) || test.id.toLowerCase().includes(q);
    }));
  }, [tests, keyword]);

  const handleSearch = useCallback((keyword: string) => {
    setKeyword(keyword);
  }, []);
  const handleSelectAll = useCallback((e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      onChange(tests.map(test => test.id));
    } else {
      onChange([]);
    }
  }, [tests, onChange])
  const handleSelectItem = useCallback((checkedTestId: string, checked) => {
    if (checked) {
      onChange(selectedTestIds.concat(checkedTestId));
    } else {
      onChange(selectedTestIds.filter(testId => testId !== checkedTestId));
    }
  }, [selectedTestIds, onChange]);

  return (
    <div className="availableTestList">
      <div className="listHeader">
        <SearchBox onSearch={handleSearch} placeholder="Search Test" border full />
      </div>
      <div className="listContent">
        {filteredTests.length ? (
          <div className="listItem">
            <Checkbox 
              onChange={handleSelectAll}
              checked={isAllChecked}
            >Select All</Checkbox>
          </div>
        ) : null}
        {filteredTests.map((item) => (
          <div className="listItem" key={item.id}>
            <Checkbox 
              checked={selectedTestIds.includes(item.id)}
              onChange={(e) => {
                handleSelectItem(item.id, e.target.checked);
              }}
            >{item.id} - {item.name}</Checkbox></div>
        ))}
      </div>
    </div>
  );
}

export default BranchEditTestList;