import React from 'react';
import { Spin } from 'antd';
import { Images } from 'themes';
import './index.css';

const LoadingWithMask: React.FC<{
    loadingIconSrc?: string;
}> = ({
    loadingIconSrc = Images.OvalLoad,
}) => {
    const loadingIcon = <img className="loadingWithMaskIcon" src={loadingIconSrc} alt="loading" />;
    return (
      <div className={`loadingWithMaskWrapper`}>
          <Spin indicator={loadingIcon} />
      </div>
    )
};

export default LoadingWithMask;
