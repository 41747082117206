import {call, put, takeLatest} from 'redux-saga/effects';
import OrderApi from 'data/api/OrderApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './OrderManagementAction';

function* orderManagementTask(orderManagementAction: action.OrderManagementRequest) {
    const { filterParams } = orderManagementAction;
    let queryParams: any = {};
    Object.entries(filterParams).forEach(entry => {
        const [entryKey, entryValue] = entry;
        if (entryValue) {
            queryParams[entryKey] = entryValue;
        }
    });
    const response = yield call(OrderApi.getOrderList, queryParams);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doOrderManagementSuccess(response.data.orderList));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doOrderManagementRequest(queryParams) });
            yield put(action.doOrderManagementFailure(newTimeoutError));
        } else {
            yield put(action.doOrderManagementFailure(response.error));
        }
    }
}
export function* orderManagementSaga() {
    yield takeLatest(
        action.ORDER_MANAGEMENT_REQUEST,
        orderManagementTask,
    );
}