import { call, put, takeLatest } from 'redux-saga/effects';
import PanelApi from 'data/api/PanelApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './PanelEditAction';

function* updatePanelTask(updatePanelAction: action.UpdatePanelRequest) {
    const { updateData } = updatePanelAction;
    const response = yield call(PanelApi.updatePanel, updateData);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doUpdatePanelSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doUpdatePanelRequest(updateData) });
            yield put(action.doUpdatePanelFailure(newTimeoutError));
        } else {
            yield put(action.doUpdatePanelFailure(response.error));
        }
    }
}
export function* updatePanelSaga() {
    yield takeLatest(
        action.UPDATE_PANEL_REQUEST,
        updatePanelTask,
    );
}
