import React, {Dispatch, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {
  doGetProfileInitial,
  GetProfileAction,
} from "features/scaffold/ScaffoldAction";
import RootStoreState from "rootStore";
import {isPermissionAllowed} from "common/PermissionUtils";
import {FrontendRoutes} from "navigation/Routes";
import AlertComponent from "components/AlertComponent";
import {ModalError, TypePageNotAllowed} from "components/ModalComponent";
import {GetMethodLoading, LoadingWithMask} from "components/LoadingComponent";
import PromoFormView from "../PromoForm/PromoFormView";
import {PromoFormValue} from "../PromoForm/Commons";
import {ContractDetail, HttpError, PromoFormData} from "data/Models";
import {promoTypeListState} from "common/constans";
import {
  UpdatePromoAction,
  doUpdatePromoInitial,
  doUpdatePromoRequest,
} from "./PromoEditAction";
import {
  doPromoDetailInitial,
  doPromoDetailRequest,
  PromoDetailAction,
} from "../PromoDetail/PromoDetailAction";
import moment from "moment";
import {serverDateFormat} from "../PromoForm/PromoFormConfig";
import {useQuery} from "hooks/useQuery";

function PromoEditView() {
  const history = useHistory();
  const {promoId} = useParams<{promoId: string}>();
  const promoType = useQuery("type");
  const [isDetailDataRequested, setDetailDataRequested] =
    useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<PromoFormValue>();

  const profileAction = useDispatch<Dispatch<GetProfileAction>>();
  const profileState = useSelector((state: RootStoreState) => state.profile);
  const promoDetailAction = useDispatch<Dispatch<PromoDetailAction>>();
  const promoDetailState = useSelector(
    (state: RootStoreState) => state.promoDetail
  );
  const updatePromoAction = useDispatch<Dispatch<UpdatePromoAction>>();
  const updatePromoState = useSelector(
    (state: RootStoreState) => state.updatePromo
  );

  const permissions = profileState.data?.permissions || [];
  const isAllowedUpdatePromo = isPermissionAllowed(
    FrontendRoutes.PromoEditPage.permissionKey,
    permissions
  );
  const loading = profileState.onRequest || promoDetailState.onRequest;

  const [showNotAllowed, setShowNotAllowed] = useState<boolean>(false);
  const [errorHttp, setErrorHttp] = useState<HttpError | null>(null);
  const [errorClient, setErrorClient] = useState<string | null>(null);

  useEffect(() => {
    if (profileState.error) {
      profileAction(doGetProfileInitial());
      setErrorHttp(profileState.error);
    } else if (profileState.data) {
      if (isAllowedUpdatePromo) {
        promoDetailAction(doPromoDetailInitial());
        promoDetailAction(doPromoDetailRequest(promoId, promoType));
      } else {
        setShowNotAllowed(true);
      }
    }
  }, [
    profileAction,
    profileState,
    promoDetailAction,
    promoId,
    promoType,
    isAllowedUpdatePromo,
  ]);
  useEffect(() => {
    if (promoDetailState.onRequest) {
      setDetailDataRequested(false);
    } else {
      setDetailDataRequested(true);
      if (promoDetailState.error) {
        promoDetailAction(doPromoDetailInitial());
        setErrorHttp(promoDetailState.error);
      }
    }
  }, [promoDetailAction, promoDetailState]);
  useEffect(() => {
    if (isDetailDataRequested && promoDetailState.data) {
      const data = promoDetailState.data;
      let values: PromoFormValue = {
        promoType: promoType,
        source: data.source,
        titleId: data.title,
        titleEn: data.title_en,
        coverImage: {
          image: data.cover_image,
          from: "network",
        },
        descriptionId: data.description,
        descriptionEn: data.description_en,
        tncId: data.tnc,
        tncEn: data.tnc_en,
        periodeDateStart: moment(data.periode_date.start, serverDateFormat),
        periodeDateEnd: moment(data.periode_date.end, serverDateFormat),
        publishDateStart: moment(data.publish_date.start, serverDateFormat),
        publishDateEnd: moment(data.publish_date.end, serverDateFormat),
      };

      switch (promoType) {
        case promoTypeListState[0].id: {
          values.contracts = data.contracts;
          values.source = data.source;
          break;
        }
        case promoTypeListState[2].id: {
          values.promoCode = data.promo_code;
          values.used = data.quantity.used;
          values.quota = data.quantity.quota;
          values.contractId = data.contract?.id;
          break;
        }
      }

      setInitialValues(values);
    }
  }, [
    isDetailDataRequested,
    profileState.data,
    promoId,
    promoType,
    promoDetailState,
  ]);
  useEffect(() => {
    if (updatePromoState.success === true) {
      const path = `${FrontendRoutes.PromoDetailPage.path.replace(
        ":promoId",
        promoId
      )}?type=${promoType}`;
      history.push(path, {promoDetailType: promoType});
    } else if (updatePromoState.error) {
      updatePromoAction(doUpdatePromoInitial());
      setErrorHttp(updatePromoState.error);
    }
  }, [updatePromoAction, updatePromoState, history, promoId, promoType]);

  const handleClearFn = useCallback(() => {
    setErrorHttp(null);
    setErrorClient(null);
  }, []);
  const handlePromoUpdate = useCallback(
    (values: any) => {
      const serverFormat = "YYYY-MM-DDTHH:mm:ssZ";

      switch (values.promoType) {
        case promoTypeListState[0].id: {
          const data: PromoFormData = {
            id: promoId,
            promoType: values.promoType,
            source: values.source,
            title: values.titleId,
            titleEn: values.titleEn,
            description: values.descriptionId,
            descriptionEn: values.descriptionEn,
            tnc: values.tncId,
            tncEn: values.tncEn,
            contractIds: values.contracts
              .map((item: ContractDetail) => item.id)
              .join(","),
            aliases: values.contracts.map((item: ContractDetail) => {
              return {
                id: item.id,
                alias: item.alias ?? item.name,
                alias_en: item.alias_en ?? item.name_en,
              };
            }),
            quota: values.quota,
            periodeDate: {
              start: values.periodeDateStart.format(serverFormat),
              end: values.periodeDateEnd.format(serverFormat),
            },
            publishDate: {
              start: values.publishDateStart.format(serverFormat),
              end: values.publishDateEnd.format(serverFormat),
            },
          };

          if (values.coverImage.from === "local") {
            data.coverImage = values.coverImage.image;
          }

          updatePromoAction(doUpdatePromoRequest(data));
          break;
        }
        case promoTypeListState[2].id: {
          const data: PromoFormData = {
            id: promoId,
            promoType: values.promoType,
            source: values.source,
            promoCode: values.promoCode,
            title: values.titleId,
            titleEn: values.titleEn,
            description: values.descriptionId,
            descriptionEn: values.descriptionEn,
            tnc: values.tncId,
            tncEn: values.tncEn,
            contractId: values.contractId,
            quota: values.quota,
            periodeDate: {
              start: values.periodeDateStart.format(serverFormat),
              end: values.periodeDateEnd.format(serverFormat),
            },
            publishDate: {
              start: values.publishDateStart.format(serverFormat),
              end: values.publishDateEnd.format(serverFormat),
            },
          };

          if (values.coverImage.from === "local") {
            data.coverImage = values.coverImage.image;
          }

          updatePromoAction(doUpdatePromoRequest(data));
          break;
        }
      }
    },
    [promoId, updatePromoAction]
  );

  return (
    <div style={{padding: "0 25px"}}>
      {!showNotAllowed ? (
        <>
          {loading && <GetMethodLoading />}
          {updatePromoState.onRequest && <LoadingWithMask />}
          {errorHttp && (
            <AlertComponent error={errorHttp} clearFn={handleClearFn} />
          )}
          {errorClient && (
            <AlertComponent message={errorClient} clearFn={handleClearFn} />
          )}
          {isAllowedUpdatePromo && initialValues && (
            <PromoFormView
              mode={"edit"}
              initialValues={initialValues}
              onError={setErrorClient}
              onFinish={handlePromoUpdate}
            />
          )}
        </>
      ) : (
        <ModalError visible={true} type={TypePageNotAllowed} />
      )}
    </div>
  );
}

export default PromoEditView;
