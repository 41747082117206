import {FormInstance, Rule} from "antd/lib/form";
import {promoTypeListState} from "common/constans";

export const promoTypes = [
  {
    value: promoTypeListState[2].id,
    label: promoTypeListState[2].name,
  },
  {
    value: promoTypeListState[0].id,
    label: promoTypeListState[0].name,
  },
];

export const promoSource = [
  {
    value: "PRDA",
    label: "PRDA",
  },
  {
    value: "PRDI",
    label: "PRDI",
  },
];

const coverImageRule = (form: FormInstance): Rule[] => [
  () => ({
    validator(rule, value) {
      let error: Error | null = null;
      if (form.getFieldValue("coverImage") === undefined) {
        error = new Error("Cannot be empty");
      } else if (form.getFieldValue("coverImage").error) {
        error = new Error(form.getFieldValue("coverImage").error);
      }

      if (error) {
        return Promise.reject(error);
      } else {
        return Promise.resolve();
      }
    },
  }),
];

const contractsRule = (form: FormInstance): Rule[] => [
  () => ({
    validator(rule, value) {
      let error: Error | null = null;
      const contracts = form.getFieldValue("contracts");
      if (!contracts || contracts.length === 0) {
        error = new Error("Cannot be empty");
      }

      if (error) {
        return Promise.reject(error);
      } else {
        return Promise.resolve();
      }
    },
  }),
];

export const formRules = {
  promoType: [
    {
      required: true,
      message: "Cannot be empty",
    },
  ],
  source: [
    {
      required: true,
      message: "Cannot be empty",
    },
  ],
  promoCode: [
    {
      required: true,
      message: "Cannot be empty",
    },
  ],
  contract: [
    {
      required: true,
      message: "Cannot be empty",
    },
  ],
  contracts: (form: FormInstance) => contractsRule(form),
  quota: [
    {
      required: true,
      message: "Cannot be empty",
    },
  ],
  title: [
    {
      required: true,
      message: "Cannot be empty",
    },
    {
      max: 128,
      message: "The title length should be within 1 to 128 characters.",
    },
  ],
  coverImage: (form: FormInstance) => coverImageRule(form),
  description: [
    {
      required: true,
      message: "Cannot be empty",
    },
    {
      max: 2000,
      message: "The description length should be within 1 to 2000 characters.",
    },
  ],
  tnc: [
    {
      required: true,
      message: "Cannot be empty",
    },
    {
      max: 4000,
      message:
        "The terms and condition length should be within 1 to 4000 characters.",
    },
  ],
  date: [
    {
      required: true,
      message: "Cannot be empty",
    },
  ],
};

export const serverDateFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const uiDateFormat = "DD MMM yyyy - HH:mm:ss";
export const maxContractIds = 15;
