import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { LabeledValue, SelectProps, SelectValue } from 'antd/lib/select';
import './index.css';

const { Option } = Select;

interface Props<T> {
    selectedOption?: string;
    data: T[];
    onMapOption: (item: T) => { value: string | number, label: string };
    onSelectedChange: (selected: T) => void;
    selectProps?: SelectProps<SelectValue>;
    prefixLabel?: string;
    className?: string;
    optionClassName?: string;
}

function SelectComponent<T>(props: Props<T>) {
    const [selectedOption, setSelectedOption] = useState<T>();

    const onOptionSelected = (value: string | number | LabeledValue) => {
        const selected = props.data.find((item) => props.onMapOption(item).value === value);
        if (selected) {
            setSelectedOption(selected);
            props.onSelectedChange(selected);
        }
    };
    const options = props.data.map((item) => {
        const { value, label } = props.onMapOption(item);
        const newLabel = props.prefixLabel ? props.prefixLabel + label : label;
        return (
            <Option className={`${props.optionClassName || ''} selectOptionWrapper`} key={value} value={value} label={newLabel}>
                {label}
            </Option>
        );
    });

    useEffect(() => {
        if (selectedOption === undefined && props.selectedOption) {
            setSelectedOption(props.data.find((item) => props.onMapOption(item).value === props.selectedOption));
        }
    }, [selectedOption, setSelectedOption, props]);

    return (
        <Select
            {...props.selectProps}
            className={`${props.className || ''} selectWrapper`}
            optionLabelProp="label"
            value={selectedOption ? props.onMapOption(selectedOption).value : undefined}
            onSelect={onOptionSelected}
        >
            {options}
        </Select>
    );
};

export default SelectComponent;
