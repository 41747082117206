import { LoginResult } from '../../features/login/LoginState';
import InMemoryAuthStorageAdapter from './InMemoryAuthStorageAdapter';

interface IAuthStorage {
    init (onDone: (str: string) => void): void;
    save(loginResult: LoginResult): Promise<string>;
    clear(): void;
    getValidToken(): string;
    isAuthenticated(): boolean;
    logout(): void;
}

export const AuthStorage = {
    getStrategy () : IAuthStorage {
        // To use persistent local storage, returns LocalAuthStorage
        // To use in-memory storage, returns InMemoryAuthStorageAdapter
        return InMemoryAuthStorageAdapter;
    },

    init (onDone: (token: string) => void) : void {
        AuthStorage.getStrategy().init(onDone);
    },

    save(loginResult: LoginResult) {
        return AuthStorage.getStrategy().save(loginResult);
    },

    clear() {
        return AuthStorage.getStrategy().clear();
    },
    
    getValidToken(): string {
        return AuthStorage.getStrategy().getValidToken();
    },
    
    isAuthenticated(): boolean {
        return AuthStorage.getStrategy().isAuthenticated();
    },

    logout() {
        return AuthStorage.getStrategy().logout();
    }
};

export { ProfileStorageUtils } from './LocalAuthStorage';
