import { FormInstance, Rule } from 'antd/lib/form';
import { isContainMinimumAlphabet } from 'common/CommonUtils';

function validatePasswordFormat(value: string): Error|null {
    let error: Error | null = null;

    if (value == null) {
        error = new Error('Please input password.');
    } else if (value.length < 8) {
        error = new Error('Password should min 8 characters.');
    } else if (value.length > 255) {
        error = new Error('Password should max 255 characters.');
    } else if (!value.match(/.*[0-9].*/)) {
        error = new Error('Password should at least 1 number.');
    } else if (!isContainMinimumAlphabet(value, 2)) {
        error = new Error('Password should at least 2 alphabet.');
    } else if (!value.match(/.*[A-Z]/)) {
        error = new Error('Password should at least 1 uppercase.');
    } else if (!value.match(/.*[a-x]/)) {
        error = new Error('Password should at least 1 lowercase.');
    }

    return error;
}

const defaultPasswordRules: Rule[] = [
    () => ({
        validator(rule, value) {
            const error = validatePasswordFormat(value);
            if (error) {
                return Promise.reject(error);
            } else {
                return Promise.resolve();   
            }
        },
    }),
];

const confirmPasswordRule = (form: FormInstance): Rule[] => [
    () => ({
        validator(rule, value) {
            let error = validatePasswordFormat(value);
            
            if (error === null && form.getFieldValue('newPassword') !== form.getFieldValue('confirmPassword')) {
                error = new Error('Your passwords do not match.');
            }

            if (error) {
                return Promise.reject(error);
            } else {
                return Promise.resolve();   
            }
        },
    }),
];

export const formRules = {
    currentPassword: defaultPasswordRules,
    newPassword: defaultPasswordRules,
    confirmPassword: (form: FormInstance) => confirmPasswordRule(form)
};
