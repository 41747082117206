import { UnauthorizedCode, UnknownErrorCode } from "common/HttpCode";
import { HttpError } from "data/Models";
import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";

const ProfileApi = {
    getProfile<T extends object = any>(): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Profile.Get)
            .setQueryParameter({})
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    profileDetail: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },

    changePassword(currentPassword: string, newPassword: string, confirmPassword: string): Promise<http.HttpService<{}>> {
        const body = {
            current: currentPassword,
            password: newPassword,
            password_confirm: confirmPassword,
        }
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Profile.ChangePassword}`)
            .setBody(body)
            .doPut()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true }
            }))
            .catch(error => http.handleCatch(error));
    },

    createPassword(newPassword: string, passwordConfirm: string, token: string) {
        const body = {
            password: newPassword,
            password_confirm: passwordConfirm,
        }
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Activation.CreatePassword}`)
            .setBody(body)
            .setHeaders({
                'Authorization': `Bearer ${token}`,
            })
            .doPost()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true }
            }))
            .then((res: http.HttpService<{}>) => {
                if (res.status === http.HTTP_RESPONSE_FAILURE) {
                    let error = res.error;
                    // If the error is UnauthorizedCode, that means
                    // The exchange token is invalid
                    if (error.code === UnauthorizedCode) {
                        const message = 'Verification expired, please try again.';
                        error = new HttpError(UnknownErrorCode, message);
                    }
                    return Promise.reject(error);
                }

                return res;
            })
            .catch((error) => Promise.reject(http.createFallbackHttpError(error)));
    }
};

export default ProfileApi;
