import React, {useState, useEffect, useCallback, Dispatch, SetStateAction} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Popover, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Images } from 'themes';
import RootStoreState from 'rootStore';
import AppTable from 'components/AppTableComponent';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { TableIncrementNumber } from 'common/CommonUtils';
import { DefaultQueryType, defaultQuery, SendActivationLinkPermission } from 'common/constans';
import { FrontendRoutes } from 'navigation/Routes';
import { UserListItem, HttpError, AdminAccountStatus, AdminDetail } from 'data/Models';
import { SearchBox } from 'components/FilterComponent';
import { OvalButton } from 'components/ButtonComponent';
import AlertComponent from 'components/AlertComponent';
import { GetMethodLoading, LoadingWithMask } from 'components/LoadingComponent';
import { ModalConfirmation, ModalSendAccountActivation, TypeDeleteConfirmation } from 'components/ModalComponent';
import RoleFilterView from 'components/FilterComponent/RoleFilterView';
import RegionFilterView from 'components/FilterComponent/RegionFilterView';
import BranchFilterView from 'components/FilterComponent/BranchFilterView';
import { DeleteAdminAction, doDeleteAdminInitial, doDeleteAdminRequest} from 'features/user/AdminDelete/DeleteAdminAction';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { doRoleManagementInitial, RoleManagementAction } from 'features/user/RoleManagement/RoleManagementAction';
import { doRegionListInitial, RegionListAction } from 'features/region/RegionAction';
import { doBranchListInitial, BranchListAction } from 'features/branch/BranchManagement/BranchAction';
import { CreateAdminAction, doCreateAdminInitial } from 'features/user/AdminCreate/AdminCreateAction';
import { doUpdateAdminInitial, UpdateAdminAction } from '../AdminEdit/AdminEditAction';
import { AdminManagementAction, doAdminManagementRequest, doAdminManagementInitial } from './AdminManagementAction';
import userApi from 'data/api/UserApi';
import { AbortError } from 'common/HttpCode';
import './index.css'

export interface RoleFilter {
    id?: string;
    name?: string;
}
export interface RegionFilter {
    id?: string;
    name?: string;
}
export interface BranchFilter {
    id?: string;
    name?: string;
    region? : RegionFilter | null | undefined;
    city?: RegionFilter;
}

function isRequireActivation (status: string) {
    return status === AdminAccountStatus.RequireActivation;
}

const Text = {
    wrapperId: 'adminManagementWrapperId',
    addNew: 'ADD NEW ADMIN',
    viewDetail: 'View Detail',
    edit: 'Edit',
    delete: 'Delete',
    sendActivation: 'Send Activation',
    no: 'No',
    userName: 'Name',
    email: 'Email',
    branch: 'Branch',
    region: 'Region',
    role: 'Role',
    deleteConfirmation: 'Are you sure you want to delete this user?',
    allRegion: 'All Region',
    allBranch: 'All Branch',
};

const maxItemLength = 5;
const maxLine = 3;
const maxLineHeight = 60;
const AdminManagementView = () => {
    const history = useHistory();
    const [activationPIN, setActivationPIN] = useState<string>('');
    const [shownPopOver, setShownPopover] = useState<number | null>(null);
    const roleListAction = useDispatch<Dispatch<RoleManagementAction>>();
    const roleListState = useSelector((state: RootStoreState) => state.roleManagement);
    const regionListAction = useDispatch<Dispatch<RegionListAction>>();
    const regionListState = useSelector((state: RootStoreState) => state.regionList);
    const branchListAction = useDispatch<Dispatch<BranchListAction>>();
    const branchListState = useSelector((state: RootStoreState) => state.branchList);
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];

    const isAllowedUserList = isPermissionAllowed(FrontendRoutes.AdminManagement.permissionKey, permissions);
    const isAllowedCreateNew = isPermissionAllowed(FrontendRoutes.UserCreatePage.permissionKey, permissions);
    const isAllowedViewDetail = isPermissionAllowed(FrontendRoutes.UserDetailPage.permissionKey, permissions);
    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.UserEditPage.permissionKey, permissions);
    const isAllowedDeleteDetail = isPermissionAllowed(FrontendRoutes.UserDeletePage.permissionKey, permissions);
    const isAllowedSendActivationLink = isPermissionAllowed(SendActivationLinkPermission, permissions);
    const hasAnyRowActionPermission = isAllowedViewDetail || isAllowedEditDetail || isAllowedDeleteDetail || isAllowedSendActivationLink;
    const hasOnlyActivationPermission = !isAllowedViewDetail && !isAllowedEditDetail && !isAllowedDeleteDetail && isAllowedSendActivationLink;
    const adminManagementAction = useDispatch<Dispatch<AdminManagementAction>>();
    const userListState = useSelector((state: RootStoreState) => state.adminManagement);
    const userListData = userListState.data;
    const deleteAdminAction = useDispatch<Dispatch<DeleteAdminAction>>();
    const deleteAdminState = useSelector((state: RootStoreState) => state.deleteAdmin);
    const [error, setError] = useState<HttpError | null>(null);
    const [deleteFromList, setDeleteFromList] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [deleteConfirmationId, setDeleteConfirmationId] = useState<string>('');
    const [filterParams, setFilterParams] = useState<DefaultQueryType>(defaultQuery);

    // send activation link
    const [isSendActivationSuccess, setIsSendActivationSuccess] = useState<boolean>(false);
    const [isSendingActivation, setIsSendingActivation] = useState<boolean>(false);
    const hasActionProcessing = isSendingActivation || deleteAdminState.onRequest;

    const handleSendActivationLink = useCallback((userId: string) => {
        setError(null);
        setIsSendActivationSuccess(false);
        setShownPopover(null);
        setIsSendingActivation(true);
        userApi.sendActivation(userId)
            .then(() => userApi.getAdminDetail(userId))
            .then((response: unknown) => {
                const { data } = response as { data: { userDetail: AdminDetail } };
                return data.userDetail.activation_pin || '';
            })
            .then((activationPIN) => {
                setActivationPIN(activationPIN);
                setIsSendActivationSuccess(true);
            })
            .catch((error) => {
                if (error instanceof HttpError && error.code === AbortError) {
                    error.action = () => {
                        handleSendActivationLink(userId);
                    };
                }
                setError(error);
            })
            .finally(() => {
                setIsSendingActivation(false);
            });
    }, []);
    const handleCloseSendActivationLinkModal = useCallback(() => {
        setIsSendActivationSuccess(false);
    }, []);

    useEffect(() => {
        if (isAllowedUserList) {
            adminManagementAction(doAdminManagementRequest(defaultQuery));
        }
    }, [adminManagementAction, isAllowedUserList]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
        if (roleListState.error) {
            roleListAction(doRoleManagementInitial());
            setError(roleListState.error);
        }
    }, [roleListAction, roleListState]);
    useEffect(() => {
        if (regionListState.error) {
            regionListAction(doRegionListInitial());
            setError(regionListState.error);
        }
    }, [regionListAction, regionListState]);
    useEffect(() => {
        if (branchListState.error) {
            branchListAction(doBranchListInitial());
            setError(branchListState.error);
        }
    }, [branchListAction, branchListState]);
    useEffect(() => {
        if (userListState.error) {
            adminManagementAction(doAdminManagementInitial());
            setError(userListState.error);
        }
    }, [adminManagementAction, userListState]);
    useEffect(() => {
        if (deleteAdminState.error) {
            setError(deleteAdminState.error);
            deleteAdminAction(doDeleteAdminInitial());
        }
    }, [deleteAdminAction, deleteAdminState]);
    const tableLoading: boolean = profileState.onRequest || roleListState.onRequest|| userListState.onRequest || branchListState.onRequest || regionListState.onRequest;
    const handleDeleteFn = (userId: string) => {
        setShowDeleteConfirmation(true);
        setDeleteConfirmationId(userId);
        setShownPopover(null);
    };
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const handleHistoryPush = useCallback((path: string) => {
        history.push(path);
    }, [history]);
    const handleCreateNew = useCallback(() =>{
        if (isAllowedCreateNew) {
            history.push(FrontendRoutes.UserCreatePage.path);
        }
    }, [isAllowedCreateNew, history]);
    const handleShowPopOver = useCallback((isShown, index) => {
        let shownPopOver = null;
        if (!isShown) {
            shownPopOver = index;
        }

        setShownPopover(shownPopOver);
    }, []);
    const containerId = document.getElementById(Text.wrapperId);
    const PopOverContent = (record: any) => {
        const options = [
            {
                clickFn: () => handleHistoryPush(FrontendRoutes.UserDetailPage.path.replace(':userId', record.id)),
                imgSrc: Images.EyeBlue,
                text: Text.viewDetail,
                isAllowed: isAllowedViewDetail,
            },
            {
                clickFn: () => handleHistoryPush(FrontendRoutes.UserEditPage.path.replace(':userId', record.id)),
                imgSrc: Images.PencilBlue,
                text: Text.edit,
                isAllowed: isAllowedEditDetail,
                popOverItemClass: 'edit',
            },
            {
                clickFn: () => handleSendActivationLink(record.id),
                imgSrc: Images.SendActivation,
                text: Text.sendActivation,
                isAllowed: isAllowedSendActivationLink && isRequireActivation(record.status),
                popOverItemClass: 'sendLink',
            },
            {
                clickFn: () => handleDeleteFn(record.id),
                imgSrc: Images.Delete,
                text: Text.delete,
                isAllowed: isAllowedDeleteDetail,
                popOverItemClass: 'red',
            },
        ];
        const popOverContentItems = options.map((props) => {
            const { clickFn, imgSrc, text, isAllowed, popOverItemClass } = props;

            if (isAllowed) {
                return (
                    <div key={text} className={`appPopOverItem ${popOverItemClass}`} onClick={clickFn}>
                        <img src={imgSrc} alt={text} />
                        <span>{text}</span>
                    </div>
                );
            }

            return null;
        });

        return (
            <div className="appPopOverContent">
                {popOverContentItems}
            </div>
        );
    };
    const ellipsisFn = (eleP: HTMLElement) => {
        const elePTxt = eleP.innerHTML;
        const numOfTxtLines = Math.ceil(eleP.offsetHeight / 20);
        const approxTxtInALine = Math.ceil(elePTxt.length / numOfTxtLines) + 2 ;
        const numOfCharsToTruncate = approxTxtInALine * maxLine;
        const newInnerText = elePTxt.substr(0, numOfCharsToTruncate);
        eleP.innerHTML = newInnerText.replace(/[(,]$/, '') + '...';
    };
    const columns: ColumnsType<UserListItem> = [
        {
            title: Text.no,
            dataIndex: '',
            key: '',
            render: (text, row, index: number) => {
                return (
                    <span className="indexStyle">{TableIncrementNumber(filterParams.page, filterParams.limit, index)}</span>
                );
            },
            width: '5%',
        },
        {
            title: Text.userName,
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => {
                if (isAllowedViewDetail) {
                    return (
                        <Link to={{
                            pathname: FrontendRoutes.UserDetailPage.path.replace(':userId', record.id),
                        }}>
                            <span className="nameStyle">{text}</span>
                        </Link>
                    )
                }
                return (
                    <span className="nameStyle">{text}</span>
                )
            },
        },
        {
            title: Text.email,
            dataIndex: 'email',
            key: 'email',
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: Text.region,
            dataIndex: ['is_all_regions'],
            key: 'region',
            render: (isAll: boolean, record: any, index: number) => {
                let oldRegions = record?.regions;
                let newRegions = '';
                if (oldRegions?.length > 0) {
                    oldRegions = oldRegions.slice(0, maxItemLength);
                    newRegions = oldRegions.map((item: RegionFilter) => {
                        return `${item.name} (${item.id})`
                    }).join(', ');
                    const eleP = document.getElementById(`regionsList${index}`);
                    if (eleP && eleP.offsetHeight > maxLineHeight) {
                        ellipsisFn(eleP);
                    }
                }
                return (
                    <div className={'listItemEllipsis'}>
                        {isAll ? Text.allRegion : (
                            record?.regions?.length === 0 ? '-' : (
                                <p id={`regionsList${index}`} key={`regionsList`}>{newRegions}</p>
                            )
                        )}
                    </div>
                )
            },
            width: '20%',
        },
        {
            title: Text.branch,
            dataIndex: ['is_all_outlets'],
            key: 'outlet',
            render: (isAll: boolean, record: any, index: number) => {
                let oldOutlets = record?.outlets;
                let newOutlets = '';
                if (oldOutlets?.length > 0) {
                    oldOutlets = oldOutlets.slice(0, maxItemLength);
                    newOutlets = oldOutlets.map((item: RegionFilter) => {
                        return `${item.name}`
                    }).join(', ');
                    const eleP = document.getElementById(`outletsList${index}`);
                    if (eleP && eleP.offsetHeight > maxLineHeight) {
                        ellipsisFn(eleP);
                    }
                }
                return (
                    <div className={'listItemEllipsis'}>
                    {isAll ? Text.allBranch : (
                        record?.outlets?.length === 0 ? '-' : (
                            <p id={`outletsList${index}`} key={`outletsList`}>{newOutlets}</p>
                        )
                    )}
                    </div>
                )
            },
            width: '20%',
        },
        {
            title: Text.role,
            dataIndex: ['role', 'name'],
            key: 'role',
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: '',
            key: '',
            render: (text: any, record: any, index: any) => {
                const content = PopOverContent(record);
                const isShown = shownPopOver === index;

                let popupContainer = document.body;
                if (containerId) {
                    popupContainer = containerId;
                }
                return (
                    <div>
                        <Popover
                            content={content}
                            visible={isShown}
                            trigger="click"
                            placement="bottomRight"
                            onVisibleChange={()=>{}}
                            overlayClassName={'actionGroupPopOver'}
                            getPopupContainer={() => popupContainer}
                        >
                            <div className="adminManagementDotsWrapper">
                                {(() => {
                                    if (hasOnlyActivationPermission && !isRequireActivation(record.status)) {
                                        return null;
                                    }

                                    return hasAnyRowActionPermission ? (
                                        <div className="adminManagementDots"
                                             onClick={() => handleShowPopOver(isShown, index)
                                        }>
                                            <img src={Images.Dots} alt="Dots" />
                                        </div>
                                    ) : null;
                                })()}
                            </div>
                        </Popover>
                    </div>
                )
            },
            width: '141px',
        },
    ];
    const handleRequestListAction = (query: DefaultQueryType) => {
        setShownPopover(null);
        setFilterParams(query);
        adminManagementAction(doAdminManagementRequest(query));
    };
    const handleBranchSelected = (values: BranchFilter[]) => {
        const newBranchIds = values.map((item) => item.id).join(',');
        const newQuery = Object.assign({}, filterParams, { outlet_ids: newBranchIds, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };
    const handleRegionSelected = (values: RegionFilter[]) => {
        const newRegionIds = values.map((item) => item.id).join(',');
        const newQuery = Object.assign({}, filterParams, { region_ids: newRegionIds, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };
    const handleRoleSelected = (values: RoleFilter[]) => {
        const newRoleIds = values.map((item) => item.id).join(',');
        const newQuery = Object.assign({}, filterParams, { role_ids: newRoleIds, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };
    const handleSearchByKeyword = (keyword: string) => {
        const newQuery = Object.assign({}, filterParams, { keyword: keyword, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };

    return (
        <div className="adminManagementWrapper" id={Text.wrapperId}>
            {hasActionProcessing && <LoadingWithMask />}
            <AdminActionAlertView deleteFromList={deleteFromList} setFilterParams={setFilterParams} />
            {error && (<AlertComponent error={error} clearFn={() => handleClearFn()} />)}
            {isSendActivationSuccess && (
                <ModalSendAccountActivation
                    activationPIN={activationPIN}
                    onOKClick={handleCloseSendActivationLinkModal}
                />
            )}
            <ModalConfirmation
                visible={!!showDeleteConfirmation}
                handleClose={() => {
                    setShowDeleteConfirmation(false);
                }}
                buttonClick={()=>{
                    deleteAdminAction(doDeleteAdminRequest(deleteConfirmationId));
                    setDeleteFromList(true);
                }}
                message={Text.deleteConfirmation}
                type={TypeDeleteConfirmation}
            />
            <div className="adminManagementFilterWrapper">
                <Space size={16}>
                    <SearchBox onSearch={handleSearchByKeyword}/>
                    <RoleFilterView onSelect={handleRoleSelected} />
                    <RegionFilterView onSelect={handleRegionSelected} />
                    <BranchFilterView onSelect={handleBranchSelected} />
                </Space>
                {isAllowedCreateNew && (
                    <OvalButton
                        type="primary"
                        buttonText={Text.addNew}
                        icon={<PlusOutlined />}
                        onClick={()=>handleCreateNew()}
                    />
                )}
            </div>
            <AppTable
                loading={{
                    spinning: tableLoading,
                    indicator: <GetMethodLoading />,
                    wrapperClassName: 'tableLoading'
                }}
                pageSize={userListData?.pagination?.limit}
                total={userListData?.pagination?.total}
                current={userListData?.pagination?.page}
                onRequestData={(currentPage, pageSize) => {
                    const newQuery = Object.assign({}, filterParams, {
                        page: currentPage,
                        limit: pageSize,
                    });
                    handleRequestListAction(newQuery);
                }}
                columns={columns}
                dataSource={userListData?.data}
                rowKey={record => record.id}
                rowClassName={(record,index)=>index%2===0?'rowWhite':'rowBlue'}
            />
        </div>
    );
};
interface AdminActionAlertProp {
    deleteFromList: boolean;
    setFilterParams: Dispatch<SetStateAction<DefaultQueryType>>;
}
function AdminActionAlertView(props: AdminActionAlertProp) {
    const { deleteFromList, setFilterParams } = props;
    const createAdminAction = useDispatch<Dispatch<CreateAdminAction>>();
    const createAdminState = useSelector((state: RootStoreState) => state.createAdmin);
    const updateAdminAction = useDispatch<Dispatch<UpdateAdminAction>>();
    const updateAdminState = useSelector((state: RootStoreState) => state.updateAdmin);
    const deleteAdminAction = useDispatch<Dispatch<DeleteAdminAction>>();
    const deleteAdminState = useSelector((state: RootStoreState) => state.deleteAdmin);
    const adminManagementAction = useDispatch<Dispatch<AdminManagementAction>>();
    const [success, setSuccess] = useState<string | null>('');
    const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
    const handleClearFn = useCallback(() => {
        setSuccess(null);
        if (deleteSuccess && deleteFromList) {
            setDeleteSuccess(false);
            adminManagementAction(doAdminManagementRequest(defaultQuery));
            setFilterParams(defaultQuery);
        }
    }, [deleteSuccess, adminManagementAction, deleteFromList, setFilterParams]);
    useEffect(() => {
        if (createAdminState.success === true) {
            createAdminAction(doCreateAdminInitial());
            setSuccess('Create new admin is success');
        } else if (updateAdminState.success === true) {
            updateAdminAction(doUpdateAdminInitial());
            setSuccess('Edit admin is success');
        } else if (deleteAdminState.success === true) {
            deleteAdminAction(doDeleteAdminInitial());
            setSuccess('Delete user is success');
            setDeleteSuccess(true);
        }
    }, [createAdminAction, createAdminState, updateAdminAction, updateAdminState, deleteAdminAction, deleteAdminState]);

    return (
        <div>
            {success && <AlertComponent message={success} type={'success'} timeout={3000} clearFn={() => handleClearFn()} />}
        </div>   
    );
}

export default AdminManagementView;
