import React, { useCallback } from "react";
import { Images } from "themes";
import ModalProdia from "./ModalProdia";
import { OvalButton } from "components/ButtonComponent";
import { useClipboard } from "use-clipboard-copy";

const Text = {
  ok: "OK",
  activationEmailSentModal: {
    header: "Activation Email Sent",
    message:
      "You've successfully sent the token activation. For activation account, they need to input this PIN",
  },
};

interface Props {
  activationPIN: string;
  onOKClick: () => void;
}

const ModalSendAccountActivation : React.FC<Props> = (props) => {
  const {
    activationPIN,
    onOKClick,
  } = props;
  const clipboard = useClipboard();
  const handleCopyActivationPin = useCallback(() => {
    clipboard.copy(activationPIN);
  }, [activationPIN, clipboard]);

  return (
    <ModalProdia
      visible
      footer={[
        <OvalButton
          type="primary"
          buttonText={Text.ok}
          key={Text.ok}
          onClick={onOKClick}
        />,
      ]}
    >
      <div className="sendActivationContent">
        <p className="contentTitle">{Text.activationEmailSentModal.header}</p>
        <p className="contentText">{Text.activationEmailSentModal.message}</p>
        <div>
          <p className="pinText">
            <span>{activationPIN}</span>
            <span className="buttonCopyClipboardWrapper">
              <button
                className="buttonCopyClipboard"
                onClick={handleCopyActivationPin}
              >
                <img src={Images.Clipboard} alt="copy clipboard" />
              </button>
            </span>
          </p>
        </div>
      </div>
    </ModalProdia>
  );
};

export default ModalSendAccountActivation;
