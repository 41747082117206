import { DefaultStoreState } from 'common/States';
import {
    UPDATE_PANEL_INITIAL,
    UPDATE_PANEL_REQUEST,
    UPDATE_PANEL_SUCCESS,
    UPDATE_PANEL_FAILURE,
    UpdatePanelAction,
} from './PanelEditAction';

export const defaultState: DefaultStoreState = {
    onRequest: false,
    success: false,
    error: null,
}

export function updatePanelReducer(
    state: DefaultStoreState = defaultState,
    action: UpdatePanelAction
): DefaultStoreState {
    switch (action.type) {
        case UPDATE_PANEL_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, success: defaultState.success, error: defaultState.error };
        case UPDATE_PANEL_REQUEST:
            return { ...state, onRequest: true, success: defaultState.success, error: defaultState.error };
        case UPDATE_PANEL_SUCCESS:
            return { ...state, onRequest: false, success: true, error: defaultState.error };
        case UPDATE_PANEL_FAILURE:
            return { ...state, onRequest: false, success: false, error: action.error };
        default:
            return state;
    }
}
