import { DefaultStoreState } from 'common/States';
import {
    CHANGE_PASSWORD_INITIAL,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    ChangePasswordAction,
} from './ChangePasswordAction';

export const defaultState: DefaultStoreState = {
    onRequest: false,
    success: false,
    error: null,
}

export function changePasswordReducer(
    state: DefaultStoreState = defaultState,
    action: ChangePasswordAction
): DefaultStoreState {
    switch (action.type) {
        case CHANGE_PASSWORD_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, success: defaultState.success, error: defaultState.error };
        case CHANGE_PASSWORD_REQUEST:
            return { ...state, onRequest: true, success: defaultState.success, error: defaultState.error };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, onRequest: false, success: true, error: defaultState.error };
        case CHANGE_PASSWORD_FAILURE:
            return { ...state, onRequest: false, success: false, error: action.error };
        default:
            return state;
    }
}
