import React from "react";
import {Space} from "antd";
import {defaultQuery, defaultRetrieveAll} from "common/constans";
import {PromoListQuery} from "common/States";
import {SearchBox} from "components/FilterComponent";
import StatusFilterView, {
  TypeList,
} from "components/FilterComponent/StatusFilterView";
import SortFilterView from "components/FilterComponent/SortFilterView";
import * as config from "components/FilterComponent/config";

interface StatusFilter {
  id?: string;
  name?: string;
}

interface PromoFilterViewProps {
  filterParams: PromoListQuery;
  onRequestListAction: (retrieveAll: boolean, query: PromoListQuery) => void;
}

function PromoFilterView(props: PromoFilterViewProps) {
  const {filterParams, onRequestListAction} = props;

  const handleSearchByKeyword = (keyword: string) => {
    if (keyword.length >= 3) {
      const newQuery = {
        ...filterParams,
        keyword: keyword,
        page: defaultQuery.page,
      };
      onRequestListAction(defaultRetrieveAll, newQuery);
    } else if (filterParams.keyword?.length ?? 0 >= 3) {
      const newQuery = {...filterParams, keyword: "", page: defaultQuery.page};
      onRequestListAction(defaultRetrieveAll, newQuery);
    }
  };
  const handleTypesSelected = (values: StatusFilter[]) => {
    const newIds = values.map((item) => item.id).join(",");
    const newQuery = {...filterParams, type: newIds, page: defaultQuery.page};
    onRequestListAction(defaultRetrieveAll, newQuery);
  };
  const handleStatusSelected = (value: StatusFilter) => {
    let status: string | undefined = value.id;
    if (status?.toLowerCase() === "all") {
      status = undefined;
    }
    const newQuery = {...filterParams, status: status, page: defaultQuery.page};
    onRequestListAction(defaultRetrieveAll, newQuery);
  };
  const handleSourceSelected = (value: StatusFilter) => {
    let source: string | undefined = value.id;
    if (source?.toLowerCase() === "all") {
      source = undefined;
    }
    const newQuery = {...filterParams, source: source, page: defaultQuery.page};
    onRequestListAction(defaultRetrieveAll, newQuery);
  };

  const handleSortSelected = (values: StatusFilter) => {
    const newQuery = {
      ...filterParams,
      sort: values.id,
      page: defaultQuery.page,
    };
    onRequestListAction(defaultRetrieveAll, newQuery);
  };

  return (
    <Space size={16}>
      <SearchBox onSearch={handleSearchByKeyword} />
      <StatusFilterView
        onMultipleSelect={handleTypesSelected}
        type={TypeList.promoType}
      />
      <StatusFilterView
        onSingleSelect={handleStatusSelected}
        type={TypeList.promoStatus}
      />
      <StatusFilterView
        onSingleSelect={handleSourceSelected}
        type={TypeList.promoSource}
      />
      <SortFilterView
        placeholder="Sort by Start Date"
        options={config.promoDateSortState}
        onSelect={handleSortSelected}
      />
    </Space>
  );
}

export default PromoFilterView;
