import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentChannel } from 'data/Models';
import RootStoreState from 'rootStore';
import { doPaymentChannelListRequest, PaymentChannelListAction } from 'features/paymentChannel/PaymentChannelAction';
import { SelectFilterComponent } from 'components/SelectComponent';

interface Props {
    onSelect: (payments: PaymentChannel[]) => void;
}

const Text = {
    label: 'Filter by Payment',
    searchPlaceholder: 'Search Payment'
};

function PaymentFilterView(props: Props) {
    const paymentChannelListAction = useDispatch<Dispatch<PaymentChannelListAction>>();
    const paymentChannelListState = useSelector((state: RootStoreState) => state.paymentChannelList);
    const [query, setQuery] = useState('');
    const paymentChannelList = paymentChannelListState.data?.data?.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())) ?? [];
    useEffect(() => {
        paymentChannelListAction(doPaymentChannelListRequest());
    }, [paymentChannelListAction]);
    
    const onGetOption = (payment: PaymentChannel) => {
        return { value: payment.id, label: payment.name }
    };

    return (
        <SelectFilterComponent
            label={Text.label}
            searchPlaceholder={Text.searchPlaceholder}
            onSearch={(query) => setQuery(query)}
            data={paymentChannelList}
            onMapOption={onGetOption}
            onSelectedChange={props.onSelect}
        />
    );
}

export default PaymentFilterView;
