import { call, put, takeLatest } from 'redux-saga/effects';
import TestApi from 'data/api/TestApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './TestManagementAction';
import { FilterParamMapper } from 'common/CommonUtils';
import { TestList, HttpError } from 'data/Models';

function* testManagementTask(testManagementAction: action.TestManagementRequest) {
    const { filterParams } = testManagementAction;
    let queryParams = FilterParamMapper(filterParams);
    if (filterParams.isRetrieveAll) {
        const maxLimit = 20;
        let requestPage = 1;
        let isMoveToNext = true;
        let testList: TestList | null = null;
        let error: HttpError | null = null;

        while (isMoveToNext) {
            const response = yield call(TestApi.getTestList, { page: requestPage, limit: maxLimit });
            if (response.status === http.HTTP_RESPONSE_SUCCESS) {
                const isEmpty = response.data.testList.pagination.total === 0;
                if (isEmpty) {
                    break;
                }

                if (testList) {
                    testList.data = [...testList.data, ...response.data.testList.data];
                } else {
                    testList = response.data.testList;
                }

                if (response.data.testList.pagination.total <= requestPage * maxLimit) {
                    isMoveToNext = false;
                }
            } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
                error = response.error;
                break;
            }

            requestPage++;
        }

        if (testList) {
            yield put(action.doTestManagementSuccess(testList));
        } else if (error) {
            if (error.code === AbortError) {
                const newTimeoutError = Object.assign({}, error, { action: () => action.doTestManagementRequest({ isRetrieveAll: true, page: requestPage, limit: maxLimit }) });
                yield put(action.doTestManagementFailure(newTimeoutError));
            } else {
                yield put(action.doTestManagementFailure(error));
            }
        } else {
            // success, but returns empty list
            yield put(action.doTestManagementSuccess(new TestList()));
        }
    } else {
        const response = yield call(TestApi.getTestList, queryParams);
        if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
            yield put(action.doTestManagementSuccess(response.data.testList));
        } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
            if (response.error.code === AbortError) {
                const newTimeoutError = Object.assign({}, response.error, { action: () => action.doTestManagementRequest(queryParams) });
                yield put(action.doTestManagementFailure(newTimeoutError));
            } else {
                yield put(action.doTestManagementFailure(response.error));
            }
        }
    }
}

export function* testManagementSaga() {
    yield takeLatest(
        action.TEST_MANAGEMENT_REQUEST,
        testManagementTask,
    );
}
