import { call, put, takeLatest } from 'redux-saga/effects';
import OutletApi from 'data/api/OutletApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './BranchDetailAction';

function* branchDetailTask(branchDetailAction: action.BranchDetailRequest) {
    const { branchId } = branchDetailAction;
    const response = yield call(OutletApi.getOutletDetail, branchId);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doBranchDetailSuccess(response.data.branchDetail));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doBranchDetailRequest(branchId) });
            yield put(action.doBranchDetailFailure(newTimeoutError));
        } else {
            yield put(action.doBranchDetailFailure(response.error));
        }
    }
}

export function* branchDetailSaga() {
    yield takeLatest(
        action.BRANCH_DETAIL_REQUEST,
        branchDetailTask,
    );
}
