import { capitalizeFirstLetter } from "common/CommonUtils";
import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";

export interface VerifyResponse {
  token: string;
  expiredAt: string;
}

const ActivationApi = {
  verify (email: string, pin: string, token: string): Promise<http.HttpService<VerifyResponse>> {
    const body = {
      email,
      activation_pin: pin,
      activation_token: token,
    };
    return new http.RequestBuilder()
      .setUri(ApiEndpoint.Activation.Verify)
      .setBody(body)
      .doPost()
      .then(response => http.handleResponse(200, response, (json: any): VerifyResponse => {
        return {
          token: json.token,
          expiredAt: json.expired_at
        };
      }))
      .then((res: http.HttpService<VerifyResponse>) => {
        if (res.status === http.HTTP_RESPONSE_FAILURE) {
          const { error } = res;
          error.message = capitalizeFirstLetter(error.message)
          return Promise.reject(error);
        }

        return res;
      })
      .catch((error) => Promise.reject(http.createFallbackHttpError(error)));
  }
};

export default ActivationApi;
