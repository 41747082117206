import { call, put, takeLatest, } from 'redux-saga/effects';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './TNCDetailAction';
import HomeServiceApi from 'data/api/HomeServiceApi';

function* sagaTask(sagaAction: action.TNCDetailRequest) {
    const response = yield call(HomeServiceApi.getTNCDetail);

    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doTNCDetailSuccess(response.data.tncDetail.data[0]));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doTNCDetailRequest() });
            yield put(action.doTNCDetailFailure(newTimeoutError));
        } else {
            yield put(action.doTNCDetailFailure(response.error));
        }
    }
}

export function* tncDetailSaga() {
    yield takeLatest(
        action.TNC_DETAIL_REQUEST,
        sagaTask,
    );
}
