import { call, put, takeLatest } from 'redux-saga/effects';
import OutletApi from 'data/api/OutletApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './BranchEditAction';

export function* updateBranchTask(actionRequest: action.UpdateBranchRequest) {
    const data = actionRequest.data;
    const response = yield call(OutletApi.updateOutlet, data.id, data);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doUpdateBranchSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doUpdateBranchRequest(data) });
            yield put(action.doUpdateBranchFailure(newTimeoutError));
        } else {
            yield put(action.doUpdateBranchFailure(response.error));
        }
    }
}

export function* updateBranchSaga() {
    yield takeLatest(
        action.UPDATE_BRANCH_REQUEST,
        updateBranchTask,
    );
}
