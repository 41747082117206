import React, { useState, useEffect, useCallback, Dispatch } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Popover, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Images } from 'themes';
import RootStoreState from 'rootStore';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { TableIncrementNumber, DownloadBlob } from 'common/CommonUtils';
import { DefaultQueryType, defaultQuery, defaultPage, defaultLimit } from 'common/constans';
import { FrontendRoutes } from 'navigation/Routes';
import { OrderListItem, HttpError } from 'data/Models';
import AppTable from 'components/AppTableComponent';
import { SearchBox } from 'components/FilterComponent';
import { OvalButton } from 'components/ButtonComponent';
import AlertComponent from 'components/AlertComponent';
import { GetMethodLoading } from 'components/LoadingComponent';
import BranchFilterView from 'components/FilterComponent/BranchFilterView';
import StatusFilterView from 'components/FilterComponent/StatusFilterView';
import PaymentFilterView from 'components/FilterComponent/PaymentFilterView';
import { DownloadComponent, downloadOrderListPermissionKey } from 'components/DownloadComponent';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { doBranchListInitial, BranchListAction } from 'features/branch/BranchManagement/BranchAction';
import { OrderManagementDownloadAction, doOrderManagementDownloadRequest, doOrderManagementDownloadInitial } from 'features/order/OrderManagementDownload/OrderManagementDownloadAction';
import { doPaymentChannelListInitial, PaymentChannelListAction } from 'features/paymentChannel/PaymentChannelAction';
import { OrderManagementAction, doOrderManagementRequest, doOrderManagementInitial } from './OrderManagementAction';
import './index.css'
import ServiceTypeFilterView from 'components/FilterComponent/ServiceTypeFilterView';
import endOfToday from 'date-fns/endOfToday'

const serviceTypeDesc = {
    homeService: "Home Service",
    goToBranch: "Visit Branch"
};
export interface FilterServiceHS {
    id?: string;
    name?: string;
}
export interface RegionFilter {
    id?: string;
    name?: string;
}
export interface BranchFilter {
    id?: string;
    name?: string;
    region?: RegionFilter | null | undefined;
    city?: RegionFilter;
}
export interface StatusFilter {
    id?: string;
    name?: string;
}
export interface PaymentFilter {
    id?: string;
    name?: string;
    code?: string;
    installment?: boolean;
    method?: string;
    tenor?: number[];
}
const Text = {
    wrapperId: 'orderManagementWrapperId',
    download: 'DOWNLOAD ORDER',
    viewDetail: 'View Detail',
    edit: 'Edit',
    no: 'No',
    orderNumber: 'Order Number',
    doctorName: 'Referral Doctor Name',
    customerID: 'Customer ID',
    branch: 'Branch',
    paymentType: 'Payment Type',
    status: 'Status',
    searchPlaceholder: 'Search',
    serviceType: 'Service Type'
};
const dateFormatQuery = 'YYYY-MM-DD';


const OrderManagementView = () => {
    const history = useHistory();
    const branchListAction = useDispatch<Dispatch<BranchListAction>>();
    const branchListState = useSelector((state: RootStoreState) => state.branchList);
    const paymentChannelListAction = useDispatch<Dispatch<PaymentChannelListAction>>();
    const paymentChannelListState = useSelector((state: RootStoreState) => state.paymentChannelList);
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];

    const orderPermissions = {
        isAllowedShowList: isPermissionAllowed(FrontendRoutes.OrderManagement.permissionKey, permissions),
        isAllowedViewDetail: isPermissionAllowed(FrontendRoutes.OrderDetailPage.permissionKey, permissions),
        isAllowedDownloadList: isPermissionAllowed(downloadOrderListPermissionKey, permissions),
    };
    const showDots = orderPermissions.isAllowedViewDetail;
    const orderManagementAction = useDispatch<Dispatch<OrderManagementAction>>();
    const orderListState = useSelector((state: RootStoreState) => state.orderManagement);
    const orderListData = orderListState.data;
    const orderManagementDownloadAction = useDispatch<Dispatch<OrderManagementDownloadAction>>();
    const orderListDownloadState = useSelector((state: RootStoreState) => state.orderManagementDownload);
    const [error, setError] = useState<HttpError | null>(null);
    const [filterParams, setFilterParams] = useState<DefaultQueryType>(defaultQuery);
    const [shownPopOver, setShownPopover] = useState<number | null>(null);
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [forceClose, setForceClose] = useState<boolean>(false);

    const [dates, setDates] = useState<any>([]);
    const [value, setValue] = useState<any>(null);

    // Range Date
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD/MM/YYYY';
    const disabledDate = (current: any) => {
        let tooLate = false;
        let tooEarly = false;
        const afterToday = current && current > endOfToday();
        if (dates && dates.length > 0) {
            tooLate = dates[0] && current.diff(dates[0], 'year') >= 1;
            tooEarly = dates[1] && dates[1].diff(current, 'year') >= 1;
        }
        return afterToday || tooEarly || tooLate;
    };

    const containerId = document.getElementById(Text.wrapperId);

    useEffect(() => {
        if (orderPermissions.isAllowedShowList) {

            const dateToday = new Date().toISOString().split('T')[0];

            const firstDefaultQuery = {
                page: defaultPage,
                limit: defaultLimit,
                from_date: dateToday,
                to_date: dateToday
            }

            orderManagementAction(doOrderManagementRequest(firstDefaultQuery));
        }
    }, [orderManagementAction, orderPermissions.isAllowedShowList]);
    useEffect(() => {
        if (orderListDownloadState.data) {
            DownloadBlob(orderListDownloadState.data)
            setForceClose(true);
            orderManagementDownloadAction(doOrderManagementDownloadInitial());
        }
    }, [orderManagementDownloadAction, orderListDownloadState]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
        if (branchListState.error) {
            branchListAction(doBranchListInitial());
            setError(branchListState.error);
        }
    }, [branchListAction, branchListState]);
    useEffect(() => {
        if (paymentChannelListState.error) {
            paymentChannelListAction(doPaymentChannelListInitial());
            setError(paymentChannelListState.error);
        }
    }, [paymentChannelListAction, paymentChannelListState]);
    useEffect(() => {
        if (orderListDownloadState.error) {
            orderManagementDownloadAction(doOrderManagementDownloadInitial());
            setForceClose(true);
            setError(orderListDownloadState.error);
        }
    }, [orderManagementDownloadAction, orderListDownloadState]);
    useEffect(() => {
        if (orderListState.error) {
            orderManagementAction(doOrderManagementInitial());
            setError(orderListState.error);
        }
    }, [orderManagementAction, orderListState]);
    const tableLoading: boolean = profileState.onRequest || orderListState.onRequest || branchListState.onRequest || paymentChannelListState.onRequest || orderListDownloadState.onRequest;
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const handleHistoryPush = useCallback((path: string) => {
        history.push(path);
    }, [history]);
    const handleShowPopOver = useCallback((isShown, index) => {
        let shownPopOver = null;
        if (!isShown) {
            shownPopOver = index;
        }

        setShownPopover(shownPopOver);
    }, []);
    const handleRequestListAction = (query: DefaultQueryType) => {
        setShownPopover(null);
        setFilterParams(query);

        if(!query.from_date || !query.to_date) {
            console.warn('no date range');
            const dateToday = new Date().toISOString().split('T')[0];
            query.from_date = dateToday;
            query.to_date = dateToday;
        }

        orderManagementAction(doOrderManagementRequest(query));
    };
    const handleBranchSelected = (values: BranchFilter[]) => {
        const newBranchIds = values.map((item) => item.id).join(',');
        const newQuery = Object.assign({}, filterParams, { outlet_ids: newBranchIds, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };
    const handleStatusSelected = (values: StatusFilter[]) => {
        const newStatusIds = values.map((item) => item.id).join(',');
        const newQuery = Object.assign({}, filterParams, { status: newStatusIds, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };
    const handlePaymentSelected = (values: PaymentFilter[]) => {
        const newPaymentIds = values.map((item) => item.id).join(',');
        const newQuery = Object.assign({}, filterParams, { payment: newPaymentIds, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };
    const handleSearchByKeyword = (keyword: string) => {
        const newQuery = Object.assign({}, filterParams, { keyword: keyword, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };
    const handleServiceTypeSelected = (value: FilterServiceHS) => {
        const newQuery = Object.assign({}, filterParams, { service_type: value.id, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };

    const handleTransactionDate = (value: any) => {
        const fromDate =  value.dateValue[0].format(dateFormatQuery);
        const toDate = value.dateValue[1].format(dateFormatQuery);
        const newQuery = Object.assign({}, filterParams, { from_date: fromDate, to_date: toDate, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    }

    const triggerDownloadModal = () => {
        setShowDownloadModal(true);
    };
    const handleDownloadOrderList = (value: any) => {

        let queryData = {
            from_date: '',
            to_date: '',
            outlet_id: '',
            service_type: ''
        };
        if (value && value.dateValue && value.dateValue[0] && value.dateValue[1]) {
            queryData['from_date'] = value.dateValue[0].format(dateFormatQuery);
            queryData['to_date'] = value.dateValue[1].format(dateFormatQuery);
        }
        if (value && value.branchValue) {
            queryData['outlet_id'] = value.branchValue;
        }

        if (value && value.serviceType) {
            queryData['service_type'] = value.serviceType;
        }

        orderManagementDownloadAction(doOrderManagementDownloadRequest(queryData));
    };
    const columns: ColumnsType<OrderListItem> = [
        {
            title: Text.no,
            dataIndex: '',
            key: '',
            render: (text, row, index: any) => {
                return (
                    <span className="indexStyle">{TableIncrementNumber(filterParams.page, filterParams.limit, index)}</span>
                );
            },
            width: '5%',
        },
        {
            title: Text.orderNumber,
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: any) => {
                if (orderPermissions.isAllowedViewDetail) {
                    return (
                        <Link to={{
                            pathname: FrontendRoutes.OrderDetailPage.path.replace(':orderId', record.id),
                        }}>
                            <span className="nameStyle">{text}</span>
                        </Link>
                    )
                }
                return (
                    <span className="nameStyle">{text}</span>
                )
            },
        },
        {
            title: Text.doctorName,
            dataIndex: ['doctor', 'name'],
            key: 'doctorName',
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
            width: '170px',
        },
        {
            title: Text.paymentType,
            dataIndex: ['payment', 'channel'],
            key: 'paymentType',
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: Text.customerID,
            dataIndex: ['customer', 'id'],
            key: 'customerID',
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: Text.branch,
            dataIndex: ['outlet', 'name'],
            key: 'outlet',
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: Text.serviceType,
            dataIndex: ['homeservice', 'service_type'],
            key: 'homeservice',
            render: (text: string, record) => {
                const serviceType = record.homeservice.service_type ? serviceTypeDesc.homeService : serviceTypeDesc.goToBranch;
                return (<span>{serviceType}</span>)
            },
        },
        {
            title: Text.status,
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: '',
            key: '',
            render: (text, record, index) => {
                const content = PopOverContent(record, orderPermissions, handleHistoryPush);
                const isShown = shownPopOver === index;
                let popupContainer = document.body;
                if (containerId) {
                    popupContainer = containerId;
                }
                return (
                    <div>
                        <Popover
                            content={content}
                            visible={isShown}
                            trigger="click"
                            placement="bottomRight"
                            overlayClassName={'actionGroupPopOver'}
                            getPopupContainer={() => popupContainer}
                        >
                            <div className="orderManagementDotsWrapper">
                                {showDots && (
                                    <div
                                        className="orderManagementDots"
                                        onClick={() => handleShowPopOver(isShown, index)
                                        }>
                                        <img src={Images.Dots} alt="Dots" />
                                    </div>
                                )}
                            </div>
                        </Popover>
                    </div>
                )
            },
            width: '141px',
        },
    ];
    return (
        <div className="orderManagementWrapper" id={Text.wrapperId}>
            {error && (<AlertComponent error={error} clearFn={() => handleClearFn()} />)}
            <DownloadComponent
                handleOk={handleDownloadOrderList}
                handleClose={() => {
                    setShowDownloadModal(false);
                    setForceClose(false)
                }}
                forceClose={forceClose}
                visible={showDownloadModal}
            />
            <div className="orderManagementFilterWrapper">
                <Space size={16}>
                    <SearchBox
                        placeholder={Text.searchPlaceholder}
                        onSearch={handleSearchByKeyword}
                    />
                    <BranchFilterView onSelect={handleBranchSelected} />
                    <StatusFilterView onMultipleSelect={handleStatusSelected} />
                    <PaymentFilterView onSelect={handlePaymentSelected} />
                    <ServiceTypeFilterView onSingleSelect={handleServiceTypeSelected} />
                    <RangePicker
                        className={'rangePickerWrapper'}
                        format={dateFormat}
                        value={value?.dateValue}
                        disabledDate={disabledDate}
                        onCalendarChange={val => setDates(val)}
                        onChange={val => {
                            const newValue = Object.assign({}, value, { dateValue: val });
                            setValue(newValue);
                            handleTransactionDate(newValue);
                        }}
                        inputReadOnly={true}
                    />
                </Space>
                {orderPermissions.isAllowedDownloadList && (
                    <OvalButton
                        type="primary"
                        buttonText={Text.download}
                        icon={<img className="ovalButtonDownload" src={Images.DownloadWhite} alt="download" />}
                        onClick={triggerDownloadModal}
                    />
                )}
            </div>
            <AppTable
                loading={{
                    spinning: tableLoading,
                    indicator: <GetMethodLoading />,
                    wrapperClassName: 'tableLoading'
                }}
                pageSize={orderListData?.pagination?.limit}
                total={orderListData?.pagination?.total}
                current={orderListData?.pagination?.page}
                onRequestData={(currentPage, pageSize) => {
                    const newQuery = Object.assign({}, filterParams, {
                        page: currentPage,
                        limit: pageSize,
                    });
                    handleRequestListAction(newQuery);
                }}
                columns={columns}
                dataSource={orderListData?.data}
                rowKey={record => record.id}
                rowClassName={(record, index) => index % 2 === 0 ? 'rowWhite' : 'rowBlue'}
            />
        </div>
    );
};
interface OrderPermissions {
    isAllowedShowList: boolean;
    isAllowedViewDetail: boolean;
    isAllowedDownloadList: boolean;
}
function PopOverContent(
    record: OrderListItem,
    orderPermissions: OrderPermissions,
    navigateTo: (path: string) => void
) {
    const options = [
        {
            clickFn: () => navigateTo(FrontendRoutes.OrderDetailPage.path.replace(':orderId', record.id)),
            imgSrc: Images.EyeBlue,
            text: Text.viewDetail,
            isAllowed: orderPermissions.isAllowedViewDetail,
        },
    ];

    const popOverContentItems = options.map((props) => {
        const { clickFn, imgSrc, text, isAllowed } = props;

        if (isAllowed) {
            return (
                <div key={text} className="appPopOverItem" onClick={clickFn}>
                    <img src={imgSrc} alt={text} />
                    <span>{text}</span>
                </div>
            );
        }

        return null;
    });

    return (
        <div className="appPopOverContent">
            {popOverContentItems}
        </div>
    );
}

export default OrderManagementView;
