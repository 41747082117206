import { call, put, takeLatest } from 'redux-saga/effects';
import UserApi from 'data/api/UserApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './AdminCreateAction';

function* createAdminTask(createAdminAction: action.CreateAdminRequest) {
    const { newData } = createAdminAction;
    const response = yield call(UserApi.createUser, newData);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doCreateAdminSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doCreateAdminRequest(newData) });
            yield put(action.doCreateAdminFailure(newTimeoutError));
        } else {
            yield put(action.doCreateAdminFailure(response.error));
        }
    }
}

export function* createAdminSaga() {
    yield takeLatest(
        action.CREATE_ADMIN_REQUEST,
        createAdminTask,
    );
}
