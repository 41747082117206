import React, {Dispatch, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Descriptions, Typography} from "antd";
import {ColumnsType} from "antd/lib/table";
import {ContractDetail, PromoDetail} from "data/Models";
import {capitalizeFirstLetter, formatDate} from "common/CommonUtils";
import AppTable from "components/AppTableComponent";
import {Text} from "./PromoDetailView";
import {findPromoTypeName} from "common/constans";
import {
  ContractDetailAction,
  doContractDetailRequest,
} from "features/contract/ContractDetail/ContractDetailAction";
import RootStoreState from "rootStore";

interface Props {
  promoDetailData: PromoDetail;
  promoType: string;
}

const PromoDetailWithMultipleContract: React.FC<Props> = (props) => {
  const {promoDetailData, promoType} = props;

  const [isinitialLoad, setInitialLoad] = useState<boolean>(true);
  const contractDetailAction = useDispatch<Dispatch<ContractDetailAction>>();
  const contractDetailState = useSelector(
    (state: RootStoreState) => state.contractDetail
  );
  const [queueLoadContracts, setQueueLoadContracts] = useState<string[]>([]);
  const [contracts, setContracts] = useState<ContractDetail[]>([]);
  const [isWillLoadContractDetail, setWillLoadContractDetail] = useState(false);
  const [tableScroolHeight, setTableScroolHeight] = useState<number>();

  useEffect(() => {
    if (isinitialLoad && promoDetailData.contracts) {
      setQueueLoadContracts(promoDetailData.contracts.map((item) => item.id));
      setInitialLoad(false);
    }
  }, [promoDetailData.contracts, isinitialLoad]);
  useEffect(() => {
    const {onRequest, data} = contractDetailState;

    if (
      !isWillLoadContractDetail &&
      queueLoadContracts.length > 0 &&
      !onRequest
    ) {
      const id = queueLoadContracts[0];
      if (id) {
        setWillLoadContractDetail(true);
        contractDetailAction(doContractDetailRequest(id));
      }
    } else if (isWillLoadContractDetail && !onRequest && data) {
      const oriData = props.promoDetailData.contracts?.find(
        (item) => item.id === data.id
      );
      if (oriData) {
        data.alias = oriData.alias;
        data.alias_en = oriData.alias_en;
      }
      setContracts([...contracts, data]);

      if (queueLoadContracts.length > 0) {
        const arr = queueLoadContracts;
        arr.shift();
        setQueueLoadContracts(arr);
      }

      setWillLoadContractDetail(false);
    }
  }, [
    contractDetailAction,
    contractDetailState,
    contracts,
    isWillLoadContractDetail,
    queueLoadContracts,
    props.promoDetailData.contracts,
  ]);

  const columnItemsView = (
    key: string,
    items: any[],
    index: number,
    maxItemLength: number,
    maxLineHeight: number
  ) => {
    let newItems = "";
    if (items?.length > 0) {
      const slicedItems = items.slice(0, maxItemLength);
      newItems = slicedItems
        .map((item) => {
          return `${item}`;
        })
        .join(", ");
    }
    if (items.length > 5) {
      newItems = `${newItems}...`;
    }
    return (
      <div className={"listItemEllipsis"}>
        {items.length === 0 ? (
          "-"
        ) : (
          <p id={`columnItemList${key}${index}`} key={`columnItemList`}>
            {newItems}
          </p>
        )}
      </div>
    );
  };

  const columns: ColumnsType<ContractDetail> = [
    {
      title: Text.no,
      dataIndex: "",
      key: "",
      render: (text, row, index: number) => {
        return <span className="indexStyle">{index + 1}</span>;
      },
      width: "5%",
    },
    {
      title: Text.contractID,
      dataIndex: "id",
      key: "id",
      render: (text: string) => {
        return <span>{text ? text : "-"}</span>;
      },
      width: "10%",
    },
    {
      title: Text.contractName.id,
      dataIndex: "name",
      key: "name",
      render: (text: string, record: ContractDetail) => {
        return <span>{record.alias ? record.alias : text ? text : "-"}</span>;
      },
      width: "10%",
    },
    {
      title: Text.contractName.en,
      dataIndex: "name_en",
      key: "name_en",
      render: (text: string, record: ContractDetail) => {
        return (
          <span>{record.alias_en ? record.alias_en : text ? text : "-"}</span>
        );
      },
      width: "10%",
    },
    {
      title: Text.testName.id,
      dataIndex: ["tests"],
      key: "tests_id",
      render: (text: string, record: ContractDetail, index: number) => {
        return columnItemsView(
          "tests_id",
          record?.tests.map((item) => item.name),
          index,
          5,
          60
        );
      },
      width: "13%",
    },
    {
      title: Text.testName.en,
      dataIndex: ["tests"],
      key: "tests_en",
      render: (text: string, record: ContractDetail, index: number) => {
        return columnItemsView(
          "tests_en",
          record?.tests.map((item) => item.name_en),
          index,
          5,
          60
        );
      },
      width: "13%",
    },
    {
      title: Text.discountWithPercent,
      dataIndex: "discount",
      key: "discount",
      render: (text: string) => {
        return <span className="nameStyle">{text ? `${text}%` : "-"}</span>;
      },
      width: "9%",
    },
    {
      title: Text.branch,
      dataIndex: ["outlets"],
      key: "outlets",
      render: (text: string, record: ContractDetail, index: number) => {
        return columnItemsView(
          "outlets",
          record?.outlets?.map((item) => item.name),
          index,
          5,
          60
        );
      },
      width: "9%",
    },
    {
      title: Text.contractDate.start,
      dataIndex: "start_date",
      key: "start_date",
      render: (text: string) => {
        return (
          <span className="nameStyle">
            {text ? formatDate(text, "d MMM yyyy HH:mm") : "-"}
          </span>
        );
      },
      width: "10%",
    },
    {
      title: Text.contractDate.end,
      dataIndex: "end_date",
      key: "end_date",
      render: (text: string) => {
        return (
          <span className="nameStyle">
            {text ? formatDate(text, "d MMM yyyy HH:mm") : "-"}
          </span>
        );
      },
      width: "10%",
    },
  ];

  const promoDetailDesc = [
    {
      label: Text.promoType,
      content: findPromoTypeName(promoType),
    },
    {
      label: Text.source,
      content: promoDetailData?.source,
    },
    {
      label: Text.title.id,
      content: promoDetailData?.title,
    },
    {
      label: Text.title.en,
      content: promoDetailData?.title_en,
    },
    {
      label: Text.imageDisplay,
      content: promoDetailData?.cover_image && (
        <img
          className={"promoDetailImg"}
          src={promoDetailData?.cover_image}
          alt="promoDetailImg"
        />
      ),
    },
    {
      label: "",
      content: (
        <div className="contractListWrapper">
          <div className={"contractListHeader"}>{Text.contractList}</div>
          <AppTable
            id={"contractList"}
            scroll={{y: tableScroolHeight}}
            columns={columns}
            dataSource={contracts}
            rowKey={(record) => record.id}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "rowWhite" : "rowBlue"
            }
            showPagination={false}
          />
        </div>
      ),
      className: Text.descriptionRowWithBorder,
    },
    {
      label: Text.description.id,
      content: promoDetailData?.description,
      className: Text.descriptionRowWithBorder,
      setInnerHTML: true,
    },
    {
      label: Text.description.en,
      content: promoDetailData?.description_en,
      className: Text.descriptionRowWithBorder,
      setInnerHTML: true,
    },
    {
      label: Text.termsAndCondition.id,
      content: promoDetailData?.tnc,
      className: Text.descriptionRowWithBorder,
      setInnerHTML: true,
    },
    {
      label: Text.termsAndCondition.en,
      content: promoDetailData?.tnc_en,
      className: Text.descriptionRowWithBorder,
      setInnerHTML: true,
    },
    {
      label: Text.startPromoDate,
      content: formatDate(promoDetailData?.periode_date?.start),
    },
    {
      label: Text.endPromoDate,
      content: formatDate(promoDetailData?.periode_date?.end),
    },
    {
      label: Text.startPublishDate,
      content: formatDate(promoDetailData?.publish_date?.start),
    },
    {
      label: Text.endPublishDate,
      content: formatDate(promoDetailData?.publish_date?.end),
    },
    {
      label: Text.createdDate,
      content: formatDate(promoDetailData?.created_at),
    },
    {
      label: Text.createdBy,
      content: promoDetailData?.created_by?.name,
    },
    {
      label: Text.updateDate,
      content: formatDate(promoDetailData?.updated_at),
    },
    {
      label: Text.updateBy,
      content: promoDetailData?.updated_by?.name,
    },
    {
      label: Text.status,
      content: promoDetailData?.status
        ? capitalizeFirstLetter(promoDetailData?.status)
        : "",
    },
  ];

  useEffect(() => {
    const contractListTable = document.getElementById("contractList");
    const contractListHeader =
      document.getElementsByClassName("ant-table-thead")[0];
    if (contractListTable && contractListHeader) {
      if (contracts.length === 5) {
        setTableScroolHeight(
          contractListTable.offsetHeight - contractListHeader.clientHeight
        );
      }
    }
  }, [contracts.length]);

  return (
    <Descriptions
      title={Text.detailsInformation}
      className={"promoDetailContent"}
    >
      {promoDetailDesc &&
        promoDetailDesc.map((promoDetailItem, index) => {
          const isFullWidthBorder =
            promoDetailItem.className === Text.descriptionRowWithBorder;
          return (
            <Descriptions.Item
              key={`promoDetailItem${index}`}
              className={promoDetailItem.className || ""}
              label={promoDetailItem.label}
              labelStyle={
                isFullWidthBorder ? {} : {borderBottom: "1px solid #dde1e8"}
              }
              span={3}
            >
              <div>
                {promoDetailItem.setInnerHTML ? (
                  <Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: promoDetailItem.content ?? "",
                      }}
                    />{" "}
                  </Typography>
                ) : (
                  promoDetailItem.content
                )}
                {!isFullWidthBorder && <div className="descDivider"></div>}
              </div>
            </Descriptions.Item>
          );
        })}
    </Descriptions>
  );
};

export default PromoDetailWithMultipleContract;
