import React from "react";
import {Button, DatePicker, Form, Input} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {FormInstance} from "antd/lib/form";
import {
  formRules,
  promoTypes,
  promoSource,
  uiDateFormat,
} from "features/promo/PromoForm/PromoFormConfig";
import ContractInputView from "features/contract/ContractList/ContractInputView";
import {PeriodDateDivider, PromoFormValue, Text} from "./Commons";
import {Editor as TinyMCEEditor} from "tinymce";
import {SelectComponent} from "components/SelectComponent";
import ImageUpload from "components/ImageUpload";
import RichTextEditor from "components/RichTextEditor";

interface PromoGeneralFormViewProps {
  mode: "create" | "edit";
  form: FormInstance;
  initialValues: PromoFormValue;
  promoCode: string | undefined;
  source: string | undefined;
  promoType: string | undefined;
  quota: number | undefined;
  imageState: "showImage" | "hideImage";
  descriptionId: string | undefined;
  descriptionEn: string | undefined;
  tncId: string | undefined;
  tncEn: string | undefined;
  handlePromoTypeSelected: (option: {label: string; value: string}) => void;
  handlePromoSource: (option: {label: string; value: string}) => void;
  handlePromoCodeChange: (value: string) => void;
  handleContractLoaded: (values: string[]) => void;
  handleContractSelected: (value: string) => void;
  handleInputQuota: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageError: (error: string | null) => void;
  handleImageChange: (value: string | undefined) => void;
  handleDescriptionIdChange: (content: string, editor: TinyMCEEditor) => void;
  handleDescriptionEnChange: (content: string, editor: TinyMCEEditor) => void;
  handleTncIdChange: (content: string, editor: TinyMCEEditor) => void;
  handleTncEnChange: (content: string, editor: TinyMCEEditor) => void;
  handlePeriodeDateStartFilter: (date: any) => boolean;
  handlePeriodeDateStartSelect: (date: any) => void;
  handlePeriodeDateEndFilter: (date: any) => boolean;
  handlePeriodeDateEndSelect: (date: any) => void;
  handlePublishDateStartFilter: (date: any) => boolean;
  handlePublishDateStartSelect: (date: any) => void;
  handlePublishDateEndFilter: (date: any) => boolean;
  handlePublishDateEndSelect: (date: any) => void;
  handleCancel: () => void;
  onFinish: any;
}

function PromoGeneralFormView(props: PromoGeneralFormViewProps) {
  return (
    <Form layout="vertical" form={props.form} onFinish={props.onFinish}>
      <Form.Item
        label={Text.promoType}
        name="promoType"
        rules={formRules.promoType}
      >
        <SelectComponent
          selectProps={{
            placeholder: Text.promoType,
            disabled: props.mode === "edit",
          }}
          selectedOption={props.promoType}
          data={promoTypes}
          onMapOption={(value) => value}
          onSelectedChange={props.handlePromoTypeSelected}
        />
      </Form.Item>
      <Form.Item label={Text.source} name="source" rules={formRules.source}>
        <SelectComponent
          selectProps={{
            placeholder: Text.source,
          }}
          selectedOption={props.initialValues.source}
          data={promoSource}
          onMapOption={(value) => value}
          onSelectedChange={props.handlePromoSource}
        />
      </Form.Item>
      <Form.Item
        label={Text.promoCode}
        name="promoCode"
        rules={formRules.promoCode}
      >
        <Input
          bordered
          value={props.promoCode}
          maxLength={20}
          onChange={(event) => {
            props.handlePromoCodeChange(event.target.value);
          }}
        />
      </Form.Item>
      <Form.Item
        label={Text.contractId}
        name="contractId"
        rules={formRules.contract}
      >
        <ContractInputView
          selectedOption={props.initialValues.contractId}
          onSelect={props.handleContractSelected}
          onLoad={props.handleContractLoaded}
        />
      </Form.Item>
      <Form.Item label={Text.contractName.id} name="contractNameId">
        <Input disabled bordered />
      </Form.Item>
      <Form.Item label={Text.contractName.en} name="contractNameEn">
        <Input disabled bordered />
      </Form.Item>
      <Form.Item label={Text.testName.id} name="testNameId">
        <TextArea disabled autoSize={{minRows: 1, maxRows: 5}} />
      </Form.Item>
      <Form.Item label={Text.testName.en} name="testNameEn">
        <TextArea disabled autoSize={{minRows: 1, maxRows: 5}} />
      </Form.Item>
      <Form.Item label={Text.discount} name="discount">
        <Input disabled bordered />
      </Form.Item>
      <Form.Item label={Text.branches} name="branches">
        <TextArea disabled autoSize={{minRows: 1, maxRows: 5}} />
      </Form.Item>
      {props.mode === "edit" && (
        <Form.Item label={Text.used} name="used">
          <Input bordered disabled />
        </Form.Item>
      )}
      <Form.Item label={Text.quota} name="quota" rules={formRules.quota}>
        <Input bordered value={props.quota} onChange={props.handleInputQuota} />
      </Form.Item>
      <Form.Item label={Text.title.id} name="titleId" rules={formRules.title}>
        <Input maxLength={128} bordered />
      </Form.Item>
      <Form.Item label={Text.title.en} name="titleEn" rules={formRules.title}>
        <Input maxLength={128} bordered />
      </Form.Item>
      <Form.Item
        label={Text.image}
        name="coverImage"
        rules={formRules.coverImage(props.form)}
      >
        <ImageUpload
          defaultImage={props.initialValues.coverImage?.image}
          imageState={props.imageState}
          onValidateError={props.handleImageError}
          onImageChange={props.handleImageChange}
        />
      </Form.Item>
      <Form.Item
        label={Text.description.id}
        name="descriptionId"
        rules={formRules.description}
      >
        <RichTextEditor
          htmlValue={props.descriptionId}
          onEditorChange={props.handleDescriptionIdChange}
        />
      </Form.Item>
      <Form.Item
        label={Text.description.en}
        name="descriptionEn"
        rules={formRules.description}
      >
        <RichTextEditor
          htmlValue={props.descriptionEn}
          onEditorChange={props.handleDescriptionEnChange}
        />
      </Form.Item>
      <Form.Item label={Text.tnc.id} name="tncId" rules={formRules.tnc}>
        <RichTextEditor
          htmlValue={props.tncId}
          onEditorChange={props.handleTncIdChange}
        />
      </Form.Item>
      <Form.Item label={Text.tnc.en} name="tncEn" rules={formRules.tnc}>
        <RichTextEditor
          htmlValue={props.tncEn}
          onEditorChange={props.handleTncEnChange}
        />
      </Form.Item>
      <div style={{display: "flex"}}>
        <Form.Item
          label={Text.periodeDate.start}
          name="periodeDateStart"
          rules={formRules.date}
          style={{width: "auto"}}
        >
          <DatePicker
            showTime
            format={uiDateFormat}
            disabledDate={props.handlePeriodeDateStartFilter}
            onSelect={props.handlePeriodeDateStartSelect}
          />
        </Form.Item>
        <PeriodDateDivider />
        <Form.Item
          label={Text.periodeDate.end}
          name="periodeDateEnd"
          rules={formRules.date}
        >
          <DatePicker
            showTime
            format={uiDateFormat}
            disabledDate={props.handlePeriodeDateEndFilter}
            onSelect={props.handlePeriodeDateEndSelect}
          />
        </Form.Item>
      </div>
      <div style={{display: "flex"}}>
        <Form.Item
          label={Text.publishDate.start}
          name="publishDateStart"
          rules={formRules.date}
          style={{width: "auto"}}
        >
          <DatePicker
            showTime
            format={uiDateFormat}
            disabledDate={props.handlePublishDateStartFilter}
            onSelect={props.handlePublishDateStartSelect}
          />
        </Form.Item>
        <PeriodDateDivider />
        <Form.Item
          label={Text.publishDate.end}
          name="publishDateEnd"
          rules={formRules.date}
        >
          <DatePicker
            showTime
            format={uiDateFormat}
            disabledDate={props.handlePublishDateEndFilter}
            onSelect={props.handlePublishDateEndSelect}
          />
        </Form.Item>
      </div>
      <div className="promoFormViewAction">
        <Button type="primary" htmlType="submit">
          {Text.submit}
        </Button>
        <span style={{flex: 1}} />
        <Button type="text" onClick={props.handleCancel}>
          {Text.cancel}
        </Button>
      </div>
    </Form>
  );
}

export default PromoGeneralFormView;
