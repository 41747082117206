import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { Images } from 'themes';
import RootStoreState from 'rootStore';
import { FrontendRoutes } from 'navigation/Routes';
import { OvalButton } from 'components/ButtonComponent';
import { doLogoutFinish, doLogoutRequest, LogoutAction } from 'features/scaffold/ScaffoldAction';
import { TypePageNotAllowed, TypeSessionExpired, TypePageNotFound } from './index';
import './index.css';

export const Text = {
    ok: 'OK',
    login: 'LOGIN',
    backToDashboard: 'BACK TO DASHBOARD',
    pageNotAllowed: {
        title: 'Page not allowed',
        description: 'Sorry, you are not allowed to access this page',
    },
    sessionExpired: {
        title: 'Your Session Has Expired',
        description: 'Please login to continue the session',
    },
    pageNotFound: {
        title: 'Page not found',
        description: 'Sorry, the page you requested couldn\'t be found',
    }
};
const ModalError: React.FC<{
    title?: string;
    visible?: boolean;
    width?: number;
    maskStyle?: Object;
    closable?: boolean;
    maskClosable?: boolean;
    handleClose?: () => void;
    message?: string;
    wrapClassName?: string;
    buttonText?: string;
    buttonIcon?: any;
    buttonType?: string;
    buttonClassName?: string;
    buttonClick?: () => void;
    type?: string;
}> = ({
    title = null,
    visible = false,
    width = 356,
    maskStyle = {
        opacity: 0.7,
        backgroundColor: '#000000',
    },
    closable = false,
    maskClosable = false,
    handleClose = () => {},
    message = '',
    wrapClassName = '',
    buttonText = 'OK',
    buttonIcon = '',
    buttonType = 'primary',
    buttonClassName = '',
    buttonClick = null,
    type = ''
}) => {
    const handleCloseFn = () => {
        handleClose();
        setVisible(false);
    };
    const history = useHistory();
    const logoutAction = useDispatch<Dispatch<LogoutAction>>();
    const onLogout = useSelector((state: RootStoreState) => state.logout.onLogout);
    const [visibleState, setVisible] = useState(visible);
    useEffect(() => {
        if (onLogout) {
            logoutAction(doLogoutFinish());
            history.replace(FrontendRoutes.Login.path);
        }
    }, [logoutAction, onLogout, history]);

    const didLogout = useCallback(() => {
        logoutAction(doLogoutRequest());
    }, [logoutAction]);
    let footer: any[] = [];
    if (type === TypePageNotAllowed) {
        footer = [
            <OvalButton
                type={buttonType}
                wrapperClassName={buttonClassName}
                buttonText={Text.ok}
                key={buttonText}
                onClick={()=>{
                    handleCloseFn();
                    if (buttonClick) {
                        buttonClick()
                    }
                }}
            />
        ];
    } else if (type === TypeSessionExpired) {
        footer = [
            <OvalButton
                type={buttonType}
                wrapperClassName={buttonClassName}
                buttonText={Text.login}
                key={buttonText}
                onClick={()=>{
                    handleCloseFn();
                    didLogout();
                }}
            />
        ];
    } else if (type === TypePageNotFound) {
        footer = [
            <OvalButton
                type={buttonType}
                wrapperClassName={buttonClassName}
                buttonText={Text.backToDashboard}
                key={buttonText}
                onClick={()=>{
                    handleCloseFn();
                    history.replace(FrontendRoutes.Dashboard.path);
                }}
            />
        ];
    }
    const renderContent = (type: string) => {
        if (type === TypePageNotAllowed) {
            return (
                <div className="handleErrorContent">
                    <img className="handleErrorImg" src={Images.Warning} alt="warning" />
                    <div className="title">{Text.pageNotAllowed.title}</div>
                    <div className="description">{Text.pageNotAllowed.description}</div>
                </div>
            )
        } else if (type === TypeSessionExpired) {
            return (
                <div className="handleErrorContent">
                    <img className="handleErrorImg" src={Images.SessionExpired} alt="sessionExpired" />
                    <div className="title">{Text.sessionExpired.title}</div>
                    <div className="description">{Text.sessionExpired.description}</div>
                </div>
            )
        } else if (type === TypePageNotFound) {
            return (
                <div className="handleErrorContent">
                    <img className="handleErrorImg" src={Images.NotFound} alt="NotFound" />
                    <div className="title">{Text.pageNotFound.title}</div>
                    <div className="description">{Text.pageNotFound.description}</div>
                </div>
            )
        }
        return null;
    };

    return (
        <Modal
          maskClosable={maskClosable}
          title={title}
          centered
          closeIcon={null}
          visible={visibleState}
          width={width}
          maskStyle={maskStyle}
          closable={closable}
          onCancel={() => handleCloseFn()}
          footer={footer}
          wrapClassName={`${wrapClassName} ModalErrorWrapper ${type}`}
        >
          {renderContent(type)}
        </Modal>
    )
};

export default ModalError;
