import { HttpError, TestList } from 'data/Models';
import { TestListQuery } from 'common/States';

export const TEST_MANAGEMENT_INITIAL = 'TEST_MANAGEMENT_INITIAL';
export type TEST_MANAGEMENT_INITIAL = typeof TEST_MANAGEMENT_INITIAL;

export const TEST_MANAGEMENT_REQUEST = 'TEST_MANAGEMENT_REQUEST';
export type TEST_MANAGEMENT_REQUEST = typeof TEST_MANAGEMENT_REQUEST;

export const TEST_MANAGEMENT_SUCCESS = 'TEST_MANAGEMENT_SUCCESS';
export type TEST_MANAGEMENT_SUCCESS = typeof TEST_MANAGEMENT_SUCCESS;

export const TEST_MANAGEMENT_FAILURE = 'TEST_MANAGEMENT_FAILURE';
export type TEST_MANAGEMENT_FAILURE = typeof TEST_MANAGEMENT_FAILURE;

interface TestManagementInitial {
    type: TEST_MANAGEMENT_INITIAL;
}

export interface TestManagementRequest {
    type: TEST_MANAGEMENT_REQUEST;
    filterParams: TestListQuery;
}

interface TestManagementSuccess {
    type: TEST_MANAGEMENT_SUCCESS;
    data: TestList;
}

interface TestManagementFailure {
    type: TEST_MANAGEMENT_FAILURE;
    error: HttpError;
}

export type TestManagementAction = TestManagementInitial | TestManagementRequest | TestManagementSuccess | TestManagementFailure;

export function doTestManagementInitial(): TestManagementInitial {
    return {
        type: TEST_MANAGEMENT_INITIAL,
    };
}

export function doTestManagementRequest(filterParams : TestListQuery): TestManagementRequest {
    return {
        type: TEST_MANAGEMENT_REQUEST,
        filterParams: filterParams
    };
}

export function doTestManagementSuccess(data: TestList): TestManagementSuccess {
    return {
        type: TEST_MANAGEMENT_SUCCESS,
        data: data,
    };
}

export function doTestManagementFailure(error: HttpError): TestManagementFailure {
    return {
        type: TEST_MANAGEMENT_FAILURE,
        error: error,
    };
}
