import React from 'react';
import { ModalError, TypePageNotFound } from 'components/ModalComponent';

function NotFoundPageView() {
    return (
        <ModalError
            visible={true}
            type={TypePageNotFound}
        />
    );
}

export default NotFoundPageView;
