import { HttpError, PaymentChannelList } from "data/Models";

export const PAYMENT_CHANNEL_LIST_INITIAL = 'PAYMENT_CHANNEL_LIST_INITIAL';
export type PAYMENT_CHANNEL_LIST_INITIAL = typeof PAYMENT_CHANNEL_LIST_INITIAL;

export const PAYMENT_CHANNEL_LIST_REQUEST = 'PAYMENT_CHANNEL_LIST_REQUEST';
export type PAYMENT_CHANNEL_LIST_REQUEST = typeof PAYMENT_CHANNEL_LIST_REQUEST;

export const PAYMENT_CHANNEL_LIST_SUCCESS = 'PAYMENT_CHANNEL_LIST_SUCCESS';
export type PAYMENT_CHANNEL_LIST_SUCCESS = typeof PAYMENT_CHANNEL_LIST_SUCCESS;

export const PAYMENT_CHANNEL_LIST_FAILURE = 'PAYMENT_CHANNEL_LIST_FAILURE';
export type PAYMENT_CHANNEL_LIST_FAILURE = typeof PAYMENT_CHANNEL_LIST_FAILURE;

interface PaymentChannelListInitial {
    type: PAYMENT_CHANNEL_LIST_INITIAL;
}

export interface PaymentChannelListRequest {
    type: PAYMENT_CHANNEL_LIST_REQUEST;
}

interface PaymentChannelListSuccess {
    type: PAYMENT_CHANNEL_LIST_SUCCESS;
    data: PaymentChannelList;
}

interface PaymentChannelListFailure {
    type: PAYMENT_CHANNEL_LIST_FAILURE;
    error: HttpError;
}

export type PaymentChannelListAction = PaymentChannelListInitial | PaymentChannelListRequest | PaymentChannelListSuccess | PaymentChannelListFailure;

export function doPaymentChannelListInitial(): PaymentChannelListInitial {
    return {
        type: PAYMENT_CHANNEL_LIST_INITIAL,
    };
}

export function doPaymentChannelListRequest(): PaymentChannelListRequest {
    return {
        type: PAYMENT_CHANNEL_LIST_REQUEST,
    };
}

export function doPaymentChannelListSuccess(data: PaymentChannelList): PaymentChannelListSuccess {
    return {
        type: PAYMENT_CHANNEL_LIST_SUCCESS,
        data: data,
    };
}

export function doPaymentChannelListFailure(error: HttpError): PaymentChannelListFailure {
    return {
        type: PAYMENT_CHANNEL_LIST_FAILURE,
        error: error,
    };
}
