import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";
import { OrderListQuery, OrderListDownloadQuery } from "common/States";

const OrderApi = {
    getOrderList (queryParams: OrderListQuery): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Order.Base)
            .setQueryParameter(queryParams)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    orderList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    getOrderDetail (orderId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Order.Base}/${orderId}`)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    orderDetail: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    getOrderListDownload (queryParams: OrderListDownloadQuery): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Order.Download)
            .setQueryParameter(queryParams)
            .doGet()
            .then(response => http.handleDownloadResponse(200, response, (blob: Blob, filename: string): {} => {
                return {
                    orderListDownload: {
                        blob: blob,
                        filename: filename,
                    },
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    getOrderDetailDownload (orderId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Order.DetailDownload}/${orderId}`)
            .doGet()
            .then(response => http.handleDownloadResponse(200, response, (blob: Blob, filename: string): {} => {
                return {
                    orderDetailDownload: {
                        blob: blob,
                        filename: filename,
                    },
                }
            }))
            .catch(error => http.handleCatch(error));
    },
};

export default OrderApi;
