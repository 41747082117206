import { call, put, takeLatest } from 'redux-saga/effects';
import ContractApi from 'data/api/ContractApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './ContractDetailAction';

function* sagaTask(req: action.ContractDetailRequest) {
    const response = yield call(ContractApi.getContractDetail, req.contractId);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doContractDetailSuccess(response.data.contractDetail));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = {
                ...response.error,
                action: () => action.doContractDetailRequest(req.contractId),
            };
            yield put(action.doContractDetailFailure(newTimeoutError));
        } else {
            yield put(action.doContractDetailFailure(response.error));
        }
    }
}

export function* contractDetailSaga() {
    yield takeLatest(
        action.CONTRACT_DETAIL_REQUEST,
        sagaTask,
    );
}
