import {
    REGION_LIST_INITIAL,
    REGION_LIST_REQUEST,
    REGION_LIST_SUCCESS,
    REGION_LIST_FAILURE,
    RegionListAction
} from './RegionAction';
import { RegionListStoreState } from './RegionState';

export const defaultState: RegionListStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function regionListReducer(
    state: RegionListStoreState = defaultState,
    action: RegionListAction
): RegionListStoreState {
    switch (action.type) {
        case REGION_LIST_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case REGION_LIST_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case REGION_LIST_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case REGION_LIST_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
