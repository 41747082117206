import React, {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {PlusOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import RootStoreState from "rootStore";
import {isPermissionAllowed} from "common/PermissionUtils";
import {
  capitalizeFirstLetter,
  formatDate,
  TableIncrementNumber,
} from "common/CommonUtils";
import {
  defaultQuery,
  defaultRetrieveAll,
  defaultPage,
  defaultLimit,
  promoTypeListState,
} from "common/constans";
import {FrontendRoutes} from "navigation/Routes";
import {PromoListItem, HttpError} from "data/Models";
import {PromoListQuery} from "common/States";
import AppTable from "components/AppTableComponent";
import {OvalButton} from "components/ButtonComponent";
import AlertComponent from "components/AlertComponent";
import {GetMethodLoading, LoadingWithMask} from "components/LoadingComponent";
import {
  ModalConfirmation,
  TypeDeleteConfirmation,
} from "components/ModalComponent";
import {
  GetProfileAction,
  doGetProfileInitial,
} from "features/scaffold/ScaffoldAction";
import {
  PromoManagementAction,
  doPromoManagementRequest,
  doPromoManagementInitial,
} from "./PromoManagementAction";
import {
  DeletePromoAction,
  doDeletePromoInitial,
  doDeletePromoRequest,
} from "features/promo/PromoDelete/DeletePromoAction";
import "./index.css";
import PromoFilterView from "./PromoFilterView";
import PromoOptionsView from "./PromoOptionsView";
import {
  CreatePromoAction,
  doCreatePromoInitial,
} from "../PromoCreate/PromoCreateAction";
import {promoManagementConfigs} from "./PromoManagemetConfig";

const Text = {
  wrapperId: "promoManagementWrapperId",
  addNew: "ADD NEW",
  no: "No",
  promoTitle: "Promo Name",
  promoCode: "Promo Code",
  startPromoDate: "Start Promo Date",
  endPromoDate: "End Promo Date",
  usedQuota: "Used/Quota",
  type: "Type",
  source: "Source",
  status: "Status",
  specialOffer: "Special Offer",
  birthdayPromo: "Birthday Promo",
  promoCodeGeneral: "Promo Code General",
  voucher: "Voucher",
  deleteConfirmation: {
    specialOffer: "Are you sure you want to delete this special offer?",
    flashDeal: "Are you sure you want to delete this flash sale?",
    general: "Are you sure you want to delete this promo code general?",
  },
};

function PromoManagementView() {
  const history = useHistory();

  const profileAction = useDispatch<Dispatch<GetProfileAction>>();
  const profileState = useSelector((state: RootStoreState) => state.profile);
  const userPermissions = profileState.data?.permissions || [];
  const promoPermissions = {
    isAllowedPromoList: isPermissionAllowed(
      FrontendRoutes.PromoManagement.permissionKey,
      userPermissions
    ),
    isAllowedCreatePromo: isPermissionAllowed(
      FrontendRoutes.PromoCreatePage.permissionKey,
      userPermissions
    ),
    isAllowedViewDetail: isPermissionAllowed(
      FrontendRoutes.PromoDetailPage.permissionKey,
      userPermissions
    ),
    isAllowedEdit: isPermissionAllowed(
      FrontendRoutes.PromoEditPage.permissionKey,
      userPermissions
    ),
    isAllowedDelete: isPermissionAllowed(
      FrontendRoutes.PromoDeletePage.permissionKey,
      userPermissions
    ),
  };

  const promoManagementAction = useDispatch<Dispatch<PromoManagementAction>>();
  const promoListState = useSelector(
    (state: RootStoreState) => state.promoManagement
  );
  const promoListData = promoListState.data;
  const deletePromoAction = useDispatch<Dispatch<DeletePromoAction>>();
  const deletePromoState = useSelector(
    (state: RootStoreState) => state.deletePromo
  );
  const [error, setError] = useState<HttpError | null>(null);
  const [filterParams, setFilterParams] =
    useState<PromoListQuery>(defaultQuery);
  const [shownPopOver, setShownPopover] = useState<number | null>(null);
  const [deleteFromList, setDeleteFromList] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [selectedPromo, setSelectedPromo] = useState<PromoListItem | null>(
    null
  );
  const tableLoading = profileState.onRequest || promoListState.onRequest;

  useEffect(() => {
    if (promoPermissions.isAllowedPromoList) {
      promoManagementAction(
        doPromoManagementRequest(defaultRetrieveAll, defaultQuery)
      );
    }
  }, [promoManagementAction, promoPermissions.isAllowedPromoList]);
  useEffect(() => {
    if (profileState.error) {
      profileAction(doGetProfileInitial());
      setError(profileState.error);
    }
  }, [profileAction, profileState]);
  useEffect(() => {
    if (promoListState.error) {
      promoManagementAction(doPromoManagementInitial());
      setError(promoListState.error);
    }
  }, [promoManagementAction, promoListState]);
  useEffect(() => {
    if (deletePromoState.error) {
      setError(deletePromoState.error);
      deletePromoAction(doDeletePromoInitial());
    }
  }, [deletePromoAction, deletePromoState]);
  const handleClearFn = useCallback(() => {
    setError(null);
  }, []);
  const handleCreatePromo = useCallback(() => {
    if (promoPermissions.isAllowedCreatePromo) {
      history.push(FrontendRoutes.PromoCreatePage.path);
    }
  }, [promoPermissions.isAllowedCreatePromo, history]);
  const handleRequestListAction = useCallback(
    (retrieveAll: boolean, query: PromoListQuery) => {
      setShownPopover(null);
      setFilterParams(query);
      promoManagementAction(doPromoManagementRequest(retrieveAll, query));
    },
    [promoManagementAction]
  );
  const handleDeleteFn = useCallback((promo: PromoListItem) => {
    setShowDeleteConfirmation(true);
    setSelectedPromo(promo);
    setShownPopover(null);
  }, []);

  const containerId = document.getElementById(Text.wrapperId);
  const columns: ColumnsType<PromoListItem> = [
    {
      title: Text.no,
      dataIndex: "",
      key: "",
      render: (text, row, index: number) => {
        return (
          <span className="indexStyle">
            {TableIncrementNumber(
              filterParams.page ?? defaultPage,
              filterParams.limit ?? defaultLimit,
              index
            )}
          </span>
        );
      },
      width: "75px",
    },
    {
      title: Text.promoTitle,
      dataIndex: "title",
      key: "title",
      render: (text: any, record: PromoListItem) => {
        if (
          promoPermissions.isAllowedViewDetail &&
          promoManagementConfigs.supportedTypes.includes(record.type)
        ) {
          const path = `${FrontendRoutes.PromoDetailPage.path.replace(
            ":promoId",
            record.id
          )}?type=${record.type}`;
          return (
            <a
              href={path}
              onClick={(e) => {
                e.preventDefault();
                history.push(path);
              }}
            >
              <span className="nameStyle">{text ? text : "-"}</span>
            </a>
          );
        }
        return <span className="nameStyle">{text ? text : "-"}</span>;
      },
    },
    {
      title: Text.promoCode,
      dataIndex: "code",
      key: "code",
      render: (text) => <span>{text ? text : "-"}</span>,
      width: "110px",
    },
    {
      title: Text.startPromoDate,
      dataIndex: ["periode_date", "start"],
      key: "periode_date.start",
      render: (text) => (
        <span>{text ? formatDate(text, "d MMM yyyy - HH:mm:ss") : "-"}</span>
      ),
      width: "165px",
    },
    {
      title: Text.endPromoDate,
      dataIndex: ["periode_date", "end"],
      key: "periode_date.end",
      render: (text) => (
        <span>{text ? formatDate(text, "d MMM yyyy - HH:mm:ss") : "-"}</span>
      ),
      width: "165px",
    },
    {
      title: Text.usedQuota,
      key: "quantity.quota",
      render: (text: string, record: PromoListItem) => (
        <span>
          {record.quantity.used ? record.quantity.used : "0"} /{" "}
          {record.quantity.quota ? record.quantity.quota : "0"}
        </span>
      ),
    },
    {
      title: Text.type,
      dataIndex: "type",
      key: "type",
      render: (text) => {
        let type = "-";
        switch (text) {
          case promoTypeListState[0].id:
            type = promoTypeListState[0].name;
            break;
          case promoTypeListState[1].id:
            type = promoTypeListState[1].name;
            break;
          case promoTypeListState[2].id:
            type = promoTypeListState[2].name;
            break;
        }
        return <span>{type}</span>;
      },
      width: "150px",
    },
    {
      title: Text.source,
      dataIndex: "source",
      key: "source",
      render: (text) => <span>{text ? text : "-"}</span>,
      width: "50px",
    },
    {
      title: Text.status,
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text ? capitalizeFirstLetter(text) : "-"}</span>,
      width: "50px",
    },
    {
      title: "",
      key: "",
      render: (text: string, record: PromoListItem, index: number) => {
        return (
          <PromoOptionsView
            containerId={containerId}
            index={index}
            record={record}
            permissions={promoPermissions}
            isShown={shownPopOver === index}
            onPopoverVisibilityChange={(shownPopOver) =>
              setShownPopover(shownPopOver)
            }
            handleDeleteFn={(promo) => handleDeleteFn(promo)}
          />
        );
      },
      width: "100px",
    },
  ];

  const renderDeleteConfirmaitonMessage = useCallback(
    (promoType: string | undefined) => {
      let message = "-";
      switch (promoType) {
        case promoTypeListState[0].id:
          message = Text.deleteConfirmation.specialOffer;
          break;
        case promoTypeListState[1].id:
          message = Text.deleteConfirmation.flashDeal;
          break;
        case promoTypeListState[2].id:
          message = Text.deleteConfirmation.general;
          break;
      }
      return message;
    },
    []
  );

  return (
    <div className="promoManagementWrapper" id={Text.wrapperId}>
      {deletePromoState.onRequest && <LoadingWithMask />}
      <PromoActionAlertView
        deleteFromList={deleteFromList}
        setFilterParams={setFilterParams}
      />
      {error && (
        <AlertComponent error={error} clearFn={() => handleClearFn()} />
      )}
      <ModalConfirmation
        visible={!!showDeleteConfirmation}
        handleClose={() => {
          setShowDeleteConfirmation(false);
        }}
        buttonClick={() => {
          if (selectedPromo) {
            deletePromoAction(
              doDeletePromoRequest(selectedPromo.id, selectedPromo.type)
            );
            setDeleteFromList(true);
          }
        }}
        message={renderDeleteConfirmaitonMessage(selectedPromo?.type)}
        type={TypeDeleteConfirmation}
        wrapClassName={"promoWrapper"}
      />
      <div className="promoManagementFilterWrapper">
        <PromoFilterView
          filterParams={filterParams}
          onRequestListAction={handleRequestListAction}
        />
        {promoPermissions.isAllowedCreatePromo && (
          <OvalButton
            type="primary"
            buttonText={Text.addNew}
            icon={<PlusOutlined />}
            onClick={() => handleCreatePromo()}
          />
        )}
      </div>
      <AppTable
        loading={{
          spinning: tableLoading,
          indicator: <GetMethodLoading />,
          wrapperClassName: "tableLoading",
        }}
        pageSize={promoListData?.pagination?.limit}
        total={promoListData?.pagination?.total}
        current={promoListData?.pagination?.page}
        onRequestData={(currentPage, pageSize) => {
          const newQuery = {
            ...filterParams,
            page: currentPage,
            limit: pageSize,
          };
          handleRequestListAction(defaultRetrieveAll, newQuery);
        }}
        columns={columns}
        dataSource={promoListData?.data}
        rowKey={(record) => record.id}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "rowWhite" : "rowBlue"
        }
      />
    </div>
  );
}

interface PromoActionAlertProp {
  deleteFromList: boolean;
  setFilterParams: Dispatch<SetStateAction<PromoListQuery>>;
}

function PromoActionAlertView(props: PromoActionAlertProp) {
  const {deleteFromList, setFilterParams} = props;
  const createPromoAction = useDispatch<Dispatch<CreatePromoAction>>();
  const createPromoState = useSelector(
    (state: RootStoreState) => state.createPromo
  );
  const deletePromoAction = useDispatch<Dispatch<DeletePromoAction>>();
  const deletePromoState = useSelector(
    (state: RootStoreState) => state.deletePromo
  );
  const promoManagementAction = useDispatch<Dispatch<PromoManagementAction>>();
  const [success, setSuccess] = useState<string | null>("");
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const handleClearFn = useCallback(() => {
    setSuccess(null);
    if (deleteSuccess && deleteFromList) {
      setDeleteSuccess(false);
      promoManagementAction(
        doPromoManagementRequest(defaultRetrieveAll, defaultQuery)
      );
      setFilterParams(defaultQuery);
    }
  }, [deleteSuccess, promoManagementAction, deleteFromList, setFilterParams]);
  useEffect(() => {
    if (createPromoState.success === true) {
      createPromoAction(doCreatePromoInitial());
      setSuccess("Create new promo is success");
    } else if (deletePromoState.success === true) {
      deletePromoAction(doDeletePromoInitial());

      let message = "";
      switch (deletePromoState.promoType) {
        case promoTypeListState[0].id: {
          message = "Delete special offer is success";
          break;
        }
        case promoTypeListState[2].id: {
          message = "Delete promo code general is success";
          break;
        }
      }

      setSuccess(message);
      setDeleteSuccess(true);
    }
  }, [
    createPromoAction,
    createPromoState,
    deletePromoAction,
    deletePromoState,
  ]);

  return (
    <div>
      {success && (
        <AlertComponent
          message={success}
          type={"success"}
          timeout={3000}
          clearFn={() => handleClearFn()}
        />
      )}
    </div>
  );
}

export default PromoManagementView;
