import BrandLogo from 'images/BrandLogo.svg';
import cms404 from 'images/404.svg';
import DownOutlined from 'images/DownOutlined.svg';
import UpOutlined from 'images/UpOutlined.svg';
import LogoutOutlined from 'images/LogoutOutlined.svg';
import UserOutlined from 'images/UserOutlined.svg';
import UserManagement from 'images/UserManagement.svg';
import OrderManagement from 'images/OrderManagement.svg';
import ProductManagement from 'images/ProductManagement.svg';
import PromoEventManagement from 'images/PromoEventManagement.svg';
import LogoLoginHeader from 'images/LogoLoginHeader.png';
import TreeLoad from 'images/TreeLoad.png';
import OvalLoad from 'images/OvalLoad.svg';
import Close from 'images/Close.svg';
import CloseWhite from 'images/CloseWhite.svg';
import EyeBlue from 'images/EyeBlue.svg';
import PencilBlue from 'images/PencilBlue.svg';
import Delete from 'images/Delete.svg';
import Dots from 'images/Dots.svg';
import TreeOpen from 'images/TreeOpen.svg';
import TreeClose from 'images/TreeClose.svg';
import DeleteWhite from 'images/DeleteWhite.svg';
import PencilWhite from 'images/PencilWhite.svg';
import Cancel from 'images/Cancel.svg';
import SearchGrey from 'images/SearchGrey.svg';
import ChevronDownGrey from 'images/ChevronDownGrey.svg';
import ChevronUpGrey from 'images/ChevronUpGrey.svg';
import DownloadWhite from 'images/DownloadWhite.svg';
import Zoom from 'images/Zoom.svg';
import Warning from 'images/Warning.svg';
import SessionExpired from 'images/SessionExpired.svg';
import Timeout from 'images/Timeout.svg';
import NotFound from 'images/NotFound.svg';
import DefaultPDF from 'images/DefaultPDF.svg';
import ArrowLeft from 'images/ArrowLeft.svg';
import ArrowRight from 'images/ArrowRight.svg';
import SendActivation from 'images/SendActivation.svg';
import ProdiaLogo from 'images/ProdiaLogo.png';
import Clipboard from 'images/Clipboard.svg';

const images = {
    DefaultPDF,
    NotFound,
    Timeout,
    SessionExpired,
    Warning,
    Zoom,
    DownloadWhite,
    cms404,
    SearchGrey,
    BrandLogo,
    DownOutlined,
    UpOutlined,
    LogoutOutlined,
    UserOutlined,
    UserManagement,
    OrderManagement,
    ProductManagement,
    PromoEventManagement,
    LogoLoginHeader,
    TreeLoad,
    OvalLoad,
    Close,
    CloseWhite,
    EyeBlue,
    PencilBlue,
    Delete,
    Dots,
    TreeOpen,
    TreeClose,
    DeleteWhite,
    PencilWhite,
    Cancel,
    ChevronDownGrey,
    ChevronUpGrey,
    ArrowLeft,
    ArrowRight,
    SendActivation,
    ProdiaLogo,
    Clipboard,
};

export default images;
