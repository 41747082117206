import { BranchListQuery } from "common/States";
import { BranchFormData } from "data/Models";
import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";

const OutletApi = {
    getOutletList (queryParams: BranchListQuery): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Outlet.Base)
            .setQueryParameter(queryParams)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    branchList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    getOutletDetail (branchId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Outlet.Base}/${branchId}`)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    branchDetail: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    updateOutlet (branchId: string, data: BranchFormData): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Outlet.Base}/${branchId}`)
            .setBody(data)
            .doPut()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true };
            }))
            .catch(error => http.handleCatch(error));
    }
};

export default OutletApi;
