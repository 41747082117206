import React from "react";
import {ContractDetail} from "data/Models";

export const Text = {
  promoType: "Promo Type",
  source: "Source",
  promoCode: "Promo Code",
  no: "No",
  contractId: "Contract ID",
  contractList: "Contract List",
  contractName: {
    id: "Contract Name (ID)",
    en: "Contract Name (EN)",
  },
  testName: {
    id: "Test Name (ID)",
    en: "Test Name (EN)",
  },
  discount: "Discount (%)",
  branches: "Branches",
  used: "Used",
  quota: "Quota",
  title: {
    id: "Title (ID)",
    en: "Title (EN)",
  },
  image: "Image Display",
  description: {
    id: "Description (ID)",
    en: "Description (EN)",
  },
  tnc: {
    id: "Terms and Conditions (ID)",
    en: "Terms and Conditions (EN)",
  },
  periodeDate: {
    start: "Start Promo Date",
    end: "End Promo Date",
  },
  publishDate: {
    start: "Start Publish Date",
    end: "End Publish Date",
  },
  wrapperId: "contractListWrapperId",
  contractDate: {
    start: "Start Contract",
    end: "End Contract",
  },
  branch: "Branch",
  submit: "SUBMIT",
  cancel: "Cancel",
};

export interface PromoFormValue {
  promoType?: string;
  source?: string;
  promoCode?: string;
  contractId?: string;
  contracts?: ContractDetail[];
  titleId?: string;
  titleEn?: string;
  used?: number;
  quota?: number;
  coverImage?: {
    image?: string;
    from?: "local" | "network";
    error?: string;
  };
  descriptionId?: string;
  descriptionEn?: string;
  tncId?: string;
  tncEn?: string;
  periodeDateStart?: any;
  periodeDateEnd?: any;
  publishDateStart?: any;
  publishDateEnd?: any;
}

export function PeriodDateDivider() {
  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        margin: 10,
        width: 25,
        height: 2,
        border: "1px solid #212020",
      }}
    />
  );
}
