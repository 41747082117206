import { call, put, takeLatest } from 'redux-saga/effects';
import PanelApi from 'data/api/PanelApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './PanelManagementAction';
import { FilterParamMapper } from 'common/CommonUtils';

function* panelManagementTask(panelManagementAction: action.PanelManagementRequest) {
    const { filterParams } = panelManagementAction;
    let queryParams = FilterParamMapper(filterParams);
    const response = yield call(PanelApi.getPanelList, queryParams);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doPanelManagementSuccess(response.data.panelList));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doPanelManagementRequest(queryParams) });
            yield put(action.doPanelManagementFailure(newTimeoutError));
        } else {
            yield put(action.doPanelManagementFailure(response.error));
        }
    }
}
export function* panelManagementSaga() {
    yield takeLatest(
        action.PANEL_MANAGEMENT_REQUEST,
        panelManagementTask,
    );
}
