import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { TableIncrementNumber } from 'common/CommonUtils';
import { DefaultQueryType, defaultQuery } from 'common/constans';
import { formatCurrency } from 'common/CommonUtils';
import AppTable from 'components/AppTableComponent';
import AlertComponent from 'components/AlertComponent';
import { GetMethodLoading } from 'components/LoadingComponent';
import { SearchBox } from 'components/FilterComponent';
import { PanelListItem, HttpError } from 'data/Models';
import { FrontendRoutes } from 'navigation/Routes';
import RootStoreState from 'rootStore';
import { Images } from 'themes';
import { doUpdatePanelInitial, UpdatePanelAction } from '../PanelEdit/PanelEditAction';
import { doPanelManagementRequest, PanelManagementAction, doPanelManagementInitial } from './PanelManagementAction';
import { doGetProfileInitial, GetProfileAction } from 'features/scaffold/ScaffoldAction';
import './index.css';

const Text = {
    wrapperId: 'panelManagementWrapperId',
    searchPlaceHolder: "Search",
    tableColumnHeader: {
        no: "No",
        code: "Code Test",
        name: "Panel Name",
        cost: "Cost",
        status: "Status",
    },
    status: {
        active: "Active",
        inactive: "Inactive"
    },
    popOverMenu: {
        viewDetail: 'View Detail',
        edit: 'Edit',
    }
};

interface PanelPermissions {
    isAllowedShowList: boolean;
    isAllowedViewDetail: boolean;
    isAllowedEdit: boolean;
}

function PanelManagementView() {
    const history = useHistory();
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];

    const panelPermissions = {
        isAllowedShowList: isPermissionAllowed(FrontendRoutes.PanelManagement.permissionKey, permissions),
        isAllowedViewDetail: isPermissionAllowed(FrontendRoutes.PanelDetailPage.permissionKey, permissions),
        isAllowedEdit: isPermissionAllowed(FrontendRoutes.PanelEditPage.permissionKey, permissions),
    };
    const showDots = panelPermissions.isAllowedViewDetail || panelPermissions.isAllowedEdit;
    const [error, setError] = useState<HttpError | null>(null);
    const panelManagementAction = useDispatch<Dispatch<PanelManagementAction>>();
    const panelListState = useSelector((state: RootStoreState) => state.panelManagement);
    const panelListData = panelListState.data;
    const [shownPopOver, setShownPopover] = useState<number | null>(null);
    const [filterParams, setFilterParams] = useState<DefaultQueryType>(defaultQuery);
    const containerId = document.getElementById(Text.wrapperId);

    useEffect(() => {
        if (panelPermissions.isAllowedShowList) {
            panelManagementAction(doPanelManagementRequest(defaultQuery));
        }
    }, [panelManagementAction, panelPermissions.isAllowedShowList]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
        if (panelListState.error) {
            panelManagementAction(doPanelManagementInitial());
            setError(panelListState.error);
        }
    }, [panelManagementAction, panelListState]);
    const tableLoading: boolean = profileState.onRequest || panelListState.onRequest;

    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const handleRequestListAction = (query: DefaultQueryType) => {
        setShownPopover(null);
        setFilterParams(query);
        panelManagementAction(doPanelManagementRequest(query));
    };
    const handleSearchByKeyword = (keyword: string) => {
        const newQuery = Object.assign({}, filterParams, { keyword: keyword, page: defaultQuery.page });
        handleRequestListAction(newQuery);
    };
    const handleHistoryPush = useCallback((path: string) => {
        history.push(path);
    }, [history]);
    const handleShowPopOver = useCallback((isShown, index) => {
        let shownPopOver = null;
        if (!isShown) {
            shownPopOver = index;
        }

        setShownPopover(shownPopOver);
    }, []);

    const columns: ColumnsType<PanelListItem> = [
        {
            title: Text.tableColumnHeader.no,
            dataIndex: '',
            key: '',
            render: (text, row, index: number) => {
                return (
                    <span className="indexStyle">{TableIncrementNumber(filterParams.page, filterParams.limit, index)}</span>
                );
            },
            width: '5%',
        },
        {
            title: Text.tableColumnHeader.code,
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                const cell = (<span className="nameStyle">{text}</span>);

                if (panelPermissions.isAllowedViewDetail) {
                    return (
                        <Link to={{pathname: FrontendRoutes.PanelDetailPage.path.replace(':panelId', record.id)}}>
                            {cell}
                        </Link>
                    )
                }

                return cell;
            },
        },
        {
            title: Text.tableColumnHeader.name,
            dataIndex: 'name',
            key: 'name',
            render: (text) => (<span>{text}</span>),
        },
        {
            title: Text.tableColumnHeader.cost,
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => (
                <span>{record.price.actual && formatCurrency(record.price.actual)}</span>
            ),
        },
        {
            title: Text.tableColumnHeader.status,
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                const status = record.is_active ? Text.status.active : Text.status.inactive;
                return (<span>{status}</span>)
            },
        },
        {
            title: '',
            key: '',
            render: (text, record, index) => {
                const content = PopOverContent(record, panelPermissions, handleHistoryPush);
                const isShown = shownPopOver === index;
                
                let popupContainer = document.body;
                if (containerId) {
                    popupContainer = containerId;
                }

                return (
                    <div>
                        <Popover
                            content={content}
                            visible={isShown}
                            trigger="click"
                            placement="bottomRight"
                            overlayClassName={'appActionGroupPopOver'}
                            getPopupContainer={() => popupContainer}
                        >
                            <div className="panelManagementDotsWrapper">
                                {showDots && (
                                    <div
                                        className="panelManagementDots"
                                        onClick={() => handleShowPopOver(isShown, index)
                                    }>
                                        <img src={Images.Dots} alt="Dots" />
                                    </div>
                                )}
                            </div>
                        </Popover>
                    </div>
                );
            },
            width: '141px',
        },
    ];

    return (
        <div className="panelManagementWrapper" id={Text.wrapperId}>
            {error && (<AlertComponent error={error} clearFn={() => handleClearFn()} />)}
            <PanelActionAlertView />
            <div className="panelManagemenHeader">
                <SearchBox placeholder={Text.searchPlaceHolder} onSearch={handleSearchByKeyword}/>
            </div>
            <AppTable
                loading={{
                    spinning: tableLoading,
                    indicator: <GetMethodLoading />,
                    wrapperClassName: 'tableLoading'
                }}
                pageSize={panelListData?.pagination?.limit}
                total={panelListData?.pagination?.total}
                current={panelListData?.pagination?.page}
                onRequestData={(currentPage, pageSize) => {
                    const newQuery = Object.assign({}, filterParams, {
                        page: currentPage,
                        limit: pageSize,
                    });
                    handleRequestListAction(newQuery);
                }}
                columns={columns}
                dataSource={panelListData?.data}
                rowKey={record => record.id}
                rowClassName={(record, index) => index%2 === 0 ? 'rowWhite' : 'rowBlue'}
            />
        </div>
    );
}

function PopOverContent(
    record: PanelListItem,
    panelPermissions: PanelPermissions,
    navigateTo: (path: string) => void
) {
    const options = [
        {
            clickFn: () => navigateTo(FrontendRoutes.PanelDetailPage.path.replace(':panelId', record.id)),
            imgSrc: Images.EyeBlue,
            text: Text.popOverMenu.viewDetail,
            isAllowed: panelPermissions.isAllowedViewDetail,
        },
        {
            clickFn: () => navigateTo(FrontendRoutes.PanelEditPage.path.replace(':panelId', record.id)),
            imgSrc: Images.PencilBlue,
            text: Text.popOverMenu.edit,
            isAllowed: panelPermissions.isAllowedEdit,
            popOverItemClass: 'edit',
        },
    ];

    const popOverContentItems = options.map((props) => {
        const { clickFn, imgSrc, text, isAllowed, popOverItemClass } = props;
        
        if (isAllowed) {
            return (
                <div key={text} className={`appPopOverItem ${popOverItemClass}`} onClick={clickFn}>
                    <img src={imgSrc} alt={text} />
                    <span>{text}</span>
                </div>
            );
        }
    
        return null;
    });

    return (
        <div className="appPopOverContent">
            {popOverContentItems}
        </div>
    );
}

function PanelActionAlertView() {
    const updatePanelAction = useDispatch<Dispatch<UpdatePanelAction>>();
    const updatePanelState = useSelector((state: RootStoreState) => state.updatePanel);
    const [success, setSuccess] = useState<string | null>('');
    const handleClearFn = useCallback(() => {
        setSuccess(null);
    }, []);
    useEffect(() => {
        if (updatePanelState.success === true) {
            updatePanelAction(doUpdatePanelInitial());
            setSuccess('Edit panel is success');
        }
    }, [updatePanelAction, updatePanelState]);

    return (
        <div>
            {success && <AlertComponent message={success} type={'success'} timeout={3000} clearFn={() => handleClearFn()} />}
        </div>   
    );
}

export default PanelManagementView;
