interface ItemError {
  field: string;
  error: string;
}

export enum AdminAccountStatus {
  Active = "active",
  Inactive = "inactive",
  RequireActivation = "require_activation",
}

export class HttpError extends Error {
  message: string;
  code?: string | number;
  errors?: [ItemError];
  action?: () => void;

  constructor(
    code: string | number,
    message: string,
    errors?: [ItemError],
    action?: () => void
  ) {
    super(message);

    this.code = code;
    this.message = message;
    this.errors = errors;
    this.action = action;

    Object.setPrototypeOf(this, new.target.prototype);
  }

  toJSON(): string {
    return JSON.stringify({
      code: this.code,
      message: this.message,
      errors: this.errors,
    });
  }
}

export class ProfileDetail {
  name: string = "";
  id: string = "";
  email: string = "";
  permissions: [string] = [""];
  role: Role = new Role();
  outlets?: Branch[] = [];
  is_all_outlets: boolean = false;
  regions?: Region[] = [];
  is_all_regions: boolean = false;
}

export class Role {
  id: string = "";
  name: string = "";
}

export class Region {
  id: string = "";
  name: string = "";
}

export class RegionList {
  data: Region[] = [];
}

export class Branch {
  id: string = "";
  name: string = "";
  region?: Region | null = null;
  available_hs: boolean = false;
}

export class BranchListItem {
  id: string = "";
  name: string = "";
  region?: Region | null = null;
  city?: City | null = null;
  available_hs: boolean = false;
}

export class BranchList {
  pagination: Pagination = new Pagination();
  data: Branch[] = [];
}

export class BranchDetailTest {
  id: string = "";
  name: string = "";
}

export class BranchDetail {
  id: string = "";
  name: string = "";
  address: string = "";
  phone: string = "";
  email: string = "";
  region: Region = new Region();
  city: City = new City();
  schedule: BranchSchedule[] = [];
  facilities: BranchFacility[] = [];
  tests: BranchDetailTest[] = [];
  days_off: string[] = [];
  panels: {id: string; name: string}[] = [];
  updated_at: string = "";
  updated_by: {id: string; name: string} = {id: "", name: ""};
}

export class BranchFacility {
  id: number = 0;
  category: FacilityCategory = new FacilityCategory();
  name: string = "";
  name_en: string = "";
  note: string = "";
  note_en: string = "";
}

export class FacilityCategory {
  id: number = 0;
  name: string = "";
  name_en: string = "";
}

export class BranchSchedule {
  day: string = "";
  open: string = "";
  close: string = "";
  note: string = "";
  note_en: string = "";
  timezone: string = "";
}

export class UserListItem {
  id: string = "";
  name: string = "";
  email: string = "";
  role: Role = new Role();
  is_active: boolean = false;
  outlets?: Branch[] = [];
  is_all_outlets: boolean = false;
  regions?: Region[] = [];
  is_all_regions: boolean = false;
}

export class UserList {
  pagination: Pagination = new Pagination();
  data: UserListItem[] = [];
}

export class UserFormData {
  id?: string | null = null;
  fullName: string = "";
  email: string = "";
  regions: string[] = [];
  branchs: string[] = [];
  role: string = "";
  status?: AdminAccountStatus;
}

export class RoleList {
  pagination: Pagination = new Pagination();
  data: Role[] = [];
}

export class PromoListItem {
  id: string = "";
  title: string = "";
  type: string = "";
  code: string = "";
  periode_date: PeriodDate = new PeriodDate();
  quantity: Quantity = new Quantity();
  status: string = "";
}

export class PeriodDate {
  start: string = "";
  end: string = "";
}

class Quantity {
  quota: number = 0;
  used: number = 0;
}

export class PromoList {
  pagination: Pagination = new Pagination();
  data: PromoListItem[] = [];
}

export class Pagination {
  limit: number = 1;
  total: number = 20;
  page: number = 20;
}

export class CreatedBy {
  id: string = "";
  name: string = "";
}

export class UpdatedBy {
  id: string = "";
  name: string = "";
}

export class PromoDetailTest {
  id: string = "";
  name: string = "";
  name_en: string = "";
}

export class PromoDetailContract {
  id: string = "";
  name: string = "";
  name_en: string = "";
  discount: number = 0;
  alias: string = "";
  outlets: string[] = [];
  tests: PromoDetailTest[] = [];
}

export class PromoDetail {
  id: number = 1;
  source: string = "";
  title: string = "";
  title_en: string = "";
  description: string = "";
  description_en: string = "";
  tnc: string = "";
  tnc_en: string = "";
  cover_image: string = "";
  contract?: PromoDetailContract = new PromoDetailContract();
  contracts?: ContractDetail[] = [];
  promo_code: string = "";
  quantity: Quantity = new Quantity();
  periode_date: PeriodDate = new PeriodDate();
  publish_date: PeriodDate = new PeriodDate();
  created_at: string = "";
  created_by: CreatedBy = new CreatedBy();
  updated_at: string = "";
  updated_by: UpdatedBy = new UpdatedBy();
  status: string = "";
}

export class RoleDetail {
  id: string = "";
  name: string = "";
  permission_group: PermissionGroup[] = [];
  is_active?: boolean | null = false;
  created_at: string = "";
  created_by: CreatedBy = new CreatedBy();
  updated_at: string = "";
  updated_by: UpdatedBy = new UpdatedBy();
}

export class AdminDetail {
  id: string = "";
  name: string = "";
  email: string = "";
  role: Role = new Role();
  outlets?: Branch[] = [];
  is_all_outlets: boolean = false;
  regions?: Region[] = [];
  is_all_regions: boolean = false;
  is_active?: boolean | null = false;
  activation_pin: string = "";
  created_at: string = "";
  created_by: CreatedBy = new CreatedBy();
  updated_at: string = "";
  status: AdminAccountStatus = AdminAccountStatus.RequireActivation;
  updated_by: UpdatedBy = new UpdatedBy();
}

export class RoleFormData {
  id?: string = "";
  name: string = "";
  permissions: any[] = [];
  isActive: boolean = false;
}

export class PermissionGroup {
  id: string = "";
  name: string = "";
  permissions: Permission[] = [];
}

export class Permission {
  id: string = "";
  name: string = "";
}

class Price {
  original: number = 0;
  actual: number = 0;
  discount?: number = 0;
}

export class PanelListItem {
  id: string = "";
  name: string = "";
  price: Price = new Price();
  is_active: boolean = false;
}

export class PanelList {
  pagination: Pagination = new Pagination();
  data: PanelListItem[] = [];
}

export class preparations {
  id: string = "";
  description: string = "";
  description_en: string = "";
}

export class Condition {
  code: string = "";
  name: string = "";
  name_en: string = "";
}

export class Requirement {
  referral: boolean = false;
  age_min: number = 0;
  age_max: number = 0;
  sex: string = "";
}

export class PanelTestItem {
  id: string = "";
  name: string = "";
  name_en: string = "";
  preparations: preparations[] = [];
}

export class PanelDetail {
  id: string = "";
  name: string = "";
  name_en: string = "";
  benefit: string = "";
  benefit_en: string = "";
  description: string = "";
  description_en: string = "";
  price: Price = new Price();
  tests: PanelTestItem[] = [];
  types: string[] = [];
  condition: Condition = new Condition();
  requirement: Requirement = new Requirement();
  is_active: boolean = false;
}

export class PanelFormData {
  id: string = "";
  types: string[] = [];
  beginAge: number = 0;
  endAge: number = 0;
  isActive: boolean = false;
}

export class TestListItem {
  id: string = "";
  name: string = "";
  price: Price = new Price();
  is_active: boolean = false;
  available_hs: boolean = false;
}

export class HomeServiceListItem {
  id: string = "";
  name: string = "";
  price: Price = new Price();
  is_active: boolean = false;
}

export class TestList {
  pagination: Pagination = new Pagination();
  data: TestListItem[] = [];
}

export class HomeServiceList {
  pagination: Pagination = new Pagination();
  data: HomeServiceListItem[] = [];
}

export class TNCDetail {
  checkout: string = "";
  detail: string = "";
  summary: string = "";
}

export class PreparationsItem {
  id: string = "";
  description: string = "";
  description_en: string = "";
}

export class Category {
  code: string = "";
  name: string = "";
  name_en: string = "";
}

export class TestDetail {
  id: string = "";
  name: string = "";
  name_en: string = "";
  benefit: string = "";
  benefit_en: string = "";
  keyword: string = "";
  keyword_en: string = "";
  description: string = "";
  description_en: string = "";
  preparations: PreparationsItem[] = [];
  price: Price = new Price();
  types: string[] = [];
  category: Category = new Category();
  requirement: Requirement = new Requirement();
  is_active: boolean = false;
}

export class TestFormData {
  id: string = "";
  types: string[] = [];
  beginAge: number = 0;
  endAge: number = 0;
  isActive: boolean = false;
}

export class City {
  id: string = "";
  name: string = "";
}
export class Customer {
  id: string = "";
  name: string = "";
  phone: string = "";
  email: string = "";
  dob?: string = "";
  sex?: string = "";
  city?: City = new City();
  address?: string = "";
  title?: string = "";
}

export class OrderHomeService {
  address_hs?: string = "";
  agent_id?: string = "";
  agent_name?: string = "";
  catatan_alamat?: string = "";
  date_request?: string = "";
  date_schedule?: string = "";
  home_service_charge: number = 0;
  service_charge?: number = 0;
  apd_fee?: number = 0;
  transport_fee?: number = 0;
  hs_hour?: string = "";
  note_hs?: string = "";
  result_test_at_branch?: string = "";
  service_type?: boolean;
  amount_ppn?: number = 0;
  amount_ppn_free?: number = 0;
  amount_ppn_levied?: number = 0;
}

export class OrderAgentInformation {
  agent_name?: string = "";
  agent_id?: string = "";
  scheduled_date_time?: string = "";
  on_process_date_time?: string = "";
  done_date_time?: string = "";
  completed_date_time?: string = "";
  cancelled_date_time?: string = "";
}

export class OrderRescheduleInformation {
  reschedule_date_time?: string = "";
  changes_date_time?: string = "";
  status_reschedule?: string = "";
  reason_reschedule?: string = "";
}

export class Channel {
  id: string = "";
  name: string = "";
  name_en: string = "";
}

export class Method {
  id: string = "";
  name: string = "";
  name_en: string = "";
}

export class PaymentItem {
  method: Method = new Method();
  channel: Channel = new Channel();
  bank_name: string = "";
  account_no: string = "";
  expired_at: string = "";
  confirmed_at: string = "";
  reference_id?: string = "";
}

export class PaymentItemForOrderList {
  channel: string = "";
  bank_name: string = "";
  account_no: string = "";
  expired_at: string = "";
  confirmed_at: string = "";
}

export class Outlet {
  id: string = "";
  name: string = "";
}

export class Doctor {
  id: string = "";
  name: string = "";
  speciality_id: string = "";
}

export class OrderListItem {
  id: string = "";
  status: string = "";
  created_at: string = "";
  updated_at: string = "";
  note: string = "";
  customer: Customer = new Customer();
  amount: Price = new Price();
  payment: PaymentItemForOrderList = new PaymentItemForOrderList();
  doctor: Doctor = new Doctor();
  outlet: Outlet = new Outlet();
  hardcopy_result: boolean | null = null;
  homeservice: OrderHomeService = new OrderHomeService();
}

export class OrderList {
  pagination: Pagination = new Pagination();
  data: OrderListItem[] = [];
}

export class PaymentChannel {
  id: string = "";
  name: string = "";
  code: string = "";
  installment: boolean = false;
  method: string = "";
  tenor: number[] = [];
}

export class PaymentChannelList {
  data: PaymentChannel[] = [];
}

export class OrderListDownload {
  blob: any;
  filename?: string;
}

export class OrderDetailDownload {
  blob: any;
  filename?: string;
}

export class Discounts {
  type: string = "";
  type_id: string = "";
  code: string = "";
  name: string = "";
  amount: number = 0;
}

export class Product {
  id: string = "";
  type: string = "";
  name: string = "";
}

export class OrderItems {
  id: string = "";
  product: Product = new Product();
  price: Price = new Price();
  discount_id: string = "";
  contract_id: string = "";
  quantity: number = 0;
}

export class Referral {
  type: string = "";
  doctor: Doctor = new Doctor();
  city: City = new City();
  address: string = "";
  proof_url: string = "";
}

export class Appointment {
  date: string = "";
  outlet: Outlet = new Outlet();
  region: Region = new Region();
  hardcopy_result: boolean | null = null;
}

export class OrderHistory {
  id: string = "";
  status: string = "";
  note: string = "";
  created_at: string = "";
  hs_date: string = "";
  agent_id: string = "";
  agent_name: string = "";
}

export class OrderDetail {
  id: string = "";
  order_number: string = "";
  status: string = "";
  booking_code: string = "";
  created_at: string = "";
  updated_at: string = "";
  note: string = "";
  customer: Customer = new Customer();
  amount: Price = new Price();
  payment: PaymentItem = new PaymentItem();
  discounts: Discounts[] = [];
  items: OrderItems[] = [];
  referral: Referral = new Referral();
  appointment: Appointment = new Appointment();
  history: OrderHistory[] = [];
  home_service: OrderHomeService = new OrderHomeService();
  agent: OrderAgentInformation = new OrderAgentInformation();
  reschedule: OrderRescheduleInformation = new OrderRescheduleInformation();
}

export class OrderDetailItems {
  id: string = "";
  product: Product = new Product();
}

export class ContractList {
  pagination: Pagination = new Pagination();
  data: string[] = [];
}

export class ContractDetail {
  id: string = "";
  name: string = "";
  name_en: string = "";
  alias: string = "";
  alias_en: string = "";
  discount: number = 0;
  price: number = 0;
  start_date: string = "";
  end_date: string = "";
  periode_date: PeriodDate = new PeriodDate();
  outlets: Branch[] = [];
  tests: TestDetail[] = [];
  panels: PanelDetail[] = [];
}

export class PromoFormData {
  id?: string | null = null;
  promoType: string = "";
  source: string = "";
  promoCode?: string = "";
  title: string = "";
  titleEn: string = "";
  description: string = "";
  descriptionEn: string = "";
  tnc: string = "";
  tncEn: string = "";
  contractId?: string = "";
  contractIds?: string = "";
  aliases?: {id: string; alias: string; alias_en: string}[] = [];
  quota: number = 0;
  periodeDate: PeriodDate = new PeriodDate();
  publishDate: PeriodDate = new PeriodDate();
  coverImage?: any;
}

export class BranchFormData {
  id: string = "";
  tests: string[] = [];
  panels: string[] = [];
  days_off: string[] = [];
}

type Second = number;

export class TimeoutConfig {
  idleDelayTimer: Second = 0;
  idleGracePeriodConfirmation: Second = 0;
  idleTimeout: Second = 0;
}
