import { call, put, takeLatest } from 'redux-saga/effects';
import RoleApi from 'data/api/RoleApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './RoleCreateAction';

function* createRoleTask(createRoleAction: action.CreateRoleRequest) {
    const { newData } = createRoleAction;
    const response = yield call(RoleApi.createRole, newData);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doCreateRoleSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doCreateRoleRequest(newData) });
            yield put(action.doCreateRoleFailure(newTimeoutError));
        } else {
            yield put(action.doCreateRoleFailure(response.error));
        }
    }
}
export function* createRoleSaga() {
    yield takeLatest(
        action.CREATE_ROLE_REQUEST,
        createRoleTask,
    );
}
