import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';
import Navigation from 'navigation/Navigation';
import { AuthStorage } from 'data/local/AuthStorage';
import sessionTimeManager, { sessionTimeConfigStore, listenUserActivity } from 'lib/SessionTimeManager';
import { OvalButton } from 'components/ButtonComponent';
import { ModalProdia } from 'components/ModalComponent';
import AuthApi from 'data/api/AuthApi';
import { HttpError } from 'data/Models';
import AlertComponent from 'components/AlertComponent';

const { Content } = Layout;

// Set this to true/or false to toggle session time feature
// todo: move this into .env file? or just remove this flag
const isUseSessionTime = true;

const Text = {
    stay: 'STAY LOGGED IN',
    logout: 'LOGOUT',
};

function App() {
    const [isAuthInited, setIsAuthInited] = useState<boolean>(false);
    const [isConfirmModalShowing, setIsConfirmModalShowing] = useState<boolean>(false);
    const [error, setError] = useState<HttpError | null>(null);

    const handleStay = useCallback(() => {
        sessionTimeManager.refreshSession();
    }, []);
    const handleLogout = useCallback(() => {
        sessionTimeManager.endSession();
    }, []);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);

    const handleInitSessionTime = useCallback(() => {
        const config = sessionTimeConfigStore.get();

        if (!config) {
            AuthStorage.logout();
            window.location.href = '/login';
            return;
        };

        const ONE_SECOND_IN_MS = 1000;
        const timerConfig = {
            idleDelayTimerInMs: config.idleDelayTimer * ONE_SECOND_IN_MS,
            idleGracePeriodConfirmationInMs: config.idleGracePeriodConfirmation * ONE_SECOND_IN_MS,
            idleTimeoutInMs: config.idleTimeout * ONE_SECOND_IN_MS,
        };
        sessionTimeManager.setTimers(timerConfig);
        sessionTimeManager.on('idle_timeout', () => {
            setIsConfirmModalShowing(true);
        });
        sessionTimeManager.on('refresh_session', () => {
            setIsConfirmModalShowing(false);
        });

        // This listener will be called upon:
        // menu logout click, logout button on modal, and the session timer time out
        sessionTimeManager.on('end_session', (event) => {
            setIsConfirmModalShowing(false);
            Promise.resolve()
                .then(()=> {
                    // If it's not triggered by this window or it's triggered when the session is not valid (401)
                    // Then don't call logout api
                    if (!event.triggeredByThisWindow || !event.isSessionValid) {
                        return;
                    }

                    // The logout api will only be called by 
                    // the window which triggering it
                    return AuthApi.logout();
                })
                .then(() => {
                    AuthStorage.logout();
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 500);
                })
                .catch((error) => {
                    setError(error);
                });
        });
        const isLoggingEnabled = process.env.REACT_APP_SESSION_TIMER_LOGGING === 'true';
        const tickFn = isLoggingEnabled ? console.log : () => {};
        sessionTimeManager.start(tickFn);
    }, []);

    useEffect(() => {
        // The auth initialization is an async operation
        // If the auth storage strategy is InMemoryAuthStorage, it waits for the token to be resolved
        // We make the app renders nothing until auth initialization is finished
        // This is needed to make the synchronous auth checking (private route etc) within the route works.
        AuthStorage.init((token) => {
            setIsAuthInited(true);
            if (!token || !isUseSessionTime) return;
            handleInitSessionTime();
            listenUserActivity();
        });
    }, [handleInitSessionTime]);

    if (!isAuthInited) return null;

    return (
        <Router>
            <>
                {error && <AlertComponent error={error} clearFn={handleClearFn} />}
                {isConfirmModalShowing && (
                    <ModalProdia
                        visible={isConfirmModalShowing}
                        footer={
                            <>
                                <OvalButton wrapperClassName="ovalButtonNeutral" key="back" type="ghost" onClick={handleLogout} buttonText={Text.logout} />
                                <OvalButton key="submit" type="primary" onClick={handleStay} buttonText={Text.stay} />
                            </>
                        }
                    >
                        <>
                            <p className="contentTitle">Your Session Has Timed Out</p>
                            <p className="contentText">Please wake up, you will be timed out and force to logout.<br />Do you want to stay?</p>
                        </>
                    </ModalProdia>
                )}
            </>
            <Layout style={{ height: "100%", overflow: "auto" }}>
                <Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Navigation />
                </Content>
            </Layout>
        </Router>
    );
}

export default App;
