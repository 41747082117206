import LoginView from 'features/login/LoginView';
import AccountActivation from 'features/account/AccountActivation/AccountActivationView';
import DashboardPageView from 'features/dashboard/DashboardView';
import NotFoundPageView from 'features/notFoundPage/NotFoundPageView';
import AdminManagementView from 'features/user/AdminManagement/AdminManagementView';
import AdminCreateView from 'features/user/AdminCreate/AdminCreateView';
import AdminDetailView from 'features/user/AdminDetail/AdminDetailView';
import RoleManagementView from 'features/user/RoleManagement/RoleManagementView';
import RoleDetailView from 'features/user/RoleDetail/RoleDetailView';
import RoleCreateView from 'features/user/RoleCreate/RoleCreateView';
import RoleEditView from 'features/user/RoleEdit/RoleEditView';
import OrderManagementView from 'features/order/OrderManagement/OrderManagementView';
import OrderDetailView from 'features/order/OrderDetail/OrderDetailView';
import TestManagementView from 'features/product/test/TestManagement/TestManagementView';
import TestDetailView from 'features/product/test/TestDetail/TestDetailView';
import PanelManagementView from 'features/product/panel/PanelManagement/PanelManagementView';
import PanelDetailView from 'features/product/panel/PanelDetail/PanelDetailView';
import PromoManagementView from 'features/promo/PromoManagement/PromoManagementView';
import PromoDetailView from 'features/promo/PromoDetail/PromoDetailView';
import * as BreadCrumbs from 'navigation/BreadCrumbs';
import { ScreenType } from 'common/constans';
import { FrontendRoutes } from './Routes';
import AdminEditView from 'features/user/AdminEdit/AdminEditView';
import PanelEditView from 'features/product/panel/PanelEdit/PanelEditView';
import TestEditView from 'features/product/test/TestEdit/TestEditView';
import PromoCreateView from 'features/promo/PromoCreate/PromoCreateView';
import PromoEditView from 'features/promo/PromoEdit/PromoEditView';
import BranchManagementView from 'features/branch/BranchManagement/BranchManagementView';
import BranchDetailView from 'features/branch/BranchDetail/BranchDetailView';
import BranchEditView from 'features/branch/BranchEdit/BranchEditView';
import CreatePasswordView from 'features/account/CreatePassword/CreatePasswordView';
import TNCManagementView from 'features/homeService/tnc/TNCManagement/TNCManagementView';

const LoginPage = {
    path: FrontendRoutes.Login.path,
    component: LoginView,
    title: FrontendRoutes.Login.title,
    exact: true,
    screenType: ScreenType.DEFAULT,
};

const AccountActivationPage = {
    path: FrontendRoutes.AccountActivation.path,
    component: AccountActivation,
    title: FrontendRoutes.AccountActivation.title,
    exact: true,
    screenType: ScreenType.DEFAULT,
};

const CreatePasswordPage = {
    path: FrontendRoutes.CreatePassword.path,
    component: CreatePasswordView,
    title: FrontendRoutes.CreatePassword.title,
    exact: true,
    screenType: ScreenType.DEFAULT,
};

const DashboardPage = {
    path: FrontendRoutes.Dashboard.path,
    component: DashboardPageView,
    title: FrontendRoutes.Dashboard.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
};

const OrderManagementPage = {
    path: FrontendRoutes.OrderManagement.path,
    component: OrderManagementView,
    title: FrontendRoutes.OrderManagement.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.OrderManagement.permissionKey,
};

const OrderDetailPage = {
    path: FrontendRoutes.OrderDetailPage.path,
    component: OrderDetailView,
    title: FrontendRoutes.OrderDetailPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.OrderDetailPage.permissionKey,
    breadcrumbs: BreadCrumbs.OrderDetailPage,
};

const TestManagementPage = {
    path: FrontendRoutes.TestManagement.path,
    component: TestManagementView,
    title: FrontendRoutes.TestManagement.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.TestManagement.permissionKey,
};

const TestDetailPage = {
    path: FrontendRoutes.TestDetailPage.path,
    component: TestDetailView,
    title: FrontendRoutes.TestDetailPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.TestDetailPage.permissionKey,
    breadcrumbs: BreadCrumbs.TestDetailPage,
    breadcrumbsWithChildren: true,
};

const TestEditPage = {
    path: FrontendRoutes.TestEditPage.path,
    component: TestEditView,
    title: FrontendRoutes.TestEditPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.TestEditPage.permissionKey,
    breadcrumbs: BreadCrumbs.TestEditPage,
};

const PanelManagementPage = {
    path: FrontendRoutes.PanelManagement.path,
    component: PanelManagementView,
    title: FrontendRoutes.PanelManagement.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.PanelManagement.permissionKey,
};

const PanelDetailPage = {
    path: FrontendRoutes.PanelDetailPage.path,
    component: PanelDetailView,
    title: FrontendRoutes.PanelDetailPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.PanelDetailPage.permissionKey,
    breadcrumbs: BreadCrumbs.PanelDetailPage,
    breadcrumbsWithChildren: true,
};

const PanelEditPage = {
    path: FrontendRoutes.PanelEditPage.path,
    component: PanelEditView,
    title: FrontendRoutes.PanelEditPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.PanelEditPage.permissionKey,
    breadcrumbs: BreadCrumbs.PanelEditPage,
};

const PromoManagementPage = {
    path: FrontendRoutes.PromoManagement.path,
    component: PromoManagementView,
    title: FrontendRoutes.PromoManagement.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.PromoManagement.permissionKey,
};

const PromoDetailPage = {
    path: FrontendRoutes.PromoDetailPage.path,
    component: PromoDetailView,
    title: FrontendRoutes.PromoDetailPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.PromoDetailPage.permissionKey,
    breadcrumbs: BreadCrumbs.PromoDetailPage,
    breadcrumbsWithChildren: true,
};

const PromoCreatePage = {
    path: FrontendRoutes.PromoCreatePage.path,
    component: PromoCreateView,
    title: FrontendRoutes.PromoCreatePage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.PromoCreatePage.permissionKey,
    breadcrumbs: BreadCrumbs.PromoCreatePage,
};

const PromoEditPage = {
    path: FrontendRoutes.PromoEditPage.path,
    component: PromoEditView,
    title: FrontendRoutes.PromoEditPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.PromoDetailPage.permissionKey,
    breadcrumbs: BreadCrumbs.PromoEditPage,
};

const AdminManagementPage = {
    path: FrontendRoutes.AdminManagement.path,
    component: AdminManagementView,
    title: FrontendRoutes.AdminManagement.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.AdminManagement.permissionKey,
};

const AdminCreatePage = {
    path: FrontendRoutes.UserCreatePage.path,
    component: AdminCreateView,
    title: FrontendRoutes.UserCreatePage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.UserCreatePage.permissionKey,
    breadcrumbs: BreadCrumbs.AdminCreatePage,
};

const UserDetailPage = {
    path: FrontendRoutes.UserDetailPage.path,
    component: AdminDetailView,
    title: FrontendRoutes.UserDetailPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.UserDetailPage.permissionKey,
    breadcrumbs: BreadCrumbs.UserDetailPage,
    breadcrumbsWithChildren: true,
};

const AdminEditPage = {
    path: FrontendRoutes.UserEditPage.path,
    component: AdminEditView,
    title: FrontendRoutes.UserEditPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.UserEditPage.permissionKey,
    breadcrumbs: BreadCrumbs.AdminEditPage,
};

const RoleManagementPage = {
    path: FrontendRoutes.RoleManagement.path,
    component: RoleManagementView,
    title: FrontendRoutes.RoleManagement.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.RoleManagement.permissionKey,
};

const RoleDetailPage = {
    path: FrontendRoutes.RoleDetailPage.path,
    component: RoleDetailView,
    title: FrontendRoutes.RoleDetailPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.RoleDetailPage.permissionKey,
    breadcrumbs: BreadCrumbs.RoleDetailPage,
    breadcrumbsWithChildren: true,
};

const RoleCreatePage = {
    path: FrontendRoutes.RoleCreatePage.path,
    component: RoleCreateView,
    title: FrontendRoutes.RoleCreatePage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.RoleCreatePage.permissionKey,
    breadcrumbs: BreadCrumbs.RoleCreatePage,
};

const RoleEditPage = {
    path: FrontendRoutes.RoleEditPage.path,
    component: RoleEditView,
    title: FrontendRoutes.RoleEditPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.RoleEditPage.permissionKey,
    breadcrumbs: BreadCrumbs.RoleEditPage,
};

const BranchManagementPage = {
    path: FrontendRoutes.BranchManagement.path,
    component: BranchManagementView,
    title: FrontendRoutes.BranchManagement.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.BranchManagement.permissionKey,
};

const BranchDetailPage = {
    path: FrontendRoutes.BranchDetailPage.path,
    component: BranchDetailView,
    title: FrontendRoutes.BranchDetailPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.BranchDetailPage.permissionKey,
    breadcrumbs: BreadCrumbs.BranchDetailPage,
    breadcrumbsWithChildren: true,
};

const BranchEditPage = {
    path: FrontendRoutes.BranchEditPage.path,
    component: BranchEditView,
    title: FrontendRoutes.BranchEditPage.title,
    exact: true,
    guard: true,
    screenType: ScreenType.WITH_SCAFFOLLD,
    permissionKey: FrontendRoutes.BranchEditPage.permissionKey,
    breadcrumbs: BreadCrumbs.BranchEditPage,
};

const TNCManagementPage = {
    path: FrontendRoutes.TNCManagementPage.path,
    component: TNCManagementView,
    title: FrontendRoutes.TNCManagementPage.title,
    exact: true,
    guard: true,
    permissionKey: FrontendRoutes.TestEditPage.permissionKey,
    screenType: ScreenType.WITH_SCAFFOLLD,
    breadcrumbs: BreadCrumbs.TNCManagementPage,
};

const NotFoundPage = {
    path: FrontendRoutes.NotFoundPage.path,
    component: NotFoundPageView,
    title: FrontendRoutes.NotFoundPage.title,
    exact: false,
    screenType: ScreenType.DEFAULT,
};

export default {
	LoginPage,
    AccountActivationPage,
    CreatePasswordPage,
    DashboardPage,
    OrderManagementPage,
    OrderDetailPage,
    TestManagementPage,
    TestDetailPage,
    TestEditPage,
    PanelManagementPage,
    PanelDetailPage,
    PanelEditPage,
    PromoManagementPage,
    PromoDetailPage,
    AdminManagementPage,
    AdminCreatePage,
    AdminEditPage,
    UserDetailPage,
    RoleManagementPage,
    RoleDetailPage,
    RoleCreatePage,
    RoleEditPage,
    PromoCreatePage,
    PromoEditPage,
    BranchManagementPage,
    BranchDetailPage,
    BranchEditPage,
    TNCManagementPage,
    NotFoundPage,
};
