export const container = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 115,
};

export const header = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ffdd00',
    height: 80,
};

export const title = {
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.88,
    color: '#495057',
}

export const subtitle = {
    fontSize: 16.8,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.68,
    color: '#495057',
    marginBottom: 26.4,
}

export const row = {
    display: 'flex',
    justifyContent: 'center'
};

export const col = {
    display: 'flex',
    alignItems: 'center',
    height: 84,
    fontSize: 19.2,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: -0.78,
    color: '#ffffff',
    padding: '16px 40px 16px 16px',
    borderRadius: 6,
};

export const icon = {
    marginRight: 16,
}
