import { TimeoutConfig } from "data/Models";

export const key = 'idle_timeout_config';

function isValidConfigObject (config: TimeoutConfig) {
  if (!config.hasOwnProperty('idleDelayTimer')
    || !config.hasOwnProperty('idleGracePeriodConfirmation')
    || !config.hasOwnProperty('idleTimeout')
  ) return false;

  if (typeof config.idleDelayTimer !== 'number'
    || typeof config.idleGracePeriodConfirmation != 'number'
    || typeof config.idleTimeout != 'number'
  ) return false;

  return true;
}

const sessionTimeConfigStore = {
  save (config: TimeoutConfig) : void {
    window.localStorage.setItem(key, JSON.stringify(config));
  },
  delete () : void {
    window.localStorage.removeItem(key);
  },
  get () : TimeoutConfig | null {
    try {
      const configStr = window.localStorage.getItem(key) || '';
      const config = JSON.parse(configStr);
      if (!isValidConfigObject(config)) return null;

      return config;
    } catch (err) {
      return null;
    }
  },
};

export default sessionTimeConfigStore;
