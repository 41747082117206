import {
    ADMIN_DETAIL_INITIAL,
    ADMIN_DETAIL_REQUEST,
    ADMIN_DETAIL_SUCCESS,
    ADMIN_DETAIL_FAILURE,
    AdminDetailAction
} from './AdminDetailAction';
import { AdminDetailStoreState } from './AdminDetailState';

export const defaultState: AdminDetailStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function adminDetailReducer(
    state: AdminDetailStoreState = defaultState,
    action: AdminDetailAction
): AdminDetailStoreState {
    switch (action.type) {
        case ADMIN_DETAIL_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case ADMIN_DETAIL_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case ADMIN_DETAIL_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case ADMIN_DETAIL_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
