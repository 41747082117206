import { RoleFormData } from "data/Models";
import * as http from "common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";
import { RoleListQuery } from "common/States";

const RoleApi = {
    getRoleList (queryParams: RoleListQuery): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Role.Base)
            .setQueryParameter(queryParams)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    roleList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    createRole (newData: RoleFormData): Promise<http.HttpService<{}>> {
        const body = {
            name: newData.name,
            permissions: newData.permissions,
            set_active: newData.isActive,
        }
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Role.Base)
            .setBody(body)
            .doPost()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true }
            }))
            .catch(error => http.handleCatch(error));
    },
    updateRole (updateData: RoleFormData): Promise<http.HttpService<{}>> {
        const body = {
            name: updateData.name,
            permissions: updateData.permissions,
            set_active: updateData.isActive,
        }
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Role.Base}/${updateData.id}`)
            .setBody(body)
            .doPut()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true }
            }))
            .catch(error => http.handleCatch(error));
    },
    getPermissionList (): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Role.Permission)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return { permissionList: json.data }
            }))
            .catch(error => http.handleCatch(error));
    },
    getRoleDetail (roleId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Role.Base}/${roleId}`)
            .setQueryParameter({})
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    roleDetail: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    deleteRole (roleId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Role.Base}/${roleId}`)
            .setQueryParameter({})
            .doDelete()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    success: true,
                }
            }))
            .catch(error => http.handleCatch(error));
    }
};

export default RoleApi;
