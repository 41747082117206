import { HttpError } from 'data/Models';
import { PromoList } from 'data/Models';
import { PromoListQuery } from 'common/States';

export const PROMO_MANAGEMENT_INITIAL = 'PROMO_MANAGEMENT_INITIAL';
export type PROMO_MANAGEMENT_INITIAL = typeof PROMO_MANAGEMENT_INITIAL;

export const PROMO_MANAGEMENT_REQUEST = 'PROMO_MANAGEMENT_REQUEST';
export type PROMO_MANAGEMENT_REQUEST = typeof PROMO_MANAGEMENT_REQUEST;

export const PROMO_MANAGEMENT_SUCCESS = 'PROMO_MANAGEMENT_SUCCESS';
export type PROMO_MANAGEMENT_SUCCESS = typeof PROMO_MANAGEMENT_SUCCESS;

export const PROMO_MANAGEMENT_FAILURE = 'PROMO_MANAGEMENT_FAILURE';
export type PROMO_MANAGEMENT_FAILURE = typeof PROMO_MANAGEMENT_FAILURE;

interface PromoManagementInitial {
    type: PROMO_MANAGEMENT_INITIAL;
}

export interface PromoManagementRequest {
    type: PROMO_MANAGEMENT_REQUEST;
    isRetrieveAll?: boolean;
    filterParams : PromoListQuery
}

interface PromoManagementSuccess {
    type: PROMO_MANAGEMENT_SUCCESS;
    data: PromoList;
}

interface PromoManagementFailure {
    type: PROMO_MANAGEMENT_FAILURE;
    error: HttpError;
}

export type PromoManagementAction = PromoManagementInitial | PromoManagementRequest | PromoManagementSuccess | PromoManagementFailure;

export function doPromoManagementInitial(): PromoManagementInitial {
    return {
        type: PROMO_MANAGEMENT_INITIAL
    };
}

export function doPromoManagementRequest(
    isRetrieveAll: boolean,
    filterParams: PromoListQuery,
): PromoManagementRequest {
    return {
        type: PROMO_MANAGEMENT_REQUEST,
        isRetrieveAll: isRetrieveAll,
        filterParams: filterParams,
    };
}

export function doPromoManagementSuccess(data: PromoList): PromoManagementSuccess {
    return {
        type: PROMO_MANAGEMENT_SUCCESS,
        data: data
    };
}

export function doPromoManagementFailure(error: HttpError): PromoManagementFailure {
    return {
        type: PROMO_MANAGEMENT_FAILURE,
        error: error
    };
}
