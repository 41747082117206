import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Popover } from 'antd';
import { PromoListItem } from 'data/Models';
import { FrontendRoutes } from 'navigation/Routes';
import { Images } from 'themes';
import { promoManagementConfigs } from './PromoManagemetConfig';

const Text = {
    viewDetail: 'View Detail',
    edit: 'Edit',
    delete: 'Delete',
};

interface PromoOptionsViewProps {
    containerId: HTMLElement | null;
    index: number;
    record: PromoListItem;
    permissions: {
        isAllowedViewDetail: boolean;
        isAllowedEdit: boolean;
        isAllowedDelete: boolean;
    };
    isShown: boolean;
    onPopoverVisibilityChange: (shownPopOver: number | null) => void
    handleDeleteFn: (promo: PromoListItem) => void;
}

function PromoOptionsView(props: PromoOptionsViewProps) {
    const { containerId, index, record, permissions, isShown, onPopoverVisibilityChange, handleDeleteFn } = props;
    const isSupported = promoManagementConfigs.supportedTypes.includes(record.type);

    const history = useHistory();
    const showDots = permissions.isAllowedViewDetail || permissions.isAllowedEdit|| permissions.isAllowedDelete;

    let popupContainer = document.body;
    if (containerId) {
        popupContainer = containerId;
    }

    const handleDetailPush = useCallback(() => {
        if (isSupported) {
            history.push(`${FrontendRoutes.PromoDetailPage.path.replace(':promoId', record.id)}?type=${record.type}`, { promoDetailType: record.type });
        }
    }, [history, isSupported, record]);
    const handleEditPush = useCallback(() => {
        if (isSupported) {
            history.push(`${FrontendRoutes.PromoEditPage.path.replace(':promoId', record.id)}?type=${record.type}`, { promoDetailType: record.type });
        }
    }, [history, isSupported, record]);

    const content = () => {
        const options = [
            {
                clickFn: () => handleDetailPush(),
                imgSrc: Images.EyeBlue,
                text: Text.viewDetail,
                isAllowed: permissions.isAllowedViewDetail && isSupported,
            },
            {
                clickFn: () => handleEditPush(),
                imgSrc: Images.PencilBlue,
                text: Text.edit,
                isAllowed: permissions.isAllowedEdit && isSupported,
                popOverItemClass: 'edit',
            },
            {
                clickFn: () => handleDeleteFn(record),
                imgSrc: Images.Delete,
                text: Text.delete,
                isAllowed: permissions.isAllowedDelete && isSupported,
                popOverItemClass: 'red',
            },
        ];
        const popOverContentItems = options.map((props) => {
            const { clickFn, imgSrc, text, isAllowed, popOverItemClass } = props;

            if (isAllowed) {
                return (
                    <div key={text} className={`appPopOverItem ${popOverItemClass}`} onClick={clickFn}>
                        <img src={imgSrc} alt={text} />
                        <span>{text}</span>
                    </div>
                );
            }

            return null;
        });

        return (
            <div className="appPopOverContent">
                {popOverContentItems}
            </div>
        );
    };

    return (
        <div>
            <Popover
                content={content}
                visible={isShown}
                trigger="click"
                placement="bottomRight"
                overlayClassName={'actionGroupPopOver'}
                getPopupContainer={() => popupContainer}
            >
                <div className="promoManagementDotsWrapper">
                    {showDots && (
                        <div
                            className="promoManagementDots"
                            onClick={() => onPopoverVisibilityChange(!isShown ? index : null)}
                        >
                            <img src={Images.Dots} alt="Dots" />
                        </div>
                    )}
                </div>
            </Popover>
        </div>
    );
}

export default PromoOptionsView;