import React, { useCallback, useRef, useState } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { FrontendRoutes } from 'navigation/Routes';
import { Images } from 'themes';
import { HttpError } from 'data/Models';
import { LoadingWithMask } from 'components/LoadingComponent';
import AlertComponent from 'components/AlertComponent';
import { OvalButton } from 'components/ButtonComponent';
import { confirmPasswordRules, passwordRules, createPasswordText, maxPasswordLen, passwordRulesText } from './CreatePasswordConfig';
import { useQuery } from 'hooks/useQuery';
import profileApi from 'data/api/ProfileApi';
import { ModalProdia } from 'components/ModalComponent';
import './CreatePasswordView.css';
import { AbortError } from 'common/HttpCode';

interface Props {
    history?: any;
}

const CreatePasswordView: React.FC<Props> = ({
    history,
}) => {
    const token = useQuery('exchange_token');
    const [isCreatePasswordSuccess, setIsCreatePasswordSuccess] = useState<boolean>(false);
    const [isCreatingPassword, setIsCreatingPassword] = useState<boolean>(false);
    const [error, setError] = useState<HttpError | null>(null);
    const [form] = Form.useForm();

    // tooltip
    const tooltipRef = useRef<HTMLButtonElement>(null);

    const handlePasswordFocus = useCallback(() => {
        if (tooltipRef.current) {
            tooltipRef.current.click();
        }
    }, []);
    const handlePasswordBlur = useCallback(() => {
        const triggerElement = tooltipRef.current;
        if (!triggerElement) return;
        const needClickToHideTooltip = Boolean(triggerElement && triggerElement.classList.contains('ant-tooltip-open'));
        if (needClickToHideTooltip) {
            triggerElement.click();
        }
    }, []);
    const handlePasswordMouseDown = useCallback((event: React.SyntheticEvent<HTMLElement | MouseEvent>) => {
        // Prevent tooltip hide when clicking password input
        // when the password input is already on focus state
        event.persist();
        const triggerElement = tooltipRef.current;
        if (!triggerElement) return;
        const shouldIgnoreHideTooltip = Boolean(triggerElement && triggerElement.classList.contains('ant-tooltip-open'));
        if (shouldIgnoreHideTooltip) {
            event.stopPropagation();
        }
    }, []);
    
    const onFinish = useCallback((values: any) => {
        setError(null);
        setIsCreatingPassword(true);
        profileApi.createPassword(
            values.newPassword,
            values.confirmPassword,
            token,
        )
            .then(() => {
                setIsCreatingPassword(false);
                setIsCreatePasswordSuccess(true);
            })
            .catch((err) => {
                if (err instanceof HttpError && err.code === AbortError) {
                    err.action = () => {
                        onFinish(values);
                    };
                }
                setIsCreatingPassword(false);
                setError(err);
            });
    }, [token]);
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);

    const passwordHints = (<ul style={{ paddingLeft: 16, marginBottom: 0 }}>
        {passwordRulesText.map(text => (
            <li key={text}>{text}</li>
        ))}
    </ul>);

    return (
        <div className="createPasswordViewWrapper">
            {isCreatePasswordSuccess ? (
                <ModalProdia 
                    visible
                    footer={[
                        <OvalButton
                            key="OK"
                            type="primary"
                            htmlType="submit"
                            buttonText="OK"
                            onClick={() => history.replace(FrontendRoutes.Login.path)}
                        />
                    ]}
                >
                    <>
                        <p className="contentTitle">Password Created</p>
                        <p className="contentText">You have successfully create a password. Now you can login using the password you have created.</p>
                    </>
                </ModalProdia>
            ) : null}
            {isCreatingPassword && <LoadingWithMask />}
            {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <div className="createPasswordViewHeader">
                    <img src={Images.LogoLoginHeader} alt="login logo" />
                </div>
                <div className="createPasswordViewFormContent">
                    <div className="createPasswordViewFormTitle">
                        {createPasswordText.formTitle}
                    </div>
                    <div className="createPasswordViewFormDesc">
                        {createPasswordText.formDesc}
                    </div>
                    
                    <Form.Item label="Password" className="newPasswordFormItem">
                        <Form.Item
                            name="newPassword"
                            rules={passwordRules}
                            validateFirst
                            noStyle   
                        >
                            <Input.Password onMouseDown={handlePasswordMouseDown}  onBlur={handlePasswordBlur} onFocus={handlePasswordFocus} maxLength={maxPasswordLen} bordered />
                        </Form.Item>
                        <Tooltip trigger="click" align={{ offset: [0, -9] }} overlayClassName="blue-tooltip password-tooltip" placement="bottom" title={passwordHints}>  
                            <button ref={tooltipRef} className="tooltipTriggerBtn">tooltip trigger</button>
                        </Tooltip>
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        rules={confirmPasswordRules(form)}
                    >
                        <Input.Password maxLength={maxPasswordLen} bordered />
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <div className="createPasswordViewButtonWrapper">
                                <OvalButton
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                    buttonText={createPasswordText.buttonText}
                                />
                            </div>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default CreatePasswordView;