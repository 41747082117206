import {call, put, takeLatest} from 'redux-saga/effects';
import PaymentChannelApi from 'data/api/PaymentApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './PaymentChannelAction';

function* paymentChannelListTask() {
    const response = yield call(PaymentChannelApi.getPaymentChannelList);
    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doPaymentChannelListSuccess(response.data.paymentChannelList));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doPaymentChannelListRequest() });
            yield put(action.doPaymentChannelListFailure(newTimeoutError));
        } else {
            yield put(action.doPaymentChannelListFailure(response.error));
        }
    }
}

export function* paymentChannelListSaga() {
    yield takeLatest(
        action.PAYMENT_CHANNEL_LIST_REQUEST,
        paymentChannelListTask,
    );
}
