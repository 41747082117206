import React from 'react';
import { Helmet } from 'react-helmet';

const MetaHead: React.FC<{
    title?: string;
}> = ({
    title = 'CMS - Prodia',
}) => {
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    )
};

export default MetaHead;
