import { combineReducers } from 'redux';
import { loginReducer } from 'features/login/LoginReducer';
import { getProfileReducer, logoutReducer } from 'features/scaffold/ScaffoldReducer';
import { changePasswordReducer } from 'features/account/ChangePassword/ChangePasswordReducer';
import { roleManagementReducer } from 'features/user/RoleManagement/RoleManagementReducer';
import { getPermissionsReducer } from 'features/user/RoleForm/PermissionTree/PermissionTreeReducer';
import { createRoleReducer } from 'features/user/RoleCreate/RoleCreateReducer';
import { updateRoleReducer } from 'features/user/RoleEdit/RoleEditReducer';
import { regionListReducer } from 'features/region/RegionReducer';
import { branchListReducer } from 'features/branch/BranchManagement/BranchReducer';
import { branchDetailReducer } from 'features/branch/BranchDetail/BranchDetailReducer';
import { updateBranchReducer } from 'features/branch/BranchEdit/BranchEditReducer';
import { roleDetailReducer } from 'features/user/RoleDetail/RoleDetailReducer';
import { deleteRoleReducer } from 'features/user/RoleDelete/DeleteRoleReducer';
import { adminManagementReducer } from 'features/user/AdminManagement/AdminManagementReducer';
import { createAdminReducer } from 'features/user/AdminCreate/AdminCreateReducer';
import { adminDetailReducer } from 'features/user/AdminDetail/AdminDetailReducer';
import { deleteAdminReducer } from 'features/user/AdminDelete/DeleteAdminReducer';
import { updateAdminReducer } from 'features/user/AdminEdit/AdminEditReducer';
import { panelManagementReducer } from 'features/product/panel/PanelManagement/PanelManagementReducer';
import { panelDetailReducer } from 'features/product/panel/PanelDetail/PanelDetailReducer';
import { updatePanelReducer } from 'features/product/panel/PanelEdit/PanelEditReducer';
import { testManagementReducer } from 'features/product/test/TestManagement/TestManagementReducer';
import { testDetailReducer } from 'features/product/test/TestDetail/TestDetailReducer';
import { updateTestReducer } from 'features/product/test/TestEdit/TestEditReducer';
import { orderManagementReducer } from 'features/order/OrderManagement/OrderManagementReducer';
import { paymentChannelListReducer } from 'features/paymentChannel/PaymentChannelReducer';
import { orderManagementDownloadReducer } from 'features/order/OrderManagementDownload/OrderManagementDownloadReducer';
import { orderDetailReducer } from 'features/order/OrderDetail/OrderDetailReducer';
import { orderDetailDownloadReducer } from 'features/order/OrderDetailDownload/OrderDetailDownloadReducer';
import { promoManagementReducer } from 'features/promo/PromoManagement/PromoManagementReducer';
import { promoDetailReducer } from 'features/promo/PromoDetail/PromoDetailReducer';
import { deletePromoReducer } from 'features/promo/PromoDelete/DeletePromoReducer';
import { contractListReducer } from 'features/contract/ContractList/ContractListReducer';
import { contractDetailReducer } from 'features/contract/ContractDetail/ContractDetailReducer';
import { createPromoReducer } from 'features/promo/PromoCreate/PromoCreateReducer';
import { updatePromoReducer } from 'features/promo/PromoEdit/PromoEditReducer';
import { updateTNCReducer } from 'features/homeService/tnc/TNCManagement/TNCManagementReducer';
import { tncDetailReducer } from 'features/homeService/tnc/TNCDetail/TNCDetailReducer';

const rootReducer = combineReducers({
    login: loginReducer,
    logout: logoutReducer,
    profile: getProfileReducer,
    changePassword: changePasswordReducer,
    roleManagement: roleManagementReducer,
    createRole: createRoleReducer,
    updateRole: updateRoleReducer,
    permissions: getPermissionsReducer,
    roleDetail: roleDetailReducer,
    deleteRole: deleteRoleReducer,
    regionList: regionListReducer,
    branchList: branchListReducer,
    branchDetail: branchDetailReducer,
    updateBranch: updateBranchReducer,
    adminManagement: adminManagementReducer,
    createAdmin: createAdminReducer,
    updateAdmin: updateAdminReducer,
    adminDetail: adminDetailReducer,
    deleteAdmin: deleteAdminReducer,
    panelManagement: panelManagementReducer,
    panelDetail: panelDetailReducer,
    updatePanel: updatePanelReducer,
    testManagement: testManagementReducer,
    tncDetail: tncDetailReducer,
    updateTNC: updateTNCReducer,
    testDetail: testDetailReducer,
    updateTest: updateTestReducer,
    orderManagement: orderManagementReducer,
    paymentChannelList: paymentChannelListReducer,
    orderManagementDownload: orderManagementDownloadReducer,
    orderDetail: orderDetailReducer,
    orderDetailDownload: orderDetailDownloadReducer,
    promoManagement: promoManagementReducer,
    promoDetail: promoDetailReducer,
    deletePromo: deletePromoReducer,
    contractList: contractListReducer,
    contractDetail: contractDetailReducer,
    createPromo: createPromoReducer,
    updatePromo: updatePromoReducer,
});

export default rootReducer;
