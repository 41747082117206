import React, { Key, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tree, Typography } from 'antd';
import { DataNode } from 'antd/lib/tree';
import RootStoreState from 'rootStore';
import { Images } from 'themes';
import { PermissionGroup } from 'data/Models';
import './PermissionTreeView.css';

interface Props {
    initialValues: string[] | undefined;
    onChecked: (values: Key[]) => void;
    permissionError?: string | null
}

function PermissionTreeView(props: Props) {
    const { initialValues, onChecked, permissionError } = props;

    const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const permissionsState = useSelector((state: RootStoreState) => state.permissions);

    useEffect(() => {
        if (permissionsState.data) {
            if (initialValues) {
                setCheckedKeys(initialValues);
                const groupKeys = permissionsState.data
                    .filter((item) => item.permissions.find((child) => initialValues.includes(child.id)))
                    .map((item) => item.id)
                setExpandedKeys(groupKeys)
            }
            setTreeData(createDataNode(permissionsState.data));
        }
    }, [permissionsState.data, initialValues]);

    const handlePermissionChecked = (values: any) => {
        if (values instanceof Array) {
            setCheckedKeys(values);
            onChecked(values);
        }
    };
    const handlePermissionSelect = (selectedKeys: Key[], info: any) => {
        const { node } = info;
        if (node.children) {
            if (expandedKeys.includes(node.key)) {
                setExpandedKeys(expandedKeys.filter((item) => item !== node.key));
            } else {
                setExpandedKeys([...expandedKeys, node.key]);
            }
        } else {
            if (checkedKeys.includes(node.key)) {
                handlePermissionChecked(checkedKeys.filter((item) => item !== node.key));
            } else {
                handlePermissionChecked([...checkedKeys, node.key]);
            }
        }
    };

    return (
        <div>
            <Tree
                checkable
                showIcon
                checkedKeys={checkedKeys}
                expandedKeys={expandedKeys}
                treeData={treeData}
                onCheck={handlePermissionChecked}
                onSelect={handlePermissionSelect}
            />
            {permissionError && (<Typography.Text type="danger">{permissionError}</Typography.Text>)}
        </div>
    );
}

function createDataNode(data: PermissionGroup[]): DataNode[] {
    return data.map((group) => {
        return {
            key: group.id,
            title: group.name,
            children: group.permissions.map((child) => {
                return {
                    key: child.id,
                    title: child.name,
                    isLeaf: true
                };
            }),
            icon: ({ expanded }) => (<img src={!expanded ? Images.TreeOpen : Images.TreeClose} alt="Expand" />),
            checkable: false
        }
    })
}

export default PermissionTreeView;
