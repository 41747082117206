import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";

const RegionApi = {
    getRegionList (): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Region.Base)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    regionList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
};

export default RegionApi;
