// General Errors
export const DefaultCode = "G000";
export const InternalErrorCode = "G001";
export const ValidationCode = "G002";
export const BadRequestCode = "G003";
export const DataNotFoundCode = "G004";
export const UnauthorizedCode = "G005";
export const InvalidCredentialsCode = "G006";
export const FailedRequestActivationCode = "G007";
export const ReachLimitRequestActivationCode = "G008";
export const CustomerInactiveCode = "G009";
export const InvalidOTPCode = "G010";
export const DuplicateEmailCode = "G011";
export const UnauthorizedPermissionCode = "G012";
export const InvalidCaptchaTokenCode = "G018";
export const UnauthorizedEmailDomainCode = "G019";
export const DoubleSessionCode = "G022";
export const ActivationTokenExpiredCode = "G023";
export const InvalidActivationCredentialsCode = "G024";
// Unhandled Errors
export const UnknownErrorCode = "E001";
// Payment Errors
export const InsufficientBalanceCode = "P001";
// Used By Active Users Errors
export const UsedByUsersCode = "G101";
export const AbortError = "AbortError";

export const SessionExpiredError = "G999"; // fixme: change with the actual one

