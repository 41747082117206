import { UserFormData, HttpError } from "data/Models";

export const UPDATE_ADMIN_INITIAL = 'UPDATE_ADMIN_INITIAL';
export type UPDATE_ADMIN_INITIAL = typeof UPDATE_ADMIN_INITIAL;

export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST';
export type UPDATE_ADMIN_REQUEST = typeof UPDATE_ADMIN_REQUEST;

export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export type UPDATE_ADMIN_SUCCESS = typeof UPDATE_ADMIN_SUCCESS;

export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE';
export type UPDATE_ADMIN_FAILURE = typeof UPDATE_ADMIN_FAILURE;

interface UpdateAdminInitial {
    type: UPDATE_ADMIN_INITIAL;
}

export interface UpdateAdminRequest {
    type: UPDATE_ADMIN_REQUEST;
    updateData: UserFormData;
}

interface UpdateAdminSuccess {
    type: UPDATE_ADMIN_SUCCESS;
}

interface UpdateAdminFailure {
    type: UPDATE_ADMIN_FAILURE;
    error: HttpError;
}

export type UpdateAdminAction = UpdateAdminInitial | UpdateAdminRequest | UpdateAdminSuccess | UpdateAdminFailure;

export function doUpdateAdminInitial(): UpdateAdminInitial {
    return {
        type: UPDATE_ADMIN_INITIAL,
    };
}

export function doUpdateAdminRequest(updateData: UserFormData): UpdateAdminRequest {
    return {
        type: UPDATE_ADMIN_REQUEST,
        updateData: updateData,
    };
}

export function doUpdateAdminSuccess(): UpdateAdminSuccess {
    return {
        type: UPDATE_ADMIN_SUCCESS,
    };
}

export function doUpdateAdminFailure(error: HttpError): UpdateAdminFailure {
    return {
        type: UPDATE_ADMIN_FAILURE,
        error: error,
    };
}
