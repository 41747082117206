import React, { Fragment } from 'react';
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import { AuthStorage } from 'data/local/AuthStorage';
import { ScreenType } from 'common/constans';
import Scaffold from 'features/scaffold/ScaffoldView';
import MetaHead from 'components/MetaHead';
import BreadCrumbsComp from 'components/BreadCrumbsComponent';
import FrontendScreens from './Frontend.screens';

const FrontendAuthGuard: React.FC<{
    from: any;
}> = ({ from }) => {
    return (
        <Redirect exact from={from} to={FrontendScreens.LoginPage.path}/>
    );
};

const renderFrontendPage: React.FC<{
    metaTitle?: string;
    routeProps: any;
    Component: React.FC;
    customProps: any;
}> = ({
    metaTitle,
    routeProps,
    Component,
    customProps,
}) => {
    return (
      <Fragment>
          {metaTitle && <MetaHead title={metaTitle} />}
          {customProps.customProps.breadcrumbs && !customProps.customProps.breadcrumbsWithChildren && (
              <BreadCrumbsComp
                  crumbs={customProps.customProps.breadcrumbs}
              />
          )}
          <Component {...routeProps} {...customProps} />
      </Fragment>
    )
};

const RouteWithGuard: React.FC<{
    path: string;
    permissionKey?: string;
    guard?: boolean;
    render: any;
}> = ({
    path,
    permissionKey,
    guard,
    ...optProps
}) => {
    const isAuthenticated = AuthStorage.isAuthenticated();
    if (guard && !isAuthenticated) {
        return <FrontendAuthGuard from={path} />;
    }

    return <Route path={path} {...optProps} />;
};

const routes = () => {
    return (
        Object.entries(FrontendScreens).map(item => {
            const [name, props] = item;
            const { path, title: metaTitle, screenType, component: Component, ...optProps } = props;
            return (
                <RouteWithGuard
                    key={name}
                    path={path}
                    render={(routeProps: any) =>  {
                        const page = renderFrontendPage({
                            metaTitle,
                            routeProps,
                            Component,
                            customProps: {
                                customProps: props,
                            },
                        });

                        switch(screenType) {
                            case ScreenType.WITH_SCAFFOLLD:
                                return (<Scaffold title={metaTitle}>{page}</Scaffold>);
                            default:
                                return page;
                        }
                    }}
                    {...optProps}
                />
            );
        })
    )
};

function Navigation() {
    return (
        <Switch>
            {routes()}
        </Switch>
    );
}

export default Navigation;
