import { call, put, takeLatest } from 'redux-saga/effects';
import PromoApi from 'data/api/PromoApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './PromoCreateAction';

export function* createPromoTask(actionRequest: action.CreatePromoRequest) {
    const newData = actionRequest.newData;
    const response = yield call(PromoApi.createPromo, newData);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doCreatePromoSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doCreatePromoRequest(newData) });
            yield put(action.doCreatePromoFailure(newTimeoutError));
        } else {
            yield put(action.doCreatePromoFailure(response.error));
        }
    }
}

export function* createPromoSaga() {
    yield takeLatest(
        action.CREATE_PROMO_REQUEST,
        createPromoTask,
    );
}
