export const text = {
    MyAccountModal: {
        ModalTitle: 'Account',
        Name: 'Name',
        Email: 'Email',
        Role: 'Role',
        Branch: 'Branch',
        Region: 'Region',
        Logout: 'Logout',
        ChangePassword: 'Change Password'
    }
}
