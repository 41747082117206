import { call, put, takeLatest } from 'redux-saga/effects';
import RegionApi from 'data/api/RegionApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './RegionAction';

function* regionListTask() {
    const response = yield call(RegionApi.getRegionList);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doRegionListSuccess(response.data.regionList));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doRegionListRequest() });
            yield put(action.doRegionListFailure(newTimeoutError));
        } else {
            yield put(action.doRegionListFailure(response.error));
        }
    }
}
export function* regionListSaga() {
    yield takeLatest(
        action.REGION_LIST_REQUEST,
        regionListTask,
    );
}