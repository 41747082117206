import {
    TNC_DETAIL_INITIAL,
    TNC_DETAIL_REQUEST,
    TNC_DETAIL_SUCCESS,
    TNC_DETAIL_FAILURE,
    TNCDetailAction
} from './TNCDetailAction';
import { TNCDetailStoreState } from './TNCDetailState';

export const defaultState: TNCDetailStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function tncDetailReducer(
    state: TNCDetailStoreState = defaultState,
    action: TNCDetailAction
): TNCDetailStoreState {
    switch (action.type) {
        case TNC_DETAIL_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case TNC_DETAIL_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case TNC_DETAIL_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case TNC_DETAIL_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
