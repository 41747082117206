import {
    ROLE_DETAIL_INITIAL,
    ROLE_DETAIL_REQUEST,
    ROLE_DETAIL_SUCCESS,
    ROLE_DETAIL_FAILURE,
    RoleDetailAction
} from './RoleDetailAction';
import { RoleDetailStoreState } from './RoleDetailState';

export const defaultState: RoleDetailStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function roleDetailReducer(
    state: RoleDetailStoreState = defaultState,
    action: RoleDetailAction
): RoleDetailStoreState {
    switch (action.type) {
        case ROLE_DETAIL_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case ROLE_DETAIL_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case ROLE_DETAIL_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case ROLE_DETAIL_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
