import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Typography, Checkbox, Divider } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { sortTestList } from 'common/BranchUtils';
import { BranchDetailTest } from 'data/Models';
import './BranchEditSelectedTestList.css';

interface Props {
  selectedTests: BranchDetailTest[];
  selectedTestIds: string[];
  onChange?: (testIds: string[]) => void;
}

const BranchEditSelectedTestList : React.FC<Props> = (props) => {
  const { selectedTests, selectedTestIds: toBeRemovedTestIds, onChange = () => {} } = props;  
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const sortedSelectedTests = useMemo(() => {
    return sortTestList(selectedTests.slice());
  }, [selectedTests]);

  useEffect(() => {
    if (!selectedTests.length) {
      setIsAllSelected(false);
    }
  }, [selectedTests]);

  const handleUnselectTestIds = useCallback((checkedTestId: string) => {
    let nextRemovedTestIds : string[] = [];
    const isAlreadySelected = toBeRemovedTestIds.includes(checkedTestId);
    if (isAlreadySelected) {
      nextRemovedTestIds = toBeRemovedTestIds.filter(testId => testId !== checkedTestId);
    } else {
      nextRemovedTestIds = toBeRemovedTestIds.concat(checkedTestId);
    }
    setIsAllSelected(nextRemovedTestIds.length === selectedTests.length);
    onChange(nextRemovedTestIds);
  }, [toBeRemovedTestIds, selectedTests, onChange]);
  const handleSelectAll = useCallback((e: CheckboxChangeEvent) => {
    const nextRemovedTestIds = e.target.checked
      ? selectedTests.map(test => test.id)
      : [];
    onChange(nextRemovedTestIds);
    setIsAllSelected(nextRemovedTestIds.length === selectedTests.length);
  }, [selectedTests, onChange]);

  return (
    <div className="selectedTestList">
      <div className="listHeader">
        <Typography><strong>Selected Test</strong></Typography>
        <Divider />
      </div>
      <div className="listContent">
        {sortedSelectedTests.length ? (
          <div className="listItem"><Checkbox checked={isAllSelected} onChange={handleSelectAll}>Select All</Checkbox></div>
        ) : null}
        {sortedSelectedTests.map((item) => (
          <div className="listItem" key={item.id}>
            <Checkbox
              checked={toBeRemovedTestIds.includes(item.id)}
              onChange={() => {
                handleUnselectTestIds(item.id);
              }}
            >{item.id} - {item.name}</Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BranchEditSelectedTestList;