import { call, put, takeLatest } from 'redux-saga/effects';
import UserApi from 'data/api/UserApi';
import * as http from 'common/HttpUtils';
import * as action from './AdminManagementAction';
import { FilterParamMapper } from 'common/CommonUtils';

function* adminManagementTask(adminManagementAction: action.AdminManagementRequest) {
    const { filterParams } = adminManagementAction;
    let queryParams = FilterParamMapper(filterParams);
    const response = yield call(UserApi.getUserList, queryParams);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doAdminManagementSuccess(response.data.userList));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        yield put(action.doAdminManagementFailure(response.error));
    }
}

export function* adminManagementSaga() {
    yield takeLatest(
        action.ADMIN_MANAGEMENT_REQUEST,
        adminManagementTask,
    );
}
