import { HttpError, TestDetail } from 'data/Models';

export const TEST_DETAIL_INITIAL = 'TEST_DETAIL_INITIAL';
export type TEST_DETAIL_INITIAL = typeof TEST_DETAIL_INITIAL;

export const TEST_DETAIL_REQUEST = 'TEST_DETAIL_REQUEST';
export type TEST_DETAIL_REQUEST = typeof TEST_DETAIL_REQUEST;

export const TEST_DETAIL_SUCCESS = 'TEST_DETAIL_SUCCESS';
export type TEST_DETAIL_SUCCESS = typeof TEST_DETAIL_SUCCESS;

export const TEST_DETAIL_FAILURE = 'TEST_DETAIL_FAILURE';
export type TEST_DETAIL_FAILURE = typeof TEST_DETAIL_FAILURE;

interface TestDetailInitial {
    type: TEST_DETAIL_INITIAL;
}

export interface TestDetailRequest {
    type: TEST_DETAIL_REQUEST;
    testId: string;
}

interface TestDetailSuccess {
    type: TEST_DETAIL_SUCCESS;
    data: TestDetail;
}

interface TestDetailFailure {
    type: TEST_DETAIL_FAILURE;
    error: HttpError;
}

export type TestDetailAction = TestDetailInitial | TestDetailRequest | TestDetailSuccess | TestDetailFailure;

export function doTestDetailInitial(): TestDetailInitial {
    return {
        type: TEST_DETAIL_INITIAL
    };
}

export function doTestDetailRequest(testId: string): TestDetailRequest {
    return {
        type: TEST_DETAIL_REQUEST,
        testId: testId,
    };
}

export function doTestDetailSuccess(data: TestDetail): TestDetailSuccess {
    return {
        type: TEST_DETAIL_SUCCESS,
        data: data
    };
}

export function doTestDetailFailure(error: HttpError): TestDetailFailure {
    return {
        type: TEST_DETAIL_FAILURE,
        error: error
    };
}
