import {
    DELETE_PROMO_INITIAL,
    DELETE_PROMO_REQUEST,
    DELETE_PROMO_SUCCESS,
    DELETE_PROMO_FAILURE,
    DeletePromoAction,
} from './DeletePromoAction';
import { DefaultStoreState } from 'common/States';
import { DeletePromoStoreState } from './DeletePromoState';

export const defaultState: DeletePromoStoreState = {
    promoType: null,
    onRequest: false,
    success: false,
    error: null,
};

export function deletePromoReducer(
    state: DefaultStoreState = defaultState,
    action: DeletePromoAction,
): DeletePromoStoreState {
    switch (action.type) {
        case DELETE_PROMO_INITIAL:
            return { ...state, promoType: defaultState.promoType, onRequest: defaultState.onRequest, success: defaultState.success, error: defaultState.error };
        case DELETE_PROMO_REQUEST:
            return { ...state, promoType: action.promoType, onRequest: true, success: false, error: defaultState.error };
        case DELETE_PROMO_SUCCESS:
            return { ...state, promoType: action.promoType, onRequest: false, success: true, error: defaultState.error };
        case DELETE_PROMO_FAILURE:
            return { ...state, promoType: action.promoType, onRequest: false, success: false, error: action.error };
        default:
            return state;
    }
}
