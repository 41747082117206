import { HttpError, OrderList } from 'data/Models';
import { OrderListQuery } from "common/States";

export const ORDER_MANAGEMENT_INITIAL = 'ORDER_MANAGEMENT_INITIAL';
export type ORDER_MANAGEMENT_INITIAL = typeof ORDER_MANAGEMENT_INITIAL;

export const ORDER_MANAGEMENT_REQUEST = 'ORDER_MANAGEMENT_REQUEST';
export type ORDER_MANAGEMENT_REQUEST = typeof ORDER_MANAGEMENT_REQUEST;

export const ORDER_MANAGEMENT_SUCCESS = 'ORDER_MANAGEMENT_SUCCESS';
export type ORDER_MANAGEMENT_SUCCESS = typeof ORDER_MANAGEMENT_SUCCESS;

export const ORDER_MANAGEMENT_FAILURE = 'ORDER_MANAGEMENT_FAILURE';
export type ORDER_MANAGEMENT_FAILURE = typeof ORDER_MANAGEMENT_FAILURE;

interface OrderManagementInitial {
    type: ORDER_MANAGEMENT_INITIAL;
}

export interface OrderManagementRequest {
    type: ORDER_MANAGEMENT_REQUEST;
    filterParams: OrderListQuery;
}

interface OrderManagementSuccess {
    type: ORDER_MANAGEMENT_SUCCESS;
    data: OrderList;
}

interface OrderManagementFailure {
    type: ORDER_MANAGEMENT_FAILURE;
    error: HttpError;
}

export type OrderManagementAction = OrderManagementInitial | OrderManagementRequest | OrderManagementSuccess | OrderManagementFailure;

export function doOrderManagementInitial(): OrderManagementInitial {
    return {
        type: ORDER_MANAGEMENT_INITIAL
    };
}

export function doOrderManagementRequest(filterParams : OrderListQuery): OrderManagementRequest {
    return {
        type: ORDER_MANAGEMENT_REQUEST,
        filterParams: filterParams
    };
}

export function doOrderManagementSuccess(data: OrderList): OrderManagementSuccess {
    return {
        type: ORDER_MANAGEMENT_SUCCESS,
        data: data
    };
}

export function doOrderManagementFailure(error: HttpError): OrderManagementFailure {
    return {
        type: ORDER_MANAGEMENT_FAILURE,
        error: error
    };
}
