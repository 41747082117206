import React, { useEffect, Dispatch, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { Descriptions } from 'antd';
import RootStoreState from 'rootStore';
import AlertComponent from 'components/AlertComponent';
import { GetMethodLoading } from 'components/LoadingComponent';
import { OvalButton } from 'components/ButtonComponent';
import BreadCrumbsComp from 'components/BreadCrumbsComponent';
import { formatCurrency, formatDays, LanguageType, translateGender } from 'common/CommonUtils';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { DefaultCustomProps } from 'common/States';
import { FrontendRoutes } from 'navigation/Routes';
import { Images } from 'themes';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { TestDetailAction, doTestDetailInitial, doTestDetailRequest } from './TestDetailAction';
import { PreparationsItem, HttpError } from 'data/Models';
import './index.css'

interface Props {
    customProps?: DefaultCustomProps
}
const Text = {
    detailsInformation: 'Details Information',
    code: 'Code Test',
    testName: {
        id: 'Test Name (ID)',
        en: 'Test Name (EN)',
    },
    keyword: {
        id: 'Keyword (ID)',
        en: 'Keyword (EN)',
    },
    description: {
        id: 'Test Description (ID)',
        en: 'Test Description (EN)',
    },
    benefit: {
        id: 'Test Benefit (ID)',
        en: 'Test Benefit (EN)',
    },
    preparations: {
        id: 'Test Preparation (ID)',
        en: 'Test Preparation (EN)',
    },
    actualPrice: 'Actual Price',
    originalPrice: 'Original Price',
    type: 'Test Type',
    category: {
        id: 'Category Test (ID)',
        en: 'Category Test (EN)',
    },
    gender: {
        id: 'Gender (ID)',
        en: 'Gender (EN)',
    },
    beginAge: 'Begin Age',
    endAge: 'End Age',
    status: 'Status',
    edit: 'EDIT',
    Status: {
        Active: 'Active',
        Inactive: 'Inactive',
    },
    descriptionRow: 'descriptionRow'
};

const TestDetailView: React.FC<Props> = (props) => {
    const history = useHistory();
    const { testId } = useParams<{ testId: string }>();
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];

    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.TestEditPage.permissionKey, permissions);
    const testDetailAction = useDispatch<Dispatch<TestDetailAction>>();
    const testDetailState = useSelector((state: RootStoreState) => state.testDetail);
    const testDetailData = testDetailState.data;
    const [error, setError] = useState<HttpError | null>(null);
    useEffect(() => {
        testDetailAction(doTestDetailRequest(testId));
    }, [testId, testDetailAction]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
       if (testDetailState.error) {
            testDetailAction(doTestDetailInitial());
            setError(testDetailState.error);
        }
    }, [testDetailAction, testDetailState]);
    const loading: boolean = profileState.onRequest || testDetailState.onRequest;
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const renderPreparations = useCallback((detailData, type = LanguageType.id) => {
        return (
            <div>
                {
                    detailData?.preparations?.map((group: PreparationsItem, index: number)=>{
                        return (
                            <div key={`preparationsList${index}`}>
                                <span className="listItemIndex">{`${index + 1}`}.</span>
                                <span>{type === LanguageType.en ? group.description_en: group.description}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }, []);
    const testDetailDesc = [
        {
            label: Text.code,
            content: testDetailData?.id,
        },
        {
            label: Text.testName.id,
            content: testDetailData?.name,
        },
        {
            label: Text.testName.en,
            content: testDetailData?.name_en,
        },
        {
            label: Text.keyword.id,
            content: testDetailData?.keyword,
        },
        {
            label: Text.keyword.en,
            content: testDetailData?.keyword_en,
        },
        {
            label: Text.description.id,
            content: testDetailData?.description,
            className: Text.descriptionRow
        },
        {
            label: Text.description.en,
            content: testDetailData?.description_en,
            className: Text.descriptionRow
        },
        {
            label: Text.preparations.id,
            content: renderPreparations(testDetailData),
            className: Text.descriptionRow
        },
        {
            label: Text.preparations.en,
            content: renderPreparations(testDetailData, LanguageType.en),
            className: Text.descriptionRow
        },
        {
            label: Text.benefit.id,
            content: testDetailData?.benefit,
            className: Text.descriptionRow
        },
        {
            label: Text.benefit.en,
            content: testDetailData?.benefit_en,
            className: Text.descriptionRow
        },
        {
            label: Text.actualPrice,
            content: testDetailData?.price?.actual && formatCurrency(testDetailData.price.actual),
        },
        {
            label: Text.originalPrice,
            content: testDetailData?.price?.original && formatCurrency(testDetailData.price.original),
        },
        {
            label: Text.type,
            content: (
                <div>
                    {testDetailData?.types?.map((typeItem, index)=>{
                        return (
                            <div key={`typeList${index}`}>{typeItem}</div>
                        )
                    })}
                </div>
            ),
        },
        {
            label: Text.category.id,
            content: testDetailData?.category?.name,
        },
        {
            label: Text.category.en,
            content: testDetailData?.category?.name_en,
        },
        {
            label: Text.gender.id,
            content: translateGender(testDetailData?.requirement?.sex),
        },
        {
            label: Text.gender.en,
            content: translateGender(testDetailData?.requirement?.sex, LanguageType.en),
        },
        {
            label: Text.beginAge,
            content: testDetailData?.requirement && formatDays(testDetailData?.requirement?.age_min),
        },
        {
            label: Text.endAge,
            content: testDetailData?.requirement && formatDays(testDetailData?.requirement?.age_max) ,
        },
        {
            label: Text.status,
            content: testDetailData?.is_active ? Text.Status.Active : Text.Status.Inactive,
        },
    ];
    const handleNavigateToEdit = () => {
        const path = FrontendRoutes.TestEditPage.path.replace(
            ':testId',
            `${testId}`,
        );
        history.push(path);
    };
    const breadCrumbsButtons = () => {
        return (
            <div className="breadCrumbsButtonsWrapper">
                {isAllowedEditDetail && (
                    <OvalButton
                        type="primary"
                        buttonText={Text.edit}
                        icon={<img className="ovalButtonImg" src={Images.PencilWhite} alt="edit" />}
                        onClick={() => handleNavigateToEdit()}
                    />
                )}
            </div>
        )
    };
    return (
        <div className="testDetailWrapper">
            {props.customProps?.breadcrumbs && (
                <BreadCrumbsComp
                    crumbs={props.customProps.breadcrumbs}
                >
                    {breadCrumbsButtons()}
                </BreadCrumbsComp>
            )}
            {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            {loading && <GetMethodLoading />}
            {testDetailData && (
                <Descriptions
                    title={Text.detailsInformation}
                    className={'testDetailContent'}
                >
                    {testDetailData && testDetailDesc.map((testDetailItem, index) => {
                        return (
                            <Descriptions.Item key={`testDetailItem${index}`} className={testDetailItem.className || ''} label={testDetailItem.label} span={3}>{testDetailItem.content}</Descriptions.Item>
                        )
                    })}
                </Descriptions>
            )}
        </div>
    );
};

export default TestDetailView;
