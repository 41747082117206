export const formRules = {
    name: [
        {
            required: true,
            message: 'Cannot be empty',
        }
    ],
    permission: [
        {
            required: true,
            message: 'Choose at least one permission',
        }
    ]
};

export const status = [
    {
        label: 'Active',
        value: 'active'
    },
    {
        label: 'Inactive',
        value: 'inactive'
    },
];
