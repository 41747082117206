import { PromoFormData, HttpError } from "data/Models";

export const CREATE_PROMO_INITIAL = 'CREATE_PROMO_INITIAL';
export type CREATE_PROMO_INITIAL = typeof CREATE_PROMO_INITIAL;

export const CREATE_PROMO_REQUEST = 'CREATE_PROMO_REQUEST';
export type CREATE_PROMO_REQUEST = typeof CREATE_PROMO_REQUEST;

export const CREATE_PROMO_SUCCESS = 'CREATE_PROMO_SUCCESS';
export type CREATE_PROMO_SUCCESS = typeof CREATE_PROMO_SUCCESS;

export const CREATE_PROMO_FAILURE = 'CREATE_PROMO_FAILURE';
export type CREATE_PROMO_FAILURE = typeof CREATE_PROMO_FAILURE;

interface CreatePromoInitial {
    type: CREATE_PROMO_INITIAL;
}

export interface CreatePromoRequest {
    type: CREATE_PROMO_REQUEST;
    newData: PromoFormData;
}

interface CreatePromoSuccess {
    type: CREATE_PROMO_SUCCESS;
}

interface CreatePromoFailure {
    type: CREATE_PROMO_FAILURE;
    error: HttpError;
}

export type CreatePromoAction = CreatePromoInitial | CreatePromoRequest | CreatePromoSuccess | CreatePromoFailure;

export function doCreatePromoInitial(): CreatePromoInitial {
    return {
        type: CREATE_PROMO_INITIAL,
    };
}

export function doCreatePromoRequest(newData: PromoFormData): CreatePromoRequest {
    return {
        type: CREATE_PROMO_REQUEST,
        newData: newData,
    };
}

export function doCreatePromoSuccess(): CreatePromoSuccess {
    return {
        type: CREATE_PROMO_SUCCESS,
    };
}

export function doCreatePromoFailure(error: HttpError): CreatePromoFailure {
    return {
        type: CREATE_PROMO_FAILURE,
        error: error,
    };
}
