/**
 * Helper class for recaptcha v3.
 * Call getToken() to get token string. 
 * Once we are done, we can call tearDown() to remove injected script
 */
export default class Recaptcha {
  static script = document.createElement('script');

  public static getToken () : Promise<grecaptcha.RechaptchaToken> {
    const siteKey = process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY || '';

    // Always create and load new script element 
    // so that we get different captcha token
    Recaptcha.script = document.createElement('script')
    return new Promise((resolve, reject) => {
      Recaptcha.script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      document.body.appendChild(Recaptcha.script);

      Recaptcha.script.onload = () => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(siteKey, {
            action: 'submit'
          })
          .then(resolve)
          .catch(reject);
        });
      };
    });
  }

  public static tearDown () : void {
    Recaptcha.script.src = '';
    document.body.removeChild(Recaptcha.script);
  } 
}