import { HttpError,ProfileDetail } from "data/Models";

export const GET_PROFILE_INITIAL = 'GET_PROFILE_INITIAL';
export type GET_PROFILE_INITIAL = typeof GET_PROFILE_INITIAL;

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export type GET_PROFILE_REQUEST = typeof GET_PROFILE_REQUEST;

export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export type GET_PROFILE_SUCCESS = typeof GET_PROFILE_SUCCESS;

export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';
export type GET_PROFILE_FAILURE = typeof GET_PROFILE_FAILURE;

interface GetProfileInitial {
    type: GET_PROFILE_INITIAL;
}

interface GetProfileRequest {
    type: GET_PROFILE_REQUEST;
}

interface GetProfileSuccess {
    type: GET_PROFILE_SUCCESS;
    data: ProfileDetail;
}

interface GetProfileFailure {
    type: GET_PROFILE_FAILURE;
    error: HttpError;
}

export type GetProfileAction = GetProfileInitial | GetProfileRequest | GetProfileSuccess | GetProfileFailure;

export function doGetProfileInitial(): GetProfileInitial {
    return {
        type: GET_PROFILE_INITIAL,
    };
}

export function doGetProfileRequest(): GetProfileRequest {
    return {
        type: GET_PROFILE_REQUEST,
    };
}

export function doGetProfileSuccess(data: ProfileDetail): GetProfileSuccess {
    return {
        type: GET_PROFILE_SUCCESS,
        data: data
    };
}

export function doGetProfileFailure(error: HttpError): GetProfileFailure {
    return {
        type: GET_PROFILE_FAILURE,
        error: error
    };
}

export const LOGOUT_INITIAL = 'LOGOUT_INITIAL';
export type LOGOUT_INITIAL = typeof LOGOUT_INITIAL;

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export type LOGOUT_REQUEST = typeof LOGOUT_REQUEST;

export const LOGOUT_PROCESSING = 'LOGOUT_PROCESSING';
export type LOGOUT_PROCESSING = typeof LOGOUT_PROCESSING;

export const LOGOUT_FINISH = 'LOGOUT_FINISH';
export type LOGOUT_FINISH = typeof LOGOUT_FINISH;

export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export type LOGOUT_FAILURE = typeof LOGOUT_FAILURE;


interface LogoutInitial {
    type: LOGOUT_INITIAL;
}

interface LogoutRequest {
    type: LOGOUT_REQUEST;
}

interface LogoutProcessing {
    type: LOGOUT_PROCESSING;
    onLogout: boolean;
}

interface LogoutFinish {
    type: LOGOUT_FINISH;
    onLogout: boolean;
}

interface LogoutFailure {
    type: LOGOUT_FAILURE;
    error: HttpError;
    onLogout: false,
}

export type LogoutAction = LogoutInitial | LogoutRequest | LogoutProcessing | LogoutFinish | LogoutFailure;

export function doLogoutInitial(): LogoutInitial {
    return {
        type: LOGOUT_INITIAL,
    };
}


export function doLogoutRequest(): LogoutRequest {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function doLogoutProcessing(): LogoutProcessing {
    return {
        type: LOGOUT_PROCESSING,
        onLogout: true,
    };
}

export function doLogoutFailure(error: HttpError): LogoutFailure {
    return {
        type: LOGOUT_FAILURE,
        error: error,
        onLogout: false,
    };
}

export function doLogoutFinish(): LogoutFinish {
    return {
        type: LOGOUT_FINISH,
        onLogout: false,
    };
}
