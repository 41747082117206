import React, {useEffect, Dispatch, useState, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useHistory} from "react-router-dom";
import RootStoreState from "rootStore";
import AlertComponent from "components/AlertComponent";
import {HttpError} from "data/Models";
import {LoadingWithMask, GetMethodLoading} from "components/LoadingComponent";
import {
  ModalConfirmation,
  TypeDeleteConfirmation,
} from "components/ModalComponent";
import {
  DeletePromoAction,
  doDeletePromoInitial,
  doDeletePromoRequest,
} from "features/promo/PromoDelete/DeletePromoAction";
import {OvalButton} from "components/ButtonComponent";
import BreadCrumbsComp from "components/BreadCrumbsComponent";
import {promoTypeListState} from "common/constans";
import {isPermissionAllowed} from "common/PermissionUtils";
import {DefaultCustomProps} from "common/States";
import {FrontendRoutes} from "navigation/Routes";
import {Images} from "themes";
import {useQuery} from "hooks/useQuery";
import {
  doGetProfileInitial,
  GetProfileAction,
} from "features/scaffold/ScaffoldAction";
import {
  PromoDetailAction,
  doPromoDetailInitial,
  doPromoDetailRequest,
} from "./PromoDetailAction";
import "./index.css";
import {promoManagementConfigs} from "../PromoManagement/PromoManagemetConfig";
import {
  doUpdatePromoInitial,
  UpdatePromoAction,
} from "../PromoEdit/PromoEditAction";
import PromoDetailWithSingleContract from "./PromoDetailWithSingleContract";
import PromoDetailWithMultipleContract from "./PromoDetailWithMultipleContract";

interface Props {
  customProps?: DefaultCustomProps;
}

export const Text = {
  detailsInformation: "Details Information",
  promoType: "Promo Type",
  contractID: "Contract ID",
  source: "Source",
  contractName: {
    id: "Contract Name (ID)",
    en: "Contract Name (EN)",
  },
  testName: {
    id: "Test Name (ID)",
    en: "Test Name (EN)",
  },
  discount: "Discount",
  discountWithPercent: "Discount (%)",
  promoCode: "Promo Code",
  branch: "Branch",
  branches: "Branches",
  usedQuota: "Used/Quota",
  title: {
    id: "Title (ID)",
    en: "Title (EN)",
  },
  imageDisplay: "Image Display",
  contractList: "Contract List",
  no: "No",
  contractId: "Contract ID",
  contractDate: {
    start: "Start Contract",
    end: "End Contract",
  },
  description: {
    id: "Description (ID)",
    en: "Description (EN)",
  },
  termsAndCondition: {
    id: "Terms and Conditions (ID)",
    en: "Terms and Conditions (EN)",
  },
  startPromoDate: "Start Promo Date",
  endPromoDate: "End Promo Date",
  startPublishDate: "Start Publish Date",
  endPublishDate: "End Publish Date",
  createdDate: "Created Date",
  createdBy: "Created By",
  updateDate: "Update Date",
  updateBy: "Update By",
  status: "Status",
  edit: "EDIT",
  delete: "DELETE",
  descriptionRow: "descriptionRow",
  descriptionRowWithBorder: "descriptionRowWithBorder",
  deleteConfirmation: {
    specialOffer: "Are you sure you want to delete this special offer?",
    flashDeal: "Are you sure you want to delete this flash sale?",
    general: "Are you sure you want to delete this promo code general?",
  },
};

const PromoDetailView: React.FC<Props> = (props) => {
  const history = useHistory();
  const promoDetailType = useQuery("type");
  const {promoId} = useParams<{promoId: string}>();
  const isSingleContract = promoDetailType === promoTypeListState[2].id;
  const profileAction = useDispatch<Dispatch<GetProfileAction>>();
  const profileState = useSelector((state: RootStoreState) => state.profile);
  const permissions = profileState.data?.permissions || [];
  const isAllowedEditDetail = isPermissionAllowed(
    FrontendRoutes.PromoEditPage.permissionKey,
    permissions
  );
  const isAllowedDeleteDetail = isPermissionAllowed(
    FrontendRoutes.PromoDeletePage.permissionKey,
    permissions
  );
  const promoDetailAction = useDispatch<Dispatch<PromoDetailAction>>();
  const promoDetailState = useSelector(
    (state: RootStoreState) => state.promoDetail
  );
  const promoDetailData = promoDetailState.data;
  const [isDetailDataRequested, setDetailDataRequested] =
    useState<boolean>(false);
  const deletePromoAction = useDispatch<Dispatch<DeletePromoAction>>();
  const deletePromoState = useSelector(
    (state: RootStoreState) => state.deletePromo
  );
  const [error, setError] = useState<HttpError | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    if (profileState.error) {
      profileAction(doGetProfileInitial());
      setError(profileState.error);
    } else if (profileState.data) {
      promoDetailAction(doPromoDetailInitial());
      promoDetailAction(doPromoDetailRequest(promoId, promoDetailType));
    }
  }, [
    profileAction,
    profileState,
    promoId,
    promoDetailType,
    promoDetailAction,
  ]);
  useEffect(() => {
    if (promoDetailState.onRequest) {
      setDetailDataRequested(false);
    } else {
      setDetailDataRequested(true);
      if (promoDetailState.error) {
        promoDetailAction(doPromoDetailInitial());
        setError(promoDetailState.error);
      }
    }
  }, [promoDetailAction, promoDetailState]);
  useEffect(() => {
    if (deletePromoState.success === true) {
      history.replace(FrontendRoutes.PromoManagement.path);
    } else if (deletePromoState.error) {
      setError(deletePromoState.error);
      deletePromoAction(doDeletePromoInitial());
    }
  }, [deletePromoAction, deletePromoState, history]);
  const loading: boolean = profileState.onRequest || promoDetailState.onRequest;
  const handleDeleteFn = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, []);
  const handleClearFn = useCallback(() => {
    setError(null);
  }, []);

  const handleNavigateToEdit = useCallback(() => {
    const isSupported =
      promoManagementConfigs.supportedTypes.includes(promoDetailType);
    if (isSupported) {
      history.push(
        `${FrontendRoutes.PromoEditPage.path.replace(
          ":promoId",
          promoId
        )}?type=${promoDetailType}`,
        {promoDetailType: promoDetailType}
      );
    }
  }, [history, promoId, promoDetailType]);
  const breadCrumbsButtons = () => {
    return (
      <div className="breadCrumbsButtonsWrapper">
        {isAllowedEditDetail && (
          <OvalButton
            type="primary"
            buttonText={Text.edit}
            icon={
              <img
                className="ovalButtonImg"
                src={Images.PencilWhite}
                alt="edit"
              />
            }
            onClick={() => handleNavigateToEdit()}
          />
        )}
        {isAllowedDeleteDetail && (
          <OvalButton
            type="primary"
            buttonText={Text.delete}
            wrapperClassName={"ovalButtonRed"}
            icon={
              <img
                className="ovalButtonImg"
                src={Images.DeleteWhite}
                alt="delete"
              />
            }
            onClick={() => handleDeleteFn()}
          />
        )}
      </div>
    );
  };

  const renderDeleteConfirmaitonMessage = useCallback(
    (promoType: string | undefined) => {
      let message = "-";
      switch (promoType) {
        case promoTypeListState[0].id:
          message = Text.deleteConfirmation.specialOffer;
          break;
        case promoTypeListState[1].id:
          message = Text.deleteConfirmation.flashDeal;
          break;
        case promoTypeListState[2].id:
          message = Text.deleteConfirmation.general;
          break;
      }
      return message;
    },
    []
  );

  return (
    <div className="promoDetailWrapper">
      {props.customProps?.breadcrumbs && (
        <BreadCrumbsComp crumbs={props.customProps.breadcrumbs}>
          {breadCrumbsButtons()}
        </BreadCrumbsComp>
      )}
      <PromoActionAlertView />
      {error && (
        <AlertComponent error={error} clearFn={() => handleClearFn()} />
      )}
      {loading && <GetMethodLoading />}
      {deletePromoState.onRequest && <LoadingWithMask />}
      <ModalConfirmation
        visible={!!showDeleteConfirmation}
        handleClose={() => {
          setShowDeleteConfirmation(false);
        }}
        buttonClick={() => {
          deletePromoAction(doDeletePromoRequest(promoId, promoDetailType));
        }}
        message={renderDeleteConfirmaitonMessage(promoDetailType)}
        type={TypeDeleteConfirmation}
        wrapClassName={"promoWrapper"}
      />
      {promoDetailData &&
        isDetailDataRequested &&
        (isSingleContract ? (
          <PromoDetailWithSingleContract
            promoDetailData={promoDetailData}
            promoType={promoDetailType}
          />
        ) : (
          <PromoDetailWithMultipleContract
            promoDetailData={promoDetailData}
            promoType={promoDetailType}
          />
        ))}
    </div>
  );
};

function PromoActionAlertView() {
  const updatePromoAction = useDispatch<Dispatch<UpdatePromoAction>>();
  const updatePromoState = useSelector(
    (state: RootStoreState) => state.updatePromo
  );
  const [success, setSuccess] = useState<string | null>("");
  useEffect(() => {
    if (updatePromoState.success === true) {
      updatePromoAction(doUpdatePromoInitial());
      setSuccess("Edit promo is success");
    }
  }, [updatePromoAction, updatePromoState]);

  return (
    <div>
      {success && (
        <AlertComponent message={success} type={"success"} timeout={3000} />
      )}
    </div>
  );
}

export default PromoDetailView;
