import { call, put, takeLatest } from 'redux-saga/effects';
import ContractApi from 'data/api/ContractApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './ContractListAction';

function* sagaTask(req: action.ContractListRequest) {
    const response = yield call(ContractApi.getContractList, req.filterParams);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doContractListSuccess(response.data.contractList));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = {
                ...response.error,
                action: () => action.doContractListRequest(req.filterParams),
            };
            yield put(action.doContractListFailure(newTimeoutError));
        } else {
            yield put(action.doContractListFailure(response.error));
        }
    }
}

export function* contractListSaga() {
    yield takeLatest(
        action.CONTRACT_LIST_REQUEST,
        sagaTask,
    );
}
