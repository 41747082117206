import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import RoleFormView, { RoleFormValue } from '../RoleForm/RoleFormView';
import { status } from '../RoleForm/RoleFormConfig';
import { useDispatch, useSelector } from 'react-redux';
import { doUpdateRoleInitial, doUpdateRoleRequest, UpdateRoleAction } from './RoleEditAction';
import RootStoreState from 'rootStore';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { HttpError } from 'data/Models';
import { FrontendRoutes } from 'navigation/Routes';
import { ModalError, TypePageNotAllowed } from 'components/ModalComponent';
import AlertComponent from 'components/AlertComponent';
import { LoadingWithMask, GetMethodLoading } from 'components/LoadingComponent';
import { useHistory, useParams } from 'react-router-dom';
import { GetPermissionsAction, doGetPermissionsInitial } from 'features/user/RoleForm/PermissionTree/PermissionTreeAction';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { RoleDetailAction, doRoleDetailRequest, doRoleDetailInitial } from '../RoleDetail/RoleDetailAction';

function RoleEditView() {
    const history = useHistory();
    const { roleId } = useParams<{ roleId: string }>();
    const [initialValues, setInitialValues] = useState<RoleFormValue>();

    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];
    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.RoleEditPage.permissionKey, permissions);
    const getPermissionsAction = useDispatch<Dispatch<GetPermissionsAction>>();
    const permissionsState = useSelector((state: RootStoreState) => state.permissions);
    const detailRoleAction = useDispatch<Dispatch<RoleDetailAction>>();
    const detailRoleState = useSelector((state: RootStoreState) => state.roleDetail);
    const [isAlreadyFetchDetail, setAlreadyFetchDetail] = useState(false);
    const updateRoleAction = useDispatch<Dispatch<UpdateRoleAction>>();
    const updateRoleState = useSelector((state: RootStoreState) => state.updateRole);
    const [error, setError] = useState<HttpError | null>(null);
    const [showNotAllowed, setShowNotAllowed] = useState<boolean>(false);

    useEffect(() => {
        if (profileState.data && !isAllowedEditDetail) {
            setShowNotAllowed(true);
        } else if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState, isAllowedEditDetail]);
    useEffect(() => {
        if (permissionsState.error) {
            getPermissionsAction(doGetPermissionsInitial());
            setError(permissionsState.error);
        }
    }, [getPermissionsAction, permissionsState]);
    useEffect(() => {
        if (!isAlreadyFetchDetail && isAllowedEditDetail) {
            setAlreadyFetchDetail(true);
            detailRoleAction(doRoleDetailRequest(roleId));
        } else if (detailRoleState.data) {
            const { data } = detailRoleState;

            const permissions: string[] = [];
            data.permission_group.forEach((item) => {
                item.permissions.forEach((subItem: { id: string, name: string }) => {
                    permissions.push(subItem.id);
                })
            });

            setInitialValues({
                roleName: data.name,
                status: data.is_active ? status[0].value : status[1].value,
                permissions: permissions,
            });
        } else if (detailRoleState.error) {
            detailRoleAction(doRoleDetailInitial());
            setError(detailRoleState.error);
        }
    }, [detailRoleAction, detailRoleState, isAlreadyFetchDetail, isAllowedEditDetail, roleId]);
    useEffect(() => {
        if (updateRoleState.success === true) {
            history.replace(FrontendRoutes.RoleManagement.path);
        } else if (updateRoleState.error) {
            updateRoleAction(doUpdateRoleInitial());
            setError(updateRoleState.error);
        }
    }, [updateRoleAction, updateRoleState, history]);
    const loading: boolean = profileState.onRequest || detailRoleState.onRequest || permissionsState.onRequest;
    const handleRoleUpdate = useCallback((values: any) => {
        const data = {
            id: roleId,
            name: values.roleName,
            permissions: values.permissions,
            isActive: values.status === status[0].value,
        };
        updateRoleAction(doUpdateRoleRequest(data));
    }, [updateRoleAction, roleId]);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);

    return (
        <div style={{ padding: '0 25px' }}>
            {!showNotAllowed ? (
                <>
                    {loading && <GetMethodLoading />}
                    {updateRoleState.onRequest && <LoadingWithMask />}
                    {error && <AlertComponent error={error} clearFn={handleClearFn}/>}
                    {isAllowedEditDetail && initialValues && (
                        <RoleFormView
                            initialValues={initialValues}
                            onFinish={handleRoleUpdate}
                        />
                    )}
                </>
            ) : (
                <ModalError
                    visible={true}
                    type={TypePageNotAllowed}
                />
            )}
        </div>
    );
}

export default RoleEditView;
