import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import AdminFormView, { AdminFormValue } from '../AdminForm/AdminFormView';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateAdminAction, doUpdateAdminInitial, doUpdateAdminRequest } from './AdminEditAction';
import RootStoreState from 'rootStore';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { ModalError, TypePageNotAllowed } from 'components/ModalComponent';
import { LoadingWithMask, GetMethodLoading } from 'components/LoadingComponent';
import AlertComponent from 'components/AlertComponent';
import { HttpError, UserFormData } from 'data/Models';
import { FrontendRoutes } from 'navigation/Routes';
import { useHistory, useParams } from 'react-router-dom';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { doRoleManagementInitial, RoleManagementAction } from 'features/user/RoleManagement/RoleManagementAction';
import { doRegionListInitial, RegionListAction } from 'features/region/RegionAction';
import { doBranchListInitial, BranchListAction } from 'features/branch/BranchManagement/BranchAction';
import { AdminDetailAction, doAdminDetailRequest, doAdminDetailInitial } from '../AdminDetail/AdminDetailAction';

function AdminEditView() {
    const history = useHistory();
    const { userId } = useParams<{ userId: string }>();
    const [initialValues, setInitialValues] = useState<AdminFormValue>();

    const roleListAction = useDispatch<Dispatch<RoleManagementAction>>();
    const roleListState = useSelector((state: RootStoreState) => state.roleManagement);
    const regionListAction = useDispatch<Dispatch<RegionListAction>>();
    const regionListState = useSelector((state: RootStoreState) => state.regionList);
    const branchListAction = useDispatch<Dispatch<BranchListAction>>();
    const branchListState = useSelector((state: RootStoreState) => state.branchList);
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];
    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.UserEditPage.permissionKey, permissions);

    const adminDetailAction = useDispatch<Dispatch<AdminDetailAction>>();
    const adminDetailState = useSelector((state: RootStoreState) => state.adminDetail);
    const [isAlreadyFetchDetail, setAlreadyFetchDetail] = useState(false);

    const updateAdminAction = useDispatch<Dispatch<UpdateAdminAction>>();
    const updateAdminState = useSelector((state: RootStoreState) => state.updateAdmin);
    const [error, setError] = useState<HttpError | null>(null);
    const [showNotAllowed, setShowNotAllowed] = useState<boolean>(false);

    useEffect(() => {
        if (profileState.data && !isAllowedEditDetail) {
            setShowNotAllowed(true);
        } else if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState, isAllowedEditDetail]);
    useEffect(() => {
        if (roleListState.error) {
            roleListAction(doRoleManagementInitial());
            setError(roleListState.error);
        }
    }, [roleListAction, roleListState]);
    useEffect(() => {
        if (regionListState.error) {
            regionListAction(doRegionListInitial());
            setError(regionListState.error);
        }
    }, [regionListAction, regionListState]);
    useEffect(() => {
        if (branchListState.error) {
            branchListAction(doBranchListInitial());
            setError(branchListState.error);
        }
    }, [branchListAction, branchListState]);
    useEffect(() => {
        if (!isAlreadyFetchDetail && isAllowedEditDetail) {
            setAlreadyFetchDetail(true);
            adminDetailAction(doAdminDetailRequest(userId));
        } else if (adminDetailState.data) {
            const { data } = adminDetailState;
            const allRegionIds = regionListState?.data?.data?.map((item) => item.id);
            const allBranchIds = branchListState?.data?.data?.map((item) => item.id);
            const regionIds = data.is_all_regions ? allRegionIds : (
                data.regions?.length === 0 ? undefined
                    : data.regions?.map((item) => item.id)
            );
            const branchIds = data.is_all_outlets ? allBranchIds : (
                data.outlets?.length === 0 ? undefined
                    : data.outlets?.map((item) => item.id)
            );
            let values: AdminFormValue = {
                fullName: data.name,
                email: data.email,
                role: data.role.id,
                status: data.status,
            };

            if (regionIds) {
                values = {
                    ...values,
                    regions: regionIds,
                    branchs: branchIds,
                };
            }
            setInitialValues(values)
        } else if (adminDetailState.error) {
            adminDetailAction(doAdminDetailInitial());
            setError(adminDetailState.error);
        }
    }, [isAlreadyFetchDetail, isAllowedEditDetail, userId, adminDetailAction, adminDetailState, branchListState, regionListState]);
    useEffect(() => {
        if (updateAdminState.success === true) {
            history.replace(FrontendRoutes.AdminManagement.path);
        } else if (updateAdminState.error) {
            updateAdminAction(doUpdateAdminInitial());
            setError(updateAdminState.error);
        }
    }, [updateAdminAction, updateAdminState, history]);
    const loading: boolean = profileState.onRequest || adminDetailState.onRequest || roleListState.onRequest|| branchListState.onRequest || regionListState.onRequest;
    const handleAdminUpdate = useCallback((values: any) => {
        const updateData : UserFormData = {
            id: userId,
            fullName: values.fullName,
            email: values.email,
            regions: values.regions.join(','),
            branchs: values.branchs.join(','),
            role: values.role,
            status: values.status || initialValues?.status,
        };
        updateAdminAction(doUpdateAdminRequest(updateData));
    }, [updateAdminAction, initialValues, userId]);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);

    return (
        <div style={{ padding: '0 25px' }}>
            {!showNotAllowed ? (
                <>
                    {loading && <GetMethodLoading />}
                    {updateAdminState.onRequest && <LoadingWithMask />}
                    {error && (<AlertComponent error={error} clearFn={handleClearFn}/>)}
                    {isAllowedEditDetail && initialValues && <AdminFormView
                        mode="edit"
                        initialValues={initialValues}
                        onFinish={handleAdminUpdate}
                    />}
                </>
            ) : (
                <ModalError
                    visible={true}
                    type={TypePageNotAllowed}
                />
            )}
        </div>
    );
}

export default AdminEditView;
