import {
    DELETE_ROLE_INITIAL,
    DELETE_ROLE_REQUEST,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILURE,
    DeleteRoleAction,
} from './DeleteRoleAction';
import { DefaultStoreState } from 'common/States';

export const defaultState: DefaultStoreState = {
    onRequest: false,
    success: false,
    error: null,
};

export function deleteRoleReducer(
    state: DefaultStoreState = defaultState,
    action: DeleteRoleAction,
): DefaultStoreState {
    switch (action.type) {
        case DELETE_ROLE_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, success: defaultState.success, error: defaultState.error };
        case DELETE_ROLE_REQUEST:
            return { ...state, onRequest: true, success: false, error: defaultState.error };
        case DELETE_ROLE_SUCCESS:
            return { ...state, onRequest: false, success: true, error: defaultState.error };
        case DELETE_ROLE_FAILURE:
            return { ...state, onRequest: false, success: false, error: action.error };
        default:
            return state;
    }
}
