import { HttpError, TNCDetail } from 'data/Models';

export const TNC_DETAIL_INITIAL = 'TNC_DETAIL_INITIAL';
export type TNC_DETAIL_INITIAL = typeof TNC_DETAIL_INITIAL;

export const TNC_DETAIL_REQUEST = 'TNC_DETAIL_REQUEST';
export type TNC_DETAIL_REQUEST = typeof TNC_DETAIL_REQUEST;

export const TNC_DETAIL_SUCCESS = 'TNC_DETAIL_SUCCESS';
export type TNC_DETAIL_SUCCESS = typeof TNC_DETAIL_SUCCESS;

export const TNC_DETAIL_FAILURE = 'TNC_DETAIL_FAILURE';
export type TNC_DETAIL_FAILURE = typeof TNC_DETAIL_FAILURE;

interface TNCDetailInitial {
    type: TNC_DETAIL_INITIAL;
}

export interface TNCDetailRequest {
    type: TNC_DETAIL_REQUEST;
}

interface TNCDetailSuccess {
    type: TNC_DETAIL_SUCCESS;
    data: TNCDetail;
}

interface TNCDetailFailure {
    type: TNC_DETAIL_FAILURE;
    error: HttpError;
}

export type TNCDetailAction = TNCDetailInitial | TNCDetailRequest | TNCDetailSuccess | TNCDetailFailure;

export function doTNCDetailInitial(): TNCDetailInitial {
    return {
        type: TNC_DETAIL_INITIAL
    };
}

export function doTNCDetailRequest(): TNCDetailRequest {
    return {
        type: TNC_DETAIL_REQUEST,
    };
}

export function doTNCDetailSuccess(data: TNCDetail): TNCDetailSuccess {
    return {
        type: TNC_DETAIL_SUCCESS,
        data: data
    };
}

export function doTNCDetailFailure(error: HttpError): TNCDetailFailure {
    return {
        type: TNC_DETAIL_FAILURE,
        error: error
    };
}
