import {call, put, takeLatest} from 'redux-saga/effects';
import OrderApi from 'data/api/OrderApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './OrderManagementDownloadAction';

function* orderManagementDownloadTask(orderManagementDownloadAction: action.OrderManagementDownloadRequest) {
    const { filterParams } = orderManagementDownloadAction;
    let queryParams: any = {};
    Object.entries(filterParams).forEach(entry => {
        const [entryKey, entryValue] = entry;
        if (entryValue) {
            queryParams[entryKey] = entryValue;
        }
    });
    const response = yield call(OrderApi.getOrderListDownload, queryParams);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doOrderManagementDownloadSuccess(response.data.orderListDownload));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doOrderManagementDownloadRequest(queryParams) });
            yield put(action.doOrderManagementDownloadFailure(newTimeoutError));
        } else {
            yield put(action.doOrderManagementDownloadFailure(response.error));
        }
    }
}
export function* orderManagementDownloadSaga() {
    yield takeLatest(
        action.ORDER_MANAGEMENT_DOWNLOAD_REQUEST,
        orderManagementDownloadTask,
    );
}