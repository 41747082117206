import React, { useEffect, useState } from 'react';
import { Alert } from 'antd';
import { HttpError } from 'data/Models';
import { UnauthorizedCode, UnauthorizedPermissionCode, AbortError } from 'common/HttpCode';
import { ModalError, ModalTimeout, TypePageNotAllowed, TypeSessionExpired, TypeTimeOut } from 'components/ModalComponent';
import './index.css';

const AlertComponent: React.FC<{
    message?: string;
    type?: any;
    closeText?: string;
    timeout?: number | undefined;
    clearFn?: () => void,
    visible?: boolean,
    error?: HttpError | null;
}> = ({
    message = 'Error Message',
    type = "error",
    closeText = "OK",
    timeout = null,
    clearFn = () => {},
    visible = true,
    error = null
}) => {
    const [isVisible, setVisibility] = useState(visible);
    const unauthorizedPermission = error?.code === UnauthorizedPermissionCode && isVisible;
    const unauthorized = error?.code === UnauthorizedCode && isVisible;
    const apiTimeout = error?.code === AbortError && isVisible;

    useEffect(() => {
        let mounted = true;
        let timer: ReturnType<typeof setTimeout>;
        if (timeout && isVisible && mounted) {
            timer = setTimeout(() => {
                setVisibility(false);
                clearFn();
            }, timeout);
        }
        return () => {
            if(timer && timeout && isVisible && mounted) {
                mounted = false;
                clearTimeout(timer);
            }
        }
    }, [timeout, isVisible, clearFn]);

    return (
        <div>
            {unauthorized ? (
                <ModalError
                    visible={true}
                    handleClose={clearFn}
                    type={TypeSessionExpired}
                />
            ) : (unauthorizedPermission ? (
                <ModalError
                    visible={true}
                    handleClose={clearFn}
                    type={TypePageNotAllowed}
                />
            ) : (apiTimeout ? (
                <ModalTimeout
                    visible={true}
                    handleClose={clearFn}
                    type={TypeTimeOut}
                    buttonClick={error?.action}
                />
            ) : (
                isVisible && <Alert
                    className={`alertComponentWrapper`}
                    message={error?.message || message}
                    type={type}
                    closeText={closeText}
                    onClose={clearFn}
                />
            )))}
        </div>
    )
};

export default AlertComponent;
