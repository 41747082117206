import { HttpError, PanelList } from 'data/Models';
import { PanelListQuery } from 'common/States';

export const PANEL_MANAGEMENT_INITIAL = 'PANEL_MANAGEMENT_INITIAL';
export type PANEL_MANAGEMENT_INITIAL = typeof PANEL_MANAGEMENT_INITIAL;

export const PANEL_MANAGEMENT_REQUEST = 'PANEL_MANAGEMENT_REQUEST';
export type PANEL_MANAGEMENT_REQUEST = typeof PANEL_MANAGEMENT_REQUEST;

export const PANEL_MANAGEMENT_SUCCESS = 'PANEL_MANAGEMENT_SUCCESS';
export type PANEL_MANAGEMENT_SUCCESS = typeof PANEL_MANAGEMENT_SUCCESS;

export const PANEL_MANAGEMENT_FAILURE = 'PANEL_MANAGEMENT_FAILURE';
export type PANEL_MANAGEMENT_FAILURE = typeof PANEL_MANAGEMENT_FAILURE;

interface PanelManagementInitial {
    type: PANEL_MANAGEMENT_INITIAL;
}

export interface PanelManagementRequest {
    type: PANEL_MANAGEMENT_REQUEST;
    filterParams: PanelListQuery;
}

interface PanelManagementSuccess {
    type: PANEL_MANAGEMENT_SUCCESS;
    data: PanelList;
}

interface PanelManagementFailure {
    type: PANEL_MANAGEMENT_FAILURE;
    error: HttpError;
}

export type PanelManagementAction = PanelManagementInitial | PanelManagementRequest | PanelManagementSuccess | PanelManagementFailure;

export function doPanelManagementInitial(): PanelManagementInitial {
    return {
        type: PANEL_MANAGEMENT_INITIAL,
    };
}

export function doPanelManagementRequest(filterParams: PanelListQuery): PanelManagementRequest {
    return {
        type: PANEL_MANAGEMENT_REQUEST,
        filterParams: filterParams
    };
}

export function doPanelManagementSuccess(data: PanelList): PanelManagementSuccess {
    return {
        type: PANEL_MANAGEMENT_SUCCESS,
        data: data,
    };
}

export function doPanelManagementFailure(error: HttpError): PanelManagementFailure {
    return {
        type: PANEL_MANAGEMENT_FAILURE,
        error: error,
    };
}
