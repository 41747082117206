import { HttpError, RegionList } from "data/Models";

export const REGION_LIST_INITIAL = 'REGION_LIST_INITIAL';
export type REGION_LIST_INITIAL = typeof REGION_LIST_INITIAL;

export const REGION_LIST_REQUEST = 'REGION_LIST_REQUEST';
export type REGION_LIST_REQUEST = typeof REGION_LIST_REQUEST;

export const REGION_LIST_SUCCESS = 'REGION_LIST_SUCCESS';
export type REGION_LIST_SUCCESS = typeof REGION_LIST_SUCCESS;

export const REGION_LIST_FAILURE = 'REGION_LIST_FAILURE';
export type REGION_LIST_FAILURE = typeof REGION_LIST_FAILURE;

interface RegionListInitial {
    type: REGION_LIST_INITIAL;
}

interface RegionListRequest {
    type: REGION_LIST_REQUEST;
}

interface RegionListSuccess {
    type: REGION_LIST_SUCCESS;
    data: RegionList;
}

interface RegionListFailure {
    type: REGION_LIST_FAILURE;
    error: HttpError;
}

export type RegionListAction = RegionListInitial | RegionListRequest | RegionListSuccess | RegionListFailure;

export function doRegionListInitial(): RegionListInitial {
    return {
        type: REGION_LIST_INITIAL,
    };
}

export function doRegionListRequest(): RegionListRequest {
    return {
        type: REGION_LIST_REQUEST,
    };
}

export function doRegionListSuccess(data: RegionList): RegionListSuccess {
    return {
        type: REGION_LIST_SUCCESS,
        data: data,
    };
}

export function doRegionListFailure(error: HttpError): RegionListFailure {
    return {
        type: REGION_LIST_FAILURE,
        error: error,
    };
}
