import React, {useCallback, useEffect, useState} from "react";
import {Button, DatePicker, Form, Input} from "antd";
import {FormInstance} from "antd/lib/form";
import {ColumnsType} from "antd/lib/table";
import {
  formRules,
  maxContractIds,
  promoTypes,
  promoSource,
  uiDateFormat,
} from "features/promo/PromoForm/PromoFormConfig";
import ContractInputView from "features/contract/ContractList/ContractInputView";
import {PeriodDateDivider, PromoFormValue, Text} from "./Commons";
import {Editor as TinyMCEEditor} from "tinymce";
import {SelectComponent} from "components/SelectComponent";
import ImageUpload from "components/ImageUpload";
import RichTextEditor from "components/RichTextEditor";
import AppTable from "components/AppTableComponent";
import {ContractDetail} from "data/Models";
import {formatDate} from "common/CommonUtils";
import ContractOptionsView from "./ContractOptionsView";

interface PromoSpecialOfferFormViewProps {
  mode: "create" | "edit";
  form: FormInstance;
  initialValues: PromoFormValue;
  promoType: string | undefined;
  source: string | undefined;
  imageState: "showImage" | "hideImage";
  contracts: ContractDetail[];
  descriptionId: string | undefined;
  descriptionEn: string | undefined;
  tncId: string | undefined;
  tncEn: string | undefined;
  handlePromoTypeSelected: (option: {label: string; value: string}) => void;
  handlePromoSource: (option: {label: string; value: string}) => void;
  handleContractLoaded: (values: string[]) => void;
  handleContractSelected: (value: string) => void;
  handleImageError: (error: string | null) => void;
  handleImageChange: (value: string | undefined) => void;
  handleEditContract: (value: ContractDetail) => void;
  handleDeleteContract: (value: string) => void;
  handleDescriptionIdChange: (content: string, editor: TinyMCEEditor) => void;
  handleDescriptionEnChange: (content: string, editor: TinyMCEEditor) => void;
  handleTncIdChange: (content: string, editor: TinyMCEEditor) => void;
  handleTncEnChange: (content: string, editor: TinyMCEEditor) => void;
  handlePeriodeDateStartFilter: (date: any) => boolean;
  handlePeriodeDateStartSelect: (date: any) => void;
  handlePeriodeDateEndFilter: (date: any) => boolean;
  handlePeriodeDateEndSelect: (date: any) => void;
  handlePublishDateStartFilter: (date: any) => boolean;
  handlePublishDateStartSelect: (date: any) => void;
  handlePublishDateEndFilter: (date: any) => boolean;
  handlePublishDateEndSelect: (date: any) => void;
  handleCancel: () => void;
  onFinish: any;
}

function PromoSpecialOfferFormView(props: PromoSpecialOfferFormViewProps) {
  const [editableContract, setEditableContract] = useState<number | null>(null);
  const [newContractName, setNewContractName] = useState<string>("");
  const [newContractNameEn, setNewContractNameEn] = useState<string>("");
  const [errorContractName, setErrorContractName] = useState<string>("");
  const [errorContractNameEn, setErrorContractNameEn] = useState<string>("");
  const [shownPopOver, setShownPopover] = useState<number | null>(null);
  const [tableScroolHeight, setTableScroolHeight] = useState<number>();

  const columnItemsView = useCallback(
    (key: string, items: any[], index, maxItemLength, maxLineHeight) => {
      let newItems = "";
      if (items?.length > 0) {
        const slicedItems = items.slice(0, maxItemLength);
        newItems = slicedItems
          .map((item) => {
            return `${item}`;
          })
          .join(", ");
        if (items.length > 5) {
          newItems = `${newItems}...`;
        }
      }
      return (
        <div className={"listItemEllipsis"}>
          {items.length === 0 ? (
            "-"
          ) : (
            <p id={`columnItemList${key}${index}`} key={`columnItemList`}>
              {newItems}
            </p>
          )}
        </div>
      );
    },
    []
  );
  const handleContractSelected = useCallback(
    (value: string) => {
      if (props.contracts.length < maxContractIds) {
        props.handleContractSelected(value);
      }
    },
    [props]
  );
  const handleStartEditContract = useCallback(
    (index: number) => {
      setShownPopover(null);
      setEditableContract(index);
      setErrorContractName("");
      setErrorContractNameEn("");
      const selected = props.contracts[index];

      if (selected.alias) {
        setNewContractName(selected.alias);
      } else {
        setNewContractName(selected.name);
      }

      if (selected.alias_en) {
        setNewContractNameEn(selected.alias_en);
      } else {
        setNewContractNameEn(selected.name_en);
      }
    },
    [props.contracts]
  );
  const handleFinishEditContract = useCallback(
    (contractId: string) => {
      if (newContractName === "") {
        setErrorContractName("Cannot be empty");
      }
      if (newContractNameEn === "") {
        setErrorContractNameEn("Cannot be empty");
      }

      if (newContractName !== "" && newContractNameEn !== "") {
        const selected = props.contracts.find((item) => item.id === contractId);
        if (selected) {
          selected.alias = newContractName;
          selected.alias_en = newContractNameEn;

          props.handleEditContract(selected);
          setEditableContract(null);
          setNewContractName("");
          setNewContractNameEn("");
        }
      }
    },
    [newContractName, newContractNameEn, props]
  );
  const handleDeleteContract = useCallback(
    (value) => {
      props.handleDeleteContract(value);
      setShownPopover(null);
    },
    [props]
  );

  const containerId = document.getElementById(Text.wrapperId);
  const columns: ColumnsType<ContractDetail> = [
    {
      title: Text.no,
      dataIndex: "",
      key: "",
      render: (text, row, index: number) => {
        return <span className="indexStyle">{index + 1}</span>;
      },
      width: "5%",
    },
    {
      title: Text.contractId,
      dataIndex: "id",
      key: "id",
      render: (text: string) => {
        return <span>{text ? text : "-"}</span>;
      },
      width: "10%",
    },
    {
      title: Text.contractName.id,
      dataIndex: "name",
      key: "name",
      render: (text: string, record: ContractDetail, index: number) => {
        if (index === editableContract) {
          return (
            <span>
              <Input
                bordered
                maxLength={128}
                value={newContractName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setNewContractName(event.target.value)
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleFinishEditContract(record.id);
                  }
                }}
              />
              {errorContractName && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  {errorContractName}
                </div>
              )}
            </span>
          );
        } else {
          return <span>{record.alias ? record.alias : text ? text : "-"}</span>;
        }
      },
      width: "11%",
    },
    {
      title: Text.contractName.en,
      dataIndex: "name_en",
      key: "name_en",
      render: (text: string, record: ContractDetail, index: number) => {
        if (index === editableContract) {
          return (
            <span>
              <Input
                bordered
                maxLength={128}
                value={newContractNameEn}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setNewContractNameEn(event.target.value)
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleFinishEditContract(record.id);
                  }
                }}
              />
              {errorContractNameEn && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  {errorContractNameEn}
                </div>
              )}
            </span>
          );
        } else {
          return (
            <span>{record.alias_en ? record.alias_en : text ? text : "-"}</span>
          );
        }
      },
      width: "11%",
    },
    {
      title: Text.testName.id,
      dataIndex: ["tests"],
      key: "tests_id",
      render: (text: string, record: ContractDetail, index: number) => {
        return columnItemsView(
          "tests_id",
          record?.tests.map((item) => item.name),
          index,
          5,
          60
        );
      },
      width: "10%",
    },
    {
      title: Text.testName.en,
      dataIndex: ["tests"],
      key: "tests_en",
      render: (text: string, record: ContractDetail, index: number) => {
        return columnItemsView(
          "tests_en",
          record?.tests.map((item) => item.name_en),
          index,
          5,
          60
        );
      },
      width: "10%",
    },
    {
      title: Text.discount,
      dataIndex: "discount",
      key: "discount",
      render: (text: string) => {
        return <span className="nameStyle">{text ? `${text}%` : "-"}</span>;
      },
      width: "8%",
    },
    {
      title: Text.branch,
      dataIndex: ["outlets"],
      key: "outlets",
      render: (text: string, record: ContractDetail, index: number) => {
        return columnItemsView(
          "outlets",
          record?.outlets.map((item) => item.name),
          index,
          5,
          60
        );
      },
      width: "10%",
    },
    {
      title: Text.contractDate.start,
      dataIndex: "start_date",
      key: "start_date",
      render: (text: string) => {
        return (
          <span className="nameStyle">
            {text ? formatDate(text, "d MMM yyyy HH:mm") : "-"}
          </span>
        );
      },
      width: "10%",
    },
    {
      title: Text.contractDate.end,
      dataIndex: "end_date",
      key: "end_date",
      render: (text: string) => {
        return (
          <span className="nameStyle">
            {text ? formatDate(text, "d MMM yyyy HH:mm") : "-"}
          </span>
        );
      },
      width: "10%",
    },
    {
      title: "",
      key: "",
      render: (text: string, record: ContractDetail, index: number) => {
        return (
          <ContractOptionsView
            containerId={containerId}
            index={index}
            record={record}
            isShown={shownPopOver === index}
            onPopoverVisibilityChange={(shownPopOver) =>
              setShownPopover(shownPopOver)
            }
            handleEditFn={handleStartEditContract}
            handleDeleteFn={handleDeleteContract}
          />
        );
      },
      width: "5%",
    },
  ];

  useEffect(() => {
    const contractListTable = document.getElementById("contractList");
    const contractListHeader =
      document.getElementsByClassName("ant-table-thead")[0];
    if (contractListTable && contractListHeader) {
      if (props.contracts.length === 5) {
        setTableScroolHeight(
          contractListTable.offsetHeight - contractListHeader.clientHeight
        );
      }
    }
  }, [props.contracts.length]);

  return (
    <Form layout="vertical" form={props.form} onFinish={props.onFinish}>
      <Form.Item
        label={Text.promoType}
        name="promoType"
        rules={formRules.promoType}
      >
        <SelectComponent
          selectProps={{
            placeholder: Text.promoType,
            disabled: props.mode === "edit",
          }}
          selectedOption={props.promoType}
          data={promoTypes}
          onMapOption={(value) => value}
          onSelectedChange={props.handlePromoTypeSelected}
        />
      </Form.Item>
      <Form.Item label={Text.source} name="source" rules={formRules.source}>
        <SelectComponent
          selectProps={{
            placeholder: Text.source,
          }}
          selectedOption={props.initialValues.source}
          data={promoSource}
          onMapOption={(value) => value}
          onSelectedChange={props.handlePromoSource}
        />
      </Form.Item>
      <Form.Item label={Text.title.id} name="titleId" rules={formRules.title}>
        <Input maxLength={128} bordered />
      </Form.Item>
      <Form.Item label={Text.title.en} name="titleEn" rules={formRules.title}>
        <Input maxLength={128} bordered />
      </Form.Item>
      <Form.Item
        label={Text.image}
        name="coverImage"
        rules={formRules.coverImage(props.form)}
      >
        <ImageUpload
          defaultImage={props.initialValues.coverImage?.image}
          imageState={props.imageState}
          onValidateError={props.handleImageError}
          onImageChange={props.handleImageChange}
        />
      </Form.Item>
      <Form.Item
        label={Text.contractId}
        name="contracts"
        rules={formRules.contracts(props.form)}
      >
        <ContractInputView
          selectedOption={props.initialValues.contractId}
          onSelect={handleContractSelected}
          onLoad={props.handleContractLoaded}
        />
      </Form.Item>
      <div className="contractListWrapper">
        <div className={"contractListHeader"}>{Text.contractList}</div>
        <AppTable
          id={"contractList"}
          scroll={{y: tableScroolHeight}}
          columns={columns}
          dataSource={props.contracts}
          rowKey={(record) => record.id}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "rowWhite" : "rowBlue"
          }
          showPagination={false}
        />
      </div>
      <Form.Item
        label={Text.description.id}
        name="descriptionId"
        rules={formRules.description}
      >
        <RichTextEditor
          htmlValue={props.descriptionId}
          onEditorChange={props.handleDescriptionIdChange}
        />
      </Form.Item>
      <Form.Item
        label={Text.description.en}
        name="descriptionEn"
        rules={formRules.description}
      >
        <RichTextEditor
          htmlValue={props.descriptionEn}
          onEditorChange={props.handleDescriptionEnChange}
        />
      </Form.Item>
      <Form.Item label={Text.tnc.id} name="tncId" rules={formRules.tnc}>
        <RichTextEditor
          htmlValue={props.tncId}
          onEditorChange={props.handleTncIdChange}
        />
      </Form.Item>
      <Form.Item label={Text.tnc.en} name="tncEn" rules={formRules.tnc}>
        <RichTextEditor
          htmlValue={props.tncEn}
          onEditorChange={props.handleTncEnChange}
        />
      </Form.Item>
      <div style={{display: "flex"}}>
        <Form.Item
          label={Text.periodeDate.start}
          name="periodeDateStart"
          rules={formRules.date}
          style={{width: "auto"}}
        >
          <DatePicker
            showTime
            format={uiDateFormat}
            disabledDate={props.handlePeriodeDateStartFilter}
            onSelect={props.handlePeriodeDateStartSelect}
          />
        </Form.Item>
        <PeriodDateDivider />
        <Form.Item
          label={Text.periodeDate.end}
          name="periodeDateEnd"
          rules={formRules.date}
        >
          <DatePicker
            showTime
            format={uiDateFormat}
            disabledDate={props.handlePeriodeDateEndFilter}
            onSelect={props.handlePeriodeDateEndSelect}
          />
        </Form.Item>
      </div>
      <div style={{display: "flex"}}>
        <Form.Item
          label={Text.publishDate.start}
          name="publishDateStart"
          rules={formRules.date}
          style={{width: "auto"}}
        >
          <DatePicker
            showTime
            format={uiDateFormat}
            disabledDate={props.handlePublishDateStartFilter}
            onSelect={props.handlePublishDateStartSelect}
          />
        </Form.Item>
        <PeriodDateDivider />
        <Form.Item
          label={Text.publishDate.end}
          name="publishDateEnd"
          rules={formRules.date}
        >
          <DatePicker
            showTime
            format={uiDateFormat}
            disabledDate={props.handlePublishDateEndFilter}
            onSelect={props.handlePublishDateEndSelect}
          />
        </Form.Item>
      </div>
      <div className="promoFormViewAction">
        <Button type="primary" htmlType="submit">
          {Text.submit}
        </Button>
        <span style={{flex: 1}} />
        <Button type="text" onClick={props.handleCancel}>
          {Text.cancel}
        </Button>
      </div>
    </Form>
  );
}

export default PromoSpecialOfferFormView;
