import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import RootStoreState from 'rootStore';
import { HttpError } from 'data/Models';
import { FrontendRoutes } from 'navigation/Routes';
import { isPermissionAllowed } from 'common/PermissionUtils';
import AlertComponent from 'components/AlertComponent';
import * as status from 'components/StatusInput'
import { AgeUnit } from 'components/AgeInput';
import { LoadingWithMask, GetMethodLoading } from 'components/LoadingComponent';
import { ModalError, TypePageNotAllowed } from 'components/ModalComponent';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { doTestDetailInitial, doTestDetailRequest, TestDetailAction } from '../TestDetail/TestDetailAction';
import { doUpdateTestInitial, doUpdateTestRequest, UpdateTestAction } from './TestEditAction';
import TestFormView, { TestFormValue } from '../TestForm/TestFormView';

function TestEditView() {
    const history = useHistory();
    const { testId } = useParams<{ testId: string }>();
    const [initialValues, setInitialValues] = useState<TestFormValue>();

    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];
    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.TestEditPage.permissionKey, permissions);
    const testDetailAction = useDispatch<Dispatch<TestDetailAction>>();
    const testDetailState = useSelector((state: RootStoreState) => state.testDetail);
    const [isAlreadyFetchDetail, setAlreadyFetchDetail] = useState(false);
    const updateTestAction = useDispatch<Dispatch<UpdateTestAction>>();
    const updateTestState = useSelector((state: RootStoreState) => state.updateTest);
    const [error, setError] = useState<HttpError | null>(null);
    const [showNotAllowed, setShowNotAllowed] = useState<boolean>(false);

    useEffect(() => {
        if (profileState.data && !isAllowedEditDetail) {
            setShowNotAllowed(true);
        } else if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState, isAllowedEditDetail]);
    useEffect(() => {
        if (!isAlreadyFetchDetail && isAllowedEditDetail) {
            setAlreadyFetchDetail(true);
            testDetailAction(doTestDetailRequest(testId));
        } else if (testDetailState.data) {
            const { data } = testDetailState;

            if (data.types[0] == '' || data.types[0] == 'general') {
                setInitialValues({
                    name: data.name,
                    nameEn: data.name_en,
                    types: [],
                    beginAge: data.requirement.age_min,
                    beginAgeUnit: AgeUnit.Days,
                    endAge: data.requirement.age_max,
                    endAgeUnit: AgeUnit.Days,
                    status: data.is_active ? status.active : status.inactive
                });
            } else {
                setInitialValues({
                    name: data.name,
                    nameEn: data.name_en,
                    types: data.types,
                    beginAge: data.requirement.age_min,
                    beginAgeUnit: AgeUnit.Days,
                    endAge: data.requirement.age_max,
                    endAgeUnit: AgeUnit.Days,
                    status: data.is_active ? status.active : status.inactive
                });
            }

        } else if (testDetailState.error) {
            testDetailAction(doTestDetailInitial());
            setError(testDetailState.error);
        }
    }, [isAlreadyFetchDetail, isAllowedEditDetail, testDetailAction, testDetailState, testId]);

    useEffect(() => {
        if (updateTestState.success === true) {
            history.replace(FrontendRoutes.TestManagement.path);
        } else if (updateTestState.error) {
            updateTestAction(doUpdateTestInitial());
            setError(updateTestState.error);
        }
    }, [updateTestAction, updateTestState, history]);
    const loading: boolean = profileState.onRequest || testDetailState.onRequest;

    const handleTestUpdate = useCallback((values: TestFormValue) => {
        const updateData = {
            id: testId,
            types: values.types,
            beginAge: values.beginAge,
            endAge: values.endAge,
            isActive: values.status === status.active,
        };
        updateTestAction(doUpdateTestRequest(updateData));
    }, [updateTestAction, testId]);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);

    return (
        <div style={{ padding: '0 25px' }}>
            {!showNotAllowed ? (
                <>
                    {loading && <GetMethodLoading />}
                    {updateTestState.onRequest && <LoadingWithMask />}
                    {error && <AlertComponent error={error} clearFn={handleClearFn} />}
                    {!loading && isAllowedEditDetail && initialValues && (
                        <TestFormView
                            initialValues={initialValues}
                            onFinish={handleTestUpdate}
                        />
                    )}
                </>
            ) : (
                <ModalError
                    visible={true}
                    type={TypePageNotAllowed}
                />
            )}
        </div>
    );
}

export default TestEditView;
