import { ProfileDetail } from "data/Models";
import { LoginResult } from "../../features/login/LoginState";

export const LocalStorageKeys = {
    KEY_ACCESS_TOKEN: 'KEY_ACCESS_TOKEN',
    KEY_EXPIRED_AT: 'KEY_EXPIRED_AT',
    KEY_PROFILE: 'KEY_PROFILE',
};

export const LocalStorageUtils = {
    get: (key: any) => {
        const value = localStorage.getItem(key);

        if (value) {
            return JSON.parse(value);
        }
        return null;
    },
    set: (key: any, value: any) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
    remove: (key: any) => localStorage.removeItem(key),
};

export const ProfileStorageUtils = {
    get: (): ProfileDetail => {
        return LocalStorageUtils.get(LocalStorageKeys.KEY_PROFILE);
    },
    set: (value: any) => {
        LocalStorageUtils.set(LocalStorageKeys.KEY_PROFILE, value.profileDetail);
    },
    remove: () => {
        LocalStorageUtils.remove(LocalStorageKeys.KEY_PROFILE);
    },
};

export const LocalAuthStorage = {
    init (onDone: (str: string) => void) : void {
        const token = LocalStorageUtils.get(LocalStorageKeys.KEY_ACCESS_TOKEN) || '';
        onDone(token);
        // No operation. This required in order to satisfy AuthStorage interface
    },
    save(loginResult: LoginResult) {
        LocalStorageUtils.set(LocalStorageKeys.KEY_ACCESS_TOKEN, loginResult.token);
        LocalStorageUtils.set(LocalStorageKeys.KEY_EXPIRED_AT, loginResult.expiredAt);
        return Promise.resolve(loginResult.token);
    },
    
    clear() {
        LocalStorageUtils.remove(LocalStorageKeys.KEY_ACCESS_TOKEN);
        LocalStorageUtils.remove(LocalStorageKeys.KEY_EXPIRED_AT);
    },
    
    getValidToken(): string {
        const now = new Date().getTime();
        const expiredAt = Date.parse(LocalStorageUtils.get(LocalStorageKeys.KEY_EXPIRED_AT) || "");
        if (expiredAt < now) {
            // skip the expired date temporary
            // LocalAuthStorage.clear();
            // throw Error("Token is Expire");
        }
    
        const accessToken = LocalStorageUtils.get(LocalStorageKeys.KEY_ACCESS_TOKEN);
        if (accessToken == null) {
            throw Error("Doesn't have Token");
        }

        return accessToken;
    },
    
    isAuthenticated(): boolean {
        try {
            return LocalAuthStorage.getValidToken() != null;
        } catch(error) {
            return false;
        }
    },

    logout() {
        LocalAuthStorage.clear();
        ProfileStorageUtils.remove();
    }
};
