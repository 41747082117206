import React, { useCallback, useEffect, useState } from 'react';
import './index.css';
import { Input, Select } from 'antd';

const { Option } = Select;

export const AgeUnit = {
    Days: 'Days',
    Months: 'Months',
    Years: 'Years'
};

interface Props {
    value: number;
    onChange: (value: number) => void;
}

function AgeInputView(props: Props) {
    const [selectedValue, setSelectedValue] = useState<number>();
    const [selectedUnit, setSelectedUnit] = useState(AgeUnit.Days);

    const options = [
        AgeUnit.Days,
        AgeUnit.Months,
        AgeUnit.Years
    ];

    useEffect(() => {
        if (selectedValue === undefined && props.value) {
            setSelectedValue(props.value);
        }
    }, [selectedValue, setSelectedValue, props]);

    const handleChange = useCallback((value: number, unit: string) => {
        let valueInDays = 0;

        switch (unit) {
            case AgeUnit.Days:
                valueInDays = value;
                break;
            case AgeUnit.Months:
                valueInDays = value * 30;
                break;
            case AgeUnit.Years:
                valueInDays = value * 365;
                break;
        }
        
        props.onChange(valueInDays);
    }, [props]);
    const handleAgeValueVhange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = 0;
        
        if (selectedValue && selectedValue !== 0 && event.target.value !== '') {
            newValue = selectedValue;
        }

        if (event.target.value.match(/^\d+$/)) {
            newValue = Number(event.target.value);
        }
        
        setSelectedValue(newValue);
        handleChange(newValue, selectedUnit);
    }, [selectedValue, selectedUnit, handleChange]);
    const handleAgeUnitChange = useCallback((unit: string) => {
        setSelectedUnit(unit);
        handleChange(selectedValue ? selectedValue : 0, unit);
    }, [selectedValue, handleChange]);

    return (
        <span className="ageInputWrapper">
            <Input
                bordered
                value={selectedValue ? selectedValue : 0}
                maxLength={5}
                onChange={handleAgeValueVhange}
            />
            <Select
                value={selectedUnit}
                onChange={handleAgeUnitChange}
            >
                {options.map((item) => <Option key={item} value={item}>{item}</Option>)}
            </Select>
        </span>
    )
}

export default AgeInputView;
