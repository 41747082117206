import React from 'react';
import { Spin } from 'antd';
import { Images } from 'themes';
import './index.css';

const GetMethodLoading: React.FC<{
    loadingIconSrc?: string;
}> = ({
          loadingIconSrc = Images.TreeLoad,
      }) => {
    const loadingIcon = <img className="tableLoadingIcon" src={loadingIconSrc} alt="loading" />;
    return (
        <div className={`tableLoadingWrapper`}>
            <Spin indicator={loadingIcon} />
        </div>
    )
};

export default GetMethodLoading;
