import React, {useCallback, useLayoutEffect, useMemo, useRef} from 'react';
import {Descriptions, Button} from 'antd';
import {BranchDetail, BranchDetailTest, HomeServiceList, } from 'data/Models';
import {
    categorizeBranchFacilities,
    getOperationalTimeEntries,
    OperationalTime,
    sortTestList,
    toAsiaJakartaSchedule
} from 'common/BranchUtils';
import {formatDate} from 'common/CommonUtils';

import BranchFacilities from './BranchFacilities';
import BranchTestListInput from './BranchTestListInput';

import './index.css';
import {getElementListHeight} from 'common/DOMUtils';

export enum FormMode {
    View = 'view',
    Edit = 'edit',
};

interface AvailableTestEntry {
    id: string;
    name: string;
}

interface Props {
    mode: FormMode,
    data?: BranchDetail | null,
    availableTests?: BranchDetailTest[],
    persistedSelectedTests?: AvailableTestEntry[];
    homeService?: HomeServiceList | null;
    selectedTestIds?: string[];
    removedTestIds?: string[];
    onRemovedTestsChange?: (testIds: string[]) => void;
    onSelectedTestsChange?: (testIds: string[]) => void;
    onPersistTestIdsSelection?: () => void;
    onPersistTestIdsRemoval?: () => void;
    onSubmit?: (event: React.SyntheticEvent<HTMLFormElement>) => void;
    onCancel?: () => void,
}

const Text = {
    edit: 'Edit',
    detailsInformation: 'Details Information',
    branchID: 'Branch ID',
    branchName: 'Branch Name',
    city: 'City',
    region: 'Region',
    operationalTime: 'Operational Time',
    phoneNumber: 'Phone Number',
    email: 'Email',
    sectionHeader: 'Services Available on This Branch',
    save: 'SAVE',
    cancel: 'Cancel',
};

const getOperationalTimeContent = (operationalTimes: OperationalTime[]) => {
    return (
        <div className="operationalTimes">
            {operationalTimes.map((operationalTime, i) => (
                <div className="operationalTimesItem" key={i}>
                    <span className="operationalTimeDay">{operationalTime.dayRange}&nbsp;</span>
                    <span className="operationalTimeHour">{operationalTime.hourRange}</span>
                </div>
            ))}
        </div>
    )
}

const BranchForm: React.FC<Props> = (props) => {
    const {
        data,
        mode,
        availableTests = [],
        persistedSelectedTests = [],
        selectedTestIds = [],
        removedTestIds = [],
        onRemovedTestsChange = () => {
        },
        onSelectedTestsChange = () => {
        },
        onPersistTestIdsSelection = () => {
        },
        onPersistTestIdsRemoval = () => {
        },
        onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        },
        onCancel = () => {
        },
    } = props;
    const facilityCategories = categorizeBranchFacilities(data?.facilities || [])
    const testList = sortTestList(data?.tests || []);
    const asiaJakartaSchedules = useMemo(() => {
        return (data?.schedule || []).map(toAsiaJakartaSchedule);
    }, [data]);

    const isTestMoreThanFifteen = useMemo(() => {
        return testList.length > 15;
    }, [testList]);
    const testListContainerRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        const el = testListContainerRef.current as HTMLDivElement;
        if (!el) return;
        const height = getElementListHeight(el?.firstElementChild as HTMLElement, 8);
        el.setAttribute('style', `max-height: ${height}px`);
    }, [isTestMoreThanFifteen]);

    const handleCancel = useCallback(onCancel, [onCancel]);
    const handleSubmit = useCallback(onSubmit, [onSubmit]);

    const branchDetailDesc = [
        {
            label: Text.branchID,
            content: data?.id,
        },
        {
            label: Text.branchName,
            content: data?.name,
        },
        {
            label: Text.region,
            content: data?.region.name,
        },
        {
            label: Text.city,
            content: data?.city.name,
        },
        {
            label: Text.operationalTime,
            content: getOperationalTimeContent(getOperationalTimeEntries(asiaJakartaSchedules)),
        },
        {
            label: Text.phoneNumber,
            content: data?.phone,
        },
        {
            label: Text.email,
            content: data?.email,
        },
    ];

    if (!data) {
        return null;
    }


    return (
        <form onSubmit={handleSubmit} className="branchForm">
            <Descriptions title={mode === FormMode.View ? Text.detailsInformation : ' '}>
                {branchDetailDesc.map((branchDetailItem, index) => (
                    <Descriptions.Item key={`branchDetailItem${index}`} label={branchDetailItem.label} span={3}>
                        {branchDetailItem.content}
                    </Descriptions.Item>
                ))}

                <div className="sectionHeader">{Text.sectionHeader}</div>

                <Descriptions.Item label="Facility">
                    <BranchFacilities facilityCategories={facilityCategories}/>
                </Descriptions.Item>

                {mode === FormMode.View ? (<Descriptions.Item label="Test List">
                    <div ref={testListContainerRef} className="serviceTestListContent">
                        <ol className="serviceList">
                            {testList.length === 0 ? <li> - </li> : null}
                            {testList.map((test) => (
                                <li key={test.id}>{test.id} - {test.name}</li>
                            ))}
                        </ol>
                    </div>
                </Descriptions.Item>) : null}

                {mode === FormMode.Edit ? (
                    <>
                        <Descriptions.Item className="availableTestsRow" label="Test List">
                            <span/>
                        </Descriptions.Item>
                        <Descriptions.Item className="branchTestListWrapper">
                            <BranchTestListInput
                                data={data}
                                availableTests={availableTests}
                                persistedSelectedTests={persistedSelectedTests}
                                selectedTestIds={selectedTestIds}
                                removedTestIds={removedTestIds}
                                onRemovedTestsChange={onRemovedTestsChange}
                                onSelectedTestsChange={onSelectedTestsChange}
                                onPersistTestIdsSelection={onPersistTestIdsSelection}
                                onPersistTestIdsRemoval={onPersistTestIdsRemoval}
                            />
                        </Descriptions.Item>
                    </>
                ) : null}
                {mode === FormMode.View ? (
                    <>
                        <Descriptions.Item
                            label="Update Date">{data ? formatDate(data.updated_at) : '-'}</Descriptions.Item>
                        <Descriptions.Item label="Update By">{data?.updated_by.name || '-'}</Descriptions.Item>
                    </>
                ) : null}
            </Descriptions>
            {mode === FormMode.Edit ? (
                <div className="branchEditViewAction">
                    <Button type="primary" htmlType="submit">
                        {Text.save}
                    </Button>
                    <span style={{flex: 1}}/>
                    <Button type="text" onClick={handleCancel}>
                        {Text.cancel}
                    </Button>
                </div>) : null}
        </form>
    );
};

export default BranchForm;
