import { utcToZonedTime, zonedTimeToUtc, format } from "date-fns-tz";
import { BranchFacility } from "data/Models";

interface Facility {
  name: string;
  name_en: string;
}

export interface Schedule {
  day: string;
  open: string;
  close: string;
  note: string;
  note_en?: string;
  timezone: string;
}

export interface BranchDetailFacilityCategory {
  id: number;
  category: string;
  category_en: string;
  facilities: Facility[];
}

export interface BranchDetailTest {
  id: string;
  name: string;
}

export interface OperationalTime {
  dayRange: string;
  hourRange: string;
}

export enum BranchRefererPage {
  LIST,
  DETAIL,
}

export function categorizeBranchFacilities(facilities: BranchFacility[]) : BranchDetailFacilityCategory[] {
  const group : Record<string, BranchDetailFacilityCategory> = {};

  const facilityMap = facilities.reduce((accumulation, facility) => {
    const id = String(facility.category.id);
    const isInGroup = id in group;
    if (!isInGroup) {
      accumulation[id] = {
        id: facility.category.id,
        category: facility.category.name,
        category_en: facility.category.name_en,
        facilities: [{ name: facility.name, name_en: facility.name_en }],
      }
    } else {
      accumulation[id].facilities.push({
        name: facility.name, 
        name_en: facility.name_en 
      })
    }

    return accumulation
  }, group);

  return Object.keys(facilityMap).map(key => facilityMap[key]);
}

enum Days {
  MONDAY = 'MONDAY', 
  TUESDAY = 'TUESDAY', 
  WEDNESDAY = 'WEDNESDAY', 
  THURSDAY = 'THURSDAY', 
  FRIDAY = 'FRIDAY', 
  SATURDAY = 'SATURDAY', 
  SUNDAY = 'SUNDAY', 
}

enum BahasaDays {
  SENIN = 'SENIN', 
  SELASA = 'SELASA', 
  RABU = 'RABU', 
  KAMIS = 'KAMIS', 
  JUMAT = 'JUMAT', 
  SABTU = 'SABTU', 
  MINGGU = 'MINGGU', 
}

type DayMapEntry = Record<string, number>;

const daysMap : DayMapEntry = {
  [Days.MONDAY]: 1,
  [Days.TUESDAY]: 2,
  [Days.WEDNESDAY]: 3,
  [Days.THURSDAY]: 4,
  [Days.FRIDAY]: 5,
  [Days.SATURDAY]: 6,
  [Days.SUNDAY]: 7,
};

const invertedDaysMap : Record<number, string> = {
  1: Days.MONDAY,
  2: Days.TUESDAY,
  3: Days.WEDNESDAY,
  4: Days.THURSDAY,
  5: Days.FRIDAY,
  6: Days.SATURDAY,
  7: Days.SUNDAY,
};

function toTitleCase(day: string) : string {
  let text = day.toLowerCase();
  const [firstChar, ...rest] = text.split('');
  return `${firstChar.toUpperCase()}${rest.join('')}`
}

function toEnglishDay(day: BahasaDays) : string {
  const bahasaToEnglishDaysMap = {
    [BahasaDays.SENIN]: Days.MONDAY,
    [BahasaDays.SELASA]: Days.TUESDAY,
    [BahasaDays.RABU]: Days.WEDNESDAY,
    [BahasaDays.KAMIS]: Days.THURSDAY,
    [BahasaDays.JUMAT]: Days.FRIDAY,
    [BahasaDays.SABTU]: Days.SATURDAY,
    [BahasaDays.MINGGU]: Days.SUNDAY,
  };

  return bahasaToEnglishDaysMap[day] || day;
}

/**
 * Sort the schedule and map the day from bahasa to english
 */
export function sortSchedule(schedules: Schedule[]) {
  return mapBahasaToEnglish(schedules).sort((a, b) => {
    return daysMap[a.day] - daysMap[b.day];
  });
}

export function mapBahasaToEnglish(schedules: Schedule[]) {
  return schedules.map(schedule => ({
    ...schedule,
    day: toEnglishDay(schedule.day as BahasaDays),
  }));
}

export function getOperationalTimeEntries(schedules: Schedule[]) : OperationalTime[] {
  if (!schedules.length) return [];
  return getOperationalTimes(schedules);
}

/**
 * Fill empty schedules with "null schedule"
 */
function fillEmptySchedules(schedules: Schedule[]) {
  const map : Record<number, Schedule|null> = {};

  for (let i = 1; i <= 7; i++) {
    map[i] = null;
  }

  schedules.forEach((schedule) => {
    const dayNumber = daysMap[schedule.day];
    map[dayNumber] = schedule;
  });

  for (let i = 1; i <= 7; i++) {
    if (map[i] === null) {
      const nullSchedule = {
        day: invertedDaysMap[i],
        open: '',
        close: '',
        note: '',
        timezone: '',
      };
      map[i] = nullSchedule;
    }
  }

  return Object.values(map as Record<number, Schedule>);
}

function getOperationalTimes(schedules: Schedule[]) : OperationalTime[] {
  const sortedSchedules = fillEmptySchedules(sortSchedule(schedules));
  const scheduleCount = sortedSchedules.length;
  if (scheduleCount === 1) {
    return [{
      dayRange: toTitleCase(sortedSchedules[0].day), 
      hourRange: getHourRangeText(sortedSchedules[0]),
    }];
  }

  const operationalTimes : OperationalTime[] = [];
  const firstSchedule = sortedSchedules[0];
  let prevDay = firstSchedule.day;
  let prevHourRange = getHourRangeText(firstSchedule);
  let dayRange = [toTitleCase(firstSchedule.day)];

  for (let i = 1; i < scheduleCount; i++) {
    const day = sortedSchedules[i].day;
    const range = getHourRangeText(sortedSchedules[i]);
    const isContagiousDay = daysMap[day] - daysMap[prevDay] === 1;
    const hasSameHours = prevHourRange === range;

    if (isContagiousDay && hasSameHours) {
      dayRange.push(toTitleCase(day));
    } else {
      operationalTimes.push({
        dayRange: getDayRangeText(dayRange), 
        hourRange: prevHourRange,
      });
      dayRange = [toTitleCase(day)];
      prevHourRange = range;
    }

    prevDay = day;

    const isLastSchedule = scheduleCount === i + 1;
    if (isLastSchedule && dayRange.length) {
      operationalTimes.push({
        dayRange: getDayRangeText(dayRange), 
        hourRange: range
      });
    }
  }

  return operationalTimes;
}

export function getDayRangeText(days: string[]) : string {
  if (days.length === 1) return days[0];
  const firstDay = days[0];
  const lastDay = days[days.length - 1];
  if (firstDay === lastDay) return firstDay;

  return `${firstDay} - ${lastDay}`;
}

export function getHourRangeText(schedule: Schedule) : string {
  const { open, close } = schedule;
  if (open === '' && close === '') return 'Closed';
  return `${open} - ${close}`;
}

export function sortTestList(tests: BranchDetailTest[]) {
  return tests.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
}

enum IndonesianTimeZones {
  AsiaJakarta = 'Asia/Jakarta',
  AsiaMakassar = 'Asia/Makassar',
  AsiaJayapura = 'Asia/Jayapura',
}

/**
 * Convert a schedule to Asia/Jakarta
 */
export function toAsiaJakartaSchedule (schedule: Schedule) {
  const tz : IndonesianTimeZones = schedule.timezone as IndonesianTimeZones;
  if (!tz) return schedule;
  
  return Object.assign({}, schedule, {
    open: toAsiaJakartaHour(schedule.open, tz),
    close: toAsiaJakartaHour(schedule.close, tz),
    timezone: IndonesianTimeZones.AsiaJakarta,
  });
}

/**
 * @param originalHour format: HH:mm
 * @param timezone
 */
function toAsiaJakartaHour(originalHour: string, timezone: IndonesianTimeZones) {
  if (!timezone) return originalHour;
  // The date is just an arbritrary date, the important part is only the hour (HH:mm).
  const dateString = `2021-01-31 ${originalHour}:00.000`;

  const utcDate = zonedTimeToUtc(dateString, timezone);
  const zonedDate = utcToZonedTime(utcDate, IndonesianTimeZones.AsiaJakarta)
  return format(zonedDate, 'HH:mm');
}
