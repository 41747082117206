import * as http from "../../common/HttpUtils";
import { LoginResult } from "../../features/login/LoginState";
import ApiEndpoint from "./ApiEndpoint";
import { InternalErrorCode } from 'common/HttpCode';

const AuthApi = {
    login (email: string, password: string, captchaToken: string): Promise<http.HttpService<LoginResult>> {
        const body = {
            email: email,
            password: password,
            captcha_token: captchaToken,
        };
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Auth.Login)
            .setBody(body)
            .doPost()
            .then(response => http.handleResponse(200, response, (json: any): LoginResult => {
                const config = json.timeout_config;
                return {
                    token: json.token,
                    timeoutConfig: {
                        idleDelayTimer: config.idle_delay_timer,
                        idleGracePeriodConfirmation: config.idle_grace_period_confirmation,
                        idleTimeout: config.idle_timeout,
                    },
                    expiredAt: json.expired_at
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    logout (): Promise<http.HttpService<any>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Auth.Logout)
            .doPost()
            .then(response => http.handleResponse(200, response, (json: any): any => {}))
            .then((res: http.HttpService<{}>) => {
                if (res.status === http.HTTP_RESPONSE_FAILURE) {
                    const { error } = res;
                    if (error.code === InternalErrorCode) {
                        const message = 'There was an error, please try again';
                        error.message = message;
                    }
                    return Promise.reject(error);
                }

                return res;
            });
    }
};

export default AuthApi;
