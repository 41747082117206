/**
 * Get the height of first 15 items of a list
 */
export const getElementListHeight = (el: HTMLElement, heightBuffer = 0) => {
  const elements = Array.from(el.children as HTMLCollection).slice(0, 15);
  const count = elements.length;
  return elements.reduce((total, el, i) => {
    const isLastElement = (i + 1) === count;
    let { height } = el.getBoundingClientRect();
    if (!isLastElement) height += heightBuffer;

    return total + height;
  }, 0);
}