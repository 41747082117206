import { call, put, takeLatest } from 'redux-saga/effects';
import PromoApi from 'data/api/PromoApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './PromoManagementAction';
import { FilterParamMapper } from 'common/CommonUtils';
import { HttpError, PromoList } from 'data/Models';

function* promoListTask(promoListAction: action.PromoManagementRequest) {
    const { isRetrieveAll, filterParams } = promoListAction;
    let queryParams = FilterParamMapper(filterParams);
    if (isRetrieveAll) {
        const maxLimit = 100;
        let requestPage = 1;
        let isMoveToNext = true;
        let promoList: PromoList | null = null;
        let error: HttpError | null = null;

        while (isMoveToNext) {
            const response = yield call(PromoApi.getPromoList, { page: requestPage, limit: maxLimit });
            if (response.status === http.HTTP_RESPONSE_SUCCESS) {
                if (promoList) {
                    promoList.data = [...promoList.data, ...response.data.promoList.data];
                } else {
                    promoList = response.data.promoList;
                }

                if (response.data.promoList.pagination.total <= requestPage * maxLimit) {
                    isMoveToNext = false;
                }
            } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
                error = response.error;
                break;
            }

            requestPage++;
        }

        if (promoList) {
            yield put(action.doPromoManagementSuccess(promoList));
        } else if (error) {
            if (error.code === AbortError) {
                const newTimeoutError = Object.assign({}, error, { action: () => action.doPromoManagementRequest(true, { page: requestPage, limit: maxLimit }) });
                yield put(action.doPromoManagementFailure(newTimeoutError));
            } else {
                yield put(action.doPromoManagementFailure(error));
            }
        }
    } else {
        const response = yield call(PromoApi.getPromoList, queryParams);
        if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
            yield put(action.doPromoManagementSuccess(response.data.promoList));
        } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
            if (response.error.code === AbortError) {
                const newTimeoutError = Object.assign({}, response.error, { action: () => action.doPromoManagementRequest(false, queryParams) });
                yield put(action.doPromoManagementFailure(newTimeoutError));
            } else {
                yield put(action.doPromoManagementFailure(response.error));
            }
        }
    }
}

export function* promoListSaga() {
    yield takeLatest(
        action.PROMO_MANAGEMENT_REQUEST,
        promoListTask,
    );
}
