import { HttpError, OrderDetail } from 'data/Models';

export const ORDER_DETAIL_INITIAL = 'ORDER_DETAIL_INITIAL';
export type ORDER_DETAIL_INITIAL = typeof ORDER_DETAIL_INITIAL;

export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST';
export type ORDER_DETAIL_REQUEST = typeof ORDER_DETAIL_REQUEST;

export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
export type ORDER_DETAIL_SUCCESS = typeof ORDER_DETAIL_SUCCESS;

export const ORDER_DETAIL_FAILURE = 'ORDER_DETAIL_FAILURE';
export type ORDER_DETAIL_FAILURE = typeof ORDER_DETAIL_FAILURE;

interface OrderDetailInitial {
    type: ORDER_DETAIL_INITIAL;
}

export interface OrderDetailRequest {
    type: ORDER_DETAIL_REQUEST;
    orderId: string;
}

interface OrderDetailSuccess {
    type: ORDER_DETAIL_SUCCESS;
    data: OrderDetail;
}

interface OrderDetailFailure {
    type: ORDER_DETAIL_FAILURE;
    error: HttpError;
}

export type OrderDetailAction = OrderDetailInitial | OrderDetailRequest | OrderDetailSuccess | OrderDetailFailure;

export function doOrderDetailInitial(): OrderDetailInitial {
    return {
        type: ORDER_DETAIL_INITIAL
    };
}

export function doOrderDetailRequest(orderId: string): OrderDetailRequest {
    return {
        type: ORDER_DETAIL_REQUEST,
        orderId: orderId,
    };
}

export function doOrderDetailSuccess(data: OrderDetail): OrderDetailSuccess {
    return {
        type: ORDER_DETAIL_SUCCESS,
        data: data
    };
}

export function doOrderDetailFailure(error: HttpError): OrderDetailFailure {
    return {
        type: ORDER_DETAIL_FAILURE,
        error: error
    };
}
