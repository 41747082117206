import { call, put, takeLatest } from 'redux-saga/effects';
import RoleApi from 'data/api/RoleApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './PermissionTreeAction';

function* permissionListTask() {
    const response = yield call(RoleApi.getPermissionList);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doGetPermissionsSuccess(response.data.permissionList));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doGetPermissionsRequest() });
            yield put(action.doGetPermissionsFailure(newTimeoutError));
        } else {
            yield put(action.doGetPermissionsFailure(response.error));
        }
    }
}

export function* permissionListSaga() {
    yield takeLatest(
        action.GET_PERMISSIONS_REQUEST,
        permissionListTask,
    );
}
