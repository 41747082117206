import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { CrumbRouteProps } from 'navigation/BreadCrumbs'
import './index.css';

export interface CrumbParamsProps {
    lastCrumbName?: string;
}
const itemRender = (route: CrumbRouteProps, params: CrumbParamsProps, routes: CrumbRouteProps[]) => {
    const disableLink = !route.path;
    const last = routes.indexOf(route) === routes.length - 1;
    return disableLink ? (
        <span className="breadCrumbsDisable">
            {last && params.lastCrumbName ? params.lastCrumbName : route.breadcrumbName}
        </span>
    ) : (
        <Link to={route.path || ''}>
            <span>{route.breadcrumbName}</span>
        </Link>
    );
};

const BreadCrumbsComp: React.FC<{
    wrapperClassName?: string;
    crumbs: any[];
    children?: any;
    customCrumbParams?: object;
}> = ({
    wrapperClassName = '',
    crumbs = [],
    children = '',
    customCrumbParams = {},
}) => {
    return (
        <div className={`breadCrumbsComponentWrapper ${wrapperClassName}`}>
            <Breadcrumb separator=">" itemRender={itemRender} routes={crumbs} params={customCrumbParams}/>
            {children}
        </div>
    )
};

export default BreadCrumbsComp;
