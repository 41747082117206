import { availableMenus } from "features/scaffold/sider/SideMenuConfig";

export function isPermissionAllowed(permissionKey: string | undefined, permissions: string[]): boolean {
    return permissionKey ? permissions.includes(permissionKey) : true;
}

export function findGroupKey(title: string): string {
    const key = availableMenus.find((item) => {
        return item.childs?.find((subItem) => subItem.title === title);
    })?.title;

    return key ? key : '';
}
