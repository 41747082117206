import { Button, Card, Pagination, Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { defaultPage, defaultLimit } from 'common/constans';
import './index.css';

interface AppTableProps<T> extends TableProps<T> {
    pageSize?: number;
    total?: number;
    onRequestData?: (page: number, limit: number) => void;
    current?: number;
    defaultCurrent?: number;
    showSizeChanger?: boolean;
    showPagination?: boolean;
}

function AppTable<T extends object = any>(props: AppTableProps<T>) {
    let {
        pageSize = defaultLimit,
        total = 0,
        current = 1,
        defaultCurrent = defaultPage,
        showSizeChanger = false,
        onRequestData = undefined,
        showPagination = true,
    } = props;
    const [currentPage, setCurrentPage] = useState(current);
    const [requestAgain, setRequestAgain] = useState(false);
    const lastPage = Math.ceil(total / pageSize);
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setRequestAgain(true);
    };
    useEffect(() => {
        if (onRequestData !== undefined && requestAgain) {
            onRequestData(currentPage, pageSize);
            setRequestAgain(false);
        }
    }, [currentPage, pageSize, requestAgain, onRequestData]);
    useEffect(() => {
        if (current) {
            setCurrentPage(current);
        }
    }, [current]);
    return (
        <Card className={'tableCardWrapper'}>
            <Table
                {...props}
                className={'app-table'}
                pagination={false}
            />
            {showPagination && (
                <div className={'tablePaginationWrapper'}>
                    <PaginationNav
                        label={'First'}
                        isDisable={currentPage === 1}
                        onClick={() => { handlePageChange(1); }}
                    />
                    <Pagination
                        className={'app-pagination'}
                        defaultCurrent={defaultCurrent}
                        defaultPageSize={pageSize}
                        total={total}
                        itemRender={(page, type, originalElement) => PaginationItem(currentPage, page, type, originalElement)}
                        onChange={(page) => { handlePageChange(page); }}
                        showSizeChanger={showSizeChanger}
                        pageSize={pageSize}
                        current={currentPage}
                    />
                    <PaginationNav
                        label={'Last'}
                        isDisable={currentPage === lastPage}
                        onClick={() => { handlePageChange(lastPage); }}
                    />
                </div>
            )}
        </Card>
    );
}

const paginationItemStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f9f9f9',
    color: '#0063a0',
    border: 'none',
    borderRadius: 19,
    minWidth: 38,
    height: 38
};

const paginationItemActiveStyle = {
    ...paginationItemStyle,
    backgroundColor: '#0063a0',
    color: '#ffffff'
};

function PaginationItem(
    currentPage: number,
    page: number,
    type: string,
    originalElement: React.ReactElement<HTMLElement>
) {
    switch(type) {
        case 'prev': {
            return PaginationNav({label: '<'});
        }
        case 'next': {
            return PaginationNav({label: '>'});
        }
        case 'page': {
            return PaginationPage(page, page === currentPage);
        }
        default: {
            return originalElement;
        }
    }
}

interface PaginationNavProps {
    label: string;
    style?: React.CSSProperties;
    isDisable?: boolean;
    onClick?: () => void
}

function PaginationNav(props: PaginationNavProps) {
    return (
        <Button
            aria-label={'paging-nav-' + props.label.toLowerCase()}
            style={{...paginationItemStyle, ...props.style}}
            disabled={props.isDisable || false}
            onClick={props.onClick}
            className={'paginationNavButton'}
        >
            {props.label}
        </Button>
    );
}

function PaginationPage(page: number, isPageSelected: boolean) {
    const style = isPageSelected ? paginationItemActiveStyle : paginationItemStyle;
    return (
        <div aria-label={'paging-page-' + page} style={style}>{page}</div>
    );
}

export default AppTable;
