import { call, put, takeLatest } from 'redux-saga/effects';
import RoleApi from 'data/api/RoleApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './RoleManagementAction';
import { FilterParamMapper } from 'common/CommonUtils';
import { HttpError, RoleList } from 'data/Models';

function* roleListTask(roleListAction: action.RoleManagementRequest) {
    const { isRetrieveAll, filterParams } = roleListAction;
    let queryParams = FilterParamMapper(filterParams);
    if (isRetrieveAll) {
        const maxLimit = 100;
        let requestPage = 1;
        let isMoveToNext = true;
        let roleList: RoleList | null = null;
        let error: HttpError | null = null;

        while (isMoveToNext) {
            const response = yield call(RoleApi.getRoleList, { page: requestPage, limit: maxLimit });
            if (response.status === http.HTTP_RESPONSE_SUCCESS) {
                if (roleList) {
                    roleList.data = [...roleList.data, ...response.data.roleList.data];
                } else {
                    roleList = response.data.roleList;
                }

                if (response.data.roleList.pagination.total <= requestPage * maxLimit) {
                    isMoveToNext = false;
                }
            } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
                error = response.error;
                break;
            }

            requestPage++;
        }

        if (roleList) {
            yield put(action.doRoleManagementSuccess(roleList));
        } else if (error) {
            if (error.code === AbortError) {
                const newTimeoutError = Object.assign({}, error, { action: () => action.doRoleManagementRequest(true, { page: requestPage, limit: maxLimit }) });
                yield put(action.doRoleManagementFailure(newTimeoutError));
            } else {
                yield put(action.doRoleManagementFailure(error));
            }
        }
    } else {
        const response = yield call(RoleApi.getRoleList, queryParams);
        if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
            yield put(action.doRoleManagementSuccess(response.data.roleList));
        } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
            if (response.error.code === AbortError) {
                const newTimeoutError = Object.assign({}, response.error, { action: () => action.doRoleManagementRequest(false, queryParams) });
                yield put(action.doRoleManagementFailure(newTimeoutError));
            } else {
                yield put(action.doRoleManagementFailure(response.error));
            }
        }
    }
}

export function* roleListSaga() {
    yield takeLatest(
        action.ROLE_MANAGEMENT_REQUEST,
        roleListTask,
    );
}
