import { TestFormData } from "data/Models";
import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";
import { TestListQuery } from "common/States";

const TestApi = {
    getTestList(queryParams: TestListQuery): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Test.Base)
            .setQueryParameter(queryParams)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    testList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    getTestDetail(testId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Test.Base}/${testId}`)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    testDetail: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    updateTest(updateData: TestFormData): Promise<http.HttpService<{}>> {
        const body = {
            types: updateData.types,
            min_age: updateData.beginAge,
            max_age: updateData.endAge,
            is_active: updateData.isActive,
        }
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Test.Base}/${updateData.id}`)
            .setBody(body)
            .doPut()
            .then(response => http.handleResponse(200, response, (): {} => {
                return { success: true }
            }))
            .catch(error => http.handleCatch(error));
    },
};

export default TestApi;
