import React, {Dispatch, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  doGetProfileInitial,
  GetProfileAction,
} from "features/scaffold/ScaffoldAction";
import RootStoreState from "rootStore";
import {isPermissionAllowed} from "common/PermissionUtils";
import {FrontendRoutes} from "navigation/Routes";
import AlertComponent from "components/AlertComponent";
import {ModalError, TypePageNotAllowed} from "components/ModalComponent";
import {GetMethodLoading, LoadingWithMask} from "components/LoadingComponent";
import PromoFormView from "../PromoForm/PromoFormView";
import {ContractDetail, HttpError, PromoFormData} from "data/Models";
import {
  CreatePromoAction,
  doCreatePromoInitial,
  doCreatePromoRequest,
} from "./PromoCreateAction";
import {promoTypeListState} from "common/constans";

const serverFormat = "YYYY-MM-DDTHH:mm:ssZ";

function PromoCreateView() {
  const history = useHistory();
  const initialValues = {
    promoType: promoTypeListState[2].id,
    quota: 1,
    source: "PRDA",
  };

  const profileAction = useDispatch<Dispatch<GetProfileAction>>();
  const profileState = useSelector((state: RootStoreState) => state.profile);
  const createPromoAction = useDispatch<Dispatch<CreatePromoAction>>();
  const createPromoState = useSelector(
    (state: RootStoreState) => state.createPromo
  );

  const permissions = profileState.data?.permissions || [];
  const isAllowedCreatePromo = isPermissionAllowed(
    FrontendRoutes.PromoCreatePage.permissionKey,
    permissions
  );
  const loading = profileState.onRequest;

  const [showNotAllowed, setShowNotAllowed] = useState<boolean>(false);
  const [errorHttp, setErrorHttp] = useState<HttpError | null>(null);
  const [errorClient, setErrorClient] = useState<string | null>(null);

  useEffect(() => {
    if (profileState.data && !isAllowedCreatePromo) {
      setShowNotAllowed(true);
    } else if (profileState.error) {
      profileAction(doGetProfileInitial());
      setErrorHttp(profileState.error);
    }
  }, [profileAction, profileState, isAllowedCreatePromo]);
  useEffect(() => {
    if (createPromoState.success === true) {
      history.replace(FrontendRoutes.PromoManagement.path);
    } else if (createPromoState.error) {
      createPromoAction(doCreatePromoInitial());
      setErrorHttp(createPromoState.error);
    }
  }, [createPromoAction, createPromoState, history]);

  const handleClearFn = useCallback(() => {
    setErrorHttp(null);
    setErrorClient(null);
  }, []);
  const handlePromoCreate = useCallback(
    (values: any) => {
      if (values.promoType === promoTypeListState[2].id) {
        const data = {
          promoType: values.promoType,
          source: values.source,
          promoCode: values.promoCode,
          title: values.titleId,
          titleEn: values.titleEn,
          description: values.descriptionId,
          descriptionEn: values.descriptionEn,
          tnc: values.tncId,
          tncEn: values.tncEn,
          contractId: values.contractId,
          quota: values.quota,
          periodeDate: {
            start: values.periodeDateStart.format(serverFormat),
            end: values.periodeDateEnd.format(serverFormat),
          },
          publishDate: {
            start: values.publishDateStart.format(serverFormat),
            end: values.publishDateEnd.format(serverFormat),
          },
          coverImage: values.coverImage.image,
        };
        createPromoAction(doCreatePromoRequest(data));
      } else if (values.promoType === promoTypeListState[0].id) {
        const data: PromoFormData = {
          promoType: values.promoType,
          source: values.source,
          title: values.titleId,
          titleEn: values.titleEn,
          description: values.descriptionId,
          descriptionEn: values.descriptionEn,
          tnc: values.tncId,
          tncEn: values.tncEn,
          contractIds: values.contracts
            .map((item: ContractDetail) => item.id)
            .join(","),
          aliases: values.contracts.map((item: ContractDetail) => {
            return {
              id: item.id,
              alias: item.alias ?? item.name,
              alias_en: item.alias_en ?? item.name_en,
            };
          }),
          quota: values.quota,
          periodeDate: {
            start: values.periodeDateStart.format(serverFormat),
            end: values.periodeDateEnd.format(serverFormat),
          },
          publishDate: {
            start: values.publishDateStart.format(serverFormat),
            end: values.publishDateEnd.format(serverFormat),
          },
          coverImage: values.coverImage.image,
        };
        createPromoAction(doCreatePromoRequest(data));
      } else {
        // Todo - other promo type
      }
    },
    [createPromoAction]
  );

  return (
    <div style={{padding: "0 25px"}}>
      {!showNotAllowed ? (
        <>
          {loading && <GetMethodLoading />}
          {createPromoState.onRequest && <LoadingWithMask />}
          {errorHttp && (
            <AlertComponent error={errorHttp} clearFn={handleClearFn} />
          )}
          {errorClient && (
            <AlertComponent message={errorClient} clearFn={handleClearFn} />
          )}
          {isAllowedCreatePromo && (
            <PromoFormView
              mode={"create"}
              initialValues={initialValues}
              onError={setErrorClient}
              onFinish={handlePromoCreate}
            />
          )}
        </>
      ) : (
        <ModalError visible={true} type={TypePageNotAllowed} />
      )}
    </div>
  );
}

export default PromoCreateView;
