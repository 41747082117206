import { FormInstance, Rule } from 'antd/lib/form';
import { isContainMinimumAlphabet } from 'common/CommonUtils';

export const minPasswordLen = 8;
export const maxPasswordLen = 255;

export const passwordRulesText = [
    'Min 8 Characters',
    'Max 255 Characters',
    'At Least 1 Number',
    'At Least 2 Alphabet',
    'At Least 1 Uppercase',
    'At Least 1 Lowercase',
];

function validatePasswordFormat(value: string): Error|null {
    let error: Error | null = null;

    if (value == null) {
        error = new Error('Please input password.');
    } else if (value.length < minPasswordLen) {
        error = new Error('Password should min 8 characters.');
    } else if (value.length > maxPasswordLen) {
        error = new Error('Password should max 255 characters.');
    } else if (!value.match(/.*[0-9].*/)) {
        error = new Error('Password should at least 1 number.');
    } else if (!isContainMinimumAlphabet(value, 2)) {
        error = new Error('Password should at least 2 alphabet.');
    } else if (!value.match(/.*[A-Z]/)) {
        error = new Error('Password should at least 1 uppercase.');
    } else if (!value.match(/.*[a-x]/)) {
        error = new Error('Password should at least 1 lowercase.');
    }

    return error;
}

export const passwordRules: Rule[] = [
    () => ({
        validator(rule, value) {
            const error = validatePasswordFormat(value);
            if (error) {
                return Promise.reject(error);
            } else {
                return Promise.resolve();   
            }
        },
    }),
];

const confirmPasswordRule = (form: FormInstance): Rule[] => [
    () => ({
        validator(rule, value) {
            let error = validatePasswordFormat(value);
            const newPassword = form.getFieldValue('newPassword');
            const confirmPassword = form.getFieldValue('confirmPassword');
            if (error === null && newPassword !== confirmPassword) {
                error = new Error('Your passwords do not match.');
            }

            if (error) {
                return Promise.reject(error);
            } else {
                return Promise.resolve();   
            } 
        },
    }),
];
export const confirmPasswordRules = (form: FormInstance) => confirmPasswordRule(form);

export const createPasswordText = {
    formTitle: 'Create Password',
    formDesc: 'Your account activation is almost complete, please create your password.',
    buttonText: 'SAVE',
};
