import { call, put, takeLatest } from 'redux-saga/effects';
import RoleApi from 'data/api/RoleApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './RoleDetailAction';

export function* sagaTask(sagaAction: action.RoleDetailRequest) {
    const { roleId } = sagaAction;
    const response = yield call(RoleApi.getRoleDetail, roleId);

    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doRoleDetailSuccess(response.data.roleDetail));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doRoleDetailRequest(roleId) });
            yield put(action.doRoleDetailFailure(newTimeoutError));
        } else {
            yield put(action.doRoleDetailFailure(response.error));
        }
    }
}

export function* roleDetailSaga() {
    yield takeLatest(
        action.ROLE_DETAIL_REQUEST,
        sagaTask,
    );
}
