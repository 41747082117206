import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RootStoreState from 'rootStore';
import AlertComponent from 'components/AlertComponent';
import { HttpError } from 'data/Models';
import { LoadingWithMask } from 'components/LoadingComponent';
import { Images } from 'themes';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { menus, texts, DashBoardMenuitem } from './DashboardConfig';
import * as style from './DashboardView.style';

function DashboardView() {
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];
    const [error, setError] = useState<HttpError | null>(null);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    const shortcutMenus = menus.filter((menu) => {
        return menu.shortcuts
            .map((shortcut) => isPermissionAllowed(shortcut.permissionKey, permissions))
            .includes(true)
    });
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const titleSize = shortcutMenus.length > 0 ? 21.6 : 18;
    const titleMargin = shortcutMenus.length > 0 ? '0 0' : '11px 26px';

    let cardWidth = 0;
    let menuSpan = 0;
    switch (shortcutMenus.length) {
        case 0:
            cardWidth = 500;
            break;
        case 1:
            cardWidth = 589.5;
            menuSpan = 14;
            break;
        case 2:
            cardWidth = 777.4;
            menuSpan = 12;
            break;
        case 3:
            cardWidth = 1053;
            menuSpan = 8;
            break;
        default:
            cardWidth = 720;
            menuSpan = 12;
            break;
    }

    return (
        <div style={style.container}>
            {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            {profileState.onRequest && <LoadingWithMask />}
            <div>
                { shortcutMenus.length === 0 && <div style={style.header}><img src={Images.BrandLogo} alt="logo"/></div>}
                <Card style={{ width: cardWidth, minHeight: 120, borderRadius: 6 }}>
                    <div style={{...style.title, fontSize: titleSize, margin: titleMargin }}>{texts.title}</div>
                    { shortcutMenus.length > 0 && <div style={style.subtitle}>{texts.subTitle}</div>}
                    <Row gutter={[36, 24]} style={style.row}>
                        {shortcutMenus.map((menu) => <ColItem key={menu.title} span={menuSpan} menu={menu} permissions={permissions ?? []} />)}
                    </Row>
                </Card>
            </div>
        </div>
    );
}

interface ColItemProp {
    span: number;
    menu: DashBoardMenuitem;
    permissions: string[]
}

function ColItem(props: ColItemProp) {
    const { span, menu, permissions } = props;
    
    const findMenuLink = (menu: DashBoardMenuitem): string => {
        return menu.shortcuts
            .find((shortcut) => isPermissionAllowed(shortcut.permissionKey, permissions))
            ?.link
            ?? ''
    };

    return (
        <Col span={span}>
            <Link to={findMenuLink(menu)}>
                <div style={{...style.col, backgroundColor: menu.color}}>
                    <img src={menu.icon} alt={menu.title} style={style.icon}/>{menu.title}
                </div>
            </Link>
        </Col>
    )
}

export default DashboardView;
