import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './features/app/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import rootSaga from './rootSaga';
import { sagaMiddleware, appStore } from 'appStore';

sagaMiddleware.run(rootSaga);
const isDev = process.env.NODE_ENV === 'development';
const RootView = () => {
    return (
        <Provider store={appStore}><App /></Provider>
    );
}
ReactDOM.render(
    isDev ? RootView() : (
        <React.StrictMode>
            {RootView()}
        </React.StrictMode>
        ),
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
