export const FrontendRoutes = {
    Login: {
        path: '/login',
        title: 'Login',
    },
    AccountActivation: {
        path: '/activation',
        title: 'Accont Activation',
    },
    CreatePassword: {
        path: '/create-password',
        title: 'Create Password',
    },
    Dashboard: {
        path: '/',
        title: 'Dashboard',
    },
    OrderManagement: {
        path: '/order',
        title: 'Order Management',
        permissionKey: 'order_management.view_list',
        breadcrumbName: 'Order Management',
    },
    OrderDetailPage: {
        path: '/order/detail/:orderId',
        title: 'Order Management',
        permissionKey: 'order_management.view_detail',
        breadcrumbName: 'Detail',
    },
    TestManagement: {
        path: '/product/test',
        title: 'Test',
        permissionKey: 'test_management.view_list',
        breadcrumbName: 'Test Management',
    },
    TestDetailPage: {
        path: '/product/test/detail/:testId',
        title: 'Test',
        permissionKey: 'test_management.view_detail',
        breadcrumbName: 'Detail',
    },
    TestEditPage: {
        path: '/product/test/edit/:testId',
        title: 'Test',
        permissionKey: 'test_management.update',
        breadcrumbName: 'Edit Test',
    },
    PanelManagement: {
        path: '/product/panel',
        title: 'Panel',
        permissionKey: 'panel_management.view_list',
        breadcrumbName: 'Panel Management',
    },
    PanelDetailPage: {
        path: '/product/panel/detail/:panelId',
        title: 'Panel',
        permissionKey: 'panel_management.view_detail',
        breadcrumbName: 'Detail',
    },
    PanelEditPage: {
        path: '/product/panel/edit/:panelId',
        title: 'Panel',
        permissionKey: 'panel_management.update',
        breadcrumbName: 'Edit Panel',
    },
    PromoManagement: {
        path: '/promo',
        title: 'Promo Management',
        permissionKey: 'promo_management.view_list',
        breadcrumbName: 'Promo Management',
    },
    PromoDetailPage: {
        path: '/promo/detail/:promoId',
        title: 'Promo Management',
        permissionKey: 'promo_management.view_detail',
        breadcrumbName: 'Detail Promo',
    },
    PromoCreatePage: {
        path: '/promo/create',
        title: 'Promo Management',
        permissionKey: 'promo_management.create',
        breadcrumbName: 'Add New Promo',
    },
    PromoEditPage: {
        path: '/promo/edit/:promoId',
        title: 'Promo Management',
        permissionKey: 'promo_management.update',
        breadcrumbName: 'Edit Promo',
    },
    PromoDeletePage: {
        path: '',
        title: 'Promo Management',
        permissionKey: 'promo_management.delete',
    },
    RoleManagement: {
        path: '/users/role',
        title: 'Role Management',
        permissionKey: 'role_management.view_list',
        breadcrumbName: 'Role Management',
    },
    RoleDetailPage: {
        path: '/users/role/detail/:roleId',
        title: 'Role Management',
        permissionKey: 'role_management.view_detail',
        breadcrumbName: 'Role Detail',
    },
    RoleCreatePage: {
        path: '/users/role/create',
        title: 'Role Management',
        permissionKey: 'role_management.create',
        breadcrumbName: 'Add New Role',
    },
    RoleEditPage: {
        path: '/users/role/edit/:roleId',
        title: 'Role Management',
        permissionKey: 'role_management.update',
        breadcrumbName: 'Edit Role',
    },
    RoleDeletePage: {
        path: '',
        title: 'Role Management',
        permissionKey: 'role_management.delete',
    },
    AdminManagement: {
        path: '/users/admin',
        title: 'Admin Management',
        permissionKey: 'admin_management.view_list',
        breadcrumbName: 'List User',
    },
    UserDetailPage: {
        path: '/users/admin/detail/:userId',
        title: 'Admin Management',
        permissionKey: 'admin_management.view_detail',
        breadcrumbName: 'User Detail',
    },
    UserCreatePage: {
        path: '/users/admin/create',
        title: 'Admin Management',
        permissionKey: 'admin_management.create',
        breadcrumbName: 'Add New User',
    },
    UserEditPage: {
        path: '/users/admin/edit/:userId',
        title: 'Admin Management',
        permissionKey: 'admin_management.update',
        breadcrumbName: 'Edit User',
    },
    UserDeletePage: {
        path: '',
        title: 'Admin Management',
        permissionKey: 'admin_management.delete',
    },
    BranchManagement: {
        path: '/branch',
        title: 'Branch Management',
        permissionKey: 'outlet_management.view_list',
        breadcrumbName: 'Branch Management'
    },
    BranchDetailPage: {
        path: '/branch/detail/:branchId',
        title: 'Branch Management',
        permissionKey: 'outlet_management.view_detail',
        breadcrumbName: 'Details',
    },
    BranchEditPage: {
        path: '/branch/edit/:branchId',
        title: 'Branch Management',
        permissionKey: 'outlet_management.update',
        breadcrumbName: 'Edit',
    },
    TNCManagementPage: {
        path: '/tnc',
        title: 'TnC Settings',
        permissionKey: 'hs_tnc.view_detail',
        breadcrumbName: 'Edit TNC',
    },
    NotFoundPage: {
        path: '/*',
        title: 'Not Found',
    },
    NotAllowedPage: {
        path: '/not-allowed',
        title: 'Not Allowed',
    }
};
