import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { Images } from 'themes';
import { OvalButton } from 'components/ButtonComponent';
import { TypeTimeOut } from './index';
import './index.css';

const Text = {
    tryAgain: 'TRY AGAIN',
    timeoutError: {
        title: 'Timeout',
        description: `Looks like the server is taking too long to respond.<br/>Please try again.`,
    },
};
const ModalTimeout: React.FC<{
    title?: string;
    visible?: boolean;
    width?: number;
    maskStyle?: Object;
    closable?: boolean;
    maskClosable?: boolean;
    handleClose?: () => void;
    message?: string;
    wrapClassName?: string;
    buttonText?: string;
    buttonIcon?: any;
    buttonType?: string;
    buttonClassName?: string;
    buttonClick?: () => unknown;
    type?: string;
}> = ({
    title = null,
    visible = false,
    width = 356,
    maskStyle = {
        opacity: 0.7,
        backgroundColor: '#000000',
    },
    closable = false,
    maskClosable = false,
    handleClose = () => {},
    message = '',
    wrapClassName = '',
    buttonText = 'OK',
    buttonIcon = '',
    buttonType = 'primary',
    buttonClassName = '',
    buttonClick = null,
    type = ''
}) => {
    const dispatch = useDispatch();
    const handleCloseFn = useCallback(() => {
        handleClose();
    }, [handleClose]);
    const handleRetry = useCallback(() => {
        handleCloseFn();
        if (typeof buttonClick !== 'function') return;
        const payload = buttonClick();
        // check whether the return value is a redux action
        const isActionObject = payload instanceof Object && payload.hasOwnProperty('type');
        if (isActionObject) {
            dispatch(payload);
        }
    }, [dispatch, buttonClick, handleCloseFn]);

    let footer: any[] = [];
    if (type === TypeTimeOut) {
        footer = [
            <OvalButton
                type={buttonType}
                wrapperClassName={buttonClassName}
                buttonText={Text.tryAgain}
                key={buttonText}
                onClick={handleRetry}
            />
        ];
    }
    const renderContent = (type: string) => {
        if (type === TypeTimeOut) {
            return (
                <div className="handleErrorContent">
                    <img className="handleErrorImg" src={Images.Timeout} alt="timeout" />
                    <div className="title">{Text.timeoutError.title}</div>
                    <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: Text.timeoutError.description }}
                    />
                </div>
            )
        }
        return null;
    };

    return (
        <Modal
          maskClosable={maskClosable}
          title={title}
          centered
          closeIcon={null}
          visible={visible}
          width={width}
          maskStyle={maskStyle}
          closable={closable}
          onCancel={() => handleCloseFn()}
          footer={footer}
          wrapClassName={`${wrapClassName} ModalErrorWrapper ${type}`}
        >
          {renderContent(type)}
        </Modal>
    )
};

export default ModalTimeout;
