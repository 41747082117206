import {
  BRANCH_DETAIL_INITIAL,
  BRANCH_DETAIL_REQUEST,
  BRANCH_DETAIL_SUCCESS,
  BRANCH_DETAIL_FAILURE,
  BranchDetailAction
} from './BranchDetailAction';
import { BranchDetailStoreState } from './BranchDetailState';

export const defaultState: BranchDetailStoreState = {
  onRequest: false,
  data: null,
  error: null
};

export function branchDetailReducer(
  state: BranchDetailStoreState = defaultState,
  action: BranchDetailAction
): BranchDetailStoreState {
  switch (action.type) {
      case BRANCH_DETAIL_INITIAL:
          return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
      case BRANCH_DETAIL_REQUEST:
          return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
      case BRANCH_DETAIL_SUCCESS:
          return { ...state, onRequest: false, data: action.data, error: defaultState.error };
      case BRANCH_DETAIL_FAILURE:
          return { ...state, onRequest: false, data: defaultState.data, error: action.error };
      default:
          return state;
  }
}
