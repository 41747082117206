import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Branch } from 'data/Models';
import RootStoreState from 'rootStore';
import { SelectMultipleComponent } from 'components/SelectComponent';

const Text = {
    placeholder: 'Choose Branch',
};

interface Props {
    regionIds?: string[];
    selectedOptions?: string[];
    onLoad: (regions: Branch[]) => void;
    onSelect: (values: Branch[]) => void;
}

function BranchInputView(props: Props) {
    const branchListState = useSelector((state: RootStoreState) => state.branchList);
    const [query, setQuery] = useState('');
    const branchList = branchListState.data?.data?.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()) && props.regionIds?.includes(item.region?.id ?? "")) ?? [];
    const [isAlreadyLoaded, setAlreadyLoaded] = useState(false);

    useEffect(() => {
        if (branchListState.data && !isAlreadyLoaded) {
            setAlreadyLoaded(true);
            props.onLoad(branchListState.data?.data);
        }
    }, [branchListState, isAlreadyLoaded, props]);
    
    const onGetOption = (region: Branch) => {
        return { value: region.id, label: region.name }
    };

    return (
        <SelectMultipleComponent
            selectProps={{
                disabled: branchList.length === 0,
                placeholder: Text.placeholder,
                onSearch: (query) => setQuery(query)
            }}
            selectedOptions={props.selectedOptions}
            data={branchList}
            onMapOption={onGetOption}
            onSelectedChange={props.onSelect}
        />
    );
}

export default BranchInputView;
