import React from 'react';

interface PopOverOption {
  clickFn?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void | undefined,
  imgSrc: string,
  text: string,
  isAllowed?: boolean,
  popOverItemClass?: string,
}

const PopOverContent: React.FC<{
  options: PopOverOption[]
}> = ({ options }) => {
  const popOverContentItems = options.map((props) => {
      const { clickFn = () => {}, imgSrc, text, isAllowed, popOverItemClass } = props;
      
      if (!isAllowed) return null;
      return (
          <div key={text} className={`appPopOverItem ${popOverItemClass}`} onClick={clickFn}>
              {imgSrc ? <img src={imgSrc} alt={text} /> : null}
              <span>{text}</span>
          </div>
      );
  });

  return (
      <div className="appPopOverContent">
          {popOverContentItems}
      </div>
  );
}

export default PopOverContent