import React, { useState, useCallback, Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { HttpError } from 'data/Models';
import { Images } from 'themes';
import RootStoreState from 'rootStore';
import { FrontendRoutes } from 'navigation/Routes';
import { DefaultCustomProps } from 'common/States';
import { doGetProfileInitial, GetProfileAction } from 'features/scaffold/ScaffoldAction';
import AlertComponent from 'components/AlertComponent';
import { OvalButton } from 'components/ButtonComponent';
import BreadCrumbsComp from 'components/BreadCrumbsComponent';
import { GetMethodLoading } from 'components/LoadingComponent';
import { default as BranchForm, FormMode } from '../BranchForm/BranchForm';
import { BranchDetailAction, doBranchDetailInitial, doBranchDetailRequest } from './BranchDetailAction';

import './index.css';
import { BranchRefererPage } from 'common/BranchUtils';

const Text = {
  edit: 'EDIT',
};

interface Props {
  customProps?: DefaultCustomProps
}

const BranchDetailView: React.FC<Props> = (props) => {
  const history = useHistory();
  const { branchId } = useParams<{ branchId: string }>();
  const profileState = useSelector((state: RootStoreState) => state.profile);
  const branchDetail = useSelector((state: RootStoreState) => state.branchDetail);
  const branchDetailData = branchDetail.data;
  const profileAction = useDispatch<Dispatch<GetProfileAction>>();
  const permissions = profileState.data?.permissions || [];
  const [error, setError] = useState<HttpError | null>(null);
  const loading = profileState.onRequest || branchDetail.onRequest;
  const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.BranchEditPage.permissionKey, permissions);
  
  const branchDetailAction = useDispatch<Dispatch<BranchDetailAction>>();

  const handleNavigateToEdit = useCallback(() => {
    history.push(`/branch/edit/${branchId}`, BranchRefererPage.DETAIL);
  }, [history, branchId]);
  const handleClearFn = useCallback(() => {
    setError(null)
  }, []);

  useEffect(() => {
    branchDetailAction(doBranchDetailInitial());
    branchDetailAction(doBranchDetailRequest(branchId));
  }, [branchId, branchDetailAction]);
  useEffect(() => {
    if (profileState.error) {
      profileAction(doGetProfileInitial());
      setError(profileState.error);
    }
  }, [profileAction, profileState]);
  useEffect(() => {
    if (branchDetail.error) {
      branchDetailAction(doBranchDetailInitial());
      setError(branchDetail.error);
    }
  }, [branchDetailAction, branchDetail]);

  const breadCrumbsButtons = () => {
    return (
      <div className="breadCrumbsButtonsWrapper">
        {isAllowedEditDetail && (
          <OvalButton
            type="primary"
            buttonText={Text.edit}
            icon={<img className="ovalButtonImg" src={Images.PencilWhite} alt="edit" />}
            onClick={() => handleNavigateToEdit()}
          />
        )}
      </div>
    )
  };

  return (
    <div className="branchDetailWrapper">
      {props.customProps?.breadcrumbs && (
        <BreadCrumbsComp crumbs={props.customProps.breadcrumbs}>
          {breadCrumbsButtons()}
        </BreadCrumbsComp>
      )}
      {loading && <GetMethodLoading />}
      {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}

      <BranchForm data={branchDetailData} mode={FormMode.View} />
    </div>
  );
}

export default BranchDetailView;