import * as http from "../../common/HttpUtils";
import ApiEndpoint from "./ApiEndpoint";
import { ContractListQuery } from "common/States";

const ContractApi = {
    getContractList(queryParams: ContractListQuery): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(ApiEndpoint.Contract.Base)
            .setQueryParameter(queryParams)
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    contractList: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
    getContractDetail (contractId: string): Promise<http.HttpService<{}>> {
        return new http.RequestBuilder()
            .setUri(`${ApiEndpoint.Contract.Base}/${contractId}`)
            .setQueryParameter({})
            .doGet()
            .then(response => http.handleResponse(200, response, (json: any): {} => {
                return {
                    contractDetail: json,
                }
            }))
            .catch(error => http.handleCatch(error));
    },
};

export default ContractApi;
