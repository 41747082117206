import { HttpError } from 'data/Models';

export const ROLE_DETAIL_INITIAL = 'ROLE_DETAIL_INITIAL';
export type ROLE_DETAIL_INITIAL = typeof ROLE_DETAIL_INITIAL;

export const ROLE_DETAIL_REQUEST = 'ROLE_DETAIL_REQUEST';
export type ROLE_DETAIL_REQUEST = typeof ROLE_DETAIL_REQUEST;

export const ROLE_DETAIL_SUCCESS = 'ROLE_DETAIL_SUCCESS';
export type ROLE_DETAIL_SUCCESS = typeof ROLE_DETAIL_SUCCESS;

export const ROLE_DETAIL_FAILURE = 'ROLE_DETAIL_FAILURE';
export type ROLE_DETAIL_FAILURE = typeof ROLE_DETAIL_FAILURE;

interface RoleDetailInitial {
    type: ROLE_DETAIL_INITIAL;
}

export interface RoleDetailRequest {
    type: ROLE_DETAIL_REQUEST;
    roleId: string;
}

interface RoleDetailSuccess {
    type: ROLE_DETAIL_SUCCESS;
    data: any;
}

interface RoleDetailFailure {
    type: ROLE_DETAIL_FAILURE;
    error: HttpError;
}

export type RoleDetailAction = RoleDetailInitial | RoleDetailRequest | RoleDetailSuccess | RoleDetailFailure;

export function doRoleDetailInitial(): RoleDetailInitial {
    return {
        type: ROLE_DETAIL_INITIAL
    };
}

export function doRoleDetailRequest(roleId: string): RoleDetailRequest {
    return {
        type: ROLE_DETAIL_REQUEST,
        roleId: roleId,
    };
}

export function doRoleDetailSuccess(data: any): RoleDetailSuccess {
    return {
        type: ROLE_DETAIL_SUCCESS,
        data: data
    };
}

export function doRoleDetailFailure(error: HttpError): RoleDetailFailure {
    return {
        type: ROLE_DETAIL_FAILURE,
        error: error
    };
}
