import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { formRules } from './ChangePasswordConfig';
import './ChangePasswordView.css'
import { LoadingWithMask } from 'components/LoadingComponent';
import AlertComponent from 'components/AlertComponent';
import { HttpError } from 'data/Models';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePasswordAction, doChangePasswordInitial, doChangePasswordRequest } from './ChangePasswordAction';
import RootStoreState from 'rootStore';

interface Props {
    handleClose: () => void;
}

const Text = {
    ModalTitle: 'Change Password',
    CurrentPasswordLabel: 'Current Password',
    NewPasswordLabel: 'New Password',
    ConfirmPasswordLabel: 'Confirm New Password',
    SubmitButton: 'SUBMIT',
    CancelButton: 'Cancel'
}

function ChangePasswordView(props: Props) {
    const { handleClose } = props;

    const [form] = Form.useForm();
    const changePasswordAction = useDispatch<Dispatch<ChangePasswordAction>>();
    const changePasswordState = useSelector((state: RootStoreState) => state.changePassword);
    const [error, setError] = useState<HttpError | null>(null);

    const onCancel = useCallback(() => {
        form.resetFields();
        setError(null);
        handleClose();
    }, [handleClose, form]);
    const onFinish = useCallback(() => {
        changePasswordAction(doChangePasswordRequest(
            form.getFieldValue('currentPassword'),
            form.getFieldValue('newPassword'),
            form.getFieldValue('confirmPassword')
        ));
    }, [changePasswordAction, form]);
    const handleClearFn = useCallback(() => {
        setError(null);
    }, []);

    useEffect(() => {
        if (changePasswordState.success === true) {
            onCancel();
        } else if (changePasswordState.error) {
            changePasswordAction(doChangePasswordInitial());
            setError(changePasswordState.error);
        }
    }, [changePasswordAction, changePasswordState, onCancel, form]);

    return (
        <div
            className={'changePasswordViewWrapper'}
        >
            {changePasswordState.onRequest && <LoadingWithMask />}
            {error && <AlertComponent error={error} clearFn={handleClearFn}/>}
            <div className="changePasswordViewTitle">
                {Text.ModalTitle}
            </div>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label={Text.CurrentPasswordLabel}
                    name="currentPassword"
                    rules={formRules.currentPassword}
                >
                    <Input.Password bordered />
                </Form.Item>
                <Form.Item
                    label={Text.NewPasswordLabel}
                    name="newPassword"
                    rules={formRules.newPassword}
                >
                    <Input.Password bordered />
                </Form.Item>
                <Form.Item
                    label={Text.ConfirmPasswordLabel}
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    rules={formRules.confirmPassword(form)}
                >
                    <Input.Password bordered />
                </Form.Item>
                <div className="changePasswordViewAction">
                    <Button type="primary" htmlType="submit">
                        {Text.SubmitButton}
                    </Button>
                    <span style={{ flex: 1 }}/>
                    <Button type="text" onClick={onCancel}>
                        {Text.CancelButton}
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default ChangePasswordView;
