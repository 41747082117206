import {
    PROMO_DETAIL_INITIAL,
    PROMO_DETAIL_REQUEST,
    PROMO_DETAIL_SUCCESS,
    PROMO_DETAIL_FAILURE,
    PromoDetailAction
} from './PromoDetailAction';
import { PromoDetailStoreState } from './PromoDetailState';

export const defaultState: PromoDetailStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function promoDetailReducer(
    state: PromoDetailStoreState = defaultState,
    action: PromoDetailAction
): PromoDetailStoreState {
    switch (action.type) {
        case PROMO_DETAIL_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case PROMO_DETAIL_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case PROMO_DETAIL_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case PROMO_DETAIL_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
