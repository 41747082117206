import {
    CONTRACT_LIST_INITIAL,
    CONTRACT_LIST_REQUEST,
    CONTRACT_LIST_SUCCESS,
    CONTRACT_LIST_FAILURE,
    ContractListAction
} from './ContractListAction';
import { ContractListStoreState } from './ContractListState';

export const defaultState: ContractListStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function contractListReducer(
    state: ContractListStoreState = defaultState,
    action: ContractListAction
): ContractListStoreState {
    switch (action.type) {
        case CONTRACT_LIST_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case CONTRACT_LIST_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case CONTRACT_LIST_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case CONTRACT_LIST_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
