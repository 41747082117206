import React, {Dispatch, useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from 'antd';

import { FrontendRoutes } from 'navigation/Routes';
import RootStoreState from 'rootStore';
import { AuthStorage } from 'data/local/AuthStorage';
import { Images } from 'themes';
import { HttpError } from 'data/Models';
import { LoadingWithMask } from 'components/LoadingComponent';
import AlertComponent from 'components/AlertComponent';
import { OvalButton } from 'components/ButtonComponent';
import { DoubleSessionCode, InvalidCaptchaTokenCode, UnauthorizedEmailDomainCode } from 'common/HttpCode';
import { LoginAction, doLoginRequest, doLoginInitial } from './LoginAction';
import { emailRules, passwordRules, loginText, maxEmailLen, maxPasswordLen } from './LoginConfig';
import Recaptcha from 'lib/Recaptcha';
import { ModalProdia } from 'components/ModalComponent';
import './LoginView.css';
import { Link } from 'react-router-dom';

interface Props {
    history?: any;
}

const errorMessageMap = {
    [InvalidCaptchaTokenCode]: 'There was an error, please try again',
    [UnauthorizedEmailDomainCode]: 'Email domain is not authorized for login',
};

const LoginView: React.FC<Props> = ({
    history
}) => {
    const loginState = useSelector((state: RootStoreState) => state.login);
    const [isFetchingCaptcha, setIsFetchingCaptcha] = useState<boolean>(false);
    const loginAction = useDispatch<Dispatch<LoginAction>>();
    const isAuthenticated = AuthStorage.isAuthenticated();
    const [error, setError] = useState<HttpError | null>(null);
    const [form] = Form.useForm();
    const [, forceUpdate] = useState();
    const isDoubleSessionError = Boolean(error && error?.code === DoubleSessionCode);

    useEffect(() => {
        forceUpdate({});
    }, []);
    const onFinish = (values: any) => {
        setIsFetchingCaptcha(true);
        Recaptcha.getToken().then(recaptchaToken => {
            return loginAction(doLoginRequest(values.email, values.password, recaptchaToken));
        })
        .finally(() => {
            setIsFetchingCaptcha(false);
            Recaptcha.tearDown();
        });
    };
    useEffect(() => {
        if(loginState.error) {
            loginAction(doLoginInitial());

            const errorCode = loginState.error.code;
            if (errorCode === InvalidCaptchaTokenCode) {
                loginState.error.message = errorMessageMap[InvalidCaptchaTokenCode];
            } else if (errorCode === UnauthorizedEmailDomainCode) {
                loginState.error.message = errorMessageMap[UnauthorizedEmailDomainCode];
            }

            setError(loginState.error);
        }
    }, [loginState, loginAction]);

    useEffect(() => {
        if (isAuthenticated) {
            // go to dashboard page with force reload
            // this is required for initializing session timer
            window.location.href = FrontendRoutes.Dashboard.path;
        }
    }, [isAuthenticated, history]);
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    return (
        <div className="loginViewWrapper">
            {(isFetchingCaptcha || loginState.onRequest) && <LoadingWithMask />}
            {error && !isDoubleSessionError && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            {isDoubleSessionError && (
                <ModalProdia
                    visible
                    footer={[
                        <OvalButton key="submit" type="primary" onClick={() => handleClearFn()} buttonText={loginText.loginOKButtonText} />
                    ]}
                >
                    <>
                        <p className="contentTitle">Can't Login</p>
                        <p className="contentText">
                            You can't access this website using <strong>{form.getFieldValue('email')}</strong> account because it is connected to another devices.
                        </p>
                    </>
                </ModalProdia>
            )}
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <div className="loginViewHeader">
                    <img src={Images.LogoLoginHeader} alt="login logo" />
                </div>
                <div className="loginViewFormContent">
                    <div className="loginViewFormTitle">
                        {loginText.loginViewFormTitle}
                    </div>
                    <div className="loginViewFormDesc">
                        {loginText.loginViewFormDesc}
                    </div>
                    <Form.Item
                        label="Email Address"
                        name="email"
                        rules={emailRules}
                        validateFirst
                    >
                        <Input maxLength={maxEmailLen} bordered/>
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={passwordRules}
                    >
                        <Input.Password maxLength={maxPasswordLen} bordered />
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <div className="loginViewButtonWrapper">
                                <div className="activation">
                                    <div>New Administrator?</div>
                                    <Link className="activationLink" to="/activation">Activate Your Account</Link>
                                </div>
                                <OvalButton
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                    buttonText={loginText.loginButtonText}
                                />
                            </div>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default LoginView;
