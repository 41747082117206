import { call, put, takeLatest } from 'redux-saga/effects';
import TestApi from 'data/api/TestApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './TestEditAction';

function* updateTestTask(sagaAction: action.UpdateTestRequest) {
    const { updateData } = sagaAction;
    const response = yield call(TestApi.updateTest, updateData);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doUpdateTestSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doUpdateTestRequest(updateData) });
            yield put(action.doUpdateTestFailure(newTimeoutError));
        } else {
            yield put(action.doUpdateTestFailure(response.error));
        }
    }
}

export function* updateTestSaga() {
    yield takeLatest(action.UPDATE_TEST_REQUEST, updateTestTask);
}
