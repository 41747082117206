import { call, put, takeLatest } from 'redux-saga/effects';
import OrderApi from 'data/api/OrderApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './OrderDetailAction';

function* orderDetailTask(sagaAction: action.OrderDetailRequest) {
    const { orderId } = sagaAction;
    const response = yield call(OrderApi.getOrderDetail, orderId);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doOrderDetailSuccess(response.data.orderDetail));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doOrderDetailRequest(orderId) });
            yield put(action.doOrderDetailFailure(newTimeoutError));
        } else {
            yield put(action.doOrderDetailFailure(response.error));
        }
    }
}

export function* orderDetailSaga() {
    yield takeLatest(
        action.ORDER_DETAIL_REQUEST,
        orderDetailTask,
    );
}
