import { call, put, takeLatest } from 'redux-saga/effects';
import * as http from 'common/HttpUtils';
import * as action from './ChangePasswordAction';
import ProfileApi from 'data/api/ProfileApi';

function* changePasswordTask(changePasswordAction: action.ChangePasswordRequest) {
    const { currentPassword, newPassword, confirmPassword } = changePasswordAction;
    const response = yield call(ProfileApi.changePassword, currentPassword, newPassword, confirmPassword);

    if (response.status === http.HTTP_RESPONSE_SUCCESS) {
        yield put(action.doChangePasswordSuccess());
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        yield put(action.doChangePasswordFailure(response.error));
    }
}
export function* changePasswordSaga() {
    yield takeLatest(
        action.CHANGE_PASSWORD_REQUEST,
        changePasswordTask,
    );
}