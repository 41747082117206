import React, {useCallback, useState} from "react";
import {
  SelectComponent,
  SelectFilterComponent,
} from "components/SelectComponent";
import * as config from "./config";
import {Images} from "themes";

interface ItemOption {
  id: string;
  name: string;
}
interface Props {
  onSingleSelect?: (values: ItemOption) => void;
  onMultipleSelect?: (values: ItemOption[]) => void;
  type?: string;
}
const Text = {
  label: "Filter by Status",
  searchPlaceholder: "Search Status",
  promoTypeLabel: "Filter by Type",
  promoTypePlaceholder: "Search",
  promoStatusLabel: "Filter by Status",
  promoStatusPlaceholder: "Search",
  promoSourceLabel: "Filter by Source",
  promoSourcePlaceholder: "Search",
};
export const active = "true";
export const inactive = "false";
export const TypeList = {
  promoType: "promoType",
  promoStatus: "promoStatus",
  promoSource: "promoSource",
};

function StatusFilterView(props: Props) {
  let newListState: ItemOption[] = [];
  let newLabel = "";
  let newPlaceholder = "";
  let isSingleSelect = false;

  const [query, setQuery] = useState("");
  const handleGetOption = useCallback((status: ItemOption) => {
    return {value: status.id, label: status.name};
  }, []);

  switch (props.type) {
    case TypeList.promoType:
      newPlaceholder = Text.promoTypePlaceholder;
      newLabel = Text.promoTypeLabel;
      const options = config.promoTypeListState;
      // Todo - When flash sale ready will uncomment this logic
      delete options[1];
      newListState = options;
      break;
    case TypeList.promoStatus:
      newPlaceholder = Text.promoStatusPlaceholder;
      newLabel = Text.promoStatusLabel;
      newListState = config.promoStatusListState;
      isSingleSelect = true;
      break;
    case TypeList.promoSource:
      newPlaceholder = Text.promoSourceLabel;
      newLabel = Text.promoSourceLabel;
      newListState = config.promoSourceListState;
      isSingleSelect = true;
      break;
    default:
      newPlaceholder = Text.searchPlaceholder;
      newLabel = Text.label;
      newListState = config.statusListState;
      break;
  }
  const statusList =
    newListState.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    ) ?? [];

  let view = null;
  if (isSingleSelect) {
    view = (
      <SingleSelectFilterView
        label={newLabel}
        statusList={statusList}
        onGetOption={handleGetOption}
        onSelect={(value) => {
          if (props.onSingleSelect) {
            props.onSingleSelect(value);
          }
        }}
      />
    );
  } else {
    view = (
      <MultipleSelectFilterView
        label={newLabel}
        searchPlaceholder={newPlaceholder}
        statusList={statusList}
        setQuery={setQuery}
        onGetOption={handleGetOption}
        onSelect={(values) => {
          if (props.onMultipleSelect) {
            props.onMultipleSelect(values);
          }
        }}
      />
    );
  }

  return view;
}

interface SingleSelectFilterViewProps {
  label: string;
  statusList: ItemOption[];
  onGetOption: (option: ItemOption) => {value: string; label: string};
  onSelect: (values: ItemOption) => void;
}

function SingleSelectFilterView(props: SingleSelectFilterViewProps) {
  const [isVisible, setDropdownVisible] = useState<boolean>(false);

  return (
    <SelectComponent
      selectProps={{
        placeholder: props.label,
        bordered: false,
        onDropdownVisibleChange: (open) => {
          setDropdownVisible(open);
        },
        suffixIcon: () => {
          return (
            <img
              src={isVisible ? Images.ChevronUpGrey : Images.ChevronDownGrey}
              alt="Arrow"
            />
          );
        },
      }}
      data={props.statusList}
      onMapOption={props.onGetOption}
      onSelectedChange={props.onSelect}
      prefixLabel={`${props.label}: `}
      className={"statusFilterView"}
      optionClassName={"selectOptionWrapper"}
    />
  );
}

interface MultipleSelectFilterViewProps {
  label: string;
  searchPlaceholder: string;
  statusList: ItemOption[];
  setQuery: (query: string) => void;
  onGetOption: (option: ItemOption) => {value: string; label: string};
  onSelect: (values: ItemOption[]) => void;
}

function MultipleSelectFilterView(props: MultipleSelectFilterViewProps) {
  return (
    <SelectFilterComponent
      label={props.label}
      searchPlaceholder={props.searchPlaceholder}
      data={props.statusList}
      onSearch={(query) => props.setQuery(query)}
      onMapOption={props.onGetOption}
      onSelectedChange={props.onSelect}
    />
  );
}

export default StatusFilterView;
