import { RoleFormData, HttpError } from "data/Models";

export const UPDATE_ROLE_INITIAL = 'UPDATE_ROLE_INITIAL';
export type UPDATE_ROLE_INITIAL = typeof UPDATE_ROLE_INITIAL;

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export type UPDATE_ROLE_REQUEST = typeof UPDATE_ROLE_REQUEST;

export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export type UPDATE_ROLE_SUCCESS = typeof UPDATE_ROLE_SUCCESS;

export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';
export type UPDATE_ROLE_FAILURE = typeof UPDATE_ROLE_FAILURE;

interface UpdateRoleInitial {
    type: UPDATE_ROLE_INITIAL;
}

export interface UpdateRoleRequest {
    type: UPDATE_ROLE_REQUEST;
    updateData: RoleFormData;
}

interface UpdateRoleSuccess {
    type: UPDATE_ROLE_SUCCESS;
}

interface UpdateRoleFailure {
    type: UPDATE_ROLE_FAILURE;
    error: HttpError;
}

export type UpdateRoleAction = UpdateRoleInitial | UpdateRoleRequest | UpdateRoleSuccess | UpdateRoleFailure;

export function doUpdateRoleInitial(): UpdateRoleInitial {
    return {
        type: UPDATE_ROLE_INITIAL,
    };
}

export function doUpdateRoleRequest(updateData: RoleFormData): UpdateRoleRequest {
    return {
        type: UPDATE_ROLE_REQUEST,
        updateData: updateData,
    };
}

export function doUpdateRoleSuccess(): UpdateRoleSuccess {
    return {
        type: UPDATE_ROLE_SUCCESS,
    };
}

export function doUpdateRoleFailure(error: HttpError): UpdateRoleFailure {
    return {
        type: UPDATE_ROLE_FAILURE,
        error: error,
    };
}
