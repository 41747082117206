import { HttpError, PanelDetail } from 'data/Models';

export const PANEL_DETAIL_INITIAL = 'PANEL_DETAIL_INITIAL';
export type PANEL_DETAIL_INITIAL = typeof PANEL_DETAIL_INITIAL;

export const PANEL_DETAIL_REQUEST = 'PANEL_DETAIL_REQUEST';
export type PANEL_DETAIL_REQUEST = typeof PANEL_DETAIL_REQUEST;

export const PANEL_DETAIL_SUCCESS = 'PANEL_DETAIL_SUCCESS';
export type PANEL_DETAIL_SUCCESS = typeof PANEL_DETAIL_SUCCESS;

export const PANEL_DETAIL_FAILURE = 'PANEL_DETAIL_FAILURE';
export type PANEL_DETAIL_FAILURE = typeof PANEL_DETAIL_FAILURE;

interface PanelDetailInitial {
    type: PANEL_DETAIL_INITIAL;
}

export interface PanelDetailRequest {
    type: PANEL_DETAIL_REQUEST;
    panelId: string;
}

interface PanelDetailSuccess {
    type: PANEL_DETAIL_SUCCESS;
    data: PanelDetail;
}

interface PanelDetailFailure {
    type: PANEL_DETAIL_FAILURE;
    error: HttpError;
}

export type PanelDetailAction = PanelDetailInitial | PanelDetailRequest | PanelDetailSuccess | PanelDetailFailure;

export function doPanelDetailInitial(): PanelDetailInitial {
    return {
        type: PANEL_DETAIL_INITIAL
    };
}

export function doPanelDetailRequest(panelId: string): PanelDetailRequest {
    return {
        type: PANEL_DETAIL_REQUEST,
        panelId: panelId,
    };
}

export function doPanelDetailSuccess(data: PanelDetail): PanelDetailSuccess {
    return {
        type: PANEL_DETAIL_SUCCESS,
        data: data
    };
}

export function doPanelDetailFailure(error: HttpError): PanelDetailFailure {
    return {
        type: PANEL_DETAIL_FAILURE,
        error: error
    };
}
