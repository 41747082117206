import { HttpError, AdminDetail } from 'data/Models';

export const ADMIN_DETAIL_INITIAL = 'ADMIN_DETAIL_INITIAL';
export type ADMIN_DETAIL_INITIAL = typeof ADMIN_DETAIL_INITIAL;

export const ADMIN_DETAIL_REQUEST = 'ADMIN_DETAIL_REQUEST';
export type ADMIN_DETAIL_REQUEST = typeof ADMIN_DETAIL_REQUEST;

export const ADMIN_DETAIL_SUCCESS = 'ADMIN_DETAIL_SUCCESS';
export type ADMIN_DETAIL_SUCCESS = typeof ADMIN_DETAIL_SUCCESS;

export const ADMIN_DETAIL_FAILURE = 'ADMIN_DETAIL_FAILURE';
export type ADMIN_DETAIL_FAILURE = typeof ADMIN_DETAIL_FAILURE;

interface AdminDetailInitial {
    type: ADMIN_DETAIL_INITIAL;
}

export interface AdminDetailRequest {
    type: ADMIN_DETAIL_REQUEST;
    userId: string;
}

interface AdminDetailSuccess {
    type: ADMIN_DETAIL_SUCCESS;
    data: AdminDetail;
}

interface AdminDetailFailure {
    type: ADMIN_DETAIL_FAILURE;
    error: HttpError;
}

export type AdminDetailAction = AdminDetailInitial | AdminDetailRequest | AdminDetailSuccess | AdminDetailFailure;

export function doAdminDetailInitial(): AdminDetailInitial {
    return {
        type: ADMIN_DETAIL_INITIAL
    };
}

export function doAdminDetailRequest(userId: string): AdminDetailRequest {
    return {
        type: ADMIN_DETAIL_REQUEST,
        userId: userId,
    };
}

export function doAdminDetailSuccess(data: AdminDetail): AdminDetailSuccess {
    return {
        type: ADMIN_DETAIL_SUCCESS,
        data: data
    };
}

export function doAdminDetailFailure(error: HttpError): AdminDetailFailure {
    return {
        type: ADMIN_DETAIL_FAILURE,
        error: error
    };
}
