import throttle from 'lodash/throttle';
import SessionTimeManager  from './SessionTimeManager';

const sessionTimeManager = new SessionTimeManager();

const isLoggingEnabled = process.env.REACT_APP_SESSION_TIMER_LOGGING === 'true';
if (isLoggingEnabled) {
  sessionTimeManager.enableLogging();
}

export default sessionTimeManager;
export { default as sessionTimeConfigStore } from './SessionTimeConfigStore';
/**
 * Try to capture user activity which could affect session time.
 */
export function listenUserActivity () {
  const logActivity = (eventType: string) => console.log('user activity. event:', eventType);
  const throttledCaptureActivity = throttle((event: Event) => {
    if (isLoggingEnabled) {
      logActivity(event.type);
    }
    sessionTimeManager.captureActivity();
  }, 500);
  document.onmouseover = throttledCaptureActivity;
  document.onclick = throttledCaptureActivity;
  window.onscroll = throttle(() => {
    if (isLoggingEnabled) {
      logActivity('scroll');
    }
    sessionTimeManager.captureActivity();
  }, 500);;
  document.onkeypress = throttledCaptureActivity;
}