import {
    LOGIN_INITIAL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LoginAction
} from './LoginAction';
import { LoginStoreState } from "./LoginState";

export const defaultState: LoginStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function loginReducer(
    state: LoginStoreState = defaultState,
    action: LoginAction
): LoginStoreState {
    switch (action.type) {
        case LOGIN_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case LOGIN_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case LOGIN_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case LOGIN_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
