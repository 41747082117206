import React, { useState, useCallback, useMemo, Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BranchDetail, BranchDetailTest, HttpError } from 'data/Models';
import RootStoreState from 'rootStore';
import { DefaultCustomProps } from 'common/States';
import { doGetProfileInitial, GetProfileAction } from 'features/scaffold/ScaffoldAction';
import { doUpdateBranchInitial, doUpdateBranchRequest, UpdateBranchAction } from './BranchEditAction';
import { doTestManagementInitial, doTestManagementRequest, TestManagementAction } from 'features/product/test/TestManagement/TestManagementAction';
import AlertComponent from 'components/AlertComponent';
import { GetMethodLoading, LoadingWithMask } from 'components/LoadingComponent';
import { BranchDetailAction, doBranchDetailInitial, doBranchDetailRequest } from '../BranchDetail/BranchDetailAction';
import { default as BranchForm, FormMode } from '../BranchForm/BranchForm';

import './index.css';
import { FrontendRoutes } from 'navigation/Routes';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { BranchRefererPage } from 'common/BranchUtils';

interface Props {
  customProps?: DefaultCustomProps
}

function createUpdatePayload(testIds: string[], branch: BranchDetail) {
  return {
    id: branch.id,
    tests: testIds,
    panels: branch.panels.map(panel => panel.id),
    days_off: branch.days_off,
  };
}

const BranchEditView: React.FC<Props> = (props) => {
  const history = useHistory();
  const location = useLocation<BranchRefererPage>();
  const { branchId } = useParams<{ branchId: string }>();
  const [error, setError] = useState<HttpError | null>(null);
  const [removedTestIds, setRemovedTestIds] = useState<string[]>([]);
  const [selectedTestIds, setSelectedTestIds] = useState<string[]>([]);
  const profileState = useSelector((state: RootStoreState) => state.profile);
  const profileData = profileState.data;
  const branchDetail = useSelector((state: RootStoreState) => state.branchDetail);
  const branchDetailData = branchDetail.data || new BranchDetail();
  const updateBranchState = useSelector((state: RootStoreState) => state.updateBranch);
  const testListState = useSelector((state: RootStoreState) => state.testManagement);
  const [persistedSelectedTestIds, setPersistedSelectedTestIds] = useState<string[]>([]);
  const availableTests = (testListState.data?.data || []);
  const availableTestsMap : Record<string, BranchDetailTest> = useMemo(() => {
    return availableTests.reduce((map, test) => Object.assign(map, { [test.id]: test  }), {})
  }, [availableTests]);
  const persistedSelectedTests = useMemo(() => {
    return persistedSelectedTestIds.map(id => (
      availableTestsMap[id] || new BranchDetailTest()
    ));
  }, [persistedSelectedTestIds, availableTestsMap]);
  const isAllowedEditConfig = useMemo(() => {
    return isPermissionAllowed(FrontendRoutes.BranchEditPage.permissionKey, profileData?.permissions || []);
  }, [profileData]);
  const isBranchFetched = Boolean(branchDetailData.id);
  const isFetchingProfile = profileState.onRequest;
  const isFetchingBranch = branchDetail.onRequest;
  const isFetchingTestList = testListState.onRequest;
  const isFetchingAllDependencies = isFetchingProfile || isFetchingBranch || isFetchingTestList;
  const profileAction = useDispatch<Dispatch<GetProfileAction>>();
  const branchDetailAction = useDispatch<Dispatch<BranchDetailAction>>();
  const branchUpdateAction = useDispatch<Dispatch<UpdateBranchAction >>();
  const testListAction = useDispatch<Dispatch<TestManagementAction>>();

  const handleSelectedTestsChange = useCallback((nextTestIds: string []) => {
    setSelectedTestIds(nextTestIds);
  }, []);
  const handleRemovedTestsChange = useCallback((testIds: string[]) => {
    setRemovedTestIds(testIds);
  }, []);
  const handlePersistTestIdsSelection = useCallback(() => {
    const nextPersistedSelectedTestIds = Array.from(new Set(
      persistedSelectedTestIds.concat(selectedTestIds)
    ));
    setPersistedSelectedTestIds(nextPersistedSelectedTestIds);
    setSelectedTestIds([]);
  }, [selectedTestIds, persistedSelectedTestIds]);
  const handlePersistTestIdsRemoval = useCallback(() => {
    const nextSelectedTestIds = persistedSelectedTestIds.filter(id => !removedTestIds.includes(id));
    setPersistedSelectedTestIds(nextSelectedTestIds);
    setRemovedTestIds([]);
  }, [removedTestIds, persistedSelectedTestIds]);
  const handleClearFn = useCallback(() => setError(null), []);
  const handleSubmit = useCallback((e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = createUpdatePayload(persistedSelectedTestIds, branchDetailData);
    branchUpdateAction(doUpdateBranchInitial());
    branchUpdateAction(doUpdateBranchRequest(payload));
  }, [persistedSelectedTestIds, branchDetailData, branchUpdateAction]);
  const handleCancel = useCallback(() => {
    const state = location.state;
    if (state === BranchRefererPage.DETAIL) {
      history.push(FrontendRoutes.BranchDetailPage.path.replace(':branchId', branchDetailData.id));
      return;
    }
    history.push(FrontendRoutes.BranchManagement.path);
  }, [history, branchDetailData, location]);

  useEffect(() => {
    if (profileState.error) {
      profileAction(doGetProfileInitial());
      setError(profileState.error);
    }
  }, [profileAction, profileState]);
  useEffect(() => {
    branchDetailAction(doBranchDetailInitial());
    branchDetailAction(doBranchDetailRequest(branchId));
  }, [branchId, branchDetailAction]);
  useEffect(() => {
    if (branchDetail.error) {
      branchDetailAction(doBranchDetailInitial());
      setError(branchDetail.error);
    }
  }, [branchDetailAction, branchDetail]);
  useEffect(() => {
    if (isAllowedEditConfig) {
      testListAction(doTestManagementInitial());
      testListAction(doTestManagementRequest({ isRetrieveAll: true }));
    }
  }, [isAllowedEditConfig, testListAction]);
  useEffect(() => {
    const { tests } = branchDetailData;
    if (tests.length) {
      setPersistedSelectedTestIds(tests.map(test => test.id));
    }
  }, [branchDetailData]);
  useEffect(() => {
    if (updateBranchState.error) {
      setError(updateBranchState.error);
    }
  }, [updateBranchState]);
  useEffect(() => {
    if (updateBranchState.success) {
      setTimeout(() => {
        history.push(FrontendRoutes.BranchDetailPage.path.replace(':branchId', branchId));
      }, 3000);
    }
  }, [updateBranchState, branchId, history]);

  return (
    <div className="branchEditWrapper">
      {isFetchingAllDependencies && <GetMethodLoading />}
      {updateBranchState.onRequest && <LoadingWithMask />}
      {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
      <BranchActionAlertView />
      {(() => {
        if (isFetchingAllDependencies || !isBranchFetched) return null;

        return  (
          <BranchForm 
            mode={FormMode.Edit}
            data={branchDetailData}
            availableTests={availableTests}
            persistedSelectedTests={persistedSelectedTests}
            selectedTestIds={selectedTestIds}
            removedTestIds={removedTestIds}
            onRemovedTestsChange={handleRemovedTestsChange}
            onSelectedTestsChange={handleSelectedTestsChange}
            onPersistTestIdsSelection={handlePersistTestIdsSelection}
            onPersistTestIdsRemoval={handlePersistTestIdsRemoval}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          /> 
        );
      })()}
    </div>
  );
}

function BranchActionAlertView() {
  const updateBranchAction = useDispatch<Dispatch<UpdateBranchAction>>();
  const updateBranchState = useSelector((state: RootStoreState) => state.updateBranch);
  const [success, setSuccess] = useState<string | null>('');
  useEffect(() => {
    if (updateBranchState.success === true) {
      updateBranchAction(doUpdateBranchInitial());
      setSuccess('Edit branch is success');
    }
  }, [updateBranchAction, updateBranchState]);

  return (
    <div>
      {success && <AlertComponent message={success} type={'success'} timeout={3000} />}
    </div>   
  );
}

export default BranchEditView;