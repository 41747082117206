import React, { useEffect, Dispatch, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { Descriptions } from 'antd';
import RootStoreState from 'rootStore';
import AlertComponent from 'components/AlertComponent';
import { HttpError } from 'data/Models';
import { LoadingWithMask, GetMethodLoading } from 'components/LoadingComponent';
import { OvalButton } from 'components/ButtonComponent';
import BreadCrumbsComp from 'components/BreadCrumbsComponent';
import { ModalConfirmation, TypeDeleteConfirmation } from 'components/ModalComponent';
import { DeleteRoleAction, doDeleteRoleInitial, doDeleteRoleRequest} from 'features/user/RoleDelete/DeleteRoleAction';
import { formatDate } from 'common/CommonUtils';
import { isPermissionAllowed } from 'common/PermissionUtils';
import { UsedByUsersCode } from 'common/HttpCode';
import { DefaultCustomProps } from 'common/States';
import { FrontendRoutes } from 'navigation/Routes';
import { Images } from 'themes';
import { GetProfileAction, doGetProfileInitial } from 'features/scaffold/ScaffoldAction';
import { RoleDetailAction, doRoleDetailInitial, doRoleDetailRequest } from './RoleDetailAction';
import './index.css'

interface Props {
    customProps?: DefaultCustomProps
}
const Text = {
    detailsInformation: 'Details Information',
    roleName: 'Role Name',
    permission: 'Permission',
    createdDate: 'Created Date',
    createdBy: 'Created By',
    updateDate: 'Update Date',
    updateBy: 'Update By',
    edit: 'EDIT',
    delete: 'DELETE',
    deleteConfirmation: 'Are you sure you want to delete this role?',
};
const RoleDetailView: React.FC<Props> = (props) => {
    const history = useHistory();
    const { roleId } = useParams<{ roleId: string }>();
    const profileAction = useDispatch<Dispatch<GetProfileAction>>();
    const profileState = useSelector((state: RootStoreState) => state.profile);
    const permissions = profileState.data?.permissions || [];

    const isAllowedEditDetail = isPermissionAllowed(FrontendRoutes.RoleEditPage.permissionKey, permissions);
    const isAllowedDeleteDetail = isPermissionAllowed(FrontendRoutes.RoleDeletePage.permissionKey, permissions);
    const roleDetailAction = useDispatch<Dispatch<RoleDetailAction>>();
    const roleDetailState = useSelector((state: RootStoreState) => state.roleDetail);
    const roleDetailData = roleDetailState.data;
    const deleteRoleAction = useDispatch<Dispatch<DeleteRoleAction>>();
    const deleteRoleState = useSelector((state: RootStoreState) => state.deleteRole);
    const [error, setError] = useState<HttpError | null>(null);
    const [showModalConfirmation, setShowModalConfirmation] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    useEffect(() => {
        roleDetailAction(doRoleDetailRequest(roleId));
    }, [roleId, roleDetailAction]);
    useEffect(() => {
        if (profileState.error) {
            profileAction(doGetProfileInitial());
            setError(profileState.error);
        }
    }, [profileAction, profileState]);
    useEffect(() => {
       if (roleDetailState.error) {
            roleDetailAction(doRoleDetailInitial());
            setError(roleDetailState.error);
        }
    }, [roleDetailAction, roleDetailState]);
    useEffect(() => {
        if (deleteRoleState.success === true) {
            history.replace(FrontendRoutes.RoleManagement.path);
        } else if (deleteRoleState.error) {
            if (deleteRoleState.error.code === UsedByUsersCode) {
                setShowModalConfirmation(deleteRoleState.error.message);
            } else {
                setError(deleteRoleState.error);
            }
            deleteRoleAction(doDeleteRoleInitial());
        }
    }, [deleteRoleAction, deleteRoleState, history]);
    const loading: boolean = profileState.onRequest || roleDetailState.onRequest;
    const handleDeleteFn = useCallback(() => {
        setShowDeleteConfirmation(true);
    }, []);
    const handleClearFn = useCallback(() => {
        setError(null)
    }, []);
    const roleDetailDesc = [
        {
            label: Text.roleName,
            content: roleDetailData?.name,
        },
        {
            label: Text.permission,
            content: (
                <div>
                    {roleDetailData?.permission_group.map((group, index)=>{
                        return (
                            <div key={`permissionList${index}`}>
                                <span className="permissionItemIndex">{`${index + 1}`}.</span>
                                <span>{group.name} </span>
                                {
                                    group.permissions.length > 0 &&
                                    <span>
                                        {`(`}
                                        {group.permissions.map((item: any)=>{
                                            return item.name
                                        }).join(', ')}
                                        {`)`}
                                    </span>
                                }
                            </div>
                        )
                    })}
                </div>
            )
        },
        {
            label: Text.createdDate,
            content: formatDate(roleDetailData?.created_at),
        },
        {
            label: Text.createdBy,
            content: roleDetailData?.created_by?.name,
        },
        {
            label: Text.updateDate,
            content: formatDate(roleDetailData?.updated_at),
        },
        {
            label: Text.updateBy,
            content: roleDetailData?.updated_by?.name,
        },
    ];
    const handleNavigateToEdit = () => {
        const path = FrontendRoutes.RoleEditPage.path.replace(
            ':roleId',
            roleId,
        );
        history.push(path);
    };
    const breadCrumbsButtons = () => {
        return (
            <div className="breadCrumbsButtonsWrapper">
                {isAllowedEditDetail && (
                    <OvalButton
                        type="primary"
                        buttonText={Text.edit}
                        icon={<img className="ovalButtonImg" src={Images.PencilWhite} alt="edit" />}
                        onClick={() => handleNavigateToEdit()}
                    />
                )}
                {isAllowedDeleteDetail && (
                    <OvalButton
                        type="primary"
                        buttonText={Text.delete}
                        wrapperClassName={'ovalButtonRed'}
                        icon={<img className="ovalButtonImg" src={Images.DeleteWhite} alt="delete" />}
                        onClick={() => handleDeleteFn()}
                    />
                )}
            </div>
        )
    };
    return (
        <div className="roleDetailWrapper">
            {props.customProps?.breadcrumbs && (
                <BreadCrumbsComp
                    crumbs={props.customProps.breadcrumbs}
                >
                    {breadCrumbsButtons()}
                </BreadCrumbsComp>
            )}
            {error && <AlertComponent error={error} clearFn={() => handleClearFn()} />}
            {loading && <GetMethodLoading />}
            {deleteRoleState.onRequest && <LoadingWithMask />}
            <ModalConfirmation
                visible={!!showDeleteConfirmation}
                handleClose={() => {
                    setShowDeleteConfirmation(false);
                }}
                buttonClick={()=>{
                    deleteRoleAction(doDeleteRoleRequest(roleId));
                }}
                message={Text.deleteConfirmation}
                type={TypeDeleteConfirmation}
            />
            <ModalConfirmation
                visible={!!showModalConfirmation}
                handleClose={() => {
                    setShowModalConfirmation('')
                }}
                message={showModalConfirmation}
            />
            {roleDetailData && (
                <Descriptions
                    title={Text.detailsInformation}
                    className={'roleDetailContent'}
                >
                    {roleDetailData && roleDetailDesc.map((roleDetailItem, index) => {
                        return (
                            <Descriptions.Item key={`roleDetailItem${index}`} label={roleDetailItem.label} span={3}>{roleDetailItem.content}</Descriptions.Item>
                        )
                    })}
                </Descriptions>
            )}
        </div>
    );
};

export default RoleDetailView;
