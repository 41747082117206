import {
    ORDER_DETAIL_INITIAL,
    ORDER_DETAIL_REQUEST,
    ORDER_DETAIL_SUCCESS,
    ORDER_DETAIL_FAILURE,
    OrderDetailAction
} from './OrderDetailAction';
import { OrderDetailStoreState } from './OrderDetailState';

export const defaultState: OrderDetailStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function orderDetailReducer(
    state: OrderDetailStoreState = defaultState,
    action: OrderDetailAction
): OrderDetailStoreState {
    switch (action.type) {
        case ORDER_DETAIL_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case ORDER_DETAIL_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case ORDER_DETAIL_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case ORDER_DETAIL_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
