import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Region } from 'data/Models';
import RootStoreState from 'rootStore';
import { SelectMultipleComponent } from 'components/SelectComponent';

const Text = {
    placeholder: 'All Region',
};

interface Props {
    selectedOptions?: string[];
    onLoad: (regions: Region[]) => void;
    onSelect: (regions: Region[]) => void;
}

function RegionInputView(props: Props) {
    const regionListState = useSelector((state: RootStoreState) => state.regionList);
    const [query, setQuery] = useState('');
    const regionList = regionListState.data?.data?.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())) ?? [];
    const [isAlreadyLoaded, setAlreadyLoaded] = useState(false);

    useEffect(() => {
        if (regionListState.data && !isAlreadyLoaded) {
            setAlreadyLoaded(true);
            props.onLoad(regionListState.data?.data);
        }
    }, [regionListState, isAlreadyLoaded, props]);
    
    const onGetOption = (region: Region) => {
        return { value: region.id, label: region.name }
    };

    return (
        <SelectMultipleComponent
            selectProps={{
                placeholder: Text.placeholder,
                showSearch: true,
                onSearch: (query) => setQuery(query),
            }}
            selectedOptions={props.selectedOptions}
            data={regionList}
            onMapOption={onGetOption}
            onSelectedChange={props.onSelect}
        />
    );
}

export default RegionInputView;
