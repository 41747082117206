import React, {Dispatch, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Card, Form} from "antd";
import {Editor as TinyMCEEditor} from "tinymce";
import RootStoreState from "rootStore";
import FrontendScreens from "navigation/Frontend.screens";
import moment from "moment";
import {promoTypeListState} from "common/constans";
import {defaultDateFormatWithoutTime} from "common/CommonUtils";
import {ContractDetail} from "data/Models";
import {PromoFormValue} from "./Commons";
import {serverDateFormat} from "./PromoFormConfig";
import PromoGeneralFormView from "./PromoGeneralFormView";
import PromoSpecialOfferFormView from "./PromoSpecialOfferFormView";
import {
  ContractDetailAction,
  doContractDetailInitial,
  doContractDetailRequest,
} from "features/contract/ContractDetail/ContractDetailAction";
import "./PromoFormView.css";
import {
  ContractListAction,
  doContractListInitial,
} from "features/contract/ContractList/ContractListAction";

interface Props {
  mode: "create" | "edit";
  initialValues: PromoFormValue;
  onError: (error: string) => void;
  onFinish: (values: PromoFormValue) => void;
}

function PromoFormView(props: Props) {
  const history = useHistory();
  const [form] = Form.useForm();
  const [selectedContractID, setSelectedContractID] = useState<string>();

  const contractListAction = useDispatch<Dispatch<ContractListAction>>();
  const contractDetailAction = useDispatch<Dispatch<ContractDetailAction>>();
  const contractDetailState = useSelector(
    (state: RootStoreState) => state.contractDetail
  );
  const [isWillLoadContractDetail, setWillLoadContractDetail] = useState(false);

  const [isinitialLoad, setInitialLoad] = useState<boolean>(true);
  const [isViewRendered, setViewRendered] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>();
  const [source, setSource] = useState<string>();
  const [promoType, setPromoType] = useState<string>();
  const [isSingleContract, setSingleContract] = useState<boolean>();
  const [willPrefillContractDate, setWillPrefillContractDate] =
    useState<boolean>(false);
  const [contracts, setContracts] = useState<ContractDetail[]>([]);
  const [quota, setQuota] = useState<number | undefined>();
  const [imageState, setImageState] = useState<"showImage" | "hideImage">(
    "hideImage"
  );
  const [descriptionId, setDescriptionId] = useState<string>();
  const [descriptionEn, setDescriptionEn] = useState<string>();
  const [tncId, setTncId] = useState<string>();
  const [tncEn, setTncEn] = useState<string>();
  const [queueLoadContracts, setQueueLoadContracts] = useState<
    ContractDetail[]
  >([]);
  const [periodeStart, setPeriodeStart] = useState<any>();
  const [periodeEnd, setPeriodeEnd] = useState<any>();
  const [publishStart, setPublishStart] = useState<any>();
  const [publishEnd, setPublishEnd] = useState<any>();

  const handlePromoTypeSelected = useCallback(
    (option: {label: string; value: string}) => {
      form.resetFields();
      setQuota(1);
      setImageState("hideImage");
      setContracts([]);
      setDescriptionId("");
      setDescriptionEn("");
      setTncId("");
      setTncEn("");
      form.setFieldsValue({
        promoType: option.value,
        quota: quota,
        source: "PRDA",
      });
      setPromoType(option.value);
      setViewRendered(false);
      contractDetailAction(doContractDetailInitial());
    },
    [contractDetailAction, form, quota]
  );
  const handlePromoSource = useCallback(
    (option: {label: string; value: string}) => {
      const newValue = option.value.replace(/[^A-Z0-9]/gi, "").toUpperCase();
      setSource(newValue);
      form.setFieldsValue({source: newValue});
    },
    [form, setSource]
  );
  const handlePromoCodeChange = useCallback(
    (value: string) => {
      const newValue = value.replace(/[^A-Z0-9]/gi, "").toUpperCase();
      setPromoCode(newValue);
      form.setFieldsValue({promoCode: newValue});
    },
    [form, setPromoCode]
  );
  const handleContractLoaded = useCallback(
    (values: string[]) => {
      if (selectedContractID === null) {
        const contractIds = values.map((item) => item);
        form.setFieldsValue({contractId: contractIds});
      }
    },
    [form, selectedContractID]
  );
  const handleContractSelected = useCallback(
    (value: string) => {
      if (isSingleContract) {
        form.setFieldsValue({contractId: value});
        setWillLoadContractDetail(true);
        setWillPrefillContractDate(true);
        setSelectedContractID(value);
        contractDetailAction(doContractDetailRequest(value));
      } else if (
        !isSingleContract &&
        contracts.findIndex((item) => item.id === value) === -1
      ) {
        setWillLoadContractDetail(true);
        setSelectedContractID(value);
        contractDetailAction(doContractDetailRequest(value));
      } else if (
        !isSingleContract &&
        contracts.findIndex((item) => item.id === value) > -1
      ) {
        props.onError("Cannot add the same contract");
      }
    },
    [contracts, contractDetailAction, form, isSingleContract, props]
  );
  const handleEditContract = useCallback(
    (contract: ContractDetail) => {
      const newContracts = contracts.map((item) => {
        if (item.id === contract.id) {
          return contract;
        } else {
          return item;
        }
      });
      setContracts(newContracts);
      form.setFieldsValue({contracts: newContracts});
    },
    [contracts, form]
  );
  const handleDeleteContract = useCallback(
    (contractId: string) => {
      const newContracts = contracts.filter((item) => item.id !== contractId);
      setContracts(newContracts);
      form.setFieldsValue({contracts: newContracts});
    },
    [contracts, form]
  );
  const handleImageError = useCallback(
    (error) => {
      setImageState("hideImage");
      form.setFieldsValue({
        coverImage: {
          error: error,
        },
      });
      form.validateFields(["coverImage"]);
    },
    [form]
  );
  const handleImageChange = useCallback(
    (value) => {
      setImageState("showImage");
      form.setFieldsValue({
        coverImage: {
          image: value,
          from: "local",
        },
      });
    },
    [form]
  );
  const handleInputQuota = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = 0;

      if (quota && quota !== 0 && event.target.value !== "") {
        newValue = quota;
      }

      if (event.target.value.match(/^\d+$/) && event.target.value.length < 7) {
        newValue = Number(event.target.value);
      }

      form.setFieldsValue({quota: newValue});
      setQuota(newValue);
    },
    [form, quota]
  );
  const handleDescriptionIdChange = useCallback(
    (content: string, editor: TinyMCEEditor) => {
      setDescriptionId(content);
      form.setFieldsValue({descriptionId: content});
    },
    [form]
  );
  const handleDescriptionEnChange = useCallback(
    (content: string, editor: TinyMCEEditor) => {
      setDescriptionEn(content);
      form.setFieldsValue({descriptionEn: content});
    },
    [form]
  );
  const handleTncIdChange = useCallback(
    (content: string, editor: TinyMCEEditor) => {
      setTncId(content);
      form.setFieldsValue({tncId: content});
    },
    [form]
  );
  const handleTncEnChange = useCallback(
    (content: string, editor: TinyMCEEditor) => {
      setTncEn(content);
      form.setFieldsValue({tncEn: content});
    },
    [form]
  );
  const isDateEquals = useCallback((value, compare) => {
    return compare && value.toString() === compare.toString();
  }, []);
  const handlePeriodeDateStartFilter = useCallback(
    (d) => {
      let minDate = null;
      if (contractDetailState.data && promoType === promoTypeListState[2].id) {
        minDate = moment(contractDetailState.data?.start_date);
      }

      if (props.mode === "create" && (!minDate || minDate.isBefore(moment()))) {
        const now = moment();
        if (
          !periodeStart ||
          (periodeStart &&
            periodeStart.format(defaultDateFormatWithoutTime) !==
              now.format(defaultDateFormatWithoutTime))
        ) {
          minDate = now.startOf("day");
        } else {
          minDate = now;
        }
      }

      let maxDate = form.getFieldValue("periodeDateEnd");
      if (isSingleContract === true) {
        if (!maxDate) {
          maxDate = moment(contractDetailState.data?.end_date);
        }

        if (
          !periodeStart ||
          (periodeStart &&
            periodeStart.format(defaultDateFormatWithoutTime) !==
              maxDate.format(defaultDateFormatWithoutTime))
        ) {
          maxDate = maxDate.clone().endOf("day");
        }

        return (
          !d ||
          d.isBefore(minDate) ||
          d.isSameOrAfter(maxDate) ||
          d.toString() === maxDate.toString()
        );
      } else {
        return (
          !d ||
          (props.mode === "create" && d.isBefore(minDate)) ||
          (maxDate && d.isSameOrAfter(maxDate)) ||
          isDateEquals(d, maxDate)
        );
      }
    },
    [
      contractDetailState,
      form,
      isDateEquals,
      isSingleContract,
      periodeStart,
      promoType,
      props.mode,
    ]
  );
  const handlePeriodeDateEndFilter = useCallback(
    (d) => {
      let minDate = form.getFieldValue("periodeDateStart");
      if (!minDate) {
        if (props.mode === "create" && promoType === promoTypeListState[0].id) {
          minDate = moment();
        } else if (
          contractDetailState.data &&
          promoType === promoTypeListState[2].id
        ) {
          minDate = moment(contractDetailState.data?.start_date);
        }
      }

      if (props.mode === "create" && minDate && minDate.isBefore(moment())) {
        minDate = moment();
      }

      if (
        minDate &&
        (!periodeEnd ||
          (periodeEnd &&
            periodeEnd.format(defaultDateFormatWithoutTime) !==
              minDate.format(defaultDateFormatWithoutTime)))
      ) {
        minDate = minDate.clone().startOf("day");
      }

      if (isSingleContract === true) {
        let maxDate = moment(contractDetailState.data?.end_date);

        if (
          !periodeEnd ||
          (periodeEnd &&
            periodeEnd.format(defaultDateFormatWithoutTime) !==
              maxDate.format(defaultDateFormatWithoutTime))
        ) {
          maxDate = maxDate.clone().endOf("day");
        }

        return (
          !d ||
          d.isBefore(minDate) ||
          d.isAfter(maxDate) ||
          isDateEquals(d, minDate)
        );
      } else {
        return !d || d.isBefore(minDate) || isDateEquals(d, minDate);
      }
    },
    [
      contractDetailState,
      form,
      isDateEquals,
      isSingleContract,
      periodeEnd,
      props.mode,
      promoType,
    ]
  );
  const handlePublishDateStartFilter = useCallback(
    (d) => {
      let minDate = moment();
      let maxDate = form.getFieldValue("publishDateEnd");

      if (
        !publishStart ||
        (publishStart &&
          publishStart.format(defaultDateFormatWithoutTime) !==
            minDate.format(defaultDateFormatWithoutTime))
      ) {
        minDate = minDate.clone().startOf("day");
      }
      if (
        maxDate &&
        (!publishStart ||
          (publishStart &&
            publishStart.format(defaultDateFormatWithoutTime) !==
              maxDate.format(defaultDateFormatWithoutTime)))
      ) {
        maxDate = maxDate?.clone().endOf("day");
      }

      return (
        !d ||
        (props.mode === "create" && d.isBefore(minDate)) ||
        (maxDate && d.isSameOrAfter(maxDate)) ||
        isDateEquals(d, maxDate)
      );
    },
    [form, isDateEquals, publishStart, props.mode]
  );
  const handlePublishDateEndFilter = useCallback(
    (d) => {
      let minDate = form.getFieldValue("publishDateStart");

      if (props.mode === "create" && !minDate) {
        minDate = moment();
      }

      if (
        minDate &&
        (!publishEnd ||
          (publishEnd &&
            publishEnd.format(defaultDateFormatWithoutTime) !==
              minDate.format(defaultDateFormatWithoutTime)))
      ) {
        minDate = minDate.clone().startOf("day");
      }

      return !d || (minDate && d.isBefore(minDate)) || isDateEquals(d, minDate);
    },
    [form, isDateEquals, props.mode, publishEnd]
  );
  const handleCancel = useCallback(() => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.replace(FrontendScreens.PromoManagementPage.path);
    }
  }, [history]);

  useEffect(() => {
    if (!isViewRendered) {
      switch (promoType) {
        case promoTypeListState[0].id: {
          setSingleContract(false);
          break;
        }
        case promoTypeListState[2].id: {
          setSingleContract(true);
          break;
        }
        default: {
          setSingleContract(true);
          break;
        }
      }
      setViewRendered(true);
    }
  }, [isViewRendered, promoType]);
  useEffect(() => {
    if (isinitialLoad) {
      form.setFieldsValue(props.initialValues);
      setPromoType(props.initialValues.promoType);
      setDescriptionId(props.initialValues.descriptionId);
      setDescriptionEn(props.initialValues.descriptionEn);
      setTncId(props.initialValues.tncId);
      setTncEn(props.initialValues.tncEn);
      setQuota(props.initialValues.quota);
      setPeriodeStart(props.initialValues.publishDateStart);
      setPeriodeEnd(props.initialValues.publishDateEnd);
      setPublishStart(props.initialValues.publishDateStart);
      setPublishEnd(props.initialValues.publishDateEnd);

      contractListAction(doContractListInitial());

      if (props.initialValues.contractId) {
        setSingleContract(true);
        handleContractSelected(props.initialValues.contractId);
      }
      if (props.initialValues.contracts) {
        setSingleContract(false);
        setQueueLoadContracts(props.initialValues.contracts);
      }
      if (props.initialValues.coverImage?.image) {
        setImageState("showImage");
      }

      setInitialLoad(false);
    }
  }, [contractListAction, form, handleContractSelected, isinitialLoad, props]);
  useEffect(() => {
    const {onRequest, data} = contractDetailState;
    if (onRequest) {
      setWillLoadContractDetail(true);
    } else if (data && isWillLoadContractDetail) {
      const testNameId = data.tests
        .map((value, index) => `${index + 1}. ${value.name}\n`)
        .join("");
      const testNameEn = data.tests
        .map((value, index) => `${index + 1}. ${value.name_en}\n`)
        .join("");
      const branches = data.outlets
        .map((value, index) => `${index + 1}. ${value.name}\n`)
        .join("");

      let periodeDateStart = null;
      let periodeDateEnd = null;

      if (willPrefillContractDate) {
        periodeDateStart = moment(data.start_date, serverDateFormat);

        const now = moment();
        if (props.mode === "create" && periodeDateStart.isSameOrBefore(now)) {
          periodeDateStart = now;
        }

        periodeDateEnd = moment(data.end_date, serverDateFormat);
        setWillPrefillContractDate(false);
      } else {
        periodeDateStart = moment(
          props.initialValues.periodeDateStart,
          serverDateFormat
        );
        periodeDateEnd = moment(
          props.initialValues.periodeDateEnd,
          serverDateFormat
        );
      }

      setPeriodeStart(periodeDateStart);
      setPeriodeEnd(periodeDateEnd);

      if (isSingleContract) {
        form.setFieldsValue({
          contractNameId: data.name,
          contractNameEn: data.name_en,
          testNameId: testNameId,
          testNameEn: testNameEn,
          quota: quota,
          discount: `${data.discount}%`,
          branches: branches,
          periodeDateStart: periodeDateStart,
          periodeDateEnd: periodeDateEnd,
        });
      } else {
        if (queueLoadContracts.length > 0) {
          const contract = queueLoadContracts[0];
          data.alias = contract.alias;
          data.alias_en = contract.alias_en;
        }

        const newContracts = [...contracts, data];
        setContracts(newContracts);
        form.setFieldsValue({contracts: newContracts});

        if (queueLoadContracts.length > 0) {
          const arr = queueLoadContracts;
          arr.shift();
          setQueueLoadContracts(arr);
        }
      }
      setWillLoadContractDetail(false);
    }
  }, [
    contractDetailState,
    contracts,
    form,
    isSingleContract,
    isWillLoadContractDetail,
    quota,
    queueLoadContracts,
    props,
    willPrefillContractDate,
  ]);
  useEffect(() => {
    const {onRequest} = contractDetailState;

    if (queueLoadContracts.length > 0 && !onRequest) {
      const id = queueLoadContracts[0].id;
      if (id) {
        handleContractSelected(id);
      }
    }
  }, [contractDetailState, handleContractSelected, queueLoadContracts]);

  return (
    <Card className="promoFormViewWrapper">
      {promoType === promoTypeListState[0].id ? (
        <PromoSpecialOfferFormView
          mode={props.mode}
          form={form}
          initialValues={props.initialValues}
          source={source}
          promoType={promoType}
          contracts={contracts}
          imageState={imageState}
          descriptionId={descriptionId}
          descriptionEn={descriptionEn}
          tncId={tncId}
          tncEn={tncEn}
          handlePromoTypeSelected={handlePromoTypeSelected}
          handlePromoSource={handlePromoSource}
          handleContractLoaded={handleContractLoaded}
          handleContractSelected={handleContractSelected}
          handleImageError={handleImageError}
          handleImageChange={handleImageChange}
          handleEditContract={handleEditContract}
          handleDeleteContract={handleDeleteContract}
          handleDescriptionIdChange={handleDescriptionIdChange}
          handleDescriptionEnChange={handleDescriptionEnChange}
          handleTncIdChange={handleTncIdChange}
          handleTncEnChange={handleTncEnChange}
          handlePeriodeDateStartFilter={handlePeriodeDateStartFilter}
          handlePeriodeDateStartSelect={setPeriodeStart}
          handlePeriodeDateEndFilter={handlePeriodeDateEndFilter}
          handlePeriodeDateEndSelect={setPeriodeEnd}
          handlePublishDateStartFilter={handlePublishDateStartFilter}
          handlePublishDateStartSelect={setPublishStart}
          handlePublishDateEndFilter={handlePublishDateEndFilter}
          handlePublishDateEndSelect={setPublishEnd}
          handleCancel={handleCancel}
          onFinish={props.onFinish}
        />
      ) : promoType === promoTypeListState[2].id ? (
        <PromoGeneralFormView
          mode={props.mode}
          form={form}
          initialValues={props.initialValues}
          promoType={promoType}
          promoCode={promoCode}
          source={source}
          quota={quota}
          imageState={imageState}
          descriptionId={descriptionId}
          descriptionEn={descriptionEn}
          tncId={tncId}
          tncEn={tncEn}
          handlePromoTypeSelected={handlePromoTypeSelected}
          handlePromoSource={handlePromoSource}
          handlePromoCodeChange={handlePromoCodeChange}
          handleContractLoaded={handleContractLoaded}
          handleContractSelected={handleContractSelected}
          handleInputQuota={handleInputQuota}
          handleImageError={handleImageError}
          handleImageChange={handleImageChange}
          handleDescriptionIdChange={handleDescriptionIdChange}
          handleDescriptionEnChange={handleDescriptionEnChange}
          handleTncIdChange={handleTncIdChange}
          handleTncEnChange={handleTncEnChange}
          handlePeriodeDateStartFilter={handlePeriodeDateStartFilter}
          handlePeriodeDateStartSelect={setPeriodeStart}
          handlePeriodeDateEndFilter={handlePeriodeDateEndFilter}
          handlePeriodeDateEndSelect={setPeriodeEnd}
          handlePublishDateStartFilter={handlePublishDateStartFilter}
          handlePublishDateStartSelect={setPublishStart}
          handlePublishDateEndFilter={handlePublishDateEndFilter}
          handlePublishDateEndSelect={setPublishEnd}
          handleCancel={handleCancel}
          onFinish={props.onFinish}
        />
      ) : (
        <div />
      )}
    </Card>
  );
}

export default PromoFormView;
