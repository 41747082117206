import { FrontendRoutes as routes } from './Routes';
const UserManagement = 'User Management';
const HomeServiceManagement = 'Home Service';

export interface CrumbRouteProps {
    path?: string;
    breadcrumbName: string;
}
export const RoleDetailPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: UserManagement,
    },
    {
        breadcrumbName: routes.RoleManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.RoleDetailPage.breadcrumbName,
    },
];
export const RoleCreatePage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: UserManagement,
    },
    {
        breadcrumbName: routes.RoleManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.RoleCreatePage.breadcrumbName,
    },
];
export const RoleEditPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: UserManagement,
    },
    {
        breadcrumbName: routes.RoleManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.RoleEditPage.breadcrumbName,
    },
];
export const AdminCreatePage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: UserManagement,
    },
    {
        breadcrumbName: routes.AdminManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.UserCreatePage.breadcrumbName,
    },
];
export const UserDetailPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: UserManagement,
    },
    {
        breadcrumbName: routes.AdminManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.UserDetailPage.breadcrumbName,
    },
];
export const PanelDetailPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.PanelManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.PanelDetailPage.breadcrumbName,
    },
];
export const PanelEditPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.PanelManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.PanelEditPage.breadcrumbName,
    },
];
export const TestDetailPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.TestManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.TestDetailPage.breadcrumbName,
    },
];
export const TestEditPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.TestManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.TestEditPage.breadcrumbName,
    },
];
export const AdminEditPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: UserManagement,
    },
    {
        breadcrumbName: routes.AdminManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.UserEditPage.breadcrumbName,
    },
];
export const OrderDetailPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.OrderManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.OrderDetailPage.breadcrumbName,
    },
];
export const PromoCreatePage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.PromoManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.PromoCreatePage.breadcrumbName,
    },
];
export const PromoDetailPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.PromoManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.PromoDetailPage.breadcrumbName,
    },
];
export const PromoEditPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.PromoManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.PromoEditPage.breadcrumbName,
    },
];
export const BranchDetailPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.BranchManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.BranchDetailPage.breadcrumbName,
    },
];
export const BranchEditPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: routes.BranchManagement.breadcrumbName,
    },
    {
        breadcrumbName: routes.BranchEditPage.breadcrumbName,
    },
];
export const TNCManagementPage: Array<CrumbRouteProps> = [
    {
        breadcrumbName: HomeServiceManagement,
    },
    {
        breadcrumbName: routes.TNCManagementPage.title,
    },
    {
        breadcrumbName: 'Edit'
    }
];
