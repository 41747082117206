import { RuleObject } from "antd/lib/form";

export const minEmailLen = 5;
export const maxEmailLen = 50;
export const minPasswordLen = 8;
export const maxPasswordLen = 255;
export const emailRules: any[] = [
    () => ({
        validator(rule: RuleObject, value: any) {
            let error: Error | null = null;

            if (!value || value.length === 0) {
                error = new Error('Please input Email Address.');
            } else if (value.length < 5 || value.length > 50) {
                error = new Error('The email length should be within 5 to 50 characters.');
            } else if (!value.match(/\S+@\S+\.\S+/)) {
                error = new Error('Wrong email formats.');
            }

            if (error) {
                return Promise.reject(error);
            }

            return Promise.resolve();
        }
    }),
];
export const pinRules: any[] = [
    {
        required: true,
        message: 'Please input PIN.',
    },
];
export const tokenRules: any[] = [
    {
        required: true,
        message: 'Please input Token.',
    },
];
export const activationFormText = {
    title: 'Hello, Admin.',
    description: 'Please activate your account',
    verifyButton: 'VERIFY',
    tooltips: {
        token: 'Sent to your email',
        pin: 'The PIN comes from your Super Admin',
    },
    tokenExpiredModal: {
        title: 'Activation Link Has Expired',
        description: 'Please contact the site administrator if this problem persists and you can close this page.',
    },
};