import {
    GET_PROFILE_INITIAL,
    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAILURE,
    GetProfileAction,
    LogoutAction,
    LOGOUT_REQUEST,
    LOGOUT_PROCESSING,
    LOGOUT_FINISH,
    LOGOUT_FAILURE,
    LOGOUT_INITIAL
} from './ScaffoldAction';
import { ProfileStoreState, LogoutStoreState } from "./ScaffoldState";

export const defaultGetProfileState: ProfileStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function getProfileReducer(
    state: ProfileStoreState = defaultGetProfileState,
    action: GetProfileAction
): ProfileStoreState {
    switch (action.type) {
        case GET_PROFILE_INITIAL:
            return { ...state, onRequest: defaultGetProfileState.onRequest, data: defaultGetProfileState.data, error: defaultGetProfileState.error };
        case GET_PROFILE_REQUEST:
            return { ...state, onRequest: true, data: defaultGetProfileState.data, error: defaultGetProfileState.error };
        case GET_PROFILE_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultGetProfileState.error };
        case GET_PROFILE_FAILURE:
            return { ...state, onRequest: false, data: defaultGetProfileState.data, error: action.error };
        default:
            return state;
    }
}

export const defaultLogoutState: LogoutStoreState = {
    error: null,
    onLogout: false,
};

export function logoutReducer(
    state: LogoutStoreState = defaultLogoutState,
    action: LogoutAction
): LogoutStoreState {
    switch (action.type) {
        case LOGOUT_INITIAL:
            return { ...state, error: defaultLogoutState.error, onLogout: defaultLogoutState.onLogout };
        case LOGOUT_REQUEST:
            return { ...state, onLogout: defaultLogoutState.onLogout };
        case LOGOUT_PROCESSING:
            return { ...state, onLogout: action.onLogout };
        case LOGOUT_FAILURE:
            return { ...state, onLogout: action.onLogout, error: action.error };
        case LOGOUT_FINISH:
            return { ...state, onLogout: action.onLogout };
        default:
            return state;
    }
}
