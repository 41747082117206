export const statusListState = [
  {
    id: "TOPAY",
    name: "TOPAY",
  },
  {
    id: "PAID",
    name: "PAID",
  },
  {
    id: "PROCESS",
    name: "PROCESS",
  },
  {
    id: "COMPLETE",
    name: "COMPLETE",
  },
  {
    id: "CANCEL",
    name: "CANCEL",
  },
];

export const promoTypeListState = [
  {
    id: "special_promo.special_offer",
    name: "Special Offer",
  },
  {
    id: "special_promo.flash_sale",
    name: "Flash Sale",
  },
  {
    id: "promo_code.general_code",
    name: "Promo Code General",
  },
];

export const promoStatusListState = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "active",
    name: "Active",
  },
  {
    id: "inactive",
    name: "Inactive",
  },
];

export const promoSourceListState = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "prda",
    name: "PRDA",
  },
  {
    id: "prdi",
    name: "PRDI",
  },
];

export const availibilityHSListState = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "true",
    name: "Yes",
  },
  {
    id: "false",
    name: "No",
  },
];

export const serviceTypeListState = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "hs",
    name: "Home Service",
  },
  {
    id: "standard",
    name: "Visit Branch",
  },
];

export const promoDateSortState = [
  {
    id: "ASC",
    name: "A-Z",
  },
  {
    id: "DESC",
    name: "Z-A",
  },
];

export const generalSortState = [
  {
    id: "ASC",
    name: "Newest",
  },
  {
    id: "DESC",
    name: "Oldest",
  },
];
