import {
    CONTRACT_DETAIL_INITIAL,
    CONTRACT_DETAIL_REQUEST,
    CONTRACT_DETAIL_SUCCESS,
    CONTRACT_DETAIL_FAILURE,
    ContractDetailAction
} from './ContractDetailAction';
import { ContractDetailStoreState } from './ContractDetailState';

export const defaultState: ContractDetailStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function contractDetailReducer(
    state: ContractDetailStoreState = defaultState,
    action: ContractDetailAction
): ContractDetailStoreState {
    switch (action.type) {
        case CONTRACT_DETAIL_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case CONTRACT_DETAIL_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case CONTRACT_DETAIL_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case CONTRACT_DETAIL_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
