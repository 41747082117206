import { HttpError, UserList } from 'data/Models';
import { UserListQuery } from 'common/States';

export const ADMIN_MANAGEMENT_INITIAL = 'ADMIN_MANAGEMENT_INITIAL';
export type ADMIN_MANAGEMENT_INITIAL = typeof ADMIN_MANAGEMENT_INITIAL;

export const ADMIN_MANAGEMENT_REQUEST = 'ADMIN_MANAGEMENT_REQUEST';
export type ADMIN_MANAGEMENT_REQUEST = typeof ADMIN_MANAGEMENT_REQUEST;

export const ADMIN_MANAGEMENT_SUCCESS = 'ADMIN_MANAGEMENT_SUCCESS';
export type ADMIN_MANAGEMENT_SUCCESS = typeof ADMIN_MANAGEMENT_SUCCESS;

export const ADMIN_MANAGEMENT_FAILURE = 'ADMIN_MANAGEMENT_FAILURE';
export type ADMIN_MANAGEMENT_FAILURE = typeof ADMIN_MANAGEMENT_FAILURE;

interface AdminManagementInitial {
    type: ADMIN_MANAGEMENT_INITIAL;
}

export interface AdminManagementRequest {
    type: ADMIN_MANAGEMENT_REQUEST;
    filterParams: UserListQuery;
}

interface AdminManagementSuccess {
    type: ADMIN_MANAGEMENT_SUCCESS;
    data: UserList;
}

interface AdminManagementFailure {
    type: ADMIN_MANAGEMENT_FAILURE;
    error: HttpError;
}

export type AdminManagementAction = AdminManagementInitial | AdminManagementRequest | AdminManagementSuccess | AdminManagementFailure;

export function doAdminManagementInitial(): AdminManagementInitial {
    return {
        type: ADMIN_MANAGEMENT_INITIAL
    };
}

export function doAdminManagementRequest(filterParams : UserListQuery): AdminManagementRequest {
    return {
        type: ADMIN_MANAGEMENT_REQUEST,
        filterParams: filterParams
    };
}

export function doAdminManagementSuccess(data: UserList): AdminManagementSuccess {
    return {
        type: ADMIN_MANAGEMENT_SUCCESS,
        data: data
    };
}

export function doAdminManagementFailure(error: HttpError): AdminManagementFailure {
    return {
        type: ADMIN_MANAGEMENT_FAILURE,
        error: error
    };
}
