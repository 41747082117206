import {
    ORDER_MANAGEMENT_DOWNLOAD_INITIAL,
    ORDER_MANAGEMENT_DOWNLOAD_REQUEST,
    ORDER_MANAGEMENT_DOWNLOAD_SUCCESS,
    ORDER_MANAGEMENT_DOWNLOAD_FAILURE,
    OrderManagementDownloadAction
} from './OrderManagementDownloadAction';
import { OrderListDownloadStoreState } from './OrderManagementDownloadState';

export const defaultState: OrderListDownloadStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function orderManagementDownloadReducer(
    state: OrderListDownloadStoreState = defaultState,
    action: OrderManagementDownloadAction
): OrderListDownloadStoreState {
    switch (action.type) {
        case ORDER_MANAGEMENT_DOWNLOAD_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case ORDER_MANAGEMENT_DOWNLOAD_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case ORDER_MANAGEMENT_DOWNLOAD_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case ORDER_MANAGEMENT_DOWNLOAD_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
