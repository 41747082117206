import { call, put, takeLatest } from 'redux-saga/effects';
import TestApi from 'data/api/TestApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './TestDetailAction';

function* sagaTask(sagaAction: action.TestDetailRequest) {
    const { testId } = sagaAction;
    const response = yield call(TestApi.getTestDetail, testId);

    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doTestDetailSuccess(response.data.testDetail));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doTestDetailRequest(testId) });
            yield put(action.doTestDetailFailure(newTimeoutError));
        } else {
            yield put(action.doTestDetailFailure(response.error));
        }
    }
}

export function* testDetailSaga() {
    yield takeLatest(
        action.TEST_DETAIL_REQUEST,
        sagaTask,
    );
}
