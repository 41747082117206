import { HttpError, PanelFormData } from "data/Models";

export const UPDATE_PANEL_INITIAL = 'UPDATE_PANEL_INITIAL';
export type UPDATE_PANEL_INITIAL = typeof UPDATE_PANEL_INITIAL;

export const UPDATE_PANEL_REQUEST = 'UPDATE_PANEL_REQUEST';
export type UPDATE_PANEL_REQUEST = typeof UPDATE_PANEL_REQUEST;

export const UPDATE_PANEL_SUCCESS = 'UPDATE_PANEL_SUCCESS';
export type UPDATE_PANEL_SUCCESS = typeof UPDATE_PANEL_SUCCESS;

export const UPDATE_PANEL_FAILURE = 'UPDATE_PANEL_FAILURE';
export type UPDATE_PANEL_FAILURE = typeof UPDATE_PANEL_FAILURE;

interface UpdatePanelInitial {
    type: UPDATE_PANEL_INITIAL;
}

export interface UpdatePanelRequest {
    type: UPDATE_PANEL_REQUEST;
    updateData: PanelFormData;
}

interface UpdatePanelSuccess {
    type: UPDATE_PANEL_SUCCESS;
}

interface UpdatePanelFailure {
    type: UPDATE_PANEL_FAILURE;
    error: HttpError;
}

export type UpdatePanelAction = UpdatePanelInitial | UpdatePanelRequest | UpdatePanelSuccess | UpdatePanelFailure;

export function doUpdatePanelInitial(): UpdatePanelInitial {
    return {
        type: UPDATE_PANEL_INITIAL,
    };
}

export function doUpdatePanelRequest(updateData: PanelFormData): UpdatePanelRequest {
    return {
        type: UPDATE_PANEL_REQUEST,
        updateData: updateData,
    };
}

export function doUpdatePanelSuccess(): UpdatePanelSuccess {
    return {
        type: UPDATE_PANEL_SUCCESS,
    };
}

export function doUpdatePanelFailure(error: HttpError): UpdatePanelFailure {
    return {
        type: UPDATE_PANEL_FAILURE,
        error: error,
    };
}
