import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Region } from 'data/Models';
import RootStoreState from 'rootStore';
import { doRegionListRequest, RegionListAction } from 'features/region/RegionAction';
import { SelectFilterComponent } from 'components/SelectComponent';

const Text = {
    label: 'Filter by Region',
    searchPlaceholder: 'Search Region'
};

interface Props {
    onSelect: (regions: Region[]) => void;
}

function RegionFilterView(props: Props) {
    const regionListAction = useDispatch<Dispatch<RegionListAction>>();
    const regionListState = useSelector((state: RootStoreState) => state.regionList);
    const [query, setQuery] = useState('');
    const regionList = regionListState.data?.data?.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())) ?? [];

    useEffect(() => {
        regionListAction(doRegionListRequest());
    }, [regionListAction]);
    
    const onGetOption = (region: Region) => {
        return { value: region.id, label: region.name }
    };

    return (
        <SelectFilterComponent
            label={Text.label}
            searchPlaceholder={Text.searchPlaceholder}
            onSearch={(query) => setQuery(query)}
            data={regionList}
            onMapOption={onGetOption}
            onSelectedChange={props.onSelect}
        />
    );
}

export default RegionFilterView;
