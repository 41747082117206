import { DefaultStoreState } from 'common/States';
import {
    CREATE_ROLE_INITIAL,
    CREATE_ROLE_REQUEST,
    CREATE_ROLE_SUCCESS,
    CREATE_ROLE_FAILURE,
    CreateRoleAction,
} from './RoleCreateAction';

export const defaultState: DefaultStoreState = {
    onRequest: false,
    success: false,
    error: null,
}

export function createRoleReducer(
    state: DefaultStoreState = defaultState,
    action: CreateRoleAction
): DefaultStoreState {
    switch (action.type) {
        case CREATE_ROLE_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, success: defaultState.success, error: defaultState.error };
        case CREATE_ROLE_REQUEST:
            return { ...state, onRequest: true, success: defaultState.success, error: defaultState.error };
        case CREATE_ROLE_SUCCESS:
            return { ...state, onRequest: false, success: true, error: defaultState.error };
        case CREATE_ROLE_FAILURE:
            return { ...state, onRequest: false, success: false, error: action.error };
        default:
            return state;
    }
}
