import React, { useCallback, useState } from 'react';
import { SelectComponent, SelectFilterComponent } from 'components/SelectComponent';
import * as config from './config';
import { Images } from 'themes';

interface ItemOption {
    id: string;
    name: string;
}

interface Props {
    onSingleSelect?: (values: ItemOption) => void;
    onMultipleSelect?: (values: ItemOption[]) => void;
}

const Text = {
    label: 'Filter by Service Type',
    searchPlaceholder: 'Search Service Type'
};

function ServiceTypeFilterView(props: Props) {

    let newListState = config.serviceTypeListState;
    let newLabel = Text.label;

    const [query, setQuery] = useState('');
    const handleGetOption = useCallback((type: ItemOption) => {
        return { value: type.id, label: type.name }
    }, []);

    const serviceTypeList = newListState.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())) ?? [];

    return <SingleSelectFilterView
        label={newLabel}
        statusList={serviceTypeList}
        onGetOption={handleGetOption}
        onSelect={(value) => {
            if (props.onSingleSelect) {
                props.onSingleSelect(value);
            }
        }}
    />
}

interface SingleSelectFilterViewProps {
    label: string;
    statusList: ItemOption[]
    onGetOption: (option: ItemOption) => { value: string; label: string };
    onSelect: (values: ItemOption) => void;
}

function SingleSelectFilterView(props: SingleSelectFilterViewProps) {
    const [isVisible, setDropdownVisible] = useState<boolean>(false);

    return (
        <SelectComponent
            selectProps={{
                placeholder: props.label,
                bordered: false,
                onDropdownVisibleChange: (open) => {
                    setDropdownVisible(open)
                },
                suffixIcon: () => {
                    return <img src={isVisible ? Images.ChevronUpGrey : Images.ChevronDownGrey} alt="Arrow" />
                }
            }}
            data={props.statusList}
            onMapOption={props.onGetOption}
            onSelectedChange={props.onSelect}
            prefixLabel={`${props.label}: `}
            className={'statusFilterView'}
            optionClassName={'selectOptionWrapper'}
        />
    );
}

export default ServiceTypeFilterView;
