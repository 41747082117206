import React, { useEffect, useState } from 'react';
import { Checkbox, Select, Tag } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';
import './index.css';
import { Images } from 'themes';

const { Option } = Select;

const Text = {
    SelectAll: "Select All"
}

interface Props<T> {
    selectedOptions?: string[];
    data: T[];
    onMapOption: (item: T) => { value: string | number, label: string };
    onSelectedChange: (selecteds: T[]) => void;
    selectProps?: SelectProps<SelectValue>;
}

function SelectMultipleComponent<T>(props: Props<T>) {
    const [selectedOptions, setSelectedOptions] = useState<T[]>([]);

    useEffect(() => {
        if (props.selectedOptions) {
            const selecteds = props.data.filter((item) => props.selectedOptions ? props.selectedOptions.includes(props.onMapOption(item).value as string) : false);
            setSelectedOptions(selecteds);
        }
    }, [setSelectedOptions, props]);

    const onOptionChecked = (isChecked: boolean, value: T) => {
        let newValues: T[] = []
        if (isChecked) {
            newValues = [...selectedOptions, ...[value]];
        } else {
            newValues = selectedOptions.filter((filtered) => filtered !== value);
        }
        setSelectedOptions(newValues);
        props.onSelectedChange(newValues);
    };
    const options = props.data.map((item) => {
        const { value, label } = props.onMapOption(item);
        return (
            <Option className="selectOptionWrapper" key={value} value={value} label={label}>
                <Checkbox
                    checked={selectedOptions.includes(item)}
                    onChange={(event) => onOptionChecked(event.target.checked, item)}
                >
                    {label}
                </Checkbox>
            </Option>
        );
    });

    const onOptionSelectAll = (isChecked: boolean) => {
        if (isChecked) {
            setSelectedOptions(props.data);
            props.onSelectedChange(props.data);
        } else {
            setSelectedOptions([]);
            props.onSelectedChange([]);
        }
    };
    const selectAllOption = (
        <Option className="selectOptionWrapper" key={"*"} value={"*"} label={Text.SelectAll}>
            <Checkbox
                checked={selectedOptions.length === props.data.length}
                onChange={(event) => onOptionSelectAll(event.target.checked)}
            >
                {Text.SelectAll}
            </Checkbox>
        </Option>
    );

    const onTagClose = (value: string | number) => {
        const newValues = selectedOptions.filter((filtered) => props.onMapOption(filtered).value !== value);
        setSelectedOptions(newValues);
        props.onSelectedChange(newValues);
    };
    const tag = (tagProps: any): React.ReactElement => {
        const { label, value } = tagProps;
        return (
            <Tag
                closable={true}
                onClose={() => onTagClose(value)}
                closeIcon={<img src={Images.Cancel} alt="Cancel" />}
            >
                {label}
            </Tag>
        );
    };

    return (
        <Select
            {...props.selectProps}
            className="selectWrapper"
            mode="multiple"
            showArrow={true}
            optionLabelProp="label"
            tagRender={tag}
            value={selectedOptions.map((item) => props.onMapOption(item).value)}
        >
            {selectAllOption}
            {options}
        </Select>
    );
};

export default SelectMultipleComponent;
