export const conntainer = {
    display: 'flex',
    alignItems: 'center',
};

export const pageTitle = {
    flex: 1,
    fontSize: 20,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.81,
    color: '#495057',
};

export const username = {
    fontSize: 13,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.53,
    color: '#555b6d',
    padding: '0 0 0 9px',
};

export const menuItem = {
    fontSize: 13,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
};

export const profileItem = {
    borderBottom: '1px solid #dde1e8',
    padding: '20px 26px'
};

export const profileTitle = {
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.65,
    color: '#495057',
    marginBottom: 32,
};

export const profileLabel = {
    fontSize: 13,
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.53,
    color: '#495057',
};

export const profileValue = {
    fontSize: 13,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.53,
    color: '#495057',
};

export const profileFooter = {
    display: 'flex',
    marginTop: 35,
};

export const profileButton = {
    borderRadius: 3,
    width: 150,
};

export const profileButtonText = {
    fontSize: 13,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
};

export const listItemIndex = {
    marginRight: 15,
};
