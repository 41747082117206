import {
    ORDER_MANAGEMENT_INITIAL,
    ORDER_MANAGEMENT_REQUEST,
    ORDER_MANAGEMENT_SUCCESS,
    ORDER_MANAGEMENT_FAILURE,
    OrderManagementAction
} from './OrderManagementAction';
import { OrderListStoreState } from './OrderManagementState';

export const defaultState: OrderListStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function orderManagementReducer(
    state: OrderListStoreState = defaultState,
    action: OrderManagementAction
): OrderListStoreState {
    switch (action.type) {
        case ORDER_MANAGEMENT_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case ORDER_MANAGEMENT_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case ORDER_MANAGEMENT_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case ORDER_MANAGEMENT_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
