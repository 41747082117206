import { UserFormData, HttpError } from "data/Models";

export const CREATE_ADMIN_INITIAL = 'CREATE_ADMIN_INITIAL';
export type CREATE_ADMIN_INITIAL = typeof CREATE_ADMIN_INITIAL;

export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST';
export type CREATE_ADMIN_REQUEST = typeof CREATE_ADMIN_REQUEST;

export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export type CREATE_ADMIN_SUCCESS = typeof CREATE_ADMIN_SUCCESS;

export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';
export type CREATE_ADMIN_FAILURE = typeof CREATE_ADMIN_FAILURE;

interface CreateAdminInitial {
    type: CREATE_ADMIN_INITIAL;
}

export interface CreateAdminRequest {
    type: CREATE_ADMIN_REQUEST;
    newData: UserFormData;
}

interface CreateAdminSuccess {
    type: CREATE_ADMIN_SUCCESS;
}

interface CreateAdminFailure {
    type: CREATE_ADMIN_FAILURE;
    error: HttpError;
}

export type CreateAdminAction = CreateAdminInitial | CreateAdminRequest | CreateAdminSuccess | CreateAdminFailure;

export function doCreateAdminInitial(): CreateAdminInitial {
    return {
        type: CREATE_ADMIN_INITIAL,
    };
}

export function doCreateAdminRequest(newData: UserFormData): CreateAdminRequest {
    return {
        type: CREATE_ADMIN_REQUEST,
        newData: newData,
    };
}

export function doCreateAdminSuccess(): CreateAdminSuccess {
    return {
        type: CREATE_ADMIN_SUCCESS,
    };
}

export function doCreateAdminFailure(error: HttpError): CreateAdminFailure {
    return {
        type: CREATE_ADMIN_FAILURE,
        error: error,
    };
}
