import {
    PAYMENT_CHANNEL_LIST_INITIAL,
    PAYMENT_CHANNEL_LIST_REQUEST,
    PAYMENT_CHANNEL_LIST_SUCCESS,
    PAYMENT_CHANNEL_LIST_FAILURE,
    PaymentChannelListAction
} from './PaymentChannelAction';
import { PaymentChannelListStoreState } from './PaymentChannelState';

export const defaultState: PaymentChannelListStoreState = {
    onRequest: false,
    data: null,
    error: null
};

export function paymentChannelListReducer(
    state: PaymentChannelListStoreState = defaultState,
    action: PaymentChannelListAction
): PaymentChannelListStoreState {
    switch (action.type) {
        case PAYMENT_CHANNEL_LIST_INITIAL:
            return { ...state, onRequest: defaultState.onRequest, data: defaultState.data, error: defaultState.error };
        case PAYMENT_CHANNEL_LIST_REQUEST:
            return { ...state, onRequest: true, data: defaultState.data, error: defaultState.error };
        case PAYMENT_CHANNEL_LIST_SUCCESS:
            return { ...state, onRequest: false, data: action.data, error: defaultState.error };
        case PAYMENT_CHANNEL_LIST_FAILURE:
            return { ...state, onRequest: false, data: defaultState.data, error: action.error };
        default:
            return state;
    }
}
