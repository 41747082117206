import React, {useEffect, useState} from 'react';
import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';
import './index.css';

const { Option } = Select;

interface Props<T> {
    data: T[];
    onMapOption: (item: T) => { value: string | number, label: string };
    onSelectedChange: (value: SelectValue) => void;
    selectProps?: SelectProps<SelectValue>;
    clearSelectValue: boolean;
}

function SelectOneAndSearchComponent<T>(props: Props<T>) {
    const [labeledValue, setLabeledValue] = useState<SelectValue | undefined>(undefined);
    useEffect(() => {
        if (props.clearSelectValue) {
            setLabeledValue(undefined);
        }
    }, [props]);
    const handleChange = (value: SelectValue) => {
        setLabeledValue(value);
        props.onSelectedChange(value);
    };
    const options = props.data.map((item) => {
        const { value, label } = props.onMapOption(item);
        return (
            <Option className="selectOptionWrapper" key={value} value={value} label={label}>
                {label}
            </Option>
        );
    });
    return (
        <Select
            {...props.selectProps}
            showSearch
            style={{ width: 200 }}
            optionFilterProp="children"
            onChange={handleChange}
            filterOption={(input, option) => {
                return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            value={labeledValue}
        >
            {options}
        </Select>
    );
};

export default SelectOneAndSearchComponent;
