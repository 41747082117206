import { call, put, takeLatest } from 'redux-saga/effects';
import PanelApi from 'data/api/PanelApi';
import { AbortError } from 'common/HttpCode';
import * as http from 'common/HttpUtils';
import * as action from './PanelDetailAction';

function* panelDetailTask(panelDetailAction: action.PanelDetailRequest) {
    const { panelId } = panelDetailAction;
    const response = yield call(PanelApi.getPanelDetail, panelId);
    if (response.status === http.HTTP_RESPONSE_SUCCESS && response.data) {
        yield put(action.doPanelDetailSuccess(response.data.panelDetail));
    } else if (response.status === http.HTTP_RESPONSE_FAILURE) {
        if (response.error.code === AbortError) {
            const newTimeoutError = Object.assign({}, response.error, { action: () => action.doPanelDetailRequest(panelId) });
            yield put(action.doPanelDetailFailure(newTimeoutError));
        } else {
            yield put(action.doPanelDetailFailure(response.error));
        }
    }
}
export function* panelDetailSaga() {
    yield takeLatest(
        action.PANEL_DETAIL_REQUEST,
        panelDetailTask,
    );
}
